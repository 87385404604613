export const JOBS = 'JOBS';
export const COURSES = 'COURSES';
export const REST = 'ALL CONTENTS';

export const SSO = 'SSO';
export const CLONE = 'CLONE';
export const EMAIL_TEMPLATE = 'EMAIL_TEMPLATE';
export const POLICY_SETTINGS = 'POLICY_SETTINGS';
export const API_CREDENTIALS = 'API_CREDENTIALS';
export const PAYMENT_SETTINGS = 'PAYMENT_SETTINGS';
export const REGISTER_SETTINGS = 'REGISTER_SETTINGS';
export const ANALYTICS_SETTINGS = 'ANALYTICS_SETTINGS';
export const RECURITER_SETTINGS = 'RECURITER_SETTINGS';
export const CREATE_EDIT_CLIENT = 'CREATE_EDIT_CLIENT';

export const UPLOADING = 'UPLOADING';
export const SAVING = 'SAVING';
export const FETCHING = 'FETCHING';
export const ACTIVITIES = 'ACTIVITIES';
export const GAMIFICATION = 'GAMIFICATION';

export const GAMIFICATION_INPUT_NAMES = {
  activities: 'activities',
  components: 'components',
  accolades: 'accolades',
  recommendations: 'recommendations',
};
