import React, { Component } from 'react';

import { Row, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Pagination from 'components/Pagination';
import { getClient, clearClient } from 'actions/clientActions';
import ViewMore from 'components/CreditLogs/ViewMore';
import Filter from 'components/RegistrationLog/LogFilter';
import API from 'API';
import { getFromISODate, getToISODate } from '../../utils/helper';

class CreditLogs extends Component {
  statusValues = ['Deleted', 'Active'];
  actionValues = { subscribe: 'Registration', resubscribe: 'Resubscription' };
  state = {
    isError: false,
    currentPage: 1,
    pageLimit: 10,
    searchKey: '',
    actionType: '',
    fromDate: '',
    toDate: '',
    logId: '',
    showViewMoreModal: false,
    creditLogsFetched: [],
    totalCreditLogs: 0,
    totalLogPage: 0,
    isLogLoading: false,
  };

  componentDidMount() {
    // this.props.getClient(this.props.match.params.id);
    console.log(this.props.clients, this.props.match.params.id);
    this.fetchCreditLogs();
  }

  componentWillUnmount() {
    // this.props.clearClient();
  }

  fetchCreditLogs = async () => {
    try {
      this.setState({ isLogLoading: true });
      const { currentPage, pageLimit, searchKey, actionType, fromDate, toDate } = this.state;

      const payload = {
        currentPage,
        pageLimit,
        searchKey,
        actionType,
        fromDate: getFromISODate(fromDate),
        toDate: getToISODate(toDate),
        subdomain: this.props.match.params.subdomain,
        clientId: this.props.match.params.id,
      };

      const result = await new API().fetchCreditLogs(payload);
      this.setState({
        creditLogsFetched: result.creditLogs,
        totalCreditLogs: result.totalCount,
        totalLogPage: Math.ceil(result.totalCount / pageLimit),
      });
    } catch (e) {
      console.log(e);
    }
    this.setState({ isLogLoading: false });
  };

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({ currentPage: index }, () => {
      this.fetchCreditLogs();
    });
  };

  handleDateChange = (moment, name) => {
    this.setState({ [name]: '' });
    if (moment && typeof moment !== 'object') {
      return;
    }
    if (moment) {
      const value = moment.format('YYYY-MM-DD');
      this.setState({ [name]: value });
    }
  };

  handleFilterChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleFilter = () => {
    this.setState({ currentPage: 1 }, () => {
      this.fetchCreditLogs();
    });
  };

  formatDate(date) {
    return new Date(date).toLocaleDateString('en-UK', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  showCurrencySign(currency) {
    if (currency === 'usd') return '$';
    if (currency === 'eur') return '€';
    if (currency === 'gbp') return '£';
    if (currency === 'aud') return 'A$';
    return '$';
  }

  toggleViewMore = (id, e) => {
    this.setState(prevState => ({
      showViewMoreModal: !prevState.showViewMoreModal,
      logId: id,
    }));
  };

  render() {
    const {
      currentPage,
      pageLimit,
      showViewMoreModal,
      logId,
      creditLogsFetched,
      isLogLoading,
      totalCreditLogs,
      totalLogPage,
      fromDate,
      toDate,
    } = this.state;
    // const { client } = this.props.clients;

    const subdomain = this.props.match.params.subdomain;

    return (
      <>
        <div className="content">
          <div className="title">
            <span>List of</span>
            <h1>Credit Logs - {subdomain}</h1>
          </div>

          {/* <Filter
            handleDateChange={this.handleDateChange}
            handleFilterChange={this.handleFilterChange}
            handleFilter={this.handleFilter}
            fromDate={fromDate}
            toDate={toDate}
          /> */}
          {isLogLoading ? (
            <span className="loading">
              <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...
            </span>
          ) : (
            <>
              {!creditLogsFetched || !creditLogsFetched.length ? (
                <h2>No Credit logs to display</h2>
              ) : (
                <Row>
                  <Col md="12">
                    <Table className="base-table clients-table" responsive>
                      <thead>
                        <tr>
                          <th>S.N</th>
                          <th>Sub Domain</th>
                          <th>User Id</th>
                          <th>Action Type</th>
                          <th>Action By </th>
                          <th>Action At </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {creditLogsFetched.map((creditLog, index) => {
                          return (
                            <tr key={creditLog._id}>
                              <td>{currentPage * pageLimit - pageLimit + (index + 1)}</td>
                              <td> {creditLog.subdomain} </td>
                              <td>{creditLog.userId}</td>
                              <td>{creditLog.actionType}</td>
                              <td> {creditLog.actionBy} </td>
                              <td>{this.formatDate(creditLog.createdAt)}</td>

                              <td>
                                <ul>
                                  <li
                                    className="btn-setting"
                                    onClick={this.toggleViewMore.bind(this, creditLog._id)}
                                  >
                                    {' '}
                                    View{' '}
                                  </li>
                                </ul>
                                {showViewMoreModal && creditLog._id === logId ? (
                                  <ViewMore
                                    toggleViewMore={this.toggleViewMore}
                                    data={creditLog}
                                    showViewMoreModal={showViewMoreModal}
                                  />
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Pagination
                      total={totalCreditLogs}
                      currentPage={currentPage}
                      totalPage={totalLogPage}
                      pageLimit={pageLimit}
                      handleClick={this.handlePagination}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

CreditLogs.propTypes = {
  getClient: PropTypes.func.isRequired,
  clearClient: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  clients: state.client,
});

export default connect(mapStateToProps, {
  getClient,
  clearClient,
})(CreditLogs);
