import React from 'react';
import { Card, CardBody, CardTitle, Row, Col, CardFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-labels';

const data = {
  labels: [23, 37, 30, 23, 29, 18, 31, 17],
  datasets: [
    {
      data: [23, 37, 30, 23, 29, 18, 31, 17],
      borderWidth: 0,
      backgroundColor: [
        '#820D61',
        '#DA5003',
        '#C4C4C4',
        '#000F61',
        '#196100',
        '#222222',
        '#00C4B8',
        '#D20000',
      ],
      hoverBackgroundColor: [
        '#820D61',
        '#DA5003',
        '#C4C4C4',
        '#000F61',
        '#196100',
        '#222222',
        '#00C4B8',
        '#D20000',
      ],
    },
  ],
  text: '503',
  text2: 'Total Clients',
};

const options = {
  tooltips: {
    enabled: false,
  },
  plugins: {
    labels: {
      // identifies whether or not labels of value 0 are displayed, default is false
      showZero: true,
      fontSize: 14,
      fontColor: '#fff',
      fontStyle: 'normal',
      fontFamily: 'Nunito',
      position: 'border',

      // draw label even it's overlap, default is true
      overlap: true,

      // show the real calculated percentages from the values and don't apply the additional logic to fit the percentages to 100 in total, default is false
      showActualPercentages: true,
    },
  },
  legend: {
    display: true,
    position: 'right',
    align: 'start',
    labels: {
      usePointStyle: true,
      // fontSize: 16,
      fontColor: '#343434',
      padding: 20,
      generateLabels: function (chart) {
        var data = chart.data;
        // if (data.labels.length && data.datasets.length) {
        return data.labels.map(function (label, i) {
          let meta = chart.getDatasetMeta(0);
          let style = meta.controller.getStyle(i);
          // let showText = pieChartLabel[i];

          return {
            text: label + '\n online',
            fillStyle: style.backgroundColor,
            strokeStyle: style.borderColor,
            lineWidth: style.borderWidth,
            hidden: isNaN(data.datasets[0].data[i]) || meta.data[i].hidden,

            // Extra data used for toggling the correct item
            index: i,
          };
        });
        // }
        // return [];
      },
    },
  },
};

function PieChart() {
  return (
    <Col md="6">
      <Card className="piechart">
        <div className="card-head">
          <CardTitle>Total Courses</CardTitle>
          <Link to="/dashboard" className="card-link">
            <i className="icon icon-right-align" />
            View all
          </Link>
        </div>
        <CardBody>
          <div className="barchart-chart pie-chart">
            <Pie data={data} options={options} />
          </div>
        </CardBody>
        <CardFooter>
          <Row>
            <Col md="12" className="chart-footer">
              <span className="name">Total number of courses</span>
              <span className="count color-gray">223</span>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>
  );
}

export default PieChart;
