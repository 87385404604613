import { UPLOAD_DATA, UPLOAD_LOADING, CLEAR_UPLOAD_URL } from 'actions/types';

const initialState = {
  url: '',
  name: '',
  isUploading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_LOADING: {
      return {
        ...state,
        isUploading: true,
      };
    }

    case UPLOAD_DATA:
      return {
        ...state,
        url: action.payload.imageLocation,
        name: action.payload.imageName,
        isUploading: !state.isUploading,
      };

    case CLEAR_UPLOAD_URL:
      return {
        ...state,
        url: '',
        name: '',
      };

    default:
      return state;
  }
}
