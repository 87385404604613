import React from 'react';
import { Card, CardBody, CardTitle, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';

const data = {
  labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  datasets: [
    {
      data: [20, 15, 10, 25, 5, 30, 10],
      backgroundColor: [
        '#818181',
        '#818181',
        '#818181',
        '#818181',
        '#818181',
        '#1e1e1e',
        '#818181',
      ],
      hoverBackgroundColor: [
        '#818181',
        '#818181',
        '#818181',
        '#818181',
        '#818181',
        '#1e1e1e',
        '#818181',
      ],
    },
  ],
};

const scales = {
  yAxes: [
    {
      ticks: {
        beginAtZero: true,
      },
    },
  ],
};

function BarChart() {
  return (
    <Col md="6">
      <Card className="barchart">
        <div className="card-head">
          <CardTitle>
            Login Report<span>(Dialy/weekly/monthly)</span>
          </CardTitle>
          <Link to="/dashboard" className="card-link">
            <i className="icon icon-right-align" />
            View all
          </Link>
        </div>
        <CardBody>
          <div className="barchart-chart">
            <Bar
              data={data}
              options={{
                title: {
                  display: true,
                  text: 'December 2019',
                  position: 'bottom',
                },
                maintainAspectRatio: false,
                legend: false,
                scales,
                plugins: {
                  labels: false,
                },
              }}
            />
          </div>
          <div className="barchart-right">
            <div className="block">
              <span className="name">Total weekly login</span>
              <span className="count text-gray">32,106</span>
            </div>
            <div className="block">
              <span className="name">Total monthly login</span>
              <span className="count text-gray">72,035</span>
            </div>
            <div className="block">
              <span className="name">Total login</span>
              <span className="count text-gray">650,000</span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default BarChart;
