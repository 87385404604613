import React from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';

const Filter = props => {
  const { handleFilter, handleFilterChange } = props;

  return (
    <Form
      inline
      className="log-form mt-2 mb-4"
      onSubmit={e => e.preventDefault()}
    >
      <Row form className="width-100">
        <Col md={3}>
          <FormGroup>
            <Input
              type="text"
              name="searchKey"
              id="searchKey"
              placeholder="Name, Email or User Id"
              required
              onChange={handleFilterChange}
            />
          </FormGroup>
        </Col>

        <FormGroup>
          <Label for="filter"></Label>
          <Button
            id="search"
            className="btn btn-add"
            onClick={e => {
              handleFilter(e);
            }}
          >
            <i className="icon icon-search"></i> Search
          </Button>
        </FormGroup>
      </Row>
    </Form>
  );
};

export default Filter;
