import React, { Component } from 'react';

import { Row, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Pagination from 'components/Pagination';
import { getVouchers, editVoucher } from 'actions/voucherActions';
import { getClient, clearClient } from 'actions/clientActions';
import Filter from 'components/VoucherSettings/VoucherFilter';
import CreateVoucher from 'components/VoucherSettings/CreateVoucher';
import EditVoucher from 'components/VoucherSettings/EditVoucher';
import DeletePopup from 'components/VoucherSettings/DeletePopup';

class VoucherSettings extends Component {
  statusValues = ['Deleted', 'Active'];
  state = {
    currentPage: 1,
    pageLimit: 20,
    searchKey: '',
    status: '',
    fromDate: '',
    toDate: '',
    showCreateVoucherModal: false,
    showEditVoucherModal: false,
    selectedVoucher: null,
    showDeleteVoucherModal: false,
    currency: '',
  };

  componentDidMount() {
    // this.props.getClient(this.props.match.params.id);
    this.fetchVouchers();
  }

  componentWillUnmount() {
    this.props.clearClient();
  }

  fetchVouchers = () => {
    const {
      currentPage,
      pageLimit,
      searchKey,
      status,
      fromDate,
      toDate,
    } = this.state;

    const payload = {
      currentPage,
      pageLimit,
      searchKey,
      status: parseInt(status, 10),
      fromDate,
      toDate,
      subdomain: this.props.match.params.subdomain,
      clientId: this.props.match.params.id,
    };

    this.props.getVouchers(payload);
  };

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({ currentPage: index }, () => {
      this.fetchVouchers();
    });
  };

  handleDateChange = (moment, name) => {
    this.setState({ [name]: '' });
    if (moment && typeof moment !== 'object') {
      return;
    }
    if (moment) {
      const value = moment.format('YYYY-MM-DD');
      this.setState({ [name]: value });
    }
  };

  handleFilterChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleFilter = () => {
    this.setState({ currentPage: 1 }, () => {
      this.fetchVouchers();
    });
  };

  handleCreateVoucherModal = () => {
    this.setState(prevState => ({
      showCreateVoucherModal: !prevState.showCreateVoucherModal,
    }));
  };

  handleEditVoucherModal = (voucher, e) => {
    this.setState(prevState => ({
      showEditVoucherModal: !prevState.showEditVoucherModal,
    }));
    if (voucher) {
      this.setState({ selectedVoucher: voucher });
    }
  };

  handleDeleteVoucherModal = (voucher, e) => {
    this.setState(prevState => ({
      showDeleteVoucherModal: !prevState.showDeleteVoucherModal,
    }));
    if (voucher) {
      this.setState({ selectedVoucher: voucher });
    }
  };

  handleDeleteVoucher = async e => {
    try {
      const { selectedVoucher } = this.state;
      let status, action;
      switch (selectedVoucher.status) {
        case 1:
          status = 0;
          action = 'delete';
          break;
        case 0:
          status = 1;
          action = 'undelete';
          break;
        default:
      }
      const payload = {
        id: selectedVoucher._id,
        status,
        action,
        subdomain: this.props.match.params.subdomain,
      };

      await this.props.editVoucher(payload);
    } catch (e) {}
    this.handleDeleteVoucherModal();
  };

  formatDate(date) {
    return new Date(date).toLocaleDateString('en-UK', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC',
    });
  }

  showCurrencySign(currency) {
    if (currency === 'usd') return '$';
    if (currency === 'eur') return '€';
    if (currency === 'gbp') return '£';
    if (currency === 'aud') return 'A$';
    return '$';
  }

  render() {
    const {
      currentPage,
      pageLimit,
      showCreateVoucherModal,
      showEditVoucherModal,
      showDeleteVoucherModal,
      selectedVoucher,
      fromDate,
      toDate,
    } = this.state;
    const {
      vouchers,
      isLoading,
      totalVouchers,
      totalPage,
      currency,
      membership,
    } = this.props.vouchers;
    // const { client } = this.props.clients;

    const subdomain = this.props.match.params.subdomain;

    return (
      <>
        <div className="content">
          <div className="title">
            <span>List of</span>
            <h1>Vouchers - {subdomain}</h1>
            <div className="btn-container">
              <button
                className="btn btn-add"
                onClick={this.handleCreateVoucherModal}
              >
                <i className="icon icon-plus"></i> Create Voucher
              </button>
            </div>
          </div>

          {showCreateVoucherModal ? (
            <CreateVoucher
              subdomain={subdomain}
              currency={currency}
              clientId={this.props.match.params.id}
              membership={membership}
              fetchVouchers={this.fetchVouchers}
              toggleCreateVoucherModal={this.handleCreateVoucherModal}
            />
          ) : null}
          {showEditVoucherModal ? (
            <EditVoucher
              subdomain={subdomain}
              currency={currency}
              clientId={this.props.match.params.id}
              membership={membership}
              voucher={selectedVoucher}
              toggleEditVoucherModal={this.handleEditVoucherModal.bind(
                this,
                null
              )}
            />
          ) : null}
          <Filter
            handleDateChange={this.handleDateChange}
            handleFilterChange={this.handleFilterChange}
            handleFilter={this.handleFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
          {showDeleteVoucherModal ? (
            <DeletePopup
              voucher={selectedVoucher}
              handleNo={this.handleDeleteVoucherModal}
              handleDelete={this.handleDeleteVoucher}
            />
          ) : null}
          {isLoading ? (
            <span className="loading">
              <i className="fa fa-spinner fa-spin" aria-hidden="true" />{' '}
              Loading...
            </span>
          ) : (
            <>
              {!vouchers || !vouchers.length ? (
                <h2>No vouchers to display</h2>
              ) : (
                <Row>
                  <Col md="12">
                    <Table className="base-table clients-table" responsive>
                      <thead>
                        <tr>
                          <th>S.N</th>
                          <th>Title</th>
                          <th>Created At (UTC)</th>
                          <th>Discount</th>
                          <th>Unique Code</th>
                          <th>Used/Max. Usage</th>
                          <th>Applicable To</th>
                          <th>Expires At (UTC)</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vouchers.map((voucher, index) => {
                          let applicableTo = '';
                          if (voucher.applicableTo) {
                            if (voucher.applicableTo.memberSubscription) {
                              applicableTo = 'Membership Subscription';
                            } else if (voucher.applicableTo.coursePurchase) {
                              applicableTo = 'Course Purchase';
                            }
                          }
                          let voucherDiscount = voucher.isPercentage
                            ? `${voucher.discount}%`
                            : `${this.showCurrencySign(currency)}${
                                voucher.discount
                              }`;
                          if (voucher.isTrialDays) {
                            voucherDiscount = `${voucher.discount} trial days`;
                          }
                          if(voucher.isCombined) {
                            const discountPart = voucher.isPercentage
                            ? `${voucher.combinedDiscount}%`
                            : `${this.showCurrencySign(currency)}${
                                voucher.combinedDiscount
                              }`;
                            voucherDiscount = `${discountPart} + ${voucher.discount} trial days`;
                          }
                          return (
                            <tr key={voucher._id}>
                              <td>
                                {currentPage * pageLimit -
                                  pageLimit +
                                  (index + 1)}
                              </td>
                              <td> {voucher.title} </td>
                              <td>{this.formatDate(voucher.createdAt)}</td>
                              <td>{voucherDiscount}</td>
                              <td>{voucher.uniqueCode}</td>
                              <td>
                                {voucher.usageCount}/
                                {voucher.maxUsage
                                  ? voucher.maxUsage
                                  : 'Unlimitted'}
                              </td>
                              <td>{applicableTo}</td>
                              <td>
                                <div>
                                  {voucher.expiryEnabled
                                    ? this.formatDate(voucher.expiresAt)
                                    : 'Never Expires'}
                                </div>
                                <div className="text-danger">
                                  {voucher.isExpired && 'Expired'}
                                </div>
                              </td>
                              <td
                                className={
                                  voucher.status === 1
                                    ? 'text-success'
                                    : 'text-danger'
                                }
                              >
                                {this.statusValues[voucher.status]}
                              </td>
                              <td>
                                <ul>
                                  <li
                                    className="btn-setting mr-1"
                                    onClick={this.handleEditVoucherModal.bind(
                                      this,
                                      voucher
                                    )}
                                  >
                                    {' '}
                                    Edit{' '}
                                  </li>
                                  <li
                                    className="btn-setting"
                                    onClick={this.handleDeleteVoucherModal.bind(
                                      this,
                                      voucher
                                    )}
                                  >
                                    {' '}
                                    {voucher.status === 1
                                      ? 'Delete'
                                      : 'Undelete'}{' '}
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Pagination
                      total={totalVouchers}
                      currentPage={currentPage}
                      totalPage={totalPage}
                      pageLimit={pageLimit}
                      handleClick={this.handlePagination}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

VoucherSettings.propTypes = {
  getVouchers: PropTypes.func.isRequired,
  editVoucher: PropTypes.func.isRequired,
  getClient: PropTypes.func.isRequired,
  clearClient: PropTypes.func.isRequired,
  vouchers: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  vouchers: state.voucher,
  clients: state.client,
});

export default connect(mapStateToProps, {
  getVouchers,
  editVoucher,
  getClient,
  clearClient,
})(VoucherSettings);
