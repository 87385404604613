import React, { Component } from 'react';
import Breadcrumb from 'components/Breadcrumb';
import Useroptions from 'components/Useroptions';

class Header extends Component {
  render() {
    return (
      <div className="header">
        <Useroptions propsData={this.props.propsData} />
        <Breadcrumb />
      </div>
    );
  }
}
export default Header;
