import React from 'react';

export default function NoData({ text }) {
  return (
    <div className="no-item">
      <div>
        <i className="icon icon-danger fa-5x"></i>
        <h3>{text}</h3>
      </div>
    </div>
  );
}
