import Api from 'API';
import { GET_LOGS, LOG_LOADING, GET_LOGS_COMPONENT, CLEAR_LOGS } from './types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
toast.configure();

export const setLogLoading = () => {
  return {
    type: LOG_LOADING,
  };
};

export const getLogs = payload => dispatch => {
  dispatch(setLogLoading());

  new Api()
    .fetchLogs(payload)
    .then(result => {
      const data = { ...result, pageLimit: payload.pageLimit };
      dispatch({
        type: GET_LOGS,
        payload: data,
      });
    })
    .catch(err => {
      const data = {
        logs: [],
        totalLogs: 0,
        pageLimit: payload.pageLimit,
      };
      dispatch({
        type: GET_LOGS,
        payload: data,
      });
    });
};

export const getLogsCompmonent = () => dispatch => {
  new Api()
    .fetchLogsComponent()
    .then(result => {
      dispatch({
        type: GET_LOGS_COMPONENT,
        payload: result.data.component,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_LOGS_COMPONENT,
        payload: [],
      });
    });
};

export const getPaymentLogs = payload => dispatch => {
  dispatch(setLogLoading());

  new Api()
    .fetchPaymentLogs(payload)
    .then(result => {
      const res = result.data.paymentLogs;
      const data = {
        ...res,
        logs: res.paymentLogs,
        totalLogs: res.totalPaymentLogs,
        pageLimit: payload.pageLimit,
      };

      dispatch({
        type: GET_LOGS,
        payload: data,
      });
    })
    .catch(err => {
      const data = {
        logs: [],
        totalLogs: 0,
        pageLimit: payload.pageLimit,
      };
      dispatch({
        type: GET_LOGS,
        payload: data,
      });
    });
};

export const clearLogs = () => dispatch => {
  dispatch({
    type: CLEAR_LOGS,
  });
};
