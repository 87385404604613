import React from 'react';
import { Card, CardBody, CardTitle, Col, Table } from 'reactstrap';
import { Link } from 'react-router-dom';

function ActiveClients() {
  return (
    <Col md="6">
      <Card>
        <div className="card-head">
          <CardTitle>
            Most active clients<span>(top 10)</span>
          </CardTitle>
          <Link to="/clients" className="card-link">
            <i className="icon icon-right-align" />
            View all
          </Link>
        </div>
        <CardBody>
          <Table className="base-table active-clients" responsive>
            <thead>
              <tr>
                <th>S.N</th>
                <th>Clients Name</th>
                 <th className="text-center">Number of login</th>
                <th className="text-center">Number of course(s)</th>
               
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">1</td>
                <td>
                  <img className="logo" src="images/skillbase.png" alt="skillbase" />
                  Skill Base
                </td>
                
                <td className="text-center">2023</td>
                <td className="text-center">702</td>
              </tr>
              <tr>
                <td scope="row">2</td>
                <td>
                  <img className="logo" src="images/umwep.png" alt="skillbase" />
                  UMWEP
                </td>
               
                <td className="text-center">1750</td>
                 <td className="text-center">501</td>
              </tr>
              <tr>
                <td scope="row">3</td>
                <td>
                  <img className="logo" src="images/utu.png" alt="skillbase" />
                  Unite the UNION
                </td>
                <td className="text-center">1562</td>
                <td className="text-center">207</td>
                
              </tr>
                    <tr>
                <td scope="row">4</td>
                <td>
                  <img className="logo" src="images/utu.png" alt="skillbase" />
                  Unite the UNION
                </td>
                  <td className="text-center">1562</td>
                <td className="text-center">207</td>
              
              </tr>
                    <tr>
                <td scope="row">5</td>
                <td>
                  <img className="logo" src="images/utu.png" alt="skillbase" />
                  Unite the UNION
                </td>
                
                <td className="text-center">1562</td>
                <td className="text-center">207</td>
              </tr>
               <tr>
                <td scope="row">6</td>
                <td>
                  <img className="logo" src="images/skillbase.png" alt="skillbase" />
                  Skill Base
                </td>
                
                <td className="text-center">2023</td>
                <td className="text-center">702</td>
              </tr>
              <tr>
                <td scope="row">7</td>
                <td>
                  <img className="logo" src="images/umwep.png" alt="skillbase" />
                  UMWEP
                </td>
               
                <td className="text-center">1750</td>
                 <td className="text-center">501</td>
              </tr>
              <tr>
                <td scope="row">8</td>
                <td>
                  <img className="logo" src="images/utu.png" alt="skillbase" />
                  Unite the UNION
                </td>
                <td className="text-center">1562</td>
                <td className="text-center">207</td>
                
              </tr>
               <tr>
                <td scope="row">9</td>
                <td>
                  <img className="logo" src="images/utu.png" alt="skillbase" />
                  Unite the UNION
                </td>
                <td className="text-center">1562</td>
                <td className="text-center">207</td>
                
              </tr>
                <tr>
                <td scope="row">10</td>
                <td>
                  <img className="logo" src="images/skillbase.png" alt="skillbase" />
                  Skill Base
                </td>
                
                <td className="text-center">2023</td>
                <td className="text-center">702</td>
              </tr>

            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
}

export default ActiveClients;
