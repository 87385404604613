import { combineReducers } from 'redux';

import sidebarReducer from './sidebarReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import clientReducer from './clientReducer';
import superAdminReducer from './superAdminReducer';
import uploadReducer from './uploadReducer';
import logReducer from './logReducer';
import cloneReducer from './cloneReducer';
import voucherReducer from './voucherReducer';

export default combineReducers({
  sidebar: sidebarReducer,
  auth: authReducer,
  error: errorReducer,
  client: clientReducer,
  superAdmin: superAdminReducer,
  upload: uploadReducer,
  log: logReducer,
  clone: cloneReducer,
  voucher: voucherReducer,
});
