import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { forgotPassword } from 'actions/authActions';

class ForgotPassword extends Component {
  state = {
    email: '',
    isInvalid: false,
  };

  handleChange = e => {
    this.setState({ email: e.target.value });
  };

  handleReset = e => {
    e.preventDefault();

    if (!this.state.email) {
      return this.setState({ isInvalid: true });
    }

    this.props.forgotPassword(this.state.email, this.props.history);
  };

  error = () => {
    let error = '';
    const errorProp = this.props.error?.error;

    if (errorProp === 'No such user found') {
      error = 'Email not found';
    } else {
      const err = errorProp?.[0]?.email;
      if (err) error = err;
    }

    return <span style={{ color: 'red', fontSize: '80%' }}>{error}</span>;
  };

  render() {
    return (
      <div className="auth-page">
        <div className="auth-container">
          <div className="auth">
            <h1>Forgot your password?</h1>
            <span>
              Please enter your email address to request a password reset
            </span>
            <Form
              className="bp-form modal-form"
              onKeyPress={this.handleKeyPress}
            >
              <FormGroup className="with-icon email">
                <Label for="email">
                  <i className="icon icon-mail" aria-hidden="true" />
                  Email
                </Label>
                <Input
                  type="text"
                  name="userId"
                  placeholder="Email Address"
                  invalid={this.state.isInvalid}
                  onChange={this.handleChange}
                />
                <FormFeedback>Email is required</FormFeedback>
              </FormGroup>
              <FormGroup className="float-right">
                Remember your password?{' '}
                <Link to="/login">
                  <u>Login</u>
                </Link>
              </FormGroup>
              {this.error()}
            </Form>
            <Button className="btn-base center" onClick={this.handleReset}>
              {this.props.auth.isLoading ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true" />
              ) : (
                'Reset Password'
              )}
            </Button>
          </div>
          <p className="copyright">My Connect © 2020. All rights reserved</p>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error,
});

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
