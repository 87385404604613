import {
  TOGGLE_COURSES_MODAL_TO_CLONE,
  TOGGLE_COURSE_FETCH_LOADER,
  GET_CLIENT_COURSES,
  CLONE_LOADING,
  COURSE_SEARCH_KEY,
} from 'actions/types';

const initialState = {
  isCourseFetching: false,
  isClonning: false,
  courseSearchKey: '',
  clientCourses: {
    totalCourses: 0,
    courses: [],
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_COURSES_MODAL_TO_CLONE:
      return {
        ...state,
        courseSearchKey: '',
        clientCourses: {
          totalCourses: 0,
          courses: [],
        },
      };
    case TOGGLE_COURSE_FETCH_LOADER:
      return {
        ...state,
        isCourseFetching: !state.isCourseFetching,
      };
    case GET_CLIENT_COURSES:
      return {
        ...state,
        isCourseFetching: false,
        clientCourses: {
          totalCourses: action.payload.totalCourses,
          courses: action.payload.courses,
        },
      };
    case CLONE_LOADING:
      return {
        ...state,
        isClonning: !state.isClonning,
      };
    case COURSE_SEARCH_KEY:
      return {
        ...state,
        courseSearchKey: action.payload,
      };
    default:
      return state;
  }
}
