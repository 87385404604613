import React, { Component } from 'react';

import { Row, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Pagination from 'components/Pagination';
import {
  getVoucherLogs,
} from 'actions/voucherActions';
import { getClient, clearClient } from 'actions/clientActions';
import ViewMore from 'components/VoucherSettings/ViewMore';
import Filter from 'components/VoucherSettings/LogFilter';
import { getFromISODate, getToISODate } from '../../utils/helper';

class VoucherLogs extends Component {
  statusValues = ['Deleted', 'Active']
  state = {
    isError: false,
    currentPage: 1,
    pageLimit: 20,
    searchKey: '',
    actionType: '',
    fromDate: '',
    toDate: '',
    logId: '',
    showViewMoreModal: false,
  };

  componentDidMount() {
    // this.props.getClient(this.props.match.params.id);
    this.fetchVoucherLogs();
  }

  componentWillUnmount() {
    // this.props.clearClient();
  }

  fetchVoucherLogs = () => {
    const { currentPage, pageLimit, searchKey, actionType, fromDate, toDate } = this.state;

    const payload = {
      currentPage,
      pageLimit,
      searchKey,
      actionType, 
      fromDate: getFromISODate(fromDate), 
      toDate: getToISODate(toDate),
      subdomain: this.props.match.params.subdomain,
    };

    this.props.getVoucherLogs(payload);
  };

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({ currentPage: index }, () => {
      this.fetchVoucherLogs();
    });
  };

  handleDateChange = (moment, name) => {
    this.setState({ [name]: '' });
    if (moment && typeof moment !== 'object') { 
      return;
    }
    if (moment) {
      const value = moment.format('YYYY-MM-DD')
      this.setState({ [name]: value });
    }
  };

  handleFilterChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleFilter = () => {
    this.setState({ currentPage: 1 }, () => {
      this.fetchVoucherLogs();
    });
  };

  formatDate(date) {
    return new Date(date).toLocaleDateString('en-UK', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  showCurrencySign(currency) {
    if (currency === 'usd') return '$';
    if (currency === 'eur') return '€';
    if (currency === 'gbp') return '£';
    if (currency === 'aud') return 'A$';
    return '$';
  }

  toggleViewMore = (id, e) => {
    this.setState(prevState => ({
      showViewMoreModal: !prevState.showViewMoreModal,
      logId: id,
    }));
  };

  render() {
    const { currentPage, pageLimit, showViewMoreModal, logId, fromDate, toDate } = this.state;
    const {
      voucherLogs,
      isLogLoading,
      totalVoucherLogs,
      totalLogPage,
      currency,
    } = this.props.vouchers;
    // const { client } = this.props.clients;

    const subdomain = this.props.match.params.subdomain;

    return (
      <>
        <div className="content">
          <div className="title">
            <span>List of</span>
            <h1>Voucher Logs - {subdomain}</h1>
          </div>

          <Filter
            handleDateChange={this.handleDateChange}
            handleFilterChange={this.handleFilterChange}
            handleFilter={this.handleFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
          {isLogLoading ? (
            <span className="loading"><i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...</span>
          ) : (
            <>
              {!voucherLogs || !voucherLogs.length ? (
                <h2>No voucher logs to display</h2>
              ) : (
                <Row>
                  <Col md="12">
                    <Table className="base-table clients-table" responsive>
                      <thead>
                        <tr>
                          <th>S.N</th>
                          <th>User Id</th>
                          <th>Created At</th>
                          <th>Action Type</th>
                          <th>Voucher Code</th>
                          <th>Voucher Type</th>
                          <th>Discount</th>
                          <th>Discount Amount</th>
                          <th>Payment Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {voucherLogs.map((voucherlog, index) => {
                          let discount = '';
                          let applicableTo = '';
                          let discountAmount = '0';
                          if(voucherlog.metaData && voucherlog.metaData.newData) {
                            const voucher = voucherlog.metaData.newData;
                            const cur = voucherlog.currency ? voucherlog.currency : currency;
                            discount = voucher.isPercentage ? `${voucher.discount}%` : `${this.showCurrencySign(cur)}${voucher.discount}`;
                            if(voucher.isTrialDays) { 
                              discount = `${voucher.discount} trial days`;
                            }
                            if(["percentage_trialdays", "amount_trialdays"].indexOf(voucher.discountType) > -1) {
                              const discountPart = voucher.isPercentage
                              ? `${voucher.combinedDiscount}%`
                              : `${this.showCurrencySign(cur)}${
                                  voucher.combinedDiscount
                                }`;
                                discount = `${discountPart} + ${voucher.discount} trial days`;
                            }
                            if(voucher.applicableTo) {
                              if(voucher.applicableTo.memberSubscription){
                                applicableTo = 'Membership Subscription';
                              } else if(voucher.applicableTo.coursePurchase) {
                                applicableTo = 'Course Purchase';
                              }
                            }
                          }
                          if(parseFloat(voucherlog.discountAmount) > 0) {
                            const cur = voucherlog.currency ? voucherlog.currency : currency;
                            discountAmount = `${this.showCurrencySign(cur)}${voucherlog.discountAmount}`
                          }
                          return (
                            <tr key={voucherlog._id}>
                              <td>
                                {currentPage * pageLimit - pageLimit + (index + 1)}
                              </td>
                              <td> {voucherlog.userId} </td>
                              <td>{this.formatDate(voucherlog.createdAt)}</td>
                              <td>{voucherlog.actionType}</td>
                              <td>{voucherlog.voucherCode}</td>
                              <td>{applicableTo}</td>
                              <td>{discount}</td>
                              <td>{discountAmount}</td>
                              <td>{voucherlog.paymentType}</td>
                              <td>
                                <ul>
                                  <li className="btn-setting" onClick={this.toggleViewMore.bind(this, voucherlog._id)}> View </li>
                                </ul>
                                {showViewMoreModal && voucherlog._id === logId ? (
                                  <ViewMore
                                    toggleViewMore={this.toggleViewMore}
                                    data={voucherlog}
                                    showViewMoreModal={showViewMoreModal}
                                  />
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Pagination
                      total={totalVoucherLogs}
                      currentPage={currentPage}
                      totalPage={totalLogPage}
                      pageLimit={pageLimit}
                      handleClick={this.handlePagination}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

VoucherLogs.propTypes = {
  getVoucherLogs: PropTypes.func.isRequired,
  getClient: PropTypes.func.isRequired,
  clearClient: PropTypes.func.isRequired,
  vouchers: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  vouchers: state.voucher,
  clients: state.client
});

export default connect(mapStateToProps, {
  getVoucherLogs,
  getClient,
  clearClient
})(VoucherLogs);
