import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import * as actions from 'actions/types';
import { closeSidebarModal } from 'actions/clientActions';
import Api from 'API';
import { decrypt, copyText } from 'utils/helper';

toast.configure();

const hideCredentialsInMilliSeconds = 10000;
export default function AuthForm(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const intervalIdRef = React.useRef('');
  const timeoutIdRef = React.useRef('');
  const [state, setState] = React.useState({
    password: '',
    isInvalid: false,
    isLoading: false,
    createButtonClicked: false,

    appId: '',
    appSecretKey: '',
    count: 10,
  });

  const { isInvalid, password, isLoading, appId, appSecretKey } = state;
  let { count } = state;

  React.useEffect(() => {
    clearInterval(intervalIdRef.current);
    clearTimeout(timeoutIdRef.current);
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    if (isLoading) return;

    if (!password) return setState({ ...state, isInvalid: true });

    const payload = {
      password,
      clientId: params.id,
    };

    setState(state => ({ ...state, isLoading: true, password: '' }));

    if (props.showApiCred) {
      return showApiCredentials(payload);
    }

    createApiCredentials(payload);
  };

  const showApiCredentials = payload => {
    new Api()
      .showApiCredentials(payload)
      .then(result => {
        console.log({ result });

        const appId = decrypt(result.data.appId);
        const appSecretKey = decrypt(result.data.appSecretKey);

        setState(state => ({
          ...state,
          isLoading: false,
          appId,
          appSecretKey,
        }));

        interval();
        timeoutIdRef.current = setTimeout(() => {
          setState(state => ({ ...state, appId: '', appSecretKey: '' }));
        }, 10000);
      })
      .catch(err => {
        console.log({ CreateApiCredentialsError: err });

        setState(state => ({ ...state, isLoading: false }));

        const error = err.response?.data?.error || 'Something went wrong.';
        toast.error(error, { autoClose: 5000 });
      });
  };

  const interval = () => {
    intervalIdRef.current = setInterval(() => {
      count--;
      setState(state => ({ ...state, count }));

      if (count < 1) {
        setState(state => ({ ...state, count: 10 }));
        return clearInterval(intervalIdRef.current);
      }
    }, 1000);

    return count;
  };

  const createApiCredentials = payload => {
    new Api()
      .createApiCredentials(payload)
      .then(result => {
        console.log({ result });

        setState(state => ({ ...state, isLoading: false }));

        dispatch({ type: actions.API_CREDENTIALS_CREATED });

        toast.success('API credentials are created.', {
          autoClose: 3000,
        });

        setTimeout(() => {
          handleModalClose();
        }, 1000);
      })
      .catch(err => {
        console.log({ CreateApiCredentialsError: err });

        setState(state => ({ ...state, isLoading: false }));

        const error = err.response?.data?.error || 'Something went wrong.';
        toast.error(error, { autoClose: 5000 });
      });
  };

  const handleModalClose = () => {
    dispatch(closeSidebarModal());
  };

  const handleInput = e => {
    setState({
      ...state,
      error: '',
      password: e.target.value,
      isInvalid: false,
    });
  };

  const handleCopy = (credential, key) => {
    copyText(credential);

    toast.success(`${key} copied.`, {
      autoClose: 3000,
    });
  };

  return (
    <>
      <Form className="bp-form modal-form settings-container" onSubmit={handleSubmit}>
        <fieldset>
          <legend>
            Authorize yourself <span className="text-danger">*</span>
          </legend>
          <FormGroup>
            {/* <Label for="password">
            Authorize yourself <span className="text-danger">*</span>
          </Label> */}
            <Input
              type="password"
              name="password"
              value={password}
              placeholder="Login Password"
              invalid={!password && isInvalid}
              onChange={handleInput}
            />
            <FormFeedback>Login password is required</FormFeedback>
          </FormGroup>

          <Button className="btn-add">{isLoading ? 'Loading...' : props.buttonText}</Button>

          {appId && appSecretKey ? (
            <div className="mt-5 copy">
              <hr />
              <strong>App ID: </strong>{' '}
              <span
                style={{ top: '13px', fontSize: '17px' }}
                onClick={() => handleCopy(appId, 'App ID')}
              >
                {appId}
              </span>
              <p>
                <strong>App Secret Key: </strong>{' '}
                <span
                  style={{ top: '13px', fontSize: '17px' }}
                  onClick={() => handleCopy(appSecretKey, 'App Secret Key')}
                >
                  {appSecretKey}
                </span>
              </p>
              <small className="text-info">
                Credentials clears on <strong>{count}</strong>
              </small>
            </div>
          ) : null}
        </fieldset>
      </Form>
    </>
  );
}
