import React, { Component } from 'react';

import { Row, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Pagination from 'components/Pagination';
import { getClient, clearClient } from 'actions/clientActions';
// import ViewMore from 'components/VoucherSettings/ViewMore';
import Filter from 'components/RegistrationLog/LogFilter';
import API from 'API';
import { getFromISODate, getToISODate } from '../../utils/helper';

class RegistrationLogs extends Component {
  actionValues = { 
    subscribe: 'Registration', 
    resubscribe: 'Resubscription', 
    invoice_payment_failed: 'Recurring Payment Failed', 
    invoice_payment_succeeded: 'Recurring Payment Succeeded' 
  }
  state = {
    isError: false,
    currentPage: 1,
    pageLimit: 20,
    searchKey: '',
    voucherCode: '',
    actionType: '',
    fromDate: '',
    toDate: '',
    logId: '',
    showViewMoreModal: false,
    registrationLogs: [],
    totalRegistrationLogs: 0,
    totalLogPage: 0,
    isLogLoading: false,
  };

  componentDidMount() {
    // this.props.getClient(this.props.match.params.id);
    this.fetchRegistrationLogs();
  }

  componentWillUnmount() {
    // this.props.clearClient();
  }

  fetchRegistrationLogs = async () => {
    try {
      this.setState({ isLogLoading: true });
      const { currentPage, pageLimit, searchKey, actionType, fromDate, toDate, voucherCode } = this.state;
  
      const payload = {
        currentPage,
        pageLimit,
        searchKey,
        voucherCode,
        actionType, 
        fromDate: getFromISODate(fromDate), 
        toDate: getToISODate(toDate),
        subdomain: this.props.match.params.subdomain,
      };
  
      const result = await new API().fetchRegistrationLogs(payload);
      this.setState({ 
        registrationLogs: result.registrationLogs,
        totalRegistrationLogs: result.total,
        totalLogPage: Math.ceil( result.total / pageLimit ),
      })
    } catch(e) {
      console.log(e)
    }
    this.setState({ isLogLoading: false });
  };

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({ currentPage: index }, () => {
      this.fetchRegistrationLogs();
    });
  };

  handleDateChange = (moment, name) => {
    this.setState({ [name]: '' });
    if (moment && typeof moment !== 'object') { 
      return;
    }
    if (moment) {
      const value = moment.format('YYYY-MM-DD')
      this.setState({ [name]: value });
    }
  };

  handleFilterChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleFilter = () => {
    this.setState({ currentPage: 1 }, () => {
      this.fetchRegistrationLogs();
    });
  };

  formatDate(date) {
    return new Date(date).toLocaleDateString('en-UK', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  showCurrencySign(currency) {
    if (currency === 'usd') return '$';
    if (currency === 'eur') return '€';
    if (currency === 'gbp') return '£';
    if (currency === 'aud') return 'A$';
    return '$';
  }

  toggleViewMore = (id, e) => {
    this.setState(prevState => ({
      showViewMoreModal: !prevState.showViewMoreModal,
      logId: id,
    }));
  };

  render() {
    const { 
      currentPage, 
      pageLimit, 
      // showViewMoreModal, 
      // logId, 
      registrationLogs,
      isLogLoading,
      totalRegistrationLogs,
      totalLogPage,
      fromDate,
      toDate,
    } = this.state;
    // const { client } = this.props.clients;

    const subdomain = this.props.match.params.subdomain;

    return (
      <>
        <div className="content">
          <div className="title">
            <span>List of</span>
            <h1>Learner Registration Logs - {subdomain}</h1>
          </div>

          <Filter
            handleDateChange={this.handleDateChange}
            handleFilterChange={this.handleFilterChange}
            handleFilter={this.handleFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
          {isLogLoading ? (
            <span className="loading"><i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...</span>
          ) : (
            <>
              {!registrationLogs || !registrationLogs.length ? (
                <h2>No registration logs to display</h2>
              ) : (
                <Row>
                  <Col md="12">
                    <Table className="base-table clients-table" responsive>
                      <thead>
                        <tr>
                          <th>S.N</th>
                          <th>Learner Id</th>
                          <th>Created At</th>
                          <th>Action Type</th>
                          <th>Voucher Code</th>
                          <th>Discount</th>
                          <th>Discount Amount</th>
                          <th>Type</th>
                          <th>Family Membership</th>
                          <th>Amount Paid</th>
                          <th>Total Free Trial</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {registrationLogs.map((registrationLog, index) => {
                          let discount = 0;
                          let totalTrial = registrationLog.trialPeriod ? parseInt(registrationLog.trialPeriod, 10) : 0;
                          let discountAmount = '0';
                          if(registrationLog.voucher) {
                            const voucher = registrationLog.voucher;
                            discount = voucher.isPercentage ? `${voucher.discount}%` : `${this.showCurrencySign(registrationLog.currency)}${voucher.discount}`;
                            if(voucher.isTrialDays) { 
                              discount = `${voucher.discount} trial days`;
                              totalTrial += parseInt(voucher.discount, 10);
                            }
                            if(voucher.isCombined) {
                              const discountPart = voucher.isPercentage
                              ? `${voucher.combinedDiscount}%`
                              : `${this.showCurrencySign(registrationLog.currency)}${
                                  voucher.combinedDiscount
                                }`;
                                discount = `${discountPart} + ${voucher.discount} trial days`;
                            }
                          }
                          if(parseFloat(registrationLog.discountAmount) > 0) {
                            discountAmount = `${this.showCurrencySign(registrationLog.currency)} ${registrationLog.discountAmount}`
                          }
                          return (
                            <tr key={registrationLog._id}>
                              <td>
                                {currentPage * pageLimit - pageLimit + (index + 1)}
                              </td>
                              <td> {registrationLog.learnerId} </td>
                              <td>{this.formatDate(registrationLog.createdAt)}</td>
                              <td>{this.actionValues[registrationLog.actionType]}</td>
                              <td>{registrationLog.voucher && registrationLog.voucher.uniqueCode}</td>
                              <td>{discount}</td>
                              <td>{discountAmount}</td>
                              <td>{registrationLog.isRegisteredFreeWithinPaidMembership ? 'free' : registrationLog.subscriptionType}</td>
                              <td>{registrationLog.hasPaidForFamilyMembers ? 'yes' : 'No'}</td>
                              <td>{parseFloat(registrationLog.netAmount) ? `${this.showCurrencySign(registrationLog.currency)} ${registrationLog.netAmount}` : 0}</td>
                              <td>{totalTrial} days</td>
                              {/* <td>
                                <ul>
                                  <li className="btn-setting" onClick={this.toggleViewMore.bind(this, registrationLog._id)}> View </li>
                                </ul>
                                {showViewMoreModal && registrationLog._id === logId ? (
                                  <ViewMore
                                    toggleViewMore={this.toggleViewMore}
                                    data={registrationLog}
                                    showViewMoreModal={showViewMoreModal}
                                  />
                                ) : null}
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Pagination
                      total={totalRegistrationLogs}
                      currentPage={currentPage}
                      totalPage={totalLogPage}
                      pageLimit={pageLimit}
                      handleClick={this.handlePagination}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

RegistrationLogs.propTypes = {
  getClient: PropTypes.func.isRequired,
  clearClient: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  clients: state.client
});

export default connect(mapStateToProps, {
  getClient,
  clearClient
})(RegistrationLogs);
