import React from 'react';
import { Modal, Row, Col, Table, ModalHeader } from 'reactstrap';

const ViewMore = ({ showViewMoreModal, toggleViewMore, data }) => {
  return (
    
    <Modal className="log-details" isOpen={showViewMoreModal} toggle={toggleViewMore}>
      <ModalHeader className="mb-3">Payment details</ModalHeader>
      <Row>
        <Col md="4">
          <div>
            <strong>Stripe Id</strong>
          </div>
        </Col>
        <Col md="8">
          <small>{data.stripeId}</small>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <div>
            <strong>Membership Type</strong>
          </div>
        </Col>
        <Col md="8">
          <small>{data.subscriptionType}</small>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <div>
            <strong>Stripe Session Id</strong>
          </div>
        </Col>
        <Col md="8">
          <small>{data.stripeResponseId}</small>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <div>
            <strong>IP</strong>
          </div>
        </Col>
        <Col md="8">
          <small>{data.extra.ipAddress}</small>
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewMore;
