import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getClient,
  updateClientOnInputChange,
  clearClient,
  toggleCreateClientModal,
  createClient,
  editClient,
  updateCountries,
} from 'actions/clientActions';
import { clearError } from 'actions/errorActions';
import { uploadImage } from 'actions/uploadActions';
import { capitalizeWord } from 'utils/helper';

class ClientModal extends Component {
  state = {
    isLoading: false,
    isError: false,
    isInvalid: false,
    isFetching: false,
    firstName: '',
    lastName: '',
    email: '',
    userId: '',
    password: '',
    confirmPassword: '',
    country: '',
    dialcode: '',
    phone: '',
    isPasswordMatch: true,
    formNo: 1,
  };

  componentDidMount() {
    // clear previously stored client data
    this.props.clearClient();

    if (this.props.clientId) {
      this.fetchClient();
    } else if (this.props.clients.countries.length === 0) {
      this.props.updateCountries();
    }
  }

  fetchClient = () => {
    this.props.getClient(this.props.clientId);
  };

  handleInput = e => {
    this.props.clearError();
    let { name, value } = e.target;
    if (
      [
        'email',
        'password',
        'confirmPassword',
        'firstName',
        'lastName',
        'userId',
        'country',
        'phone',
      ].indexOf(name) > -1
    ) {
      if (name === 'phone' && !/^[0-9]+$|^$/.test(value)) {
        return;
      }
      let stateObj = {};
      if (name === 'country') {
        const { countries } = this.props.clients;
        const selectedObj = countries[e.target.selectedIndex - 1];
        console.log(selectedObj);
        stateObj['dialcode'] = selectedObj.calling_code;
      }
      this.setState({ [name]: value, ...stateObj, isPasswordMatch: true });
    } else {
      if (['accountId', 'authToken', 'domainName'].indexOf(name) > -1) {
        value = { [name]: value };
      }
      this.props.updateClientOnInputChange({
        name: name,
        value: value,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { url, name } = this.props.imageUpload;
    if (prevProps.imageUpload.url !== url || prevProps.imageUpload.name !== name) {
      this.props.updateClientOnInputChange({ name, value: url });
    }
  }

  handleImageUpload = e => {
    this.props.uploadImage(e.target.files[0], 'logo');
  };

  handleSubmit = e => {
    e.preventDefault();

    const { name, subdomain, ahqId, appId, appSecretKey, logo } = this.props.clients.client;

    if (!name || !subdomain || !logo) {
      return this.setState({ isInvalid: true });
    }

    const payload = {
      name,
      subdomain,
      ahqId,
      appId,
      appSecretKey,
      logo,
    };

    if (this.props.clientId) {
      return this.handleClientEdit(payload);
    }

    const {
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      userId,
      country,
      dialcode,
      phone,
    } = this.state;

    if (!email || !password || !firstName || !lastName || !userId || !country || !phone) {
      return this.setState({ isInvalid: true });
    }

    if (password !== confirmPassword) {
      return this.setState({ isPasswordMatch: false });
    }

    payload.email = email;
    payload.password = password;
    payload.firstName = firstName;
    payload.lastName = lastName;
    payload.userId = userId;
    payload.country = country;
    payload.dialcode = dialcode;
    payload.phone = phone;

    this.handleClientCreate(payload);
  };

  handleClientCreate = payload => {
    this.props.createClient(payload);
  };

  handleClientEdit = data => {
    const payload = {
      ...data,
      clientId: this.props.clientId,
    };

    this.props.editClient(payload);
  };

  handleModalClose = () => {
    this.props.toggleCreateClientModal();
  };

  uniqueValidation(key) {
    const { error } = this.props.error;

    const hasError = error && error.includes(key) && error.includes('unique');
    const DomainError = key === 'subdomain' && error && error.includes('Domain');

    return hasError || DomainError ? `${key} is already being used` : '';
  }

  checkValidation = key => {
    const { error } = this.props.error;
    const errorMessage = `${key} is already being used`;

    const hasUniqueError = error && error.includes(key) && error.includes('validation');

    if (hasUniqueError) return errorMessage;

    const hasValidityError = error && (error.includes(key) || error.includes(capitalizeWord(key)));

    if (hasValidityError) return error;

    const hasUsernameInError =
      error && error.includes('username') && key === 'userId' && error.includes('exists');

    if (hasUsernameInError) {
      return errorMessage;
    }

    const userIdLengthError = error && error.includes('character') && key === 'userId';

    if (userIdLengthError) return 'User Id must be at least 8 character';

    const errObj =
      Array.isArray(error) &&
      error.find(err => {
        return Object.keys(err)[0] === key;
      });

    return errObj ? Object.values(errObj)[0] : '';
  };

  handleContinue = (num, e) => {
    e.preventDefault();

    if (num === 2) {
      const { name, subdomain, logo } = this.props.clients.client;

      if (!name || !subdomain || !logo) {
        return this.setState({ isInvalid: true });
      }
    }

    this.setState({ formNo: num });
  };

  render() {
    const {
      isInvalid,
      firstName,
      lastName,
      email,
      userId,
      password,
      confirmPassword,
      country,
      phone,
      isPasswordMatch,
      formNo,
    } = this.state;

    let {
      name = '',
      subdomain = '',
      appId = '',
      ahqId = '',
      appSecretKey = '',
      logo = '',
    } = this.props.clients.client;

    const { isLoading: isFetching, countries } = this.props.clients;
    const { isUploading: isImageLoading } = this.props.imageUpload;

    return (
      <div className="client-add-edit-modal">
        <div className="modal-header" onClick={this.handleModalClose}>
          <i className="icon icon-cross-out" />
        </div>
        <div className="modal-body">
          {isFetching ? (
            <span>
              <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...
            </span>
          ) : (
            <>
              <h2>
                {this.props.clientId ? 'Edit' : 'Create'} {formNo === 1 ? 'Client' : 'Client Admin'}
              </h2>
              <Form className="bp-form modal-form">
                {formNo === 1 && (
                  <>
                    <FormGroup>
                      <Label for="name">
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Name"
                        invalid={!name && isInvalid}
                        onChange={this.handleInput}
                        value={name}
                      />
                      <FormFeedback>Name is required</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="subdomain">
                        Sub-Domain <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="subdomain"
                        invalid={
                          (!subdomain && isInvalid) ||
                          (this.uniqueValidation('subdomain') ? true : false)
                        }
                        onChange={this.handleInput}
                        value={subdomain}
                      />
                      <FormFeedback>
                        {this.uniqueValidation('subdomain')
                          ? this.uniqueValidation('subdomain')
                          : 'subdomain is required'}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="ahqid">AHQ_ID</Label>
                      <Input
                        type="text"
                        name="ahqId"
                        onChange={this.handleInput}
                        value={ahqId}
                        // disabled={this.props.clientId}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">APP_ID</Label>
                      <Input
                        type="text"
                        name="appId"
                        onChange={this.handleInput}
                        value={appId}
                        // disabled={this.props.clientId}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">APP_SECRET_KEY</Label>
                      <Input
                        type="text"
                        name="appSecretKey"
                        onChange={this.handleInput}
                        value={appSecretKey}
                        // disabled={this.props.clientId}
                      />
                    </FormGroup>
                    <FormGroup>
                      <div className="custom-input">
                        <Label for="name">
                          Logo <span className="text-danger">*</span>
                        </Label>{' '}
                        {!logo && isInvalid ? (
                          <span className="text-danger" style={{ fontSize: '80%' }}>
                            Logo is required
                          </span>
                        ) : null}{' '}
                        {isImageLoading ? (
                          <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                        ) : (
                          <div className="custom-inputbox">
                            <Input type="file" name="logo" onChange={this.handleImageUpload} />
                            Upload Image
                          </div>
                        )}
                      </div>
                      {logo ? <img src={logo} alt="logo" className="uploaded-logo" /> : null}
                    </FormGroup>
                    {!this.props.clientId && (
                      <Button
                        className="btn-add float-right"
                        onClick={this.handleContinue.bind(this, 2)}
                        type="button"
                      >
                        Continue to Step 2
                      </Button>
                    )}
                  </>
                )}
                {this.props.clientId && (
                  <Button className="btn-add float-right" onClick={this.handleSubmit}>
                    Update
                  </Button>
                )}
                {!this.props.clientId && formNo === 2 && (
                  <>
                    <FormGroup>
                      <Label for="firstName">
                        First name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="firstName"
                        invalid={
                          (!firstName && isInvalid) || this.checkValidation('firstName')
                            ? true
                            : false
                        }
                        onChange={this.handleInput}
                        value={firstName}
                      />
                      <FormFeedback>
                        {this.checkValidation('firstName')
                          ? this.checkValidation('firstName')
                          : 'First Name is required'}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="lastName">
                        Last name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="lastName"
                        invalid={
                          (!lastName && isInvalid) || this.checkValidation('lastName')
                            ? true
                            : false
                        }
                        onChange={this.handleInput}
                        value={lastName}
                      />
                      <FormFeedback>
                        {this.checkValidation('lastName')
                          ? this.checkValidation('lastName')
                          : 'Last Name is required'}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="userId">
                        User Id <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="userId"
                        invalid={
                          (!userId && isInvalid) || this.checkValidation('userId') ? true : false
                        }
                        onChange={this.handleInput}
                        value={userId}
                      />
                      <FormFeedback>
                        {this.checkValidation('userId')
                          ? this.checkValidation('userId')
                          : 'User id is required'}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="email">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="email"
                        invalid={
                          (!email && isInvalid) || this.checkValidation('email') ? true : false
                        }
                        onChange={this.handleInput}
                        value={email}
                      />
                      <FormFeedback>
                        {this.checkValidation('email')
                          ? this.checkValidation('email')
                          : 'Email is required'}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="country">
                        Country <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        name="country"
                        invalid={
                          (!country && isInvalid) || this.checkValidation('country') ? true : false
                        }
                        onChange={this.handleInput}
                        value={country}
                      >
                        <option key={-1} value="">
                          Select Country
                        </option>
                        {countries.map((c, i) => (
                          <option key={i} value={c.country_code}>
                            {c.country_name} ({c.calling_code})
                          </option>
                        ))}
                      </Input>
                      <FormFeedback>
                        {this.checkValidation('country')
                          ? this.checkValidation('country')
                          : 'Country is required'}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="phone">
                        Phone <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="phone"
                        invalid={
                          (!phone && isInvalid) || this.checkValidation('phone') ? true : false
                        }
                        onChange={this.handleInput}
                        value={phone}
                      />
                      <FormFeedback>
                        {this.checkValidation('phone')
                          ? this.checkValidation('phone')
                          : 'Phone number is required'}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">
                        Password <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="password"
                        name="password"
                        invalid={
                          (!password && isInvalid) || this.checkValidation('password')
                            ? true
                            : false
                        }
                        onChange={this.handleInput}
                        value={password}
                      />
                      <FormFeedback>
                        {this.checkValidation('password')
                          ? this.checkValidation('password')
                          : 'Password is required'}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="confirmPassword">
                        Confirm Password <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="password"
                        name="confirmPassword"
                        invalid={!isPasswordMatch}
                        onChange={this.handleInput}
                        value={confirmPassword}
                      />
                      <FormFeedback>Confirm password do not match</FormFeedback>
                    </FormGroup>

                    <Button className="btn-add float-right" onClick={this.handleSubmit}>
                      Create
                    </Button>
                    <Button
                      className="btn-add float-right mr-3"
                      onClick={this.handleContinue.bind(this, 1)}
                      type="button"
                    >
                      Back to Step 1
                    </Button>
                  </>
                )}
              </Form>
            </>
          )}
        </div>
      </div>
    );
  }
}

ClientModal.propTypes = {
  getClient: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
  uploadImage: PropTypes.func.isRequired,
  imageUpload: PropTypes.object.isRequired,
  updateClientOnInputChange: PropTypes.func.isRequired,
  clearClient: PropTypes.func.isRequired,
  toggleCreateClientModal: PropTypes.func.isRequired,
  createClient: PropTypes.func.isRequired,
  editClient: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  updateCountries: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  clients: state.client,
  imageUpload: state.upload,
  error: state.error,
});

export default connect(mapStateToProps, {
  getClient,
  uploadImage,
  updateClientOnInputChange,
  clearClient,
  toggleCreateClientModal,
  createClient,
  editClient,
  clearError,
  updateCountries,
})(ClientModal);
