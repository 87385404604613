import { Collapse } from 'reactstrap';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'actions/types';
import * as constants from 'common/constants';
import DeletePopup from 'components/DeletePopup';
import * as clientActions from 'actions/clientActions';
import ClientDropdownList from 'components/ClientDropdownList';

export default function ClientSettingsSidebar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({ collapse: '', icon: 'next' });
  const clients = useSelector(state => state.client);

  const { collapse, icon } = state;

  const returnActiveRow = () => {
    const c = clients;
    if (c.sidebarModal === constants.SSO) return 'SSO';
    if (c.sidebarModal === constants.CLONE) return 'courseClone';
    if (c.sidebarModal === constants.POLICY_SETTINGS) return 'policy';
    if (c.sidebarModal === constants.PAYMENT_SETTINGS) return 'payment';
    if (c.sidebarModal === constants.REGISTER_SETTINGS) return 'register';
    if (c.sidebarModal === constants.ANALYTICS_SETTINGS) return 'analytics';
    if (c.sidebarModal === constants.EMAIL_TEMPLATE) return 'emailTemplate';
    if (c.sidebarModal === constants.RECURITER_SETTINGS) return 'recruiter';
    if (c.sidebarModal === constants.API_CREDENTIALS) return 'apiCredentials';
    if (c.sidebarModal === constants.ACTIVITIES) return 'activities';
    if (c.sidebarModal === constants.GAMIFICATION) return 'gamification';
    return '';
  };

  const active = (key, value) => {
    const activeRow = returnActiveRow();
    if (key === 'background' && value === activeRow) {
      return {
        background: '#1e1e1e',
      };
    } else if (key === 'color' && value === activeRow) {
      return {
        color: '#fff',
      };
    }
  };

  const handleModal = payload => {
    dispatch({
      type: actions.TOGGLE_SIDEBAR_MODAL,
      payload,
    });
  };

  const handleCollapse = item => {
    let value = item;
    let icon = 'next';
    if (collapse === value) value = '';
    if (value) icon = 'down-arrow';
    setState(state => ({ ...state, collapse: value, icon }));
  };

  const handleEditClientModal = () => {
    dispatch(clientActions.toggleCreateClientModal());
  };

  const handleDeleteClientModal = () => {
    dispatch({ type: actions.TOGGLE_DELETE_CLIENT_MODAL });
  };

  const handleDelete = () => {
    dispatch(clientActions.deleteClient(props.clientId, history));
  };

  const activeRow = returnActiveRow();
  const { handleDropDown, showDropDown, searchKey, handleSearch, name, logo, subdomain } = props;

  const { allActiveClients, isOnlyActiveLoading: isLoading, showDeleteClientModal } = clients;

  return (
    <div className="client-settings-sidebar">
      <div
        className="client-select"
        ref={node => {
          clients.node = node;
        }}
      >
        <div className="client-dropdown-btn" onClick={handleDropDown}>
          <div className="display-tablee">
            <img className="logo" src={logo} alt={name} />
            <span>{name}</span>
          </div>
          <i className="icon icon-down-arrow float-right" />
        </div>
        {showDropDown ? (
          <ClientDropdownList
            isLoading={isLoading}
            clients={allActiveClients}
            searchKey={searchKey}
            handleSearch={handleSearch}
          />
        ) : null}
      </div>
      <div className="clent-setting-navbar">
        <ul>
          <li>
            <a className={activeRow === '' ? 'active' : ''}>
              <i className="icon icon-branding" />
              <span>Branding</span>
            </a>
          </li>
          <li onClick={handleEditClientModal}>
            <span className="haslink">
              <i className="icon icon-controls" />
              <span>Edit Client</span>
            </span>
          </li>
          <li
            onClick={() => handleModal(constants.REGISTER_SETTINGS)}
            style={active('background', 'register')}
          >
            <span className="haslink" style={active('color', 'register')}>
              <i className="icon icon-user" />
              <span>Register Settings</span>
            </span>
          </li>
          <li onClick={() => handleModal(constants.SSO)} style={active('background', 'SSO')}>
            <span className="haslink" style={active('color', 'SSO')}>
              <i className="icon icon-super-admin" />
              <span>SSO (SAML)</span>
            </span>
          </li>
          <li
            onClick={() => handleModal(constants.API_CREDENTIALS)}
            style={active('background', 'apiCredentials')}
          >
            <span className="haslink" style={active('color', 'apiCredentials')}>
              <i className="icon icon-lock" />
              <span>API Credentials</span>
            </span>
          </li>
          <li
            onClick={() => handleModal(constants.ANALYTICS_SETTINGS)}
            style={active('background', 'analytics')}
          >
            <span className="haslink" style={active('color', 'analytics')}>
              <i className="icon icon-tools" />
              <span>Analytics Settings</span>
            </span>
          </li>
          <li
            onClick={() => handleModal(constants.RECURITER_SETTINGS)}
            style={active('background', 'recruiter')}
          >
            <span className="haslink" style={active('color', 'recruiter')}>
              <i className="icon icon-clients" />
              <span>Recruiter Settings</span>
            </span>
          </li>
          <li
            onClick={() => handleModal(constants.POLICY_SETTINGS)}
            style={active('background', 'policy')}
          >
            <span className="haslink" style={active('color', 'policy')}>
              <i className="icon icon-file" />
              <span>Policy &amp; Conditions</span>
            </span>
          </li>
          <li>
            <Link to={`/clientadmin/${subdomain}`} className="">
              <i className="icon icon-add-user" />
              <span>Client Admin</span>
            </Link>
          </li>
          <li
            onClick={() => handleModal(constants.EMAIL_TEMPLATE)}
            style={active('background', 'emailTemplate')}
          >
            <span className="haslink" style={active('color', 'emailTemplate')}>
              <i className="icon icon-mail" />
              <span>Email Template</span>
            </span>
          </li>
          <li
            onClick={() => handleModal(constants.PAYMENT_SETTINGS)}
            style={active('background', 'payment')}
          >
            <span className="haslink" style={active('color', 'payment')}>
              <i className="icon icon-bitcoin" />
              <span>Payment Settings</span>
            </span>
          </li>
          <li>
            <Link to={`/${subdomain}/${props.clientId}/voucher-settings`} className="">
              <i className="icon icon-bitcoin" />
              <span>Voucher Settings</span>
            </Link>
          </li>

          <li
            onClick={() => handleModal(constants.GAMIFICATION)}
            style={active('background', 'gamification')}
          >
            <span className="haslink" style={active('color', 'gamification')}>
              <i className="icon icon-clients" />
              <span>Gamification</span>
            </span>
          </li>

          <div>
            <li onClick={() => handleCollapse('logs')}>
              <span className="haslink">
                <i className="icon icon-file" />
                <span>Logs</span>
                <i className={`icon icon-${icon} pull-right`}></i>
              </span>
            </li>
            <Collapse isOpen={collapse === 'logs'}>
              <ul className="ml-2">
                <li>
                  <Link to={`/${subdomain}/${props.clientId}/credit-logs`} className="">
                    <i className="icon icon-log-file" />
                    <span>Credit Logs</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${subdomain}/${props.clientId}/voucher-logs`} className="">
                    <i className="icon icon-log-file" />
                    <span>Voucher Logs</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${subdomain}/payment-logs`} className="">
                    <i className="icon icon-log-file" />
                    <span>Payment Logs</span>
                  </Link>
                </li>

                <li>
                  <Link to={`/${subdomain}/${props.clientId}/registration-logs`} className="">
                    <i className="icon icon-log-file" />
                    <span>Registration Logs</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${subdomain}/${props.clientId}/course-purchase-logs`} className="">
                    <i className="icon icon-log-file" />
                    <span>Course Purchase Logs</span>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </div>

          <li
            onClick={() => handleModal(constants.CLONE)}
            style={active('background', 'courseClone')}
          >
            <span className="haslink" style={active('color', 'courseClone')}>
              <i className="icon icon-floppy-disk" />
              <span>Clone</span>
            </span>
          </li>
          <li onClick={handleDeleteClientModal}>
            <span className="haslink delete">
              <i className="icon icon-trash" />
              <span>Revoke Client Access</span>
            </span>
            {showDeleteClientModal ? <DeletePopup handleDelete={handleDelete} /> : null}
          </li>
        </ul>
      </div>
    </div>
  );
}
