import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Jumbotron,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';

const Credit = props => {
  const {
    credit,
    onGenerateNewPin,
    onChangePassword,
    handleChangeForCredit,
    handlePaymentCreditSystemSave,
    handleChangeCreditMonetaryMappingError,
  } = props;

  const onChangeMonetaryMapping = (e, index) => {
    let temp = credit.monetaryMapping.mapping;
    temp[index][e.target.name] = Number(e.target.value);

    handleChangeForCredit({ target: { name: 'monetaryMapping', value: temp } });
    let isInvalid = temp.filter(ite => ite.monetaryValue < 1);

    if (Array.isArray(isInvalid) && isInvalid.length) {
      handleChangeCreditMonetaryMappingError(true);
    } else {
      handleChangeCreditMonetaryMappingError(false);
    }
  };

  const data = {
    labels: ['Total Credits', 'Credits used', 'Available credits'],
    datasets: [
      {
        label: '# of Votes',
        data: [
          credit?.totalAssignedBalance || 0,
          credit?.totalAssignedBalance || 0,
          credit?.usedBalance || 0,
        ],
        borderWidth: 0,
        backgroundColor: ['#818181', '#1e1e1e'],
        hoverBackgroundColor: ['#818181', '#1e1e1e'],
      },
    ],
  };

  const options = {
    plugins: {
      labels: false,
    },
    // rotation: 1 * Math.PI,
    // circumference: 1 * Math.PI,
    // cutoutPercentage: 70,
    legend: {
      display: true,
      position: 'right',
      align: 'start',
      reverse: true,
      labels: {
        usePointStyle: true,
        fontSize: 14,
        fontColor: '#343434',
        padding: 20,
      },
    },
  };

  return (
    <Col sm="12">
      <Form className="log-form">
        <FormGroup>
          <label className="font-weight-normal">
            <div className="checkbox float-left">
              <input
                checked={credit.isActive}
                type="checkbox"
                name="isActive"
                onChange={handleChangeForCredit}
              />
              <label></label>
            </div>
            &nbsp; Enable Credit To Clients
          </label>
        </FormGroup>

        {credit.isActive ? (
          <>
            <Row>
              <Col md={5}>
                <Row>
                  <Col md={8}>
                    <FormGroup row>
                      <Label xs={5} htmlFor="credit-name">
                        Credit name
                      </Label>
                      <Col xs={7}>
                        <Input
                          onChange={handleChangeForCredit}
                          defaultValue={credit.name}
                          name="credit-name"
                          id="credit-name"
                          required=""
                          type="text"
                          className="form-control"
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={8}>
                    <FormGroup row>
                      <Label xs={5} htmlFor="add-credit">
                        Total Credit
                      </Label>
                      <Col xs={7}>
                        <Input
                          onChange={handleChangeForCredit}
                          defaultValue={credit.totalAssignedBalance}
                          name="totalAssignedBalance"
                          id="add-credit"
                          required=""
                          type="number"
                          min="1"
                          className="form-control"
                        />
                        {props.totalCreditError ? (
                          <span style={{ color: 'red', fontWeight: 'bold' }}> Invalid Value </span>
                        ) : (
                          ''
                        )}
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={7}>
                <Jumbotron fluid className="p-3">
                  {/* <Label>Reporting</Label> */}
                  <Row>
                    <Col xs={7}>
                      {/* <Doughnut data={data} options={options} /> */}
                      <Pie data={data} options={options} />
                    </Col>
                    <Col xs={5} className="d-flex justify-content-center align-items-center">
                      <p className="lead">
                        Total Credits <br />
                        <strong>{credit.totalAssignedBalance}</strong> <br /> <br />
                        Credits used <br />
                        <strong>{credit.usedBalance}</strong> <br /> <br />
                        Available Credits <br />
                        <strong>{credit.availableBalance}</strong>
                      </p>
                    </Col>
                  </Row>
                </Jumbotron>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <Label> Credit Monetary Value </Label>
                {/* LOOP ================================================ */}
                <Row>
                  <Col md={12}>
                    <ListGroup className="mb-2">
                      <ListGroupItem>
                        <Row>
                          <Col md={3}>
                            <FormGroup row>
                              <Label xs={6} htmlFor="Creditbalance">
                                Credit Value
                              </Label>
                              <Col xs={6}>
                                <Input
                                  value={credit?.monetaryMapping?.creditValue}
                                  name="monetaryValueCreditValue"
                                  onChange={handleChangeForCredit}
                                  id="Creditbalance"
                                  required=""
                                  type="number"
                                  min="1"
                                  className="form-control"
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md={9}>
                            <Row>
                              {credit?.monetaryMapping?.mapping.map((ite, index) => {
                                return (
                                  <>
                                    <Col sm={3} key={index}>
                                      <FormGroup row>
                                        <Label xs={6} htmlFor="Pound" className="text-right">
                                          {ite.currency}
                                        </Label>
                                        <Col xs={6}>
                                          <Input
                                            name="monetaryValue"
                                            onChange={e => onChangeMonetaryMapping(e, index)}
                                            value={ite.monetaryValue}
                                            id={ite.currency}
                                            required=""
                                            type="number"
                                            min="1"
                                            className="form-control"
                                          />
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                  </>
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
                {/* ===================================================== */}
              </Col>
            </Row>

            <br />
            <Row>
              <Col md={7}>
                <FormGroup>
                  <label className="font-weight-normal">
                    <div className="checkbox float-left">
                      <input
                        name="isPrivate"
                        onChange={handleChangeForCredit}
                        checked={credit.isPrivate}
                        type="checkbox"
                      />
                      <label></label>
                    </div>
                    &nbsp; Private Credit
                  </label>
                  {credit.isPrivate && credit?.pin ? '    ( Pin   :  ' + credit.pin + ' )' : ''}

                  {credit.isPrivate && credit?.pin ? (
                    <>
                      <InputGroup className="ml-5">
                        <Label htmlFor="generatePin">
                          &nbsp;Enter your superadmin password to generate new pin
                        </Label>
                        <Input
                          name="password"
                          id="password"
                          onChange={onChangePassword}
                          required=""
                          type="password"
                          // className="form-control"
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="secondary" size="sm" onClick={() => onGenerateNewPin()}>
                            Generate!
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>

                      {/* <Input
                      xs={6}
                      name="password"
                      id="password"
                      onChange={onChangePassword}
                      required=""
                      type="password"
                      className="form-control"
                    />
                    <Button
                      style={{ marginTop: '20px', marginBottom: '20px' }}
                      onClick={() => onGenerateNewPin()}
                    >
                      Generate
                    </Button> */}
                    </>
                  ) : (
                    ''
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
        ) : (
          ''
        )}

        <Row>
          <Col md={6}>
            {credit.isActive ? (
              <>
                <FormGroup>
                  <label className="font-weight-normal">
                    <div className="checkbox float-left">
                      <input
                        onChange={handleChangeForCredit}
                        checked={credit.automaticAssignment.isEnabled}
                        type="checkbox"
                        name="automaticAssignment"
                      />
                      <label></label>
                    </div>
                    &nbsp; Automatic Assignment
                  </label>
                </FormGroup>
                {credit.automaticAssignment.isEnabled ? (
                  <>
                    <FormGroup row>
                      <Label xs={{ size: 3, offset: 1 }} htmlFor="AssignmentBalance">
                        Total Assigned
                      </Label>
                      <Col xs={3}>
                        <Input
                          name="totalAssignedAutomatic"
                          onChange={handleChangeForCredit}
                          id="AssignmentBalance"
                          defaultValue={credit.automaticAssignment.assignedBalance}
                          required=""
                          type="number"
                          min="1"
                          className="form-control"
                        />
                        {props.totalAssignedCreditError ? (
                          <span style={{ color: 'red', fontWeight: 'bold' }}> Invalid Value </span>
                        ) : (
                          ''
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label xs={{ size: 6, offset: 1 }} htmlFor="AvailableBalance">
                        Automatic assigned Allowed to admin
                      </Label>
                      <Col xs={3} className="d-flex align-items-center justify-content-start">
                        <div className="checkbox float-left">
                          <input
                            onChange={handleChangeForCredit}
                            checked={credit.automaticAssignment.isAutomaticAssignmentAllowedToAdmin}
                            type="checkbox"
                            name="isAutomaticAssignmentAllowedToAdmin"
                          />
                          <label></label>
                        </div>
                      </Col>
                    </FormGroup>
                  </>
                ) : (
                  <></>
                )}{' '}
              </>
            ) : (
              ''
            )}

            {!credit.isActive && (
              <FormGroup>
                <label className="font-weight-normal">
                  <div className="checkbox float-left">
                    <input
                      checked={credit.isAppliedToExistingLearner}
                      type="checkbox"
                      name="isAppliedToExistingLearner"
                      onChange={handleChangeForCredit}
                    />
                    <label></label>
                  </div>
                  &nbsp; Apply to existing learner
                </label>
              </FormGroup>
            )}
          </Col>
        </Row>
      </Form>
      <div className="settings-footer">
        <div className="btn btn-add">Cancel</div>
        <div className="btn btn-add" onClick={() => handlePaymentCreditSystemSave()}>
          Save
        </div>
      </div>
    </Col>
  );
};

export default Credit;
