import axios from 'axios';

class Api {
  constructor() {
    this.base_url = `${process.env.REACT_APP_API_URL}/superadmin`;
    this.baseApiUrl = `${process.env.REACT_APP_BASE_API_URL}/api`;
    // this.baseApiUrl = `http://localhost:8086/api`;
    // this.base_url = 'http://localhost:8088/superadmin';
  }

  login({ userId, password }) {
    const self = this;

    let payloadData = {
      userId,
      password,
    };

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: self.base_url + '/login',
        data: payloadData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  logout() {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: self.base_url + '/logout',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  fetchClients({
    currentPage,
    pageLimit = '',
    searchKey = '',
    sortWith = '',
    sortOrder = '',
    onlyActive = '',
  }) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'get',
        url: `${self.base_url}/fetch-clients`,
        params: {
          limit: pageLimit,
          pageNumber: currentPage,
          stype: sortWith,
          sdir: sortOrder,
          search: searchKey,
          only_active: onlyActive,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  createClient(payload) {
    const self = this;

    const {
      name,
      subdomain,
      ahqId,
      appId,
      appSecretKey,
      logo,
      email,
      password,
      firstName,
      lastName,
      userId,
      country,
      dialcode,
      phone,
    } = payload;

    const data = {
      name,
      subdomain,
      ahqId,
      appId,
      appSecretKey,
      logo,
      email,
      password,
      firstName,
      lastName,
      userId,
      country,
      dialcode,
      phone,
    };

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: self.base_url + '/create-client',
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  videoUpload(formData) {
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.post['Access-Control-Allow-Headers'] =
      'Origin, X-Requested-With, Content-Type, Accept';

    const self = this;
    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: self.base_url + '/large-upload',
        data: formData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          console.log('videoupload', response);

          resolve(response.data.file);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  imageUpload(formData) {
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.post['Access-Control-Allow-Headers'] =
      'Origin, X-Requested-With, Content-Type, Accept';

    const self = this;
    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: self.base_url + '/upload',
        data: formData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          console.log('imageupload', response);

          resolve(response.data.file);
          // resolve(response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  fetchClient(id) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'get',
        url: self.base_url + '/fetch-client/' + id,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  updateClient(payload) {
    const self = this;

    // const {
    //   name,
    //   subdomain,
    //   ahqId,
    //   appId,
    //   appSecretKey,
    //   logo,
    //   clientId,
    // } = payload;
    const { clientId } = payload;

    // const data = { name, subdomain, ahqId, appId, appSecretKey, logo };
    const data = payload;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/update-client/' + clientId,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  generatePin(payload) {
    const self = this;

    const data = payload;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: self.base_url + '/change-credit-pin',
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  updateClientKey(payload) {
    const self = this;

    const { name, subdomain, ahqId, appId, appSecretKey, logo, clientId } = payload;

    const data = { name, subdomain, ahqId, appId, appSecretKey, logo };

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/update-client-key/' + clientId,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  deleteClient(id) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/revoke-access-to-client/' + id,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  fetchSuperAdmins({
    currentPage = 1,
    pageLimit = '',
    searchKey = '',
    sortWith = '',
    sortOrder = '',
    onlyActive = '',
  }) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'get',
        url: `${self.base_url}/fetch-superadmin`,
        params: {
          limit: pageLimit,
          pageNumber: currentPage,
          stype: sortWith,
          sdir: sortOrder,
          search: searchKey,
          only_active: onlyActive,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  createSuperAdmin(payload) {
    const self = this;

    const { firstName, lastName, email, userId, password, profilePic } = payload;

    const data = { firstName, lastName, email, userId, password, profilePic };

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: self.base_url + '/create-superadmin',
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  deleteSuperAdmin(id) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/revoke-access-to-superadmin/' + id,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  createClientAdmin(payload) {
    const self = this;

    const {
      firstName,
      lastName,
      email,
      userId,
      password,
      subdomain,
      country,
      phone,
      dialcode,
    } = payload;

    const data = { firstName, lastName, email, userId, password, country, phone, dialcode };

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: self.base_url + '/create-client-admin',
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          subdomain,
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  grantAccess(id) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/grant-access-to-client/' + id,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  fetchLogs({
    currentPage,
    pageLimit = '',
    searchComponent = '',
    searchUserId = '',
    searchSubComponent = '',
    searchgte = '',
    searchlte = '',
  }) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'get',
        url: `${self.base_url}/fetch-all-logs`,
        params: {
          limit: pageLimit,
          pageNumber: currentPage,
          searchComponent,
          searchUserId,
          searchSubComponent,
          searchgte,
          searchlte,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  fetchLogsComponent() {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'get',
        url: `${self.base_url}/fetch-logs-components`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  changePassword(payload) {
    const self = this;

    const { oldPassword, newPassword, id } = payload;

    const data = { oldPassword, newPassword };

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/change-password/' + id,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  fetchClientAdmin({ currentPage = 1, pageLimit = 20, searchKey = '', subdomain }) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'get',
        url: `${self.base_url}/fetch-users?limit=${pageLimit}&pageNumber=${currentPage}&search=${searchKey}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          subdomain,
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  forgotPassword(email) {
    const self = this;

    const data = { email };
    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        // url: self.base_url + '/change-password/' + id,
        // url:'http://192.168.20.139:8088/superadmin' + '/request-forgot-password/',
        url: self.base_url + '/request-forgot-password',
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  checkResetPasswordUrl(hash) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'get',
        // url:'http://192.168.20.139:8088/superadmin' + '/reset-password/' + hash,
        url: self.base_url + '/reset-password/' + hash,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  resetPassword(payload) {
    const { email, hash } = payload;
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        // url: `http://192.168.20.139:8088/superadmin/set-new-password/${email}/${hash}`,
        url: `${self.base_url}/set-new-password/${email}/${hash}`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  fetchPaymentLogs({ currentPage = 1, pageLimit = 20, subdomain }) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'get',
        url: `${self.base_url}/fetch-payment-logs/?page_number=${currentPage}&limit=${pageLimit}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          subdomain,
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  updateCreditSystemOnSave(payload) {
    const self = this;

    const { clientId } = payload;

    const data = payload;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/assign-credit-to-client/' + clientId,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  updateMembershipCharge(payload) {
    const self = this;

    const { clientId } = payload;

    const data = payload;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/update-membership-charge/' + clientId,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  updateClientRegistrationDetails(payload) {
    const self = this;

    const { clientId } = payload;

    const data = payload;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/update-registration-details/' + clientId,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  updateAnalytics(payload) {
    const self = this;

    const { clientId } = payload;

    const data = payload;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/update-analytics/' + clientId,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  updateClientPolicy(payload) {
    const self = this;

    const { clientId } = payload;

    const data = payload;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/update-policy/' + clientId,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  updateRecruiterDetails(payload) {
    const self = this;

    const { clientId } = payload;

    const data = payload;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: self.base_url + '/update-recruiter-details/' + clientId,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  courseClone(data) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: self.base_url + '/clone-contents',
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  fetchClientCourses({ pageNumber = 1, courseSearchKey = '', cloneFrom }) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'get',
        url: `${self.base_url}/fetch-course-content/${cloneFrom}?page=${pageNumber}&search=${courseSearchKey}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  createApiCredentials(data) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: self.base_url + '/api-credentials',
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  showApiCredentials(data) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: self.base_url + '/get-api-credentials',
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /*-------Voucher APIs start----------*/
  fetchVouchers({
    currentPage,
    pageLimit = '',
    searchKey = '',
    isExpired = null,
    status = null,
    fromDate = null,
    toDate = null,
    subdomain,
    clientId,
  }) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: `${self.base_url}/voucher/list`,
        data: {
          limit: pageLimit,
          pageNumber: currentPage,
          isExpired: isExpired,
          search: searchKey,
          status: status,
          fromDate: fromDate,
          toDate: toDate,
          clientId: clientId,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          subdomain,
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  createVoucher(payload) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: `${self.base_url}/voucher/create`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          subdomain: payload.subdomain,
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  checkVoucherTitle(payload) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: `${self.base_url}/voucher/check-unique-title`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          subdomain: payload.subdomain,
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  editVoucher(payload) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: `${self.base_url}/voucher/update`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          subdomain: payload.subdomain,
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  fetchVoucherLogs({
    currentPage,
    pageLimit = '',
    searchKey = '',
    actionType = null,
    fromDate = null,
    toDate = null,
    subdomain,
  }) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: `${self.base_url}/voucher/logs`,
        data: {
          limit: pageLimit,
          pageNumber: currentPage,
          actionType: actionType,
          search: searchKey,
          fromDate: fromDate,
          toDate: toDate,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          subdomain,
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  //credit logs
  fetchCreditLogs({
    currentPage,
    pageLimit = '',
    searchKey = '',
    actionType = null,
    fromDate = null,
    clientId,
    toDate = null,
    subdomain,
  }) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'get',
        url: `${self.base_url}/credit-logs/` + clientId,
        params: {
          limit: pageLimit,
          pageNumber: currentPage,
          actionType: actionType,
          search: searchKey,
          fromDate: fromDate,
          toDate: toDate,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          subdomain,
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  /*-------Voucher APIs end----------*/
  /*------ Get Learner  Registration Logs ---------*/
  fetchRegistrationLogs({
    currentPage,
    pageLimit = '',
    searchKey = '',
    voucherCode = '',
    actionType = null,
    fromDate = null,
    toDate = null,
    subdomain,
  }) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: `${self.base_url}/learner-registration-logs`,
        data: {
          limit: pageLimit,
          pageNumber: currentPage,
          actionType: actionType,
          search: searchKey,
          voucherCode: voucherCode,
          fromDate: fromDate,
          toDate: toDate,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          subdomain,
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getCountryAndDialCodes() {
    const self = this;
    return axios({
      method: 'get',
      url: `${self.base_url}/countries-list`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => {
      return response.data;
    });
  }
  /*------ Get Course Purchase Logs ---------*/
  fetchCoursePurchaseLogs(params) {
    const self = this;
    const {
      pageSize,
      currentPage,
      actionType,
      userSearch,
      courseSearch,
      voucherCode,
      fromDate,
      toDate,
      status,
      subdomain,
    } = params;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'post',
        url: `${self.base_url}/course-purchase-logs`,
        data: {
          limit: pageSize,
          pageNumber: currentPage,
          actionType: actionType,
          userSearch: userSearch,
          courseSearch: courseSearch,
          voucherCode: voucherCode,
          fromDate: fromDate,
          toDate: toDate,
          status: status,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          subdomain,
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  configureSSOSAML({ payload, clientId }) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'put',
        url: `${self.base_url}/sso-saml/${clientId}`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getEmailTemplate(clientId) {
    const self = this;

    return new Promise(function(resolve, reject) {
      axios({
        method: 'get',
        url: `${self.base_url}/email-template/${clientId}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  saveEmailTemplate(payload, clientId) {
    const self = this;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${self.base_url}/email-template/${clientId}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: payload,
      })
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  }

  // =============================================================================
  // Gamification Activities And Components
  // =============================================================================

  getActivities({ pageNumber = 1, all = false }) {
    const self = this;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${self.base_url}/gamification/activities?pageNumber=${pageNumber}&all=${all}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  }

  getComponents({ pageNumber = 1, all = false }) {
    const self = this;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${self.base_url}/gamification/pathway-components?pageNumber=${pageNumber}&all=${all}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  }

  getAccolades({ pageNumber = 1, all = false }) {
    const self = this;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${self.base_url}/gamification/pathway-accolades?pageNumber=${pageNumber}&all=${all}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  }

  getRecommendations({ pageNumber = 1, all = false }) {
    const self = this;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${self.base_url}/gamification/pathway-recommendations?pageNumber=${pageNumber}&all=${all}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  }

  configureGamification(payload) {
    const self = this;

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${self.base_url}/gamification/configure`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  }

  postActivities(payload) {
    const self = this;
    const data = payload;

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${self.base_url}/gamification/activities`,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  }

  saveActivitiesToClient(payload) {
    const self = this;

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${self.base_url}/gamification/assign-activities`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  }
}

export default Api;
