import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import ClientModal from 'components/ClientModal';
import Pagination from 'components/Pagination';
import GrantAccessPopup from 'components/GrantAccessPopup';
import {
  getClients,
  toggleGrantAccessModal,
  grantAccess,
  toggleCreateClientModal,
  copyUrl,
} from 'actions/clientActions';
import { clientUrl } from 'utils/helper';
import { CREATE_EDIT_CLIENT } from 'common/constants';

toast.configure();

class Clients extends Component {
  state = {
    dropdownOpen: false,
    clientId: '',
    isError: false,
    totalClients: 0,
    currentPage: 1,
    pageLimit: 20,
    totalPage: 0,
    searchKey: '',
    sortWith: 'createdAt',
    sortOrder: 1,
  };

  componentDidMount() {
    this.fetchData();
    this.showCreateModalFromSidebar();
  }

  showCreateModalFromSidebar = () => {
    const { location } = this.props;
    if (location.state && location.state.fromSideBar) {
      this.handleCreateClientModal();
      this.props.history.replace({
        pathname: '/clients',
        state: {},
      });
    }
  };

  componentDidUpdate() {
    this.showCreateModalFromSidebar();
  }

  fetchData = () => {
    const { currentPage, pageLimit, searchKey, sortWith, sortOrder } = this.state;

    const payload = {
      currentPage,
      pageLimit,
      searchKey,
      sortWith,
      sortOrder,
    };

    this.props.getClients(payload);
  };

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({ currentPage: index }, () => {
      this.fetchData();
    });
  };

  handleCreateClientModal = () => {
    this.props.toggleCreateClientModal();
  };

  handleSort = e => {
    this.setState({ sortWith: e.target.value }, () => {
      this.fetchData();
    });
  };

  handleSearch = e => {
    const eventKey = e.key;
    this.setState({ searchKey: e.target.value }, () => {
      if (eventKey === 'Enter') {
        this.setState({ currentPage: 1 }, () => {
          this.fetchData();
        });
      }
    });
  };

  toggleGrantAccess = (e, clientId) => {
    this.props.toggleGrantAccessModal(clientId);
  };

  toggleAction = clientId => {
    if (!this.state.dropdownOpen) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
      clientId,
    }));
  };

  handleOutsideClick = e => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    this.toggleAction();
  };

  handleGrantAccess = () => {
    this.props.grantAccess(this.props.clients.grantAccessId);
  };

  handleSortOrder = () => {
    const { sortOrder } = this.state;

    if (sortOrder) {
      return this.setState({ sortOrder: 0 }, () => this.fetchData());
    } else {
      this.setState({ sortOrder: 1 }, () => this.fetchData());
    }
  };

  copyLoginUrl = (id, isDeleted) => {
    this.props.copyUrl(id, isDeleted);
  };

  render() {
    const {
      // dropdownOpen,
      // clientId,
      currentPage,
      sortWith,
      searchKey,
      pageLimit,
      sortOrder,
    } = this.state;
    const {
      clients,
      isLoading,
      totalClients,
      totalPage,
      showGrantAccessModal,
      grantAccessId,
      isGrantAccessLoading,
      sidebarModal,
    } = this.props.clients;
    const { handlePagination, handleCreateClientModal } = this;

    return (
      <>
        <div className="content">
          {sidebarModal === CREATE_EDIT_CLIENT ? <ClientModal /> : null}
          <div className="title">
            <span>List of</span>
            <h1>Clients</h1>
            <div className="btn-container">
              <button className="btn btn-add" onClick={handleCreateClientModal}>
                <i className="icon icon-plus"></i>Create Client
              </button>
            </div>
          </div>
          {isLoading ? (
            <span>
              <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...
            </span>
          ) : (
            <>
              <Row className="filter-options">
                <Col xs="12" className="form-inline">
                  <div className="form-group searchbar">
                    <input
                      type="text"
                      name="name"
                      placeholder="Search by name"
                      value={searchKey}
                      onChange={this.handleSearch}
                      onKeyDown={this.handleSearch}
                    />
                  </div>
                  <div className="form-group sorting">
                    <span
                      className={sortOrder ? 'icon icon-z-a' : 'icon icon-a-z'}
                      onClick={this.handleSortOrder}
                      style={{ pointerEvents: sortWith ? '' : 'none' }}
                    />
                    <label>Sort by: </label>
                    <select placeholder="Sort by" onChange={this.handleSort} value={sortWith}>
                      <option value="">Sort by</option>
                      <option value="name">Clients Name</option>
                      <option value="subdomain">Sub Domain</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {!clients.length ? (
                <h2>No clients to display</h2>
              ) : (
                <Row>
                  <Col md="12">
                    <Table className="base-table clients-table" responsive>
                      <thead>
                        <tr>
                          <th>S.N</th>
                          <th>Clients Name</th>
                          <th>Sub-domain</th>
                          <th>AHQ ID</th>
                          <th>Registeration Page URL</th>
                          <th>Login Page URL</th>
                          <th className="text-center width-140">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clients.map((client, index) => {
                          let deletedOpacity = {
                            opacity: client.isDeleted ? '0.6' : '1',
                          };

                          return (
                            <tr key={client._id}>
                              <td scope="row" style={deletedOpacity}>
                                {currentPage * pageLimit - pageLimit + (index + 1)}
                              </td>
                              <td style={deletedOpacity} width="200px">
                                <img className="logo" src={client.logo} alt="skillbase" />
                                {client.name}
                              </td>
                              <td style={deletedOpacity}>{client.subdomain}</td>
                              <td style={deletedOpacity}>{client.ahqId}</td>
                              <td
                                className={client.isDeleted ? '' : 'copy'}
                                title="Copy URL"
                                style={deletedOpacity}
                              >
                                <span
                                  id={`${client._id}_register`}
                                  onClick={() =>
                                    this.copyLoginUrl(`${client._id}_register`, client.isDeleted)
                                  }
                                >
                                  {clientUrl(client.subdomain, 'register')}
                                </span>
                              </td>
                              <td
                                className={client.isDeleted ? '' : 'copy'}
                                title="Copy URL"
                                style={deletedOpacity}
                              >
                                <span
                                  id={`${client._id}_login`}
                                  onClick={() =>
                                    this.copyLoginUrl(`${client._id}_login`, client.isDeleted)
                                  }
                                >
                                  {clientUrl(client.subdomain, 'login')}
                                </span>
                              </td>
                              <td className="has-popup text-center">
                                {/* <div
                                  ref={node => {
                                    this.node = node;
                                  }}
                                >
                                  <span
                                    onClick={() => {
                                      this.toggleAction(client._id);
                                    }}
                                    className="option"
                                  >
                                    <i className="icon icon-more haslink" />
                                  </span> */}
                                {/* {dropdownOpen && client._id === clientId ? ( */}
                                {/* <ul className="clients-action"> */}
                                <ul>
                                  {client.isDeleted ? (
                                    <li
                                      className="btn-setting"
                                      onClick={e => this.toggleGrantAccess(e, client._id)}
                                    >
                                      Grant Access
                                    </li>
                                  ) : (
                                    <li>
                                      <Link
                                        className="btn-setting"
                                        to={`/client-settings/${client._id}`}
                                      >
                                        Client Setting
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                                {/* ) : null} */}
                                {showGrantAccessModal && client._id === grantAccessId ? (
                                  <GrantAccessPopup
                                    cancelGrantAccess={this.toggleGrantAccess}
                                    handleGrantAccess={this.handleGrantAccess}
                                    isLoading={isGrantAccessLoading}
                                    subdomain={client.subdomain}
                                  />
                                ) : null}
                                {/* </div> */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Pagination
                      total={totalClients}
                      currentPage={currentPage}
                      totalPage={totalPage}
                      pageLimit={pageLimit}
                      handleClick={handlePagination}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

Clients.propTypes = {
  getClients: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
  toggleGrantAccessModal: PropTypes.func.isRequired,
  grantAccess: PropTypes.func.isRequired,
  toggleCreateClientModal: PropTypes.func.isRequired,
  copyUrl: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  clients: state.client,
});

export default connect(mapStateToProps, {
  getClients,
  toggleGrantAccessModal,
  grantAccess,
  toggleCreateClientModal,
  copyUrl,
})(Clients);
