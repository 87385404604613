import Api from 'API';
import { UPLOAD_DATA, UPLOAD_LOADING } from './types';

export const setUploadLoading = () => {
  return {
    type: UPLOAD_LOADING,
  };
};

export const uploadImage = (file, name) => dispatch => {
  const formData = new FormData();
  formData.append('image', file);

  dispatch(setUploadLoading());

  new Api()
    .imageUpload(formData)
    .then(result => {
      console.log({ imageuploadresult: result });
      const payload = {
        imageLocation: result[0].image.Location,
        imageName: name,
      };

      dispatch({
        type: UPLOAD_DATA,
        payload,
      });
    })
    .catch(err =>
      dispatch({
        type: UPLOAD_DATA,
        payload: '',
      })
    );
};

export const uploadVideo = (file, name) => dispatch => {
  const formData = new FormData();
  formData.append('video', file);

  dispatch(setUploadLoading());

  new Api()
    .videoUpload(formData)
    .then(result => {
      console.log({ videoupload: formData });
      const payload = {
        imageLocation: result.Location,
        imageName: name,
      };

      dispatch({
        type: UPLOAD_DATA,
        payload,
      });
    })
    .catch(err =>
      dispatch({
        type: UPLOAD_DATA,
        payload: '',
      })
    );
};
