import React, { Component } from 'react';

import { Row, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Pagination from 'components/Pagination';
import { getLogs, getLogsCompmonent } from 'actions/logActions';
import ViewMore from 'components/Logs/view';
import Filter from 'components/Logs/filter';

class Logs extends Component {
  state = {
    currentPage: 1,
    pageLimit: 20,
    searchComponent: '',
    searchSubComponent: '',
    searchUserId: '',
    logId: '',
    showViewMoreModal: false,
    searchgte: '',
    searchlte: '',
  };

  componentDidMount() {
    this.fetchData();
    this.props.getLogsCompmonent();
  }

  fetchData = () => {
    const {
      currentPage,
      pageLimit,
      searchComponent,
      searchUserId,
      searchSubComponent,
      searchgte,
      searchlte,
    } = this.state;

    const payload = {
      currentPage,
      pageLimit,
      searchComponent,
      searchUserId,
      searchSubComponent,
      searchgte,
      searchlte,
    };

    this.props.getLogs(payload);
  };

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({ currentPage: index }, () => {
      this.fetchData();
    });
  };

  formatDate(date) {
    return new Date(date).toLocaleDateString('en-UK', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  toggleViewMore = id => {
    this.setState(prevState => ({
      showViewMoreModal: !prevState.showViewMoreModal,
      logId: id,
    }));
  };

  handleDateChange = (moment, name) => {
    if (!moment) {
      this.setState({
        // searchSubComponent: '',
        [name]: moment,
      });
    }
    if (typeof moment !== 'object') return;
    // event.persist();
    this.setState({
      // searchSubComponent: '',
      [name]: moment.toDate(),
    });
  };

  handleFilterChange = e => {
    e.persist();
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (e.target.name === 'searchComponent') {
          this.setState({ searchSubComponent: '' });
        }
      }
    );
  };

  handleFilter = () => {
    this.setState({ currentPage: 1 }, () => {
      this.fetchData();
    });
  };

  render() {
    const { currentPage, pageLimit, showViewMoreModal, logId } = this.state;
    const {
      logs,
      isLoading,
      totalLogs,
      totalPage,
      logsComponent,
    } = this.props.logs;

    return (
      <>
        <div className="content">
          <div className="title">
            <span>List of</span>
            <h1>Logs</h1>
          </div>
          <Filter
            logsComponent={logsComponent}
            handleFilterChange={this.handleFilterChange}
            handleDateChange={this.handleDateChange}
            searchComponent={this.state.searchComponent}
            searchUserId={this.searchUserId}
            handleFilter={this.handleFilter}
          />
          {isLoading ? (
            <span>
              <i className="fa fa-spinner fa-spin" aria-hidden="true" />{' '}
              Loading...
            </span>
          ) : (
            <>
              {!logs.length ? (
                <h2>No logs to display</h2>
              ) : (
                <Row>
                  <Col md="12">
                    <Table className="base-table clients-table" responsive>
                      <thead>
                        <tr>
                          <th>S.N</th>
                          <th>UserId</th>
                          <th>Level</th>
                          <th>Component</th>
                          <th>Operation</th>
                          <th>Date</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {logs[0].meta &&
                          logs.map((log, index) => {
                            return (
                              <tr key={log._id}>
                                <td scope="row">
                                  {currentPage * pageLimit -
                                    pageLimit +
                                    (index + 1)}
                                </td>
                                <td>
                                  {log.meta.headers.userId ||
                                    log.meta.component.subComponent.data.userId}
                                </td>
                                <td>{log.level}</td>
                                <td>{log.meta.component.name}</td>
                                <td>{log.meta.component.subComponent.name}</td>
                                <td>{this.formatDate(log.timestamp)}</td>
                                <td className="has-popup text-center">
                                  {typeof log.meta.component.subComponent
                                    .data === 'object' ? (
                                    <ul>
                                      <li
                                        className="btn-setting"
                                        onClick={e =>
                                          this.toggleViewMore(log._id)
                                        }
                                      >
                                        View More
                                      </li>
                                    </ul>
                                  ) : null}
                                  {showViewMoreModal && log._id === logId ? (
                                    <ViewMore
                                      toggleViewMore={this.toggleViewMore}
                                      data={log.meta.component.subComponent}
                                      showViewMoreModal={showViewMoreModal}
                                    />
                                  ) : null}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                    <Pagination
                      total={totalLogs}
                      currentPage={currentPage}
                      totalPage={totalPage}
                      pageLimit={pageLimit}
                      handleClick={this.handlePagination}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

Logs.propTypes = {
  getLogs: PropTypes.func.isRequired,
  getLogsCompmonent: PropTypes.func.isRequired,
  logs: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  logs: state.log,
});

export default connect(mapStateToProps, {
  getLogs,
  getLogsCompmonent,
})(Logs);
