import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Api from 'API';
import * as actions from './types';
import * as constants from 'common/constants';
import store from 'store';

toast.configure();

export const setClientLoading = () => {
  return {
    type: actions.CLIENT_LOADING,
  };
};

export const setActiveClientLoading = () => {
  return {
    type: actions.ACTIVE_CLIENT_LOADING,
  };
};

export const getClients = payload => dispatch => {
  if (payload.onlyActive) {
    dispatch(setActiveClientLoading());
  } else {
    dispatch(setClientLoading());
  }

  new Api()
    .fetchClients(payload)
    .then(result => {
      if (payload.pageLimit && payload.onlyActive) {
        return dispatch({
          type: actions.GET_ACTIVE_CLIENTS,
          payload: result.clients,
        });
      }

      if (payload.pageLimit) {
        result = { ...result, pageLimit: payload.pageLimit };

        return dispatch({
          type: actions.GET_CLIENTS,
          payload: result,
        });
      }

      dispatch({
        type: actions.GET_ALL_ACTIVE_CLIENTS,
        payload: result.clients,
      });
    })
    .catch(err => {
      const data = {
        clients: [],
        totalClients: 0,
        pageLimit: payload.pageLimit,
      };
      dispatch({
        type: actions.GET_CLIENTS,
        payload: data,
      });
    });
};

export const toggleGrantAccessModal = id => {
  return {
    type: actions.TOGGLE_GRANT_ACCESS_MODAL,
    payload: id,
  };
};

export const setGrantAccessLoading = () => {
  return {
    type: actions.GRANT_ACCESS_LOADING,
  };
};

export const grantAccess = payload => dispatch => {
  dispatch(setGrantAccessLoading());

  new Api()
    .grantAccess(payload)
    .then(result => {
      toast.success('Access granted successfully !');
      dispatch(setGrantAccessLoading());
      dispatch(toggleGrantAccessModal());
      dispatch(
        getClients({
          currentPage: 1,
          pageLimit: 20,
          sortWith: 'createdAt',
          sortOrder: 1,
        })
      );
    })
    .catch(err => {
      dispatch(setGrantAccessLoading());
      dispatch({
        type: actions.GET_ERROR,
        payload: err,
      });
    });
};

export const getClient = id => dispatch => {
  dispatch(setClientLoading());
  dispatch({ type: actions.CLEAR_ERROR });

  new Api()
    .fetchClient(id)
    .then(result => {
      return dispatch({
        type: actions.GET_CLIENT,
        payload: result.client,
      });
    })
    .catch(err =>
      dispatch({
        type: actions.GET_ERROR,
        payload:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : err,
      })
    );
};

export const updateClientOnReset = payload => {
  return {
    type: actions.GET_CLIENT,
    payload,
  };
};

export const updateClientOnInputChange = payload => {
  return {
    type: actions.UPDATE_CLIENT_ON_INPUT_CHANGE,
    payload,
  };
};

export const updateCoursePurchaseInstallmentPlan = payload => {
  return {
    type: actions.UPDATE_COURSE_PURCHASE_INSTALLMENT_PLAN,
    payload,
  };
};

export const clearClient = () => {
  return {
    type: actions.CLEAR_CLIENT,
  };
};

export const toggleCreateClientModal = () => {
  let payload = constants.CREATE_EDIT_CLIENT;
  const { sidebarModal } = store.getState().client;
  if (sidebarModal === payload) payload = '';

  return {
    type: actions.TOGGLE_SIDEBAR_MODAL,
    payload,
  };
};

export const createClient = payload => dispatch => {
  dispatch(setClientLoading());
  new Api()
    .createClient(payload)
    .then(response => {
      dispatch(setClientLoading());

      // Clear uploaded image url to prevent being used in other components
      dispatch({
        type: actions.CLEAR_UPLOAD_URL,
      });

      toast.success('Client created successfully !');
      dispatch(toggleCreateClientModal());

      // Fetch data to get the newly updated data
      dispatch(
        getClients({
          currentPage: 1,
          pageLimit: 20,
          sortWith: 'createdAt',
          sortOrder: 1,
        })
      );
    })
    .catch(err => {
      dispatch(setClientLoading());
      dispatch({
        type: actions.GET_ERROR,
        payload: err && err.response && err.response.data ? err.response.data : '',
      });
    });
};

export const editClient = payload => dispatch => {
  dispatch(setClientLoading());
  new Api()
    .updateClientKey(payload)
    .then(response => {
      dispatch(setClientLoading());

      // Clear uploaded image url to prevent being used in other components
      dispatch({
        type: actions.CLEAR_UPLOAD_URL,
      });

      toast.success('Client updated successfully !');
      dispatch(toggleCreateClientModal());

      // Fetch data to get the newly updated data
      dispatch(getClient(payload.clientId));
    })
    .catch(err => {
      dispatch(setClientLoading());
      dispatch({
        type: actions.GET_ERROR,
        payload: err && err.response && err.response.data ? err.response.data : '',
      });
    });
};

export const updateClientOnSave = (payload, history) => dispatch => {
  dispatch(setClientLoading());
  // dispatch(clearErrors());
  new Api()
    .updateClient(payload)
    .then(response => {
      dispatch(setClientLoading());

      // Clear uploaded image url to prevent being used in other components
      dispatch({
        type: actions.CLEAR_UPLOAD_URL,
      });
      dispatch(clearClient());

      toast.success('Saved Successfully !');
      history.push('/clients');
    })
    .catch(err => {
      dispatch(setClientLoading());
      if (err && err.response && err.response.data && err.response.data.error) {
        toast.error(err.response.data.error, { autoClose: 7000 });
      }
      dispatch({
        type: actions.GET_ERROR,
        payload: err && err.response && err.response.data ? err.response.data : '',
      });
    });
};

export const generateNewPin = (payload, history) => dispatch => {
  dispatch(setClientLoading());
  // dispatch(clearErrors());
  new Api()
    .generatePin(payload)
    .then(response => {
      dispatch(setClientLoading());

      // Clear uploaded image url to prevent being used in other components
      dispatch({
        type: actions.CLEAR_UPLOAD_URL,
      });
      dispatch(clearClient());

      toast.success('New Pin Generated !');
      history.push('/clients');
    })
    .catch(err => {
      dispatch(setClientLoading());
      if (err && err.response && err.response.data && err.response.data.error) {
        toast.error(err.response.data.error, { autoClose: 7000 });
      }
      dispatch({
        type: actions.GET_ERROR,
        payload: err && err.response && err.response.data ? err.response.data : '',
      });
    });
};

export const deleteClient = (payload, history) => dispatch => {
  dispatch(setClientLoading());
  new Api()
    .deleteClient(payload)
    .then(response => {
      dispatch(setClientLoading());

      toast.success('Client deleted successfully !');
      history.push('/clients');
    })
    .catch(err =>
      dispatch({
        type: actions.GET_ERROR,
        payload: err && err.response && err.response.data ? err.response.data : '',
      })
    );
};

export const toggleCreateClientAdminModal = () => {
  return {
    type: actions.TOGGLE_CREATE_CLIENT_ADMIN_MODAL,
  };
};

export const setClientAdminLoading = () => {
  return {
    type: actions.CLIENT_ADMIN_LOADING,
  };
};

export const createClientAdmin = payload => dispatch => {
  dispatch(setClientAdminLoading());
  new Api()
    .createClientAdmin(payload)
    .then(response => {
      dispatch(setClientAdminLoading());

      toast.success('Client Admin created successfully !');
      dispatch(toggleCreateClientAdminModal());
      dispatch(getClientAdmin({ pageLimit: 20, subdomain: payload.subdomain }));
    })
    .catch(err => {
      dispatch(setClientAdminLoading());
      dispatch({
        type: actions.GET_ERROR,
        payload: err && err.response && err.response.data ? err.response.data : '',
      });
    });
};

export const getClientAdmin = payload => dispatch => {
  dispatch(setClientLoading());

  new Api()
    .fetchClientAdmin(payload)
    .then(result => {
      result = { ...result.data, pageLimit: payload.pageLimit };

      return dispatch({
        type: actions.GET_CLIENT_ADMIN,
        payload: result,
      });
    })
    .catch(err => {
      const data = {
        clientAdmin: [],
        totalClientAdmin: 0,
        pageLimit: payload.pageLimit,
      };
      dispatch({
        type: actions.GET_CLIENT_ADMIN,
        payload: data,
      });
    });
};

export const copyUrl = (id, isDeleted) => {
  const payload = {
    id,
    isDeleted,
  };

  return {
    type: actions.COPY_URL,
    payload,
  };
};

export const updateTemplateOnInputChange = payload => {
  return {
    type: actions.UPDATE_EMAIL_TEMPLATE_ON_INPUT_CHANGE,
    payload,
  };
};

export const updatePaymentOnInputChange = payload => {
  return {
    type: actions.UPDATE_PAYMENT_ON_INPUT_CHANGE,
    payload,
  };
};

export const updatestripeKeysOnInputChange = payload => {
  return {
    type: actions.UPDATE_STRIPE_KEY_ON_INPUT_CHANGE,
    payload,
  };
};

export const resetMembershipcharge = payload => {
  return {
    type: actions.RESET_MEMBERSHIP_CHARGE,
    payload,
  };
};

export const updateCreditOnSave = (payload, history) => dispatch => {
  dispatch(setClientLoading());
  new Api()
    .updateCreditSystemOnSave(payload)
    .then(response => {
      dispatch(setClientLoading());
      dispatch({
        type: actions.CLEAR_UPLOAD_URL,
      });
      dispatch(clearClient());

      toast.success('Saved Successfully !');
      history.push('/clients');
    })
    .catch(err => {
      dispatch(setClientLoading());
      if (err && err.response && err.response.data && err.response.data.error) {
        toast.error(err.response.data.error, { autoClose: 7000 });
      }
      dispatch({
        type: actions.GET_ERROR,
        payload: err && err.response && err.response.data ? err.response.data : '',
      });
    });
};

export const updateMembershipChargeOnSave = (payload, history) => dispatch => {
  dispatch(setClientLoading());
  // dispatch(clearErrors());
  new Api()
    .updateMembershipCharge(payload)
    .then(response => {
      dispatch(setClientLoading());

      // Clear uploaded image url to prevent being used in other components
      dispatch({
        type: actions.CLEAR_UPLOAD_URL,
      });
      dispatch(clearClient());

      toast.success('Saved Successfully !');
      history.push('/clients');
    })
    .catch(err => {
      dispatch(setClientLoading());
      if (err && err.response && err.response.data && err.response.data.error) {
        toast.error(err.response.data.error, { autoClose: 7000 });
      }
      dispatch({
        type: actions.GET_ERROR,
        payload: err && err.response && err.response.data ? err.response.data : '',
      });
    });
};

export const updateFamilyMembershipOnInputChange = payload => {
  return {
    type: actions.UPDATE_FAMILY_MEMBERSHIP_ON_INPUT_CHANGE,
    payload,
  };
};

export const handleChangeCreditPaymentSettings = payload => {
  return {
    type: actions.ON_CHANGE_CREDIT_PAYMENT_SETTING_VALUES,
    payload,
  };
};

export const handleChangeMontaryValueMapping = payload => {
  return {
    type: actions.ON_CHANGE_MONETARY_VALUE_MAPPING,
    payload,
  };
};

export const handleChangeAutomaticAssignmentCredit = payload => {
  return {
    type: actions.ON_CHANGE_AUTOMATIC_ASSIGNMENT_OF_CREDIT,
    payload,
  };
};

export const updateColorOnInputChange = payload => {
  return {
    type: actions.UPDATE_COLOR_ON_INPUT_CHANGE,
    payload,
  };
};

export const onChangeClientRegisterInput = payload => {
  return {
    type: actions.ON_CHANGE_CLIENT_REGISTER_INPUT,
    payload,
  };
};

export const resetRegisterDetails = payload => {
  return {
    type: actions.RESET_REGISTER_DETAILS,
    payload,
  };
};

export const updateClientRegistrationDetails = (payload, history) => dispatch => {
  dispatch({ type: actions.UPDATE_CLIENT_LOADER });
  new Api()
    .updateClientRegistrationDetails(payload)
    .then(() => {
      dispatch({ type: actions.UPDATE_CLIENT_LOADER });

      toast.success('Saved Successfully !');
      history.push('/clients');
    })
    .catch(err => {
      dispatch({ type: actions.UPDATE_CLIENT_LOADER });

      if (err?.response?.data?.errors) {
        toast.error(err.response.data.errors, { autoClose: 7000 });
      }
    });
};

export const updateAnalytics = (payload, history) => dispatch => {
  dispatch({ type: actions.UPDATE_CLIENT_LOADER });
  new Api()
    .updateAnalytics(payload)
    .then(() => {
      dispatch({ type: actions.UPDATE_CLIENT_LOADER });

      toast.success('Saved Successfully !');
      history.push('/clients');
    })
    .catch(err => {
      dispatch({ type: actions.UPDATE_CLIENT_LOADER });
      if (err && err.response && err.response.data && err.response.data.error) {
        toast.error(err.response.data.error, { autoClose: 7000 });
      }
    });
};

export const onChangePolicyInput = payload => {
  return {
    type: actions.ON_CHANGE_POLICY_INPUT,
    payload,
  };
};

export const updateClientPolicy = (payload, history) => dispatch => {
  dispatch({ type: actions.UPDATE_CLIENT_LOADER });
  new Api()
    .updateClientPolicy(payload)
    .then(() => {
      dispatch({ type: actions.UPDATE_CLIENT_LOADER });

      toast.success('Saved Successfully !');
      history.push('/clients');
    })
    .catch(err => {
      dispatch({ type: actions.UPDATE_CLIENT_LOADER });
      if (err?.response?.data?.errors) {
        toast.error(err.response.data.errors, { autoClose: 7000 });
      }
    });
};

export const updateRecruiterDetails = (payload, history) => dispatch => {
  dispatch({ type: actions.UPDATE_CLIENT_LOADER });
  new Api()
    .updateRecruiterDetails(payload)
    .then(() => {
      dispatch({ type: actions.UPDATE_CLIENT_LOADER });

      toast.success('Saved Successfully !');
      history.push('/clients');
    })
    .catch(err => {
      dispatch({ type: actions.UPDATE_CLIENT_LOADER });
      console.log({ updateRecruiterDetailsError: err });
      if (err?.response?.data?.errors) {
        toast.error(err.response.data.errors, { autoClose: 7000 });
      }
    });
};

export const closeSidebarModal = () => {
  return {
    type: actions.TOGGLE_SIDEBAR_MODAL,
    payload: '',
  };
};

export const updateCountries = () => dispatch => {
  new Api()
    .getCountryAndDialCodes()
    .then(response => {
      dispatch({
        type: actions.UPDATE_COUNTRIES,
        payload: response.data,
      });
    })
    .catch(console.log);
};
