import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Container,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changePassword } from 'actions/authActions';
import { clearError } from 'actions/errorActions';
import { getLocalStorage } from 'utils/localStorage';

class ChangePassword extends Component {
  state = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    showLoader: false,
    error: '',
    isInvalid: false,
  };

  componentWillUnmount() {
    this.props.clearError();
  }

  handldeInputChange = e => {
    this.setState({ error: '', [e.target.name]: e.target.value });
  };

  handleSave = e => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    if (!oldPassword || !newPassword || !confirmPassword) {
      return this.setState({ isInvalid: true });
    }

    if (newPassword !== confirmPassword) {
      return this.setState({
        error: 'New and confirm password does not match',
      });
    }

    const decoded = getLocalStorage();
    const payload = { oldPassword, newPassword, id: decoded.data._id };

    this.props.changePassword(payload, this.props.history);
  };

  checkValidation = key => {
    const { error } = this.props.error;

    if (typeof error === 'string') {
      const oldPasswordError =
        error.includes('password invalid') && key === 'oldPassword';

      const newPasswordError =
        error.includes('old password') && key === 'newPassword';
      return oldPasswordError ? error : newPasswordError ? error : '';
    }

    const errorMessage =
      error &&
      error.filter(err => {
        const objectKey = Object.keys(err)[0];
        if (objectKey === key) return err;
      });

    const message = errorMessage && errorMessage[0] && errorMessage[0][key];
    return message ? message : '';
  };

  render() {
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      isInvalid,
      error,
    } = this.state;

    return (
      <Container className="change-password">
        <div className="wrap-box">
          <h2>
            <strong>Change Password</strong>
          </h2>
          <Form className="bp-form modal-form">
            <Col>
              <FormGroup>
                <Label for="oldPassword">
                  Old Password <span className="text-danger">*</span>
                </Label>
                <Input
                  type="password"
                  name="oldPassword"
                  id="oldPassword"
                  placeholder="********"
                  onChange={this.handldeInputChange}
                  invalid={
                    (!oldPassword && isInvalid) ||
                    this.checkValidation('oldPassword')
                      ? true
                      : false
                  }
                />
                <FormFeedback>
                  {this.checkValidation('oldPassword')
                    ? this.checkValidation('oldPassword')
                    : 'Old Password is required'}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="newPassword">
                  New Password <span className="text-danger">*</span>
                </Label>
                <Input
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  placeholder="********"
                  onChange={this.handldeInputChange}
                  invalid={
                    (!newPassword && isInvalid) ||
                    this.checkValidation('newPassword')
                      ? true
                      : false
                  }
                />
                <FormFeedback>
                  {this.checkValidation('newPassword')
                    ? this.checkValidation('newPassword')
                    : 'New Password is required'}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="confirmPassword">
                  Confirm Password <span className="text-danger">*</span>
                </Label>
                <Input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="********"
                  onChange={this.handldeInputChange}
                  invalid={!confirmPassword && isInvalid}
                />
                <FormFeedback>Confirm Password is required</FormFeedback>
                <span className="text-danger" style={{ fontSize: '80%' }}>
                  {error ? error : null}
                </span>
              </FormGroup>
            </Col>
            <Button
              className="btn-add float-right btn btn-secondary"
              onClick={this.handleSave}
            >
              Save
            </Button>
          </Form>
        </div>
      </Container>
    );
  }
}

ChangePassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const mapStateToProps = state => ({
  error: state.error,
});

export default connect(mapStateToProps, { changePassword, clearError })(
  ChangePassword
);
