import React, { Component } from 'react';
import { Modal, Row, Col } from 'reactstrap';

class DeletePopup extends Component {
  state = {
    isLoading: false
  }
  handleDelete = e => {
    this.setState({ isLoading: true })
    this.props.handleDelete();
  }
  render() {
    return (
      <Modal isOpen={true}>
      <Row>
        <Col md="3">
          <i className="display-4 icon text-danger icon-danger" />
        </Col>
        <Col md="9">
          <div>
            <h3 className="mb-3"><strong>Are you sure?</strong></h3>
            <p>Do you really want to {this.props.voucher.status === 1 ? 'delete' : 'undelete'} the voucher <strong>{this.props.voucher.uniqueCode}</strong>?</p>
          </div>
          {!this.state.isLoading && <div className="d-inline-block mr-3 btn btn-add bp-btn" onClick={this.props.handleNo}>No</div>}
          <div
            className="btn btn-danger bp-btn"
            onClick={this.handleDelete}
          >
            {this.state.isLoading ? <i className="fa fa-spinner fa-spin" aria-hidden="true" /> : 'Yes'}
          </div>
        </Col>
      </Row>
    </Modal>
    )
  }
}
export default DeletePopup;
