import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Collapse, Navbar, Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getClients } from 'actions/clientActions';

class Sidebar extends Component {
  state = {
    isOpen: false,
    searchKey: '',
    clients: [],
    clientDropdownOpen: false,
  };

  componentDidMount() {
    this.fetchClients();
  }

  fetchClients = () => {
    const payload = {
      currentPage: 1,
      pageLimit: 5,
      onlyActive: true,
      searchKey: this.state.searchKey,
    };

    this.props.getClients(payload);
  };

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleSearch = e => {
    const eventKey = e.key;
    this.setState({ searchKey: e.target.value }, () => {
      if (eventKey === 'Enter') {
        this.fetchClients();
      }
    });
  };

  handleRouteTo = (to, state = {}) => {
    this.toggleClientDropDown();

    this.props.propsData.history.push({
      pathname: to,
      state,
    });
  };

  toggleClientDropDown = () => {
    this.setState(prevState => ({
      clientDropdownOpen: !prevState.clientDropdownOpen,
    }));
  };

  render() {
    const { isOpen, clientDropdownOpen, searchKey } = this.state;
    const { toggleSidebar } = this.props.sidebar;
    const { activeClients, isOnlyActiveLoading: isLoading } = this.props.clients;

    return (
      <div
        className={`sidebar ${toggleSidebar ? 'd-block' : ''}`}
        ref={node => {
          this.props.sidebar.node = node;
        }}
      >
        <div className="logo">
          <Link to="/dashboard">
            <img src={'/images/logo.svg'} alt="logo" />
          </Link>
        </div>
        <Navbar expand="xs" className="navigation-bar">
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink to="/dashboard">
                  <i className="icon icon-dashboard" />
                  <span>Dashboard</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/superadmin">
                  <i className="icon icon-super-admin" />
                  <span>Super Admin</span>
                </NavLink>
              </NavItem>

              <Dropdown
                nav
                inNavbar
                direction="right"
                isOpen={clientDropdownOpen}
                toggle={this.toggleClientDropDown}
              >
                <DropdownToggle nav>
                  <i className="icon icon-clients" />
                  <span>Clients</span>
                </DropdownToggle>
                <DropdownMenu right>
                  <div className="sub-menu">
                    {isLoading ? (
                      <span>
                        <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...
                      </span>
                    ) : (
                      <>
                        <div className="search-bar">
                          <input
                            type="text"
                            placeholder="Search client by name"
                            value={searchKey}
                            onChange={this.handleSearch}
                            onKeyDown={this.handleSearch}
                          />
                        </div>
                        <div className="client-list-dropdown">
                          {activeClients.map(client => (
                            <div className="list" key={client._id}>
                              <span
                                className="haslink active"
                                onClick={() => this.handleRouteTo(`/client-settings/${client._id}`)}
                              >
                                <img className="logo" src={client.logo} alt="logo" />
                                <span>{client.name}</span>
                              </span>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    <div className="submenu-footer">
                      <span
                        className="haslink float-left"
                        onClick={() => this.handleRouteTo('/clients', { fromSideBar: true })}
                      >
                        <i className="icon icon-add" />
                        Create new client
                      </span>
                      <span
                        className="haslink float-right"
                        onClick={() => this.handleRouteTo('/clients')}
                      >
                        <i className="icon icon-right-align" />
                        View all
                      </span>
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
              <NavItem>
                <NavLink to="/logs">
                  <i className="icon icon-log-file" />
                  <span>Log</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/activities">
                  <i class="fa fa-list-ul fa-2x" aria-hidden="true"></i>
                  <span>Activities</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

Sidebar.propTypes = {
  sidebar: PropTypes.object.isRequired,
  getClients: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  sidebar: state.sidebar,
  clients: state.client,
});

export default connect(mapStateToProps, { getClients })(Sidebar);
