import {
  GET_LOGS,
  LOG_LOADING,
  GET_LOGS_COMPONENT,
  CLEAR_LOGS,
} from 'actions/types';

const initialState = {
  logs: [],
  isLoading: false,
  totalLogs: 0,
  totalPage: 0,
  logsComponent: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOG_LOADING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };

    case GET_LOGS:
      return {
        ...state,
        logs: action.payload.logs,
        totalLogs: action.payload.totalLogs,
        totalPage: Math.ceil(
          action.payload.totalLogs / action.payload.pageLimit
        ),
        isLoading: false,
      };

    case GET_LOGS_COMPONENT:
      return {
        ...state,
        logsComponent: action.payload,
      };

    case CLEAR_LOGS:
      return {
        ...state,
        logs: [],
        isLoading: false,
        totalLogs: 0,
        totalPage: 0,
        logsComponent: [],
      };

    default:
      return state;
  }
}
