import jwtDecode from 'jwt-decode';

export const getLocalStorage = () => {
  const token = localStorage.getItem('token');

  try {
    const decoded = jwtDecode(token);

    return decoded;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};
