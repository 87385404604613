import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  createClientAdmin,
  toggleCreateClientAdminModal,
  updateCountries,
} from 'actions/clientActions';
import { clearError } from 'actions/errorActions';

class CreateClientAdmin extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    userId: '',
    password: '',
    confirmPassword: '',
    country: '',
    phone: '',
    dialcode: '',
    isInvalid: false,
    isPasswordMatch: true,
  };

  componentDidMount() {
    if (this.props.client.countries.length === 0) {
      this.props.updateCountries();
    }
  }

  componentWillUnmount() {
    this.props.clearError();
  }

  handleInput = e => {
    this.props.clearError();
    let { name, value } = e.target;
    if (name === 'phone' && !/^[0-9]+$|^$/.test(value)) {
      return;
    }
    let stateObj = {};
    if (name === 'country') {
      const { countries } = this.props.client;
      const selectedObj = countries[e.target.selectedIndex - 1];
      console.log(selectedObj);
      stateObj['dialcode'] = selectedObj.calling_code;
    }
    this.setState({ [name]: value, ...stateObj, isPasswordMatch: true });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.handleCreate();
  };

  handleCreate = async () => {
    const {
      firstName,
      lastName,
      email,
      userId,
      password,
      confirmPassword,
      country,
      phone,
      dialcode,
    } = this.state;

    if (!firstName || !lastName || !email || !userId || !password || !country || !phone) {
      return this.setState({ isInvalid: true });
    }

    if (password !== confirmPassword) {
      return this.setState({ isPasswordMatch: false });
    }

    const payload = {
      firstName,
      lastName,
      email,
      userId,
      password,
      subdomain: this.props.subdomain,
      country,
      phone,
      dialcode,
    };

    this.props.createClientAdmin(payload);
  };

  handleModalClose = () => {
    this.props.toggleCreateClientAdminModal();
  };

  checkValidation = key => {
    const { error } = this.props.error;
    const errorMessage = `${key} is already being used`;

    const hasUniqueError = error && error.includes(key) && error.includes('validation');

    if (hasUniqueError) return errorMessage;

    const hasValidityError = error && error.includes(key);

    if (hasValidityError) return error;

    const hasUsernameInError =
      error && error.includes('username') && key === 'userId' && error.includes('exists');

    if (hasUsernameInError) {
      return errorMessage;
    }

    const userIdLengthError = error && error.includes('character') && key === 'userId';

    if (userIdLengthError) return 'User Id must be at least 8 character';

    const errObj =
      Array.isArray(error) &&
      error.find(err => {
        return Object.keys(err)[0] === key;
      });

    return errObj ? Object.values(errObj)[0] : '';
  };

  AppIdValidation = () => {
    const { error } = this.props.error;

    const err = error && error[0];

    if (typeof err === 'string') {
      const isAppSignatureError = err.toLowerCase().includes('app_signature');
      if (isAppSignatureError) {
        return 'App Secret Key is needed to be updated.';
      }

      const isAppIdError = err.toLowerCase().includes('app_id');

      if (isAppIdError) return err;
    }

    if (typeof error === 'string') {
      const isAppIdErrorString = error && error.toLowerCase().includes('app_id');

      return isAppIdErrorString ? error : '';
    }
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      userId,
      password,
      country,
      phone,
      confirmPassword,
      isInvalid,
      isPasswordMatch,
    } = this.state;

    const { isClientAdminLoading: isLoading, countries } = this.props.client;

    return (
      <div className="client-add-edit-modal">
        <div className="modal-header" onClick={this.handleModalClose}>
          <i className="icon icon-cross-out" />
        </div>
        <div className="modal-body">
          <h2>Create Client Admin</h2>
          <Form className="bp-form modal-form">
            <FormGroup>
              <Label for="firstName">
                First name <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="firstName"
                invalid={
                  (!firstName && isInvalid) || this.checkValidation('firstName') ? true : false
                }
                onChange={this.handleInput}
                value={firstName}
              />
              <FormFeedback>
                {this.checkValidation('firstName')
                  ? this.checkValidation('firstName')
                  : 'First Name is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="lastName">
                Last name <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="lastName"
                invalid={
                  (!lastName && isInvalid) || this.checkValidation('lastName') ? true : false
                }
                onChange={this.handleInput}
                value={lastName}
              />
              <FormFeedback>
                {this.checkValidation('lastName')
                  ? this.checkValidation('lastName')
                  : 'Last Name is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="email">
                Email <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="email"
                invalid={(!email && isInvalid) || this.checkValidation('email') ? true : false}
                onChange={this.handleInput}
                value={email}
              />
              <FormFeedback>
                {this.checkValidation('email')
                  ? this.checkValidation('email')
                  : 'Email is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="userId">
                User Id <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="userId"
                invalid={(!userId && isInvalid) || this.checkValidation('userId') ? true : false}
                onChange={this.handleInput}
                value={userId}
              />
              <FormFeedback>
                {this.checkValidation('userId')
                  ? this.checkValidation('userId')
                  : 'User id is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="country">
                Country <span className="text-danger">*</span>
              </Label>
              <span className="sm-selectWrap">
                <Input
                  className="custom-select"
                  type="select"
                  name="country"
                  invalid={
                    (!country && isInvalid) || this.checkValidation('country') ? true : false
                  }
                  onChange={this.handleInput}
                  value={country}
                >
                  <option key={-1} value="">
                    Select Country
                  </option>
                  {countries.map((c, i) => (
                    <option key={i} value={c.country_code}>
                      {c.country_name} ({c.calling_code})
                    </option>
                  ))}
                </Input>
              </span>
              <FormFeedback>
                {this.checkValidation('country')
                  ? this.checkValidation('country')
                  : 'Country is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="phone">
                Phone <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="phone"
                invalid={(!phone && isInvalid) || this.checkValidation('phone') ? true : false}
                onChange={this.handleInput}
                value={phone}
              />
              <FormFeedback>
                {this.checkValidation('phone')
                  ? this.checkValidation('phone')
                  : 'Phone number is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="password">
                Password <span className="text-danger">*</span>
              </Label>
              <Input
                type="password"
                name="password"
                invalid={
                  (!password && isInvalid) || this.checkValidation('password') ? true : false
                }
                onChange={this.handleInput}
                value={password}
              />
              <FormFeedback>
                {this.checkValidation('password')
                  ? this.checkValidation('password')
                  : 'Password is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="confirmPassword">
                Confirm password <span className="text-danger">*</span>
              </Label>
              <Input
                type="password"
                name="confirmPassword"
                invalid={!isPasswordMatch}
                onChange={this.handleInput}
                value={confirmPassword}
              />
              <FormFeedback>Confirm password do not match</FormFeedback>
            </FormGroup>
            {this.AppIdValidation() ? (
              <span style={{ color: 'red', fontSize: '80%' }}>{this.AppIdValidation()}</span>
            ) : null}

            <Button className="btn-add float-right" onClick={this.handleSubmit}>
              {isLoading ? <i className="fa fa-spinner fa-spin" aria-hidden="true" /> : 'Create'}
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}
CreateClientAdmin.propTypes = {
  createClientAdmin: PropTypes.func.isRequired,
  toggleCreateClientAdminModal: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  updateCountries: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  error: state.error,
  client: state.client,
});

export default connect(mapStateToProps, {
  createClientAdmin,
  toggleCreateClientAdminModal,
  clearError,
  updateCountries,
})(CreateClientAdmin);
