import React, { Component } from 'react';

import { Row, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Pagination from 'components/Pagination';
import {
  getClientAdmin,
  toggleCreateClientAdminModal,
} from 'actions/clientActions';
import Filter from 'components/ClientAdmin/filter';
import CreateClientAdmin from 'components/ClientModal/CreateClientAdmin';

class ClientAdmin extends Component {
  state = {
    isError: false,
    currentPage: 1,
    pageLimit: 20,
    searchKey: '',
  };

  componentDidMount() {
    this.fetchClientAdmin();
  }

  fetchClientAdmin = () => {
    const { currentPage, pageLimit, searchKey } = this.state;

    const payload = {
      currentPage,
      pageLimit,
      searchKey,
      subdomain: this.props.match.params.subdomain,
    };

    this.props.getClientAdmin(payload);
  };

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({ currentPage: index }, () => {
      this.fetchClientAdmin();
    });
  };

  handleFilterChange = event => {
    // event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleFilter = () => {
    this.setState({ currentPage: 1 }, () => {
      this.fetchClientAdmin();
    });
  };

  handleCreateClientAdminModal = () => {
    this.props.toggleCreateClientAdminModal();
  };

  render() {
    const { currentPage, pageLimit } = this.state;
    const {
      clientAdmin,
      isLoading,
      totalClientAdmin,
      totalClientAdminPage,
      showCreateClientAdminModal,
    } = this.props.clients;

    const subdomain = this.props.match.params.subdomain;

    return (
      <>
        <div className="content">
          <div className="title">
            <span>List of</span>
            <h1>Client Admin - {subdomain}</h1>
            <div className="btn-container">
              <button
                className="btn btn-add"
                onClick={this.handleCreateClientAdminModal}
              >
                <i className="icon icon-plus"></i> Create Client Admin
              </button>
            </div>
          </div>

          {showCreateClientAdminModal ? (
            <CreateClientAdmin subdomain={subdomain} />
          ) : null}
          <Filter
            handleFilterChange={this.handleFilterChange}
            handleFilter={this.handleFilter}
          />
          {isLoading ? (
            <span className="loading"><i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...</span>
          ) : (
            <>
              {!clientAdmin || !clientAdmin.length ? (
                <h2>No client admin to display</h2>
              ) : (
                <Row>
                  <Col md="12">
                    <Table className="base-table clients-table" responsive>
                      <thead>
                        <tr>
                          <th>S.N</th>
                          <th>User Id</th>
                          <th>Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clientAdmin.map((client, index) => {
                          return (
                            <tr key={client.userId}>
                              <td scope="row">
                                {currentPage * pageLimit -
                                  pageLimit +
                                  (index + 1)}
                              </td>
                              <td>{client.userId}</td>
                              <td>
                                {client.firstName} {client.lastName}
                              </td>
                              <td>{client.email}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Pagination
                      total={totalClientAdmin}
                      currentPage={currentPage}
                      totalPage={totalClientAdminPage}
                      pageLimit={pageLimit}
                      handleClick={this.handlePagination}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

ClientAdmin.propTypes = {
  getClientAdmin: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
  toggleCreateClientAdminModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  clients: state.client,
});

export default connect(mapStateToProps, {
  getClientAdmin,
  toggleCreateClientAdminModal,
})(ClientAdmin);
