import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import MainApp from './routes/mainApp';
import Login from './routes/Login';
import ForgotPassword from './routes/ForgotPassword';
import ResetPassword from './routes/ForgotPassword/resetPassword';

import store from 'store';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password/:hash" component={ResetPassword} />
          <Route path="/" component={MainApp} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
