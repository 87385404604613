import React, { Component } from 'react';

class DeletePopup extends Component {
  render() {
    return (
      <div className="popup delete-popup">
        <div className="popup-body">
          <div className="icon-holder">
            <i className="icon icon-danger" />
          </div>
          <div>
            <h3>Are you sure?</h3>
            <p>Do you really want to delete this client?</p>
          </div>
        </div>
        <div className="popup-footer">
          <div className="btn btn-add bp-btn">No</div>
          <div
            className="btn btn-danger bp-btn"
            onClick={this.props.handleDelete}
          >
            Yes
          </div>
        </div>
      </div>
    );
  }
}
export default DeletePopup;
