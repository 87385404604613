export const GET_ERROR = 'GET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const AUTH_LOADING = 'AUTH_LOADING';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const CLIENT_LOADING = 'CLIENT_LOADING';
export const ACTIVE_CLIENT_LOADING = 'ACTIVE_CLIENT_LOADING';
export const GET_CLIENTS = 'GET_CLIENTS';

export const GET_CLIENT = 'GET_CLIENT';
export const UPDATE_CLIENT_ON_INPUT_CHANGE = 'UPDATE_CLIENT_ON_INPUT_CHANGE';
export const UPDATE_COLOR_ON_INPUT_CHANGE = 'UPDATE_COLOR_ON_INPUT_CHANGE';
export const CLEAR_CLIENT = 'CLEAR_CLIENT';

export const TOGGLE_CREATE_CLIENT_ADMIN_MODAL = 'TOGGLE_CREATE_CLIENT_ADMIN_MODAL';
export const CLIENT_ADMIN_LOADING = 'CLIENT_ADMIN_LOADING';
export const GET_CLIENT_ADMIN = 'GET_CLIENT_ADMIN';

export const TOGGLE_DELETE_CLIENT_MODAL = 'TOGGLE_DELETE_CLIENT_MODAL';

export const GET_ACTIVE_CLIENTS = 'GET_ACTIVE_CLIENTS';
export const GET_ALL_ACTIVE_CLIENTS = 'GET_ALL_ACTIVE_CLIENTS';

export const SUPER_ADMIN_LOADING = 'SUPER_ADMIN_LOADING';
export const GET_SUPER_ADMINS = 'GET_SUPER_ADMINS';
export const GET_SINGLE_SUPER_ADMIN = 'GET_SINGLE_SUPER_ADMIN';

export const CREATE_SUPER_ADMIN = 'CREATE_SUPER_ADMIN';
export const TOGGLE_SUPER_ADMIN_CREATE_MODAL = 'TOGGLE_SUPER_ADMIN_CREATE_MODAL';

export const UPLOAD_DATA = 'UPLOAD_DATA';
export const UPLOAD_LOADING = 'UPLOAD_LOADING';
export const CLEAR_UPLOAD_URL = 'CLEAR_UPLOAD_URL';

export const DELETE_SUPER_ADMIN_LOADING = 'DELETE_SUPER_ADMIN_LOADING';
export const TOGGLE_SUPER_ADMIN_DELETE_MODAL = 'TOGGLE_SUPER_ADMIN_DELETE_MODAL';

export const TOGGLE_GRANT_ACCESS_MODAL = 'TOGGLE_GRANT_ACCESS_MODAL';
export const GRANT_ACCESS_LOADING = 'GRANT_ACCESS_LOADING';

export const COPY_URL = 'COPY_URL';

export const LOG_LOADING = 'LOG_LOADING';
export const GET_LOGS = 'GET_LOGS';
export const GET_LOGS_COMPONENT = 'GET_LOGS_COMPONENT';
export const CLEAR_LOGS = 'CLEAR_LOGS';

export const UPDATE_EMAIL_TEMPLATE_ON_INPUT_CHANGE = 'UPDATE_EMAIL_TEMPLATE_ON_INPUT_CHANGE';

export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const UPDATE_PAYMENT_ON_INPUT_CHANGE = 'UPDATE_PAYMENT_ON_INPUT_CHANGE';
export const UPDATE_STRIPE_KEY_ON_INPUT_CHANGE = 'UPDATE_STRIPE_KEY_ON_INPUT_CHANGE';
export const RESET_MEMBERSHIP_CHARGE = 'RESET_MEMBERSHIP_CHARGE';
export const UPDATE_FAMILY_MEMBERSHIP_ON_INPUT_CHANGE = 'UPDATE_FAMILY_MEMBERSHIP_ON_INPUT_CHANGE';
export const ON_CHANGE_CREDIT_PAYMENT_SETTING_VALUES = 'ON_CHANGE_CREDIT_PAYMENT_SETTING_VALUES';
export const ON_CHANGE_AUTOMATIC_ASSIGNMENT_OF_CREDIT = 'ON_CHANGE_AUTOMATIC_ASSIGNMENT_OF_CREDIT';
export const ON_CHANGE_MONETARY_VALUE_MAPPING = 'ON_CHANGE_MONETARY_VALUE_MAPPING';
export const UPDATE_COURSE_PURCHASE_INSTALLMENT_PLAN = 'UPDATE_COURSE_PURCHASE_INSTALLMENT_PLAN';

export const ON_CHANGE_CLIENT_REGISTER_INPUT = 'ON_CHANGE_CLIENT_REGISTER_INPUT';
export const RESET_REGISTER_DETAILS = 'RESET_REGISTER_DETAILS';
export const UPDATE_CLIENT_LOADER = 'UPDATE_CLIENT_LOADER';

export const ON_CHANGE_ANALYTICS_INPUT = 'ON_CHANGE_ANALYTICS_INPUT';

export const ON_CHANGE_POLICY_INPUT = 'ON_CHANGE_POLICY_INPUT';

export const ON_CHANGE_RECRUITER_INPUT = 'ON_CHANGE_RECRUITER_INPUT';

export const CLONE_LOADING = 'CLONE_LOADING';
export const TOGGLE_COURSES_MODAL_TO_CLONE = 'TOGGLE_COURSES_MODAL_TO_CLONE';
export const TOGGLE_COURSE_FETCH_LOADER = 'TOGGLE_COURSE_FETCH_LOADER';
export const GET_CLIENT_COURSES = 'GET_CLIENT_COURSES';
export const COURSE_SEARCH_KEY = 'COURSE_SEARCH_KEY';

export const API_CREDENTIALS_CREATED = 'API_CREDENTIALS_CREATED';

export const GET_VOUCHERS = 'GET_VOUCHERS';
export const VOUCHER_LOADING = 'VOUCHER_LOADING';
export const UPDATE_VOUCHER = 'UPDATE_VOUCHER';
export const GET_VOUCHER_LOGS = 'GET_VOUCHER_LOGS';
export const VOUCHER_LOG_LOADING = 'VOUCHER_LOG_LOADING';
export const VOUCHER_UPDATE_CURRENCY = 'VOUCHER_UPDATE_CURRENCY';
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';

export const TOGGLE_SIDEBAR_MODAL = 'TOGGLE_SIDEBAR_MODAL';
