import React from 'react';
import { Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

const LogFilter = props => {
  const {
    handleFilter,
    handleFilterChange,
    handleDateChange,
    fromDate,
    toDate
  } = props;

  return (
    <Form
      inline
      className="log-form mt-2 mb-4"
      onSubmit={e => e.preventDefault()}
    >
      <Row form className="width-100">
        <Col>
          <FormGroup>
            <Input
              type="text"
              placeholder="Search by Learner Id"
              name="searchKey"
              id="searchKey"
              required
              onChange={handleFilterChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Input
              type="text"
              placeholder="Search by Voucher Code"
              name="voucherCode"
              id="voucherCode"
              required
              onChange={handleFilterChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <span className="sm-selectWrap">
              <select
                className="custom-select"
                type="select"
                name="actionType"
                id="actionType"
                onChange={handleFilterChange}
              >
                <option value="">Select Action Type</option>
                <option value="subscribe">Registration</option>
                <option value="resubscribe">Resubscription</option>
                <option value="invoice_payment_failed">Recurring Payment Failed</option>
                <option value="invoice_payment_succeeded">Recurring Payment Succeeded</option>
              </select>
            </span>
          </FormGroup>
        </Col>

        <Col>
          <FormGroup>
            <DateTimePicker
              value={fromDate}
              className="date w-100"
              closeOnSelect={true}
              onChange={moment => handleDateChange(moment, 'fromDate')}
              inputProps={{ 
                placeholder: 'From Date',
                value: fromDate,
                onKeyUp: e => handleDateChange('', 'fromDate') 
              }}
              dateFormat={'YYYY-MM-DD'}
              timeFormat={false}
            />
          </FormGroup>
        </Col>

        <Col>
          <FormGroup>
            <DateTimePicker
              value={toDate}
              className="date w-100"
              closeOnSelect={true}
              onChange={moment => handleDateChange(moment, 'toDate')}
              inputProps={{ 
                placeholder: 'To Date',
                value: toDate,
                onKeyUp: e => handleDateChange('', 'toDate') 
              }}
              dateFormat={'YYYY-MM-DD'}
              timeFormat={false}
            />
          </FormGroup>
        </Col>
        <FormGroup>
          <Button
            id="search"
            className="btn btn-add"
            onClick={handleFilter}
          >
            <i className="icon icon-search"></i> Filter
          </Button>
        </FormGroup>
      </Row>
    </Form>
  );
};

export default LogFilter;
