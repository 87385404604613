import Api from 'API';
import { GET_VOUCHERS, VOUCHER_LOADING, UPDATE_VOUCHER, GET_VOUCHER_LOGS, VOUCHER_LOG_LOADING, VOUCHER_UPDATE_CURRENCY } from './types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
toast.configure();

export const setVoucherLoading = () => {
  return {
    type: VOUCHER_LOADING,
  };
};

export const setVoucherLogLoading = () => {
  return {
    type: VOUCHER_LOG_LOADING,
  };
};

export const getVouchers = payload => dispatch => {
  dispatch(setVoucherLoading());

  new Api()
    .fetchVouchers(payload)
    .then(result => {
      const data = { ...result, pageLimit: payload.pageLimit };
      dispatch({
        type: GET_VOUCHERS,
        payload: data,
      });
    })
    .catch(err => {
      const data = {
        vouchers: [],
        totalVouchers: 0,
        pageLimit: payload.pageLimit,
      };
      dispatch({
        type: GET_VOUCHERS,
        payload: data,
      });
    });
};

export const createVoucher = payload => dispatch => {
  let responseMsg = 'Failed to create voucher.'
  return new Api()
    .createVoucher(payload)
    .then(result => {
      if(result) {
        if(result.success) {
          toast.success( 'Voucher created successfully.' );
          if(payload.update) {
            dispatch({
              type: VOUCHER_UPDATE_CURRENCY,
              payload: payload.update,
            });
          }
          return result;
        } else {
          responseMsg = result.message;
        }
      } 
      return { success: false, message: responseMsg };
    })
    .catch(err => {
      if(err.message){ responseMsg = err.message }
      if(err.response && err.response.data && !err.response.data.success) {
        responseMsg = err.response.data.message;
      }
      return { success: false, message: responseMsg };
    });
};

export const checkVoucherTitle = payload => dispatch => {
  let responseMsg = '';
  return new Api()
    .checkVoucherTitle(payload)
    .then(result => {
      if(result) {
        if(result.success) {
          return result;
        } else {
          responseMsg = result.message;
        }
      } 
      return { success: false };
    })
    .catch(err => {
      if(err.response && err.response.data && !err.response.data.success) {
        responseMsg = err.response.data.message;
      }
      return { success: false, message: responseMsg };
    });
};

export const editVoucher = payload => dispatch => {
  let responseMsg = 'Failed to save voucher.'
  return new Api()
    .editVoucher(payload)
    .then(result => {
      if(result) {
        if(result.success) {
          switch(payload.action) {
            case 'delete': toast.success( 'Voucher deleted successfully.' ); break;
            case 'undelete': toast.success( 'Voucher undeleted successfully.' ); break;
            default: toast.success( 'Voucher saved successfully.' );
          }
          dispatch({
            type: UPDATE_VOUCHER,
            payload: result.voucher,
          })
          if(payload.update) {
            dispatch({
              type: VOUCHER_UPDATE_CURRENCY,
              payload: payload.update,
            });
          }
          return result;
        } else {
          responseMsg = result.message;
        }
      } 
      return { success: false, message: responseMsg };
    })
    .catch(err => {
      if(err.message){ responseMsg = err.message }
      if(err.response && err.response.data && !err.response.data.success) {
        responseMsg = err.response.data.message;
      }
      return { success: false, message: responseMsg };
    });
};

export const getVoucherLogs = payload => dispatch => {
  dispatch(setVoucherLogLoading());

  new Api()
    .fetchVoucherLogs(payload)
    .then(result => {
      const data = { ...result, pageLimit: payload.pageLimit };
      dispatch({
        type: GET_VOUCHER_LOGS,
        payload: data,
      });
    })
    .catch(err => {
      const data = {
        voucherLogs: [],
        totalVoucherLogs: 0,
        pageLimit: payload.pageLimit,
      };
      dispatch({
        type: GET_VOUCHER_LOGS,
        payload: data,
      });
    });
};