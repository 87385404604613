import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, Input, Spinner, Row, Col } from 'reactstrap';

import Editor from 'components/TinyMceEditor';
import {
  onChangeClientRegisterInput,
  resetRegisterDetails,
  updateClientRegistrationDetails,
} from 'actions/clientActions';
import Wrapper from 'common/modalWrapper';

const ClientRegisterSettings = ({ cacheData, clientId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { client, updateClientLoader } = useSelector(state => state.client);
  const { registrationDetails, hideForgotPassword } = client;
  const {
    allowUserRegistration,
    memberIdLabel,
    memberIdPlaceholder,
    familySignupMessage,
    signUpMessage,
    familyMemberInviteStep,
    customSignupMessage,
    paymentMessage,
    paymentCancelMessage,
    inviteFamilyAndAddNameMessage,
    familyAddNameMessage,
    shareLinkMessage,
    welcomeMessage,
    preferenceMessage,
  } = registrationDetails;

  const onChangeMessage = (name, value) => {
    const payload = { name, value };

    dispatch(onChangeClientRegisterInput(payload));
  };

  const onInputChange = e => {
    const payload = {
      name: e.target.name,
      value: e.target.value,
    };

    dispatch(onChangeClientRegisterInput(payload));
  };

  const onCheckboxChange = e => {
    const payload = {
      name: e.target.name,
      value: e.target.checked,
    };

    dispatch(onChangeClientRegisterInput(payload));
  };

  const handleReset = e => {
    e.preventDefault();
    const payload = {
      registrationDetails: cacheData.registrationDetails,
      hideForgotPassword: cacheData.hideForgotPassword,
    };
    console.log({ payload });
    dispatch(resetRegisterDetails(payload));
  };

  const handleSave = () => {
    const payload = {
      allowUserRegistration,
      hideForgotPassword,
      memberIdLabel,
      memberIdPlaceholder,
      familySignupMessage,
      signUpMessage,
      familyMemberInviteStep,
      customSignupMessage,
      paymentMessage,
      paymentCancelMessage,
      inviteFamilyAndAddNameMessage,
      familyAddNameMessage,
      shareLinkMessage,
      welcomeMessage,
      preferenceMessage,
      clientId,
    };

    dispatch(updateClientRegistrationDetails(payload, history));
  };

  return (
    <Wrapper title="Register Info Settings">
      <Form className="bp-form">
        <div className="settings-container d-block">
          <Row>
            <Col md={6}>
              <fieldset className="form-group mt-3">
                <legend> User Registration </legend>
                <div className="on-off-menu">
                  <FormGroup>
                    <label htmlFor="allowUserRegistration">
                      <strong>Allow User Registration</strong>
                    </label>
                    <span className="checkbox pull-right">
                      <input
                        type="checkbox"
                        id="allowUserRegistration"
                        name="allowUserRegistration"
                        checked={allowUserRegistration}
                        onChange={onCheckboxChange}
                      />
                      <label></label>
                    </span>
                  </FormGroup>
                </div>
              </fieldset>
            </Col>
            <Col md={6}>
              <fieldset className="form-group mt-3">
                <legend> Forgot Password </legend>
                <div className="on-off-menu">
                  <FormGroup>
                    <label htmlFor="hideForgotPassword">
                      <strong>Hide Forgot Password</strong>
                    </label>
                    <span className="checkbox pull-right">
                      <input
                        type="checkbox"
                        id="hideForgotPassword"
                        name="hideForgotPassword"
                        checked={hideForgotPassword}
                        onChange={onCheckboxChange}
                      />
                      <label></label>
                    </span>
                  </FormGroup>
                </div>
              </fieldset>
            </Col>
          </Row>
          <div className="row">
            <div className="col-md-6">
              <label>
                SignUp page info
                <small className="d-block">
                  The added information will be displayed on the first page of normal learner
                  register.
                </small>
              </label>
              <Editor value={signUpMessage} onChange={onChangeMessage} updateTo="signUpMessage" />

              {/* <label className="mt-3">Family member signup page info</label>
                <Editor
                  value={familySignupMessage}
                  onChange={onChangeMessage}
                  updateTo="familySignupMessage"
                /> */}

              {/* <label className="mt-3">
                  Admin created user signup page info
                </label>
                <Editor
                  value={customSignupMessage}
                  onChange={onChangeMessage}
                  updateTo="customSignupMessage"
                /> */}

              <label className="mt-3">
                Payment page info
                <small className="d-block">
                  This information will be displayed on the payment page of register onboarding if
                  the client is payment type.
                </small>
              </label>
              <Editor value={paymentMessage} onChange={onChangeMessage} updateTo="paymentMessage" />

              <label className="mt-3">
                User names page info
                <small className="d-block">
                  This information will be displayed on the learner register onboarding where
                  learner user get an option to add their First and Last name.
                </small>
              </label>
              <Editor
                value={inviteFamilyAndAddNameMessage}
                onChange={onChangeMessage}
                updateTo="inviteFamilyAndAddNameMessage"
              />

              {/* <label className="mt-3">Family user names page info</label>
                <Editor
                  value={familyAddNameMessage}
                  onChange={onChangeMessage}
                  updateTo="familyAddNameMessage"
                /> */}

              <label className="mt-3">
                Share link page info
                <small className="d-block">
                  The added information will be displayed on the share link popup box of register
                  onboarding.
                </small>
              </label>
              <Editor
                value={shareLinkMessage}
                onChange={onChangeMessage}
                updateTo="shareLinkMessage"
              />

              <label className="mt-3">
                Welcome page info{' '}
                <small className="d-block">
                  This information will be displayed on the completion page for all types of users.
                </small>
              </label>
              <Editor value={welcomeMessage} onChange={onChangeMessage} updateTo="welcomeMessage" />

              <label className="mt-3">
                Preference page info
                <small className="d-block">
                  This information will be displayed in the last or Your preferenes page for all
                  types user.
                </small>
              </label>
              <Editor
                value={preferenceMessage}
                onChange={onChangeMessage}
                updateTo="preferenceMessage"
              />
            </div>
            <div className="col-md-6">
              <label>
                {' '}
                Family member signup page info{' '}
                <small className="d-block">
                  The added information will be displayed on the first page of family member signUp.
                </small>
              </label>
              <Editor
                value={familySignupMessage}
                onChange={onChangeMessage}
                updateTo="familySignupMessage"
              />
              <label className="mt-3">
                Family user names page info{' '}
                <small className="d-block">
                  This information will be displayed on the family member register onboarding page
                  where they get an option to add their First and Last name.
                </small>
              </label>
              <Editor
                value={familyAddNameMessage}
                onChange={onChangeMessage}
                updateTo="familyAddNameMessage"
              />
              <label className="mt-3">
                {' '}
                Admin created user signup page info{' '}
                <small className="d-block">
                  The added information will be displayed on the first page of admin created user
                  signup.
                </small>
              </label>
              <Editor
                value={customSignupMessage}
                onChange={onChangeMessage}
                updateTo="customSignupMessage"
              />

              <label className="mt-3">
                {' '}
                Payment cancle info{' '}
                <small className="d-block">
                  The added information will be displayed after payment info on the second page of
                  register onboarding if the client is payment type.
                </small>
              </label>
              <Editor
                value={paymentCancelMessage}
                onChange={onChangeMessage}
                updateTo="paymentCancelMessage"
              />

              <fieldset className="form-group mt-3">
                <legend>
                  {' '}
                  Member ID <small>(Label & placeholder text)</small>{' '}
                </legend>
                <FormGroup>
                  <Input
                    name="memberIdLabel"
                    type="text"
                    placeholder="Label"
                    value={memberIdLabel}
                    onChange={onInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="memberIdPlaceholder"
                    type="text"
                    placeholder="Placeholder"
                    value={memberIdPlaceholder}
                    onChange={onInputChange}
                  />
                </FormGroup>
              </fieldset>

              <fieldset className="form-group mt-3">
                <legend> Enable/Disable The Step </legend>
                <div className="on-off-menu">
                  <FormGroup>
                    <label htmlFor="familyMemberInviteStep">Family Member Invitation Page</label>
                    <span className="checkbox pull-right">
                      <input
                        type="checkbox"
                        id="familyMemberInviteStep"
                        name="familyMemberInviteStep"
                        checked={familyMemberInviteStep}
                        onChange={onCheckboxChange}
                      />
                      <label></label>
                    </span>
                  </FormGroup>
                </div>
              </fieldset>

              <div className="settings-footer">
                <button className="btn btn-add" onClick={handleSave} disabled={updateClientLoader}>
                  {updateClientLoader ? <Spinner size="sm" /> : 'Save'}
                </button>
                <button className="btn btn-add" onClick={handleReset} disabled={updateClientLoader}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Wrapper>
  );
};

export default ClientRegisterSettings;
