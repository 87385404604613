import React from 'react';
import { Card, CardBody, CardTitle, Col, CardFooter, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Doughnut, Chart } from 'react-chartjs-2';

const data = {
  labels: ['Inactive client', 'Active client'],
  datasets: [
    {
      data: [300, 500],
      borderWidth: 0,
      backgroundColor: ['#818181', '#1e1e1e'],
      hoverBackgroundColor: ['#818181', '#1e1e1e'],
    },
  ],
  text: '503',
  text2: 'Total Clients',
};

const options = {
  plugins: {
    labels: false,
  },
  rotation: 1 * Math.PI,
  circumference: 1 * Math.PI,
  cutoutPercentage: 70,
  legend: {
    display: true,
    position: 'right',
    align: 'start',
    reverse: true,
    labels: {
      usePointStyle: true,
      fontSize: 16,
      fontColor: '#343434',
      padding: 20,
    },
  },
};

function customDoughnut(chart, centerConfig, txt, color, font, centerY) {
  let ctx = chart.chart.ctx;

  let fontStyle = centerConfig.fontStyle || 'Arial';
  let sidePadding = centerConfig.sidePadding || 20;
  let sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
  ctx.font = font;

  let stringWidth = ctx.measureText(txt).width;
  let elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

  // Find out how much the font can grow in width.
  let widthRatio = elementWidth / stringWidth;
  let newFontSize = Math.floor(30 * widthRatio);
  let elementHeight = chart.innerRadius * 2;

  // Pick a new font size so it will not be larger than the height of label.
  let fontSizeToUse = Math.min(newFontSize, elementHeight);

  //Set font settings to draw it correctly.
  ctx.textAlign = 'center';
  ctx.textBaseline = 'top';
  let centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
  ctx.font = fontSizeToUse + 'px ' + fontStyle;
  ctx.fillStyle = color;

  //Draw text in center
  ctx.fillText(txt, centerX, centerY);
}

let originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);

    let chart = this.chart;

    let centerConfig = chart.config.data;
    let txt = centerConfig.text;
    let color = centerConfig.color || '#1e1e1e';
    let fontStyle = centerConfig.fontStyle || 'Arial';
    let font = '60px ' + fontStyle;
    let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

    customDoughnut(chart, centerConfig, txt, color, font, centerY);

    let txt2 = centerConfig.text2;
    let color2 = centerConfig.color || '#343434';
    let font2 = '22px';
    let centerY2 = (chart.chartArea.top + chart.chartArea.bottom) / 1.3;

    customDoughnut(chart, centerConfig, txt2, color2, font2, centerY2);
  },
});

function DoughnutChart() {
  return (
    <Col md="6">
      <Card>
        <div className="card-head">
          <CardTitle>Number of clients</CardTitle>
          <Link to="/dashboard" className="card-link">
            <i className="icon icon-right-align" />
            View all
          </Link>
        </div>
        <CardBody>
          <Doughnut data={data} options={options} />
        </CardBody>
        <CardFooter>
          <Row>
            <Col xs="6" className="chart-footer">
              <span className="name">Active Clients</span>
              <span className="count text-black">453</span>
            </Col>
            <Col xs="6" className="chart-footer">
              <span className="name">Inactive Clients</span>
              <span className="count inactive">53</span>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>
  );
}

export default DoughnutChart;
