import React from 'react';
const Footer = () => {
  return (
    <div className="footer text-center mt-5">
      <p>
        <small>Copyright © 2020 My Connect. All rights reserved.</small>
      </p>
    </div>
  );
};
export default Footer;
