import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyMceEditor = ({ value, updateTo, onChange }) => {
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      value={value}
      init={{
        relative_urls: 0,
        remove_script_host: 0,
        height: '300',
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount email',
        ],
        toolbar: `formatselect | bold italic forecolor backcolor preview | \
                           alignleft aligncenter alignright alignjustify | \
                           bullist numlist outdent indent | removeformat | image | preview | email`,
        setup: editor => {
          editor.editorManager.PluginManager.add('email', function(editor) {
            // Add a button that opens a window
            editor.ui.registry.addButton('email', {
              text: 'E-Mail',
              tooltip: 'Add email link',
              onAction: () => {
                editor.windowManager.open({
                  title: 'E-Mail Address',
                  body: {
                    type: 'panel',
                    items: [
                      {
                        type: 'input',
                        name: 'email',
                        label: 'Enter the email',
                      },
                    ],
                  },
                  buttons: [
                    {
                      type: 'cancel',
                      name: 'closeButton',
                      text: 'Cancel',
                    },
                    {
                      type: 'submit',
                      name: 'submitButton',
                      text: 'Ok',
                      primary: true,
                    },
                  ],
                  onSubmit: function(api) {
                    var data = api.getData();

                    window.tinymce.activeEditor.execCommand(
                      'mceInsertContent',
                      false,
                      '<a href="mailto:' + data.email + '">' + data.email + '</a>'
                    );
                    api.close();
                  },
                });
              },
            });
          });
        },
      }}
      onEditorChange={content => {
        onChange(updateTo, content);
      }}
    />
  );
};

export default TinyMceEditor;
