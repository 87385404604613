import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { closeSidebarModal } from 'actions/clientActions';

export default function ModalWrapper({ title, close, children }) {
  // const dispatch = useDispatch();
  const { name } = useSelector(state => state.client.client);

  // const handleModalClose = () => {
  //   if (close) close();
  //   dispatch(closeSidebarModal());
  // };
  
  return (
    <div className="client-settings-container">
      {/* <div className="cross-out" onClick={handleModalClose}>
        <i className="icon icon-cross-out" />
      </div> */}
      <div className="content client-settings">
        <div className="title">
          <span>{name} platform</span>
          <h1>{title}</h1>
          {/* <div className="btn-container">
            <button
              className="btn btn-add"
              // onClick={this.handleCreateVoucherModal}
            >
              <i className="icon icon-plus"></i> Create Activity
            </button>
          </div> */}
        </div>
        <>{children}</>
      </div>
    </div>
  );
}
