import React from 'react';
import { Modal, Row, Col, ModalHeader } from 'reactstrap';

const ViewMore = ({ showViewMoreModal, toggleViewMore, data }) => {
  return (
    
    <Modal className="log-details" isOpen={showViewMoreModal} toggle={toggleViewMore}>
      <ModalHeader toggle={toggleViewMore} className="mb-3">Voucher Log Details</ModalHeader>
      <Row>
        <Col md="3">
          <div>
            <strong>User Id</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{data.userId}</small>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <div>
            <strong>Action Type</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{data.actionType}</small>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <div>
            <strong>Voucher Code</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{data.voucherCode}</small>
        </Col>
      </Row>
    { data.usedAt &&
      <Row>
        <Col md="3">
          <div>
            <strong>Used At</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{data.usedAt}</small>
        </Col>
      </Row>
    }
    { data.course && (typeof data.course === 'object') && <>
      <Row>
        <Col md="3">
          <div>
            <strong>Course Id</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{data.course._id}</small>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <div>
            <strong>Course Name</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{data.course.name}</small>
        </Col>
      </Row></>
    }
      <Row>
        <Col md="3">
          <div>
            <strong>Old Data</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{JSON.stringify(data.metaData.oldData)}</small>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <div>
            <strong>New Data</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{JSON.stringify(data.metaData.newData)}</small>
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewMore;
