import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, Input, FormFeedback, Spinner, Label, Row, Col } from 'reactstrap';

import Wrapper from 'common/modalWrapper';
import { ON_CHANGE_ANALYTICS_INPUT } from 'actions/types';
import { updateAnalytics } from 'actions/clientActions';

export default function Analytics({ clientId }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { client, updateClientLoader } = useSelector(state => state.client);
  const { analytics } = client;
  const { hjid, hjsv, gaTrackingId, fbPixelCode, googleTagId, googleWebmasterCode } = analytics;
  const [error, setError] = useState(false);

  const onInputChange = e => {
    setError(false);
    const payload = {
      name: e.target.name,
      value: e.target.value,
    };

    dispatch({ type: ON_CHANGE_ANALYTICS_INPUT, payload });
  };

  const handleSave = () => {
    if ((hjid && !hjsv) || (!hjid && hjsv)) {
      return setError(true);
    }
    const payload = {
      hjid,
      hjsv,
      gaTrackingId,
      fbPixelCode,
      googleTagId,
      googleWebmasterCode,
      clientId,
    };

    dispatch(updateAnalytics(payload, history));
  };

  return (
    <Wrapper title="Analytics Setting">
      <Form className="bp-form">
        <div className="settings-container d-block">
          <Row>
            <Col md="4">
              <fieldset>
                <legend>Hotjar</legend>
                <FormGroup>
                  <Label for="hjid">HJ Id</Label>
                  <Input
                    id="hjid"
                    name="hjid"
                    type="text"
                    placeholder="hjid"
                    value={hjid}
                    onChange={onInputChange}
                    invalid={error && !hjid}
                  />
                  <FormFeedback>Please input hjid</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="hjsv">HJSV Id</Label>
                  <Input
                    name="hjsv"
                    type="text"
                    placeholder="hjsv"
                    value={hjsv}
                    onChange={onInputChange}
                    invalid={error && !hjsv}
                  />
                  <FormFeedback>Please input hjsv</FormFeedback>
                </FormGroup>
              </fieldset>
            </Col>
            <Col md="4">
              <fieldset>
                <legend>Google Analytics</legend>
                <FormGroup>
                  <Label for="gaTrackingId">Tracking Id</Label>
                  <Input
                    name="gaTrackingId"
                    type="text"
                    placeholder="ga tracking id"
                    value={gaTrackingId}
                    onChange={onInputChange}
                  />
                </FormGroup>
              </fieldset>
            </Col>
            <Col md="4">
              <fieldset>
                <legend>Google Tag Manager</legend>
                <FormGroup>
                  <Label for="googleTagId">Tag Id</Label>
                  <Input
                    name="googleTagId"
                    type="text"
                    placeholder="Google Tag Id"
                    value={googleTagId}
                    onChange={onInputChange}
                  />
                </FormGroup>
              </fieldset>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md="4">
              <fieldset>
                <legend>Google Webmaster</legend>
                <FormGroup>
                  <Label for="googleWebmasterCode">Webmaster Code</Label>
                  <Input
                    name="googleWebmasterCode"
                    type="text"
                    placeholder="Google Webmaster Code"
                    value={googleWebmasterCode}
                    onChange={onInputChange}
                  />
                </FormGroup>
              </fieldset>
            </Col>
            <Col md="4">
              <fieldset>
                <legend>Facebook Pixel Code</legend>
                <FormGroup>
                  <Label for="fbPixelCode">Facebook Pixel Code</Label>
                  <Input
                    name="fbPixelCode"
                    type="text"
                    placeholder="Facebook Pixel Code"
                    value={fbPixelCode}
                    onChange={onInputChange}
                  />
                </FormGroup>
              </fieldset>
            </Col>
          </Row>

          <div className="settings-footer">
            <button className="btn btn-add" onClick={handleSave} disabled={updateClientLoader}>
              {updateClientLoader ? <Spinner size="sm" /> : 'Save'}
            </button>
          </div>
        </div>
      </Form>
    </Wrapper>
  );
}
