import React, { Component } from 'react';
// import { Notifier } from '@airbrake/browser';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    // this.airbrake = new Notifier({
    //   projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID,
    //   projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY,
    //   environment: process.env.REACT_APP_ENV,
    // });
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // componentDidCatch(error, info) {
  //   this.airbrake.notify({
  //     error: error,
  //     params: { info: info },
  //   });
  // }

  render() {
    if (this.state.hasError) {
      return (
        <h1
          className="d-flex align-items-center justify-content-center"
          style={{ height: '100vh' }}
        >
          Something went wrong. Please try again later or contact to our
          administration.
        </h1>
      );
    }

    return this.props.children;
  }
}
