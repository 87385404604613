import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, Spinner } from 'reactstrap';

import Wrapper from 'common/modalWrapper';
import Editor from 'components/TinyMceEditor';
import { updateRecruiterDetails } from 'actions/clientActions';
import { ON_CHANGE_RECRUITER_INPUT } from 'actions/types';

export default function RecruiterSettings({ clientId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { client, updateClientLoader } = useSelector(state => state.client);
  const { recruiterDetails } = client;
  const {
    jobsPageMessage,
    recruiterPageMessage,
    sectorPageMessage,
    enableRecruiter,
  } = recruiterDetails;

  const onChangeMessage = (name, value) => {
    const payload = { name, value };

    dispatchChanges(payload);
  };

  const onCheckboxChange = e => {
    const payload = {
      name: e.target.name,
      value: e.target.checked,
    };

    dispatchChanges(payload);
  };

  const dispatchChanges = payload => {
    dispatch({
      type: ON_CHANGE_RECRUITER_INPUT,
      payload,
    });
  };

  const handleSave = () => {
    const payload = {
      jobsPageMessage,
      recruiterPageMessage,
      sectorPageMessage,
      enableRecruiter,
      clientId,
    };

    dispatch(updateRecruiterDetails(payload, history));
  };

  return (
    <Wrapper title="Recruiter Info Settings">
      <Form className="bp-form">
        <div className="settings-container d-block">
          <div className="row">
            <div className="col-md-6">
              <label>
                Jobs page info
                <small className="d-block">
                  The added information will be displayed on the first page of recruiter flow.
                </small>
              </label>
              <Editor
                value={jobsPageMessage}
                onChange={onChangeMessage}
                updateTo="jobsPageMessage"
              />

              <label className="mt-3">
                Sector page info
                <small className="d-block">
                  This information will be displayed on the third page of recruiter flow.
                </small>
              </label>
              <Editor
                value={sectorPageMessage}
                onChange={onChangeMessage}
                updateTo="sectorPageMessage"
              />
            </div>

            <div className="col-md-6">
              <label className="mt-3">
                Recruiter page info
                <small className="d-block">
                  This information will be displayed on the second page of recruiter flow.
                </small>
              </label>
              <Editor
                value={recruiterPageMessage}
                onChange={onChangeMessage}
                updateTo="recruiterPageMessage"
              />

              <fieldset className="form-group mt-3">
                <legend> Enable/Disable </legend>
                <div className="on-off-menu">
                  <FormGroup>
                    <label htmlFor="enableRecruiter">Enable external job posting</label>
                    <span className="checkbox pull-right">
                      <input
                        type="checkbox"
                        id="enableRecruiter"
                        name="enableRecruiter"
                        checked={enableRecruiter}
                        onChange={onCheckboxChange}
                      />
                      <label></label>
                    </span>
                  </FormGroup>
                </div>
              </fieldset>

              <div className="settings-footer">
                <button className="btn btn-add" onClick={handleSave} disabled={updateClientLoader}>
                  {updateClientLoader ? <Spinner size="sm" /> : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Wrapper>
  );
}
