import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, FormFeedback, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changePassword, checkResetPasswordUrl, resetPassword } from 'actions/authActions';

class ResetPassword extends Component {
  state = {
    newPassword: '',
    confirmPassword: '',
    showLoader: false,
    error: '',
    isInvalid: false,
  };

  componentDidMount() {
    this.checkUrl();
  }

  checkUrl = () => {
    this.props.checkResetPasswordUrl(this.props.match.params.hash);
  };

  handldeInputChange = e => {
    this.setState({ error: '', [e.target.name]: e.target.value });
  };

  handleReset = e => {
    const { newPassword, confirmPassword } = this.state;
    if (!newPassword || !confirmPassword) {
      return this.setState({ isInvalid: true });
    }

    if (newPassword !== confirmPassword) {
      return this.setState({
        error: 'New and confirm password does not match',
      });
    }

    const payload = {
      email: this.props.auth.resetPasswordEmail,
      password: newPassword,
      hash: this.props.match.params.hash,
    };

    this.props.resetPassword(payload, this.props.history);
  };

  checkValidation = () => {
    const { error } = this.props.error;

    const message = error && error[0] && error[0].password;
    return message ? message : '';
  };

  render() {
    const { newPassword, confirmPassword, isInvalid, error } = this.state;
    const { isResetPasswordLoading, isLoading } = this.props.auth;
    const { error: propsError } = this.props.error;
    const hasError = propsError && propsError.includes('token') && propsError.includes('invalid');

    if (hasError) {
      return (
        <div className="auth-page">
          <div className="auth-container">
            <div className="auth" style={{ textAlign: 'center' }}>
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width={64} height={64}>
                <path
                  fill="#8bb7f0"
                  d="M12,22.5C6.21,22.5,1.5,17.79,1.5,12S6.21,1.5,12,1.5S22.5,6.21,22.5,12S17.79,22.5,12,22.5z"
                />
                <path
                  fill="#4e7ab5"
                  d="M12,2c5.514,0,10,4.486,10,10s-4.486,10-10,10S2,17.514,2,12S6.486,2,12,2 M12,1 C5.925,1,1,5.925,1,12s4.925,11,11,11s11-4.925,11-11S18.075,1,12,1L12,1z"
                />
                <path fill="#fff" d="M12 4A8 8 0 1 0 12 20A8 8 0 1 0 12 4Z" />
                <path
                  fill="#36404d"
                  d="M12 10.625A1.375 1.375 0 1 0 12 13.375A1.375 1.375 0 1 0 12 10.625Z"
                />
                <path fill="none" stroke="#36404d" d="M15.143 15.929L12 12 14.357 5.714" />
                <path
                  fill="#ffeea3"
                  d="M8.86,38.5c-0.49,0-0.93-0.254-1.176-0.679c-0.245-0.425-0.245-0.933,0-1.357l14.14-24.491 c0.246-0.425,0.686-0.679,1.176-0.679s0.93,0.254,1.176,0.679l14.14,24.491c0.245,0.425,0.245,0.933,0,1.357 C38.069,38.246,37.63,38.5,37.14,38.5H8.86z"
                />
                <path
                  fill="#ba9b48"
                  d="M23,11.794c0.149,0,0.519,0.042,0.743,0.429l14.14,24.491c0.223,0.387,0.074,0.729,0,0.858 S37.587,38,37.14,38H8.86c-0.447,0-0.668-0.3-0.743-0.429s-0.223-0.471,0-0.858l14.14-24.491 C22.481,11.836,22.851,11.794,23,11.794 M23,10.794c-0.626,0-1.251,0.31-1.609,0.929L7.252,36.214C6.537,37.452,7.43,39,8.86,39 H37.14c1.43,0,2.324-1.548,1.609-2.786l-14.14-24.491C24.251,11.104,23.626,10.794,23,10.794L23,10.794z"
                />
                <path
                  fill="#36404d"
                  d="M23 31.291A1.709 1.709 0 1 0 23 34.709 1.709 1.709 0 1 0 23 31.291zM22 20H24V29H22z"
                />
              </svg> */}
              <h3 className="mt-3-">Password reset session expired or invalid</h3>;
              <Link to="/" className="btn-base center btn btn-secondary">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        {isResetPasswordLoading ? (
          <span>
            <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...
          </span>
        ) : (
          <div className="auth-page">
            <div className="auth-container">
              <div className="auth">
                <h1>Reset your password?</h1>

                <Form className="bp-form modal-form">
                  <Col>
                    <FormGroup>
                      <Label for="newPassword">
                        New Password <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        placeholder="********"
                        onChange={this.handldeInputChange}
                        invalid={!newPassword && isInvalid}
                      />
                      <FormFeedback>New Password is required</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="confirmPassword">
                        Confirm Password <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="********"
                        onChange={this.handldeInputChange}
                        invalid={
                          (!confirmPassword && isInvalid) || this.checkValidation() ? true : false
                        }
                      />
                      <FormFeedback>
                        {this.checkValidation()
                          ? this.checkValidation()
                          : 'Confirm Password is required'}
                      </FormFeedback>
                      <span className="text-danger" style={{ fontSize: '80%' }}>
                        {error ? error : null}
                      </span>
                    </FormGroup>
                  </Col>
                  <Button
                    className="btn-add float-right btn btn-secondary"
                    onClick={this.handleReset}
                  >
                    {isLoading ? (
                      <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Form>
              </div>
              <p className="copyright">My Connect © 2020. All rights reserved</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

ResetPassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  checkResetPasswordUrl: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error,
});

export default connect(mapStateToProps, {
  changePassword,
  checkResetPasswordUrl,
  resetPassword,
})(ResetPassword);
