import React, { Component } from 'react';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import setAuthToken from 'utils/setAuthToken';
import { toast } from 'react-toastify';

import AuthenticatedRoute from './index';
import ProtectedRoute from 'common/ProtectedRoute';
import { setCurrentUser } from 'actions/authActions';

toast.configure();

class MainApp extends Component {
  isAuthenticated = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      return false;
    }

    try {
      const decoded = jwtDecode(token);

      this.props.setCurrentUser(decoded);

      const expTime = decoded.exp * 1000; // in millisecond
      let currentTime = new Date().getTime();

      if (expTime < currentTime) {
        localStorage.removeItem('token');
        toast.info('Session expired, please log in !', {
          closeButton: false,
        });
        return false;
      }

      if (decoded.data.adminId) {
        setAuthToken(token);
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };
  render() {
    let isAuthenticated = this.isAuthenticated();

    return (
      <ProtectedRoute
        path="/"
        component={AuthenticatedRoute}
        isAuthenticated={isAuthenticated}
      />
    );
  }
}

export default connect(null, { setCurrentUser })(MainApp);
