import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';

import Api from 'API';
import Form from 'reactstrap/lib/Form';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Wrapper from 'common/modalWrapper';
import Button from 'reactstrap/lib/Button';
import FormGroup from 'reactstrap/lib/FormGroup';
import { getFileNameFromUrl } from 'utils/helper';
import { UPLOADING, SAVING } from 'common/constants';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import { updateClientOnReset } from 'actions/clientActions';

toast.configure();

export default function SSO() {
  const params = useParams();
  const dispatch = useDispatch();
  const { allData } = useSelector(state => state.client.client);
  const [state, setState] = useState({
    isActive: false,
    callbackUrl: '',
    entryPoint: '',
    issuer: '',
    algorithm: 'sha256',
    cert: '',
    isInvalid: false,
    flag: '',
    certName: '',
    logoutUrl: '',
  });
  const {
    callbackUrl,
    entryPoint,
    issuer,
    algorithm,
    isInvalid,
    cert,
    flag,
    isActive,
    logoutUrl,
  } = state;

  React.useEffect(() => {
    window.scrollTo(0, 0);

    const data = allData.ssoSAML;
    const certName = getFileNameFromUrl(data.cert);

    setState(state => ({
      ...state,
      isActive: !!data.isActive,
      callbackUrl:
        data.callbackUrl ||
        `${process.env.REACT_APP_BASE_API_URL}/sso/login/callback/${allData.subdomain}`,
      entryPoint: data.entryPoint || '',
      issuer: data.issuer || '',
      algorithm: data.algorithm || 'sha256',
      cert: data.cert || '',
      certName,
      logoutUrl: data.logoutUrl || '',
    }));
  }, [allData.ssoSAML, allData.subdomain]);

  const handleCheckbox = e => {
    const { name, checked: value } = e.target;

    setState(state => ({ ...state, [name]: value, isInvalid: false }));
  };

  const handleInput = e => {
    const { name, value } = e.target;
    setState(state => ({ ...state, [name]: value.trim(), isInvalid: false }));
  };

  const handleUpload = e => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    setState(state => ({ ...state, flag: UPLOADING }));

    new Api()
      .imageUpload(formData)
      .then(result => {
        console.log({ certificateUploaded: result });

        setState(state => ({
          ...state,
          cert: result[0].image.Location,
          flag: '',
          certName: file.name,
        }));
      })
      .catch(err => {
        console.log({ certificateUploadError: err });
        setState(state => ({ ...state, flag: '' }));
      });
  };

  const handleSave = () => {
    if (!entryPoint || !issuer || !algorithm || !cert || !logoutUrl) {
      return setState(state => ({ ...state, isInvalid: true }));
    }

    setState(state => ({ ...state, flag: SAVING }));

    const payload = { entryPoint, issuer, algorithm, cert, isActive, logoutUrl };

    new Api()
      .configureSSOSAML({ payload, clientId: params.id })
      .then(result => {
        console.log({ configureSSOSAML: result });
        setState(state => ({ ...state, flag: '' }));
        toast.success('SSO-SAML config saved!', { autoClose: 3000 });

        updateStore(result);
      })
      .catch(err => {
        console.log({ configureSSOSAMLError: err });
        setState(state => ({ ...state, flag: '' }));
        toast.error(`Something went wrong! ${err.response?.data?.errors || ''}`, {
          autoClose: 7000,
        });
      });
  };

  const updateStore = result => {
    const payload = {
      ...allData,
      ...result.data,
    };

    dispatch(updateClientOnReset(payload));
  };

  return (
    <Wrapper title="SSO (SAML)">
      <div className="modal-body" style={{ width: '60%' }}>
        <FormGroup>
          <label className="font-weight-normal">
            <div className="checkbox float-left">
              <input onChange={handleCheckbox} checked={isActive} type="checkbox" name="isActive" />
              <label></label>
            </div>
            &nbsp; Enable SSO
          </label>
        </FormGroup>
        {isActive ? (
          <Form className="bp-form">
            <FormGroup>
              <Label for="name">Callback URL</Label>
              <Input
                type="text"
                name="callbackUrl"
                style={{ color: '#797d7a' }}
                value={callbackUrl}
                disabled
              />
              <FormFeedback>Callback URL is required</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="name">
                Entry Point <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="entryPoint"
                placeholder="https://entry-point.domain/"
                value={entryPoint}
                invalid={!entryPoint && isInvalid}
                onChange={handleInput}
              />
              <FormFeedback>Entry Point is required</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="logoutUrl">
                Logout URL <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="logoutUrl"
                placeholder="https://logout-url.domain/"
                value={logoutUrl}
                invalid={!logoutUrl && isInvalid}
                onChange={handleInput}
              />
              <FormFeedback>Logout URL is required</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="name">
                Issuer <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="issuer"
                placeholder="dummy-issuer"
                value={issuer}
                invalid={!issuer && isInvalid}
                onChange={handleInput}
              />
              <FormFeedback>Issuer is required</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="name">
                Algorithm <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="algorithm"
                placeholder="Name"
                value={algorithm}
                invalid={!algorithm && isInvalid}
                onChange={handleInput}
              />
              <FormFeedback>Algorithm is required</FormFeedback>
            </FormGroup>

            <FormGroup>
              <div className="custom-input">
                <Label for="name" style={{ display: 'block' }}>
                  Certificate <span className="text-danger">*</span>
                </Label>
                {flag === UPLOADING ? (
                  <>
                    Uploading <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                  </>
                ) : (
                  <div className="custom-inputbox" style={{ display: 'inline-block' }}>
                    <Input type="file" name="cert" onChange={handleUpload} />
                    Upload
                  </div>
                )}
                {state.certName ? <em> {state.certName}</em> : null}
                {!cert && isInvalid ? (
                  <span className="text-danger" style={{ fontSize: '80%', display: 'block' }}>
                    Logo is required
                  </span>
                ) : null}
              </div>
            </FormGroup>
          </Form>
        ) : null}
        <Button className="btn-add float-right" onClick={handleSave} style={{ width: '115px' }}>
          {flag === SAVING ? (
            <>
              Saving <i className="fa fa-spinner fa-spin" />
            </>
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </Wrapper>
  );
}
