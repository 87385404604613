import React, { Component } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleSidebar } from 'actions/sidebarActions';
import { logout } from 'actions/authActions';
import { getLocalStorage } from 'utils/localStorage';

class UserOptions extends Component {
  state = {
    dropdownOpen: false,
    profilePic: '',
  };

  componentDidMount() {
    this.getLoggedInData();
  }

  getLoggedInData = () => {
    const decoded = getLocalStorage();
    if (decoded) {
      const { profilePic } = decoded.data;
      this.setState({ profilePic });
    }
  };

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  handleLogout = () => {
    this.props.logout(this.props.propsData.history);
  };

  handleToggleSidebar = () => {
    if (!this.props.sidebar.toggleSidebar) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.props.toggleSidebar();
  };

  handleOutsideClick = e => {
    // ignore clicks on the component itself
    if (this.props.sidebar.node.contains(e.target)) {
      return;
    }

    this.handleToggleSidebar();
  };

  render() {
    const { dropdownOpen, profilePic } = this.state;
    const { toggleSidebar } = this.props.sidebar;

    return (
      <div className="user-options">
        {/* <div className="menu" onClick={this.props.toggleSidebar}> */}
        <div
          className="menu"
          onClick={this.handleToggleSidebar}
          ref={node => {
            this.props.sidebar.node = node;
          }}
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
        <div className={`logo ${toggleSidebar ? 'd-none' : ''}`}>
          <Link to="/">
            <img src={'/images/logo.svg'} alt="logo" />
          </Link>
        </div>
        <div className="options">
          <div className="searchbox">
            <i className="icon icon-search"></i>
          </div>
          <div className="notification">
            <i className="icon icon-ring"></i>
            <span className="badge badge-danger">0</span>
          </div>
          <Dropdown isOpen={dropdownOpen} toggle={this.toggle} className="user">
            <DropdownToggle>
              {profilePic ? (
                <img
                  src={profilePic}
                  style={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '100%',
                  }}
                  alt="profile"
                />
              ) : (
                <i className="icon icon-user"></i>
              )}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <Link to="/superadmin">
                  <i className="icon icon-view"></i> View Profile
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/change-password">
                  {' '}
                  <i className="icon icon-lock"></i> Change Password
                </Link>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={this.handleLogout}>
                {' '}
                <i className="icon icon-logout"></i> Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

UserOptions.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  sidebar: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  sidebar: state.sidebar,
});

export default connect(mapStateToProps, { toggleSidebar, logout })(UserOptions);
