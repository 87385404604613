import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  getSuperAdmins,
  toggleSuperAdminCreateModal,
  deleteSuperAdmin,
  toggleSuperAdminDeleteModal,
  getSingleSuperAdmin,
} from 'actions/superAdminActions';
import SuperAdminModal from 'components/SuperAdminModal';
import DeleteModal from 'components/GrantAccessPopup';

class SuperAdmin extends Component {
  state = {
    searchKey: '',
    singleData: [],
    stopGetDerivedStateFromProps: false,
    deleteModal: false,
    ok: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const payload = {
      currentPage: 1,
      // pageLimit: 5,
      onlyActive: true,
      searchKey: this.state.searchKey,
    };

    this.props.getSuperAdmins(payload);
  };

  showSingleData = id => {
    this.props.getSingleSuperAdmin(id);
  };

  handleSearch = e => {
    const eventKey = e.key;
    this.setState({ searchKey: e.target.value }, () => {
      if (eventKey === 'Enter') {
        this.setState(
          { singleData: [], stopGetDerivedStateFromProps: false },
          () => {
            this.fetchData();
          }
        );
      }
    });
  };

  handleModal = () => {
    this.props.toggleSuperAdminCreateModal();
  };

  handleDeleteModal = (e) => {
    e.preventDefault()
    this.props.toggleSuperAdminDeleteModal();
  };

  handleDelete = () => {
    const id = this.props.superAdmin.singleData[0]._id;
    const currentUserId = this.props.auth.user.data._id;

    let logout = false;
    if (id === currentUserId) {
      logout = true;
    }
    this.props.deleteSuperAdmin(id, logout, this.props.history);
  };

  render() {
    const { searchKey } = this.state;
    const singleData = this.props.superAdmin.singleData[0];
    const {
      data,
      isLoading,
      showModal,
      showDeleteModal,
      isDeleting,
    } = this.props.superAdmin;

    return (
      <div className="content" style={{ padding: '0'}}>
        {showModal ? <SuperAdminModal handleModal={this.handleModal} /> : null}
    
        <div className="superadmin-section">
          <div className="superadmin-list-container">
            <div className="form-group searchbar">
              <input
                type="text"
                name="name"
                placeholder="Search by name"
                value={searchKey}
                onChange={this.handleSearch}
                onKeyDown={this.handleSearch}
              />
            </div>
            <div className="filter-options"></div>
            {/* {isLoading && !singleData ? ( */}
            <PerfectScrollbar style={{ position: 'absolute', top: '29%' }}>
              {isLoading ? (
                <span><i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...</span>
              ) : (
                <>
                  {!data.length ? (
                    <>No user found</>
                  ) : (
                    <div className="superadmin-list">
                      {data.map(superadmin => (
                        <div
                          className={`superadmin-user ${
                            superadmin._id === singleData._id ? 'active' : ''
                          }`}
                          key={superadmin._id}
                          onClick={() => this.showSingleData(superadmin._id)}
                        >
                          <div className="user-img">
                            <img
                              src={
                                superadmin.profilePic
                                  ? superadmin.profilePic
                                  : '/images/user.svg'
                              }
                              alt="user"
                            />
                          </div>
                          <div className="user">
                            <span className="name">
                              {superadmin.firstName} {superadmin.lastName}
                            </span>
                           
                            <span className="created-date">
                              {superadmin.createdAt}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </PerfectScrollbar>
          </div>
          {!isLoading && singleData ? (
            <div className="superadmin-edit">

                <div className="title">
          <span onClick={this.fetchData}>Superadmin</span>
          <h1>User info</h1>
          <div className="btn-container" onClick={this.handleModal}>
            <button className="btn btn-add">
              <i className="icon icon-plus"></i>Create Superadmin
            </button>
          </div>
        </div>

              {/* {!isLoading && singleData ? ( */}
              <Form className="edit-profile text-center">

                <span className="profileimg-uploader">
                    <span className="image-uploaded">
                      <img
                        src={
                          singleData.profilePic
                            ? singleData.profilePic
                            : '/images/user.svg'
                        }
                        alt="user"
                      />
                      </span>
                    </span>
              
                            <span className="name">
                              {singleData.firstName} {singleData.lastName}
                            </span>
               
                    <span>
                    {singleData.email}              
                  </span>
                  <span>
                    {singleData.userId}
                  </span>

                      <span className="checkbox-super-admin-status">
                        <input
                          type="checkbox"
                          checked={singleData.isActive}
                          readOnly
                        />
                        <label></label>
                      </span>
                       

                 
                  <button
                    className="btn btn-add"
                    onClick={this.handleDeleteModal}
                  >
                    <i className="icon icon-trash" />
                    Delete
                  </button>
                  {showDeleteModal ? (
                    <DeleteModal
                      handleDeleteModal={this.handleDeleteModal}
                      handleDelete={this.handleDelete}
                      fromSuperAdminDelete={true}
                      isLoading={isDeleting}
                    />
                  ) : null}
            
              </Form>
              {/* ) : (
                <span>Loading...</span>
              )} */}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

SuperAdmin.propTypes = {
  getSuperAdmins: PropTypes.func.isRequired,
  toggleSuperAdminCreateModal: PropTypes.func.isRequired,
  deleteSuperAdmin: PropTypes.func.isRequired,
  toggleSuperAdminDeleteModal: PropTypes.func.isRequired,
  getSingleSuperAdmin: PropTypes.func.isRequired,
  superAdmin: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  superAdmin: state.superAdmin,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getSuperAdmins,
  toggleSuperAdminCreateModal,
  deleteSuperAdmin,
  toggleSuperAdminDeleteModal,
  getSingleSuperAdmin,
})(SuperAdmin);
