import React from 'react';

const GrantAccessPopup = props => {
  return (
    <div className="popup">
      <div className="popup-body">
        <div className="icon-holder">
          <i className="icon icon-danger" />
        </div>
        {props.fromSuperAdminDelete ? (
          <div>
            <h3>Are you sure?</h3>
            <p>Do you really want to delete this super admin?</p>
          </div>
        ) : (
          <div>
            <h3>Grant access!</h3>
            <p>You are about to give access to {props.subdomain}</p>
          </div>
        )}
      </div>
      <div className="popup-footer">
        {props.isLoading ? 'Loading...' : null}
        <div
          className="btn btn-success bp-btn"
          onClick={
            props.fromSuperAdminDelete
              ? props.handleDelete
              : props.handleGrantAccess
          }
        >
          Yes
        </div>
        <div
          className="btn btn-danger bp-btn"
          onClick={
            props.fromSuperAdminDelete
              ? props.handleDeleteModal
              : props.cancelGrantAccess
          }
        >
          No
        </div>
      </div>
    </div>
  );
};
export default GrantAccessPopup;
