import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createSuperAdmin, clearErrors } from 'actions/superAdminActions';
import { uploadImage } from 'actions/uploadActions';

class SuperAdminModal extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    userId: '',
    password: '',
    confirmPassword: '',
    profilePic: '',
    isInvalid: false,
    isPasswordMatch: true,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.upload.url !== prevState.profilePic) {
      return { profilePic: nextProps.upload.url };
    }
    return null;
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  handleInput = e => {
    this.props.clearErrors();
    this.setState({ [e.target.name]: e.target.value, isPasswordMatch: true });
  };

  handleImageUpload = e => {
    this.props.uploadImage(e.target.files[0], 'profilePic');
  };

  handleSubmit = e => {
    e.preventDefault();

    this.handleCreate();
  };

  handleCreate = async () => {
    const {
      firstName,
      lastName,
      email,
      userId,
      password,
      profilePic,
      confirmPassword,
    } = this.state;

    if (!firstName || !lastName || !email || !userId || !password) {
      return this.setState({ isInvalid: true });
    }

    if (password !== confirmPassword) {
      return this.setState({ isPasswordMatch: false });
    }

    const payload = {
      firstName,
      lastName,
      email,
      userId,
      password,
      profilePic,
    };

    this.props.createSuperAdmin(payload, this.props);
  };

  handleModalClose = () => {
    this.props.handleModal();
  };

  uniqueValidation(key) {
    const { error } = this.props;

    const hasError = error.includes(key) && error.includes('validation');

    return hasError ? `${key} is already being used` : '';
  }

  checkValidation(key) {
    const { error } = this.props;

    if (typeof error === 'object') {
      const errObj = error.find(err => {
        return Object.keys(err)[0] === key;
      });

      return errObj ? Object.values(errObj)[0] : '';
    }
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      userId,
      profilePic,
      password,
      confirmPassword,
      isInvalid,
      isPasswordMatch,
    } = this.state;

    const { isUploading } = this.props.upload;
    const { isLoading } = this.props.superAdmin;

    return (
      <div className="client-add-edit-modal">
        <div className="modal-header" onClick={this.handleModalClose}>
          <i className="icon icon-cross-out" />
        </div>
        <div className="modal-body">
          <h2>Create Super Admin</h2>
          <Form className="bp-form modal-form">
            <FormGroup>
              <Label for="firstName">
                First name <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="firstName"
                invalid={
                  (!firstName && isInvalid) || this.checkValidation('firstName')
                    ? true
                    : false
                }
                onChange={this.handleInput}
                value={firstName}
              />
              <FormFeedback>
                {this.checkValidation('firstName')
                  ? this.checkValidation('firstName')
                  : 'First Name is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="lastName">
                Last name <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="lastName"
                invalid={
                  (!lastName && isInvalid) || this.checkValidation('lastName')
                    ? true
                    : false
                }
                onChange={this.handleInput}
                value={lastName}
              />
              <FormFeedback>
                {this.checkValidation('lastName')
                  ? this.checkValidation('lastName')
                  : 'Last Name is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="email">
                Email <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="email"
                invalid={
                  (!email && isInvalid) ||
                  (this.uniqueValidation('email') ? true : false) ||
                  this.checkValidation('email')
                    ? true
                    : false
                }
                onChange={this.handleInput}
                value={email}
              />
              <FormFeedback>
                {this.uniqueValidation('email')
                  ? this.uniqueValidation('email')
                  : this.checkValidation('email')
                  ? this.checkValidation('email')
                  : 'Email is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="userId">
                User Id <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="userId"
                invalid={
                  (!userId && isInvalid) ||
                  (this.uniqueValidation('userId') ? true : false) ||
                  this.checkValidation('userId')
                    ? true
                    : false
                }
                onChange={this.handleInput}
                value={userId}
              />
              <FormFeedback>
                {this.uniqueValidation('userId')
                  ? this.uniqueValidation('userId')
                  : this.checkValidation('userId')
                  ? this.checkValidation('userId')
                  : 'User id is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="password">
                Password <span className="text-danger">*</span>
              </Label>
              <Input
                type="password"
                name="password"
                invalid={
                  (!password && isInvalid) || this.checkValidation('password')
                    ? true
                    : false
                }
                onChange={this.handleInput}
                value={password}
              />
              <FormFeedback>
                {this.checkValidation('password')
                  ? this.checkValidation('password')
                  : 'Password is required'}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="confirmPassword">
                Confirm password <span className="text-danger">*</span>
              </Label>
              <Input
                type="password"
                name="confirmPassword"
                invalid={!isPasswordMatch}
                onChange={this.handleInput}
                value={confirmPassword}
              />
              <FormFeedback>Confirm password do not match</FormFeedback>
            </FormGroup>
            <FormGroup>
              <div className="custom-input">
                <Label for="profilePic">Profile photo</Label>{' '}
                {isUploading ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                ) : (
                  <div className="custom-inputbox">
                    <Input
                      type="file"
                      name="logo"
                      onChange={this.handleImageUpload}
                    />
                    Upload image
                  </div>
                )}
              </div>
              {profilePic ? (
                <img src={profilePic} alt="profile" className="uploaded-logo" />
              ) : null}
            </FormGroup>
            <Button className="btn-add float-right" onClick={this.handleSubmit}>
              {isLoading ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true" />
              ) : (
                'Create'
              )}
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}
SuperAdminModal.propTypes = {
  createSuperAdmin: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  superAdmin: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  superAdmin: state.superAdmin,
  error: state.error,
  upload: state.upload,
});

export default connect(mapStateToProps, {
  createSuperAdmin,
  clearErrors,
  uploadImage,
})(SuperAdminModal);
