import jwtDecode from 'jwt-decode';

import {
  GET_ERROR,
  SET_CURRENT_USER,
  AUTH_LOADING,
  CLEAR_ERROR,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD,
} from './types';
import setAuthToken from 'utils/setAuthToken';
import Api from 'API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

toast.configure();

export const setAuthLoading = isLoading => {
  return {
    type: AUTH_LOADING,
    payload: isLoading,
  };
};

/* Set logged in user */
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const login = (data, history) => dispatch => {
  dispatch(setAuthLoading(true));
  new Api()
    .login(data)
    .then(result => {
      /* save to localStorage */
      const { token } = result.data;
      localStorage.setItem('token', token);

      /* set token to auth header */
      setAuthToken(token);

      const decoded = jwtDecode(token);

      /* set current user */
      dispatch(setCurrentUser(decoded));

      dispatch(setAuthLoading(false));

      history.push('/dashboard');
    })
    .catch(err => {
      console.log({ loginError: err });

      dispatch(setAuthLoading(false));

      const errorCode = err.response?.status;
      let error = 'Invalid username or password';
      if (errorCode === 429) {
        const try_after = err.response.data?.try_after;
        error = `Too many request. Please try again after ${try_after} seconds.`;
      }

      dispatch({
        type: GET_ERROR,
        payload: error,
      });
    });
};

export const logout = history => dispatch => {
  let toastId = toast.info('Logging out !', {
    toastId: 'logout-toast',
    autoClose: false,
    closeButton: false,
  });

  new Api()
    .logout()
    .then(response => {
      localStorage.removeItem('token');
      history.replace('/');

      toast.update(toastId, {
        render: 'You are logged out !',
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      });
    })
    .catch(error => {
      localStorage.removeItem('token');
      history.replace('/');

      toast.update(toastId, {
        render: 'You are logged out !',
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      });
    });
};

export const changePassword = (data, history) => dispatch => {
  dispatch(setAuthLoading(true));
  dispatch({ type: CLEAR_ERROR });
  new Api()
    .changePassword(data)
    .then(result => {
      toast.success('Password has been changed !', { autoClose: 3000 });

      /* remove token from localStorage */
      localStorage.removeItem('token');
      history.replace('/');

      dispatch(setAuthLoading(false));
    })
    .catch(err => {
      dispatch(setAuthLoading(false));
      dispatch({
        type: GET_ERROR,
        payload:
          err && err.response && err.response.data ? err.response.data : '',
      });
    });
};

export const forgotPassword = (email, history) => dispatch => {
  dispatch(setAuthLoading(true));
  dispatch({ type: CLEAR_ERROR });
  new Api()
    .forgotPassword(email)
    .then(result => {
      toast.success(`Email has been sent to ${email}`, { autoClose: 3000 });

      /* remove token from localStorage */
      history.replace('/');

      dispatch(setAuthLoading(false));
    })
    .catch(err => {
      dispatch(setAuthLoading(false));
      dispatch({
        type: GET_ERROR,
        payload:
          err && err.response && err.response.data ? err.response.data : '',
      });
    });
};

export const checkResetPasswordUrl = hash => dispatch => {
  dispatch({ type: RESET_PASSWORD_LOADING });
  dispatch({ type: CLEAR_ERROR });
  new Api()
    .checkResetPasswordUrl(hash)
    .then(result => {
      dispatch({ type: RESET_PASSWORD, payload: result.data });
    })
    .catch(err => {
      dispatch({ type: RESET_PASSWORD_LOADING });
      dispatch({
        type: GET_ERROR,
        payload:
          err && err.response && err.response.data ? err.response.data : '',
      });
    });
};

export const resetPassword = (payload, history) => dispatch => {
  dispatch(setAuthLoading(true));
  dispatch({ type: CLEAR_ERROR });
  new Api()
    .resetPassword(payload)
    .then(result => {
      toast.success(`Password has been reset successfully !`, {
        autoClose: 3000,
      });

      /* remove token from localStorage */
      history.replace('/');

      dispatch(setAuthLoading(false));
    })
    .catch(err => {
      dispatch(setAuthLoading(false));
      dispatch({
        type: GET_ERROR,
        payload:
          err && err.response && err.response.data ? err.response.data : '',
      });
    });
};
