import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from 'reactstrap';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { login } from 'actions/authActions';
import { clearError } from 'actions/errorActions';

class Login extends Component {
  state = {
    userId: '',
    password: '',
    isInvalid: false,
  };

  checkLogin = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      return false;
    }

    try {
      const decoded = jwtDecode(token);

      if (decoded.data.adminId) {
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  handleInputChange = event => {
    this.props.clearError();

    this.setState({
      [event.target.name]: event.target.value,
      isInvalid: false,
    });
  };

  handleLogin = () => {
    const { userId, password } = this.state;

    if (!userId || !password) {
      return this.setState({ isInvalid: true });
    }

    this.handleLoginApi();
  };

  handleLoginApi = () => {
    const { userId, password } = this.state;
    const data = {
      userId,
      password,
    };

    this.props.login(data, this.props.history);
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleLogin();
    }
  };

  render() {
    const { userId, password, isInvalid } = this.state;
    const { error, auth } = this.props;

    return this.checkLogin() ? (
      <Redirect to="/dashboard" />
    ) : (
      <div className="auth-page">
        <div className="auth-container">
          <div className="auth">
            <h1>Login</h1>
            <span>with super admin credentials</span>
            <Form onKeyPress={this.handleKeyPress} className="bp-form">
              <FormGroup className="with-icon username">
                <Label for="email">
                  <i className="icon icon-user" aria-hidden="true" />
                  Username
                </Label>
                <Input
                  type="text"
                  name="userId"
                  placeholder="username"
                  onChange={this.handleInputChange}
                  invalid={!userId && isInvalid}
                />
                <FormFeedback>Username is required</FormFeedback>
              </FormGroup>
              <FormGroup className="with-icon password">
                <Label for="password">
                  <i className="icon icon-lock" aria-hidden="true" />
                  Password
                </Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="password"
                  onChange={this.handleInputChange}
                  invalid={!password && isInvalid}
                />
                <FormFeedback>Password is required</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Remember me</Label>
                <div className="custon-checkbox">
                  <Input type="checkbox" name="remember" id="exampleEmail" />
                  <span />
                </div>
                <Link className="float-right" to="/forgot-password">
                  Forgot Password?
                </Link>
              </FormGroup>
            </Form>
            {error && typeof error === 'string' ? (
              <span
                className={'text-danger'}
                style={{
                  position: 'absolute',
                  marginTop: '-45px',
                  fontSize: '80%',
                }}
              >
                {error}
              </span>
            ) : null}
            <Button className="btn-base center" onClick={this.handleLogin}>
              {auth.isLoading ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true" />
              ) : (
                'Sign In'
              )}
            </Button>
          </div>
          <p className="copyright">My Connect © 2020. All rights reserved</p>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error,
});

export default connect(mapStateToProps, { login, clearError })(Login);
