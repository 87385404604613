import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Api from 'API';
import Loader from 'common/loader';
import NoData from 'common/noData';
import * as constants from 'common/constants';
import ModalWrapper from 'common/modalWrapper';

toast.configure();

export default function Activities() {
  const params = useParams();
  const { allData } = useSelector(state => state.client.client);
  const [state, setState] = useState({
    activities: [],
    loading: constants.FETCHING,
    unchecked: [],
  });

  useEffect(() => {
    new Api()
      .getActivities({ all: true })
      .then(res => {
        const unchecked = [];
        res.data.map(activity => {
          if (allData.activities.length && !allData.activities.includes(activity._id)) {
            unchecked.push(activity._id);
          }

          return activity;
        });
        setState(state => ({ ...state, activities: res.data, loading: '', unchecked }));
      })
      .catch(error => {
        console.log({ getActivitiesError: error });
        setState(state => ({ ...state, loading: '' }));
      });
  }, []);

  const onCheckboxChange = (e, id) => {
    let data = state.unchecked;
    if (e.target.checked) {
      data = data.filter(value => value !== id);
    } else {
      data.push(id);
    }

    setState({ ...state, unchecked: data });
  };

  const handleSave = () => {
    setState(state => ({ ...state, loading: constants.SAVING }));

    const payload = {
      clientId: params.id,
      unchecked: state.unchecked,
    };
    new Api()
      .saveActivitiesToClient(payload)
      .then(result => {
        setState(state => ({ ...state, loading: '' }));
        const message = result.message || 'Activities assigned';
        toast.success(message, { autoClose: 3000 });
      })
      .catch(error => {
        console.log({ saveActivitiesToClientError: error });
        const err = error.response.data.error || 'Error on assigning activities';
        toast.error(err, { autoClose: 5000 });
        setState(state => ({ ...state, loading: '' }));
      });
  };

  return (
    <ModalWrapper title="Activities">
      {state.loading === constants.FETCHING ? (
        <Loader />
      ) : (
        <>
          {!state.activities.length ? (
            <NoData title="No Activities" />
          ) : (
            <div className="table-responsive mt-3">
              <table className="career-list table first-col-bordered bg-white">
                <thead>
                  <tr>
                    <th className="width-50">Select</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {state.activities.map((activity, i) => {
                    return (
                      <tr key={activity._id}>
                        <td>
                          <div className="tbl-checkbox">
                            <div className="styled-input--square">
                              <div className="styled-input-single">
                                <Input
                                  type="checkbox"
                                  checked={!state.unchecked.find(data => data === activity._id)}
                                  onChange={e => onCheckboxChange(e, activity._id)}
                                  className="form-checkbox-input"
                                  id={activity._id}
                                />
                                <label htmlFor={activity._id}></label>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{activity.name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Button className="btn-add" onClick={handleSave}>
                {state.loading === constants.SAVING ? 'Saving...' : 'Save'}
              </Button>
            </div>
          )}
        </>
      )}
    </ModalWrapper>
  );
}
