import React from 'react';

import Checkbox from 'components/Forms/checkbox';

const data = [
  { label: 'Learner Community Forum', name: 'allowLearnerCommunityForum' },
  { label: 'Admin Community Forum', name: 'allowAdminCommunityForum' },
  { label: 'Advertisement', name: 'enableAdvertisement' },
];

export default function({ handleCheckbox, client }) {
  return (
    <>
      {data.map(datum => (
        <Checkbox
          label={datum.label}
          checked={client[datum.name]}
          name={datum.name}
          onChange={handleCheckbox}
        />
      ))}
    </>
  );
}
