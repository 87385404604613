import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Spinner } from 'reactstrap';

import Wrapper from 'common/modalWrapper';
import Editor from 'components/TinyMceEditor';
import { onChangePolicyInput, updateClientPolicy } from 'actions/clientActions';

const ClientPolicy = ({ clientId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { client, updateClientLoader } = useSelector(state => state.client);
  const { policies } = client;
  const { privacyPolicy, termsAndConditions, cookiePolicy, userLicense } = policies;

  const onChangeMessage = (name, value) => {
    const payload = { name, value };

    dispatch(onChangePolicyInput(payload));
  };

  const handleSave = () => {
    const payload = {
      clientId,
      policies: {
        privacyPolicy,
        termsAndConditions,
        cookiePolicy,
        userLicense,
      },
    };

    dispatch(updateClientPolicy(payload, history));
  };

  return (
    <Wrapper title="Policy &amp; Conditions">
      <Form className="bp-form">
        <div className="settings-container d-block">
          <div className="row">
            <div className="col-md-6">
              <label>Privacy Policy</label>
              <Editor value={privacyPolicy} onChange={onChangeMessage} updateTo="privacyPolicy" />

              <label className="mt-3">Cookie Policy </label>
              <Editor value={cookiePolicy} onChange={onChangeMessage} updateTo="cookiePolicy" />
            </div>
            <div className="col-md-6">
              <label>Terms &amp; Conditions</label>
              <Editor
                value={termsAndConditions}
                onChange={onChangeMessage}
                updateTo="termsAndConditions"
              />
              <label className="mt-3">User License</label>
              <Editor value={userLicense} onChange={onChangeMessage} updateTo="userLicense" />

              <div className="settings-footer">
                <button className="btn btn-add" onClick={handleSave} disabled={updateClientLoader}>
                  {updateClientLoader ? <Spinner size="sm" /> : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Wrapper>
  );
};

export default ClientPolicy;
