import React from 'react';
import { Modal, Row, Col, ModalHeader, ModalBody } from 'reactstrap';
import { formatDate, currencySign } from 'utils/helper';

const ViewMore = ({ showViewMoreModal, toggleViewMore, paymentLog }) => {
  const actionValues = { 
    fullPayment: 'Full Payment', 
    downPayment: 'Down Payment', 
    installmentPayment: 'Installment Payment', 
  }
  let voucherDiscount = parseFloat(paymentLog.voucherDiscountAmount) ? `${currencySign(paymentLog.currency)} ${paymentLog.voucherDiscountAmount}` : '0';
  let discountAmount = parseFloat(paymentLog.discountAmount) ? `${currencySign(paymentLog.currency)} ${paymentLog.discountAmount}` : '0';
  let grossAmount = parseFloat(paymentLog.grossAmount) ? `${currencySign(paymentLog.currency)} ${paymentLog.grossAmount}` : '0';
  let amountPaid = parseFloat(paymentLog.netAmount) ? `${currencySign(paymentLog.currency)} ${paymentLog.netAmount}` : '0';
  let creditUsed = paymentLog.creditUsed ? `${paymentLog.creditUsed.creditBalance} (${currencySign(paymentLog.currency)} ${paymentLog.creditUsed.equivalentPrice})` : '';
  return (
    <Modal className="" isOpen={showViewMoreModal} toggle={toggleViewMore}>
      <ModalHeader toggle={toggleViewMore}>Course Payment Log</ModalHeader>
      <ModalBody style={{ height: '70vh', overflow: 'auto' }}>
        <table className="table table-striped">
          <tbody>
            <tr>
              <th scope="row">Learner Id</th>
              <td className="align-middle">{paymentLog.learner ? paymentLog.learner.userId : ''}</td>
            </tr>
            <tr>
              <th scope="row">Course</th>
              <td className="align-middle">{paymentLog.course ? paymentLog.course.name : ''}</td>
            </tr>
            <tr>
              <th scope="row">Created At</th>
              <td className="align-middle">{formatDate(paymentLog.createdAt)}</td>
            </tr>
            <tr>
              <th scope="row">Type</th>
              <td className="align-middle">{actionValues[paymentLog.action]}</td>
            </tr>
            <tr>
              <th scope="row">Gross Amount</th>
              <td className="align-middle">{grossAmount}</td>
            </tr>
            <tr>
              <th scope="row">Discount Amount</th>
              <td className="align-middle">{discountAmount}</td>
            </tr>
            <tr>
              <th scope="row">VAT</th>
              <td className="align-middle">{parseFloat(paymentLog.vat) ? `${paymentLog.vat}%` : 0}</td>
            </tr>
            <tr>
              <th scope="row">Voucher Code</th>
              <td className="align-middle">{paymentLog.voucherCode}</td>
            </tr>
            <tr>
              <th scope="row">Voucher Discount</th>
              <td className="align-middle">{voucherDiscount}</td>
            </tr>
            <tr>
              <th scope="row">Amount Paid</th>
              <td className="align-middle">{amountPaid}</td>
            </tr>
            <tr>
              <th scope="row">Status</th>
              <td className="align-middle">{paymentLog.isPaymentSucceeded ? 'Payment Successful' : 'Payment Failed'}</td>
            </tr>
            { paymentLog.failureDetails && 
              <tr>
                <th scope="row">Failed Installment</th>
                <td className="align-middle">{paymentLog.failureDetails.installmentNumber}</td>
              </tr>
            }
            { paymentLog.failureDetails && 
              <tr>
                <th scope="row">Failure Reason</th>
                <td className="align-middle">{paymentLog.failureDetails.reason}</td>
              </tr>
            }
            { creditUsed && 
              <tr>
                <th scope="row">Credit Used (Equivalent Price)</th>
                <td className="align-middle">{creditUsed}</td>
              </tr>
            }
          </tbody>
        </table>
      </ModalBody>
    </Modal>
  );
};

export default ViewMore;
