import React from 'react';
import { ListGroup, ListGroupItem, Badge } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import { COURSES, JOBS, REST } from 'common/constants';

const courses = ['Courses', 'Categories', 'Learning Topics', 'Learning Sub Sections'];
const rest = [
  'Menus',
  'Sectors',
  'Regions',
  'Sub Menus',
  'Taxonomies',
  'Organisation Sectors',
  'Recommended Courses',
  'Third Party Jobs Configs',
];

export default function CloneInstance({ cloneWhat, handleClone, isCloning }) {
  return (
    <div className="mt-4">
      <h2>
        {cloneWhat === JOBS
          ? 'All of the jobs will be cloned. It might take time depending on the number of jobs.'
          : 'Following contents will be cloned'}
          <hr className='mt-1 w-50' />
      </h2>
      <ListGroup className="mt-2 customListGroup">
        {cloneWhat === COURSES && (
          <>
            {courses.map(course => (
              <ListGroupItem key={course} className="justify-content-between">
                {course}
              </ListGroupItem>
            ))}
          </>
        )}
        {cloneWhat === REST && (
          <>
            {rest.map(r => (
              <ListGroupItem key={r} className="justify-content-between">
                {r}
              </ListGroupItem>
            ))}
          </>
        )}
      </ListGroup>
      <div className="mt-3">
        <div className="btn btn-add w-25 py-1" onClick={handleClone}>
          {isCloning ? (
            <>
              Loading <i className="fa fa-spinner fa-spin" />
            </>
          ) : (
            `Clone ${cloneWhat.toLowerCase()}`
          )}
        </div>
      </div>
      {/* <Button
        style={{ width: '155px' }}
        color="secondary"
        onClick={handleClone}
        outline
        className="mt-3"
      >
        {isCloning ? (
          <>
            Loading <i className="fa fa-spinner fa-spin" />
          </>
        ) : (
          `Clone ${cloneWhat.toLowerCase()}`
        )}
      </Button> */}
    </div>
  );
}
