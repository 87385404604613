import { TOGGLE_SIDEBAR } from 'actions/types';

const initialState = {
  toggleSidebar: false,
  node: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        toggleSidebar: !state.toggleSidebar,
      };
    default:
      return state;
  }
}
