import React, { Component } from 'react';

import { Row, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Pagination from 'components/Pagination';
import { getPaymentLogs, clearLogs } from 'actions/logActions';
import ViewMore from 'components/Logs/paymentView';

class PaymentLogs extends Component {
  state = {
    currentPage: 1,
    pageLimit: 20,
    logId: '',
    showViewMoreModal: false,
  };

  componentDidMount() {
    this.props.clearLogs();
    this.fetchData();
  }

  componentWillUnmount() {
    this.props.clearLogs();
  }

  fetchData = () => {
    const { currentPage, pageLimit } = this.state;

    const payload = {
      currentPage,
      pageLimit,
      subdomain: this.props.match.params.subdomain,
    };

    this.props.getPaymentLogs(payload);
  };

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({ currentPage: index }, () => {
      this.fetchData();
    });
  };

  formatDate(date) {
    return new Date(date).toLocaleDateString('en-UK', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  showCurrencySign(currency) {
    if (currency === 'usd') return '$';
    if (currency === 'eur') return '€';
    if (currency === 'gbp') return '£';
    if (currency === 'aud') return 'A$';
    return '$';
  }

  toggleViewMore = id => {
    this.setState(prevState => ({
      showViewMoreModal: !prevState.showViewMoreModal,
      logId: id,
    }));
  };

  render() {
    const { currentPage, pageLimit, showViewMoreModal, logId } = this.state;
    const { logs, isLoading, totalLogs, totalPage } = this.props.logs;

    return (
      <>
        <div className="content">
          <div className="title">
            <span>List of</span>
            <h1>Payment Logs</h1>
          </div>
          {isLoading ? (
            <span>
              <i className="fa fa-spinner fa-spin" aria-hidden="true" />{' '}
              Loading...
            </span>
          ) : (
            <>
              {!logs.length ? (
                <h2>No logs to display</h2>
              ) : (
                <Row>
                  <Col md="12">
                    <Table className="base-table clients-table" responsive>
                      <thead>
                        <tr>
                          <th>S.N</th>
                          <th>Client Subdomain</th>
                          <th>User Id</th>
                          <th>Email</th>
                          <th>Amount</th>
                          <th>Date of Payment</th>

                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {logs.map((log, index) => {
                          return (
                            <tr key={log._id}>
                              <td scope="row">
                                {currentPage * pageLimit -
                                  pageLimit +
                                  (index + 1)}
                              </td>
                              <td>{log.clientSubdomain}</td>
                              <td>{log.learnerId}</td>
                              <td>{log.learnerEmail}</td>
                              <td>
                                {this.showCurrencySign(log.currency)}{' '}
                                {log.amount}
                              </td>
                              <td>{this.formatDate(log.dateOfPay)}</td>
                              <td className="has-popup text-center">
                                <ul>
                                  <li
                                    className="btn-setting"
                                    onClick={e => this.toggleViewMore(log._id)}
                                  >
                                    View More
                                  </li>
                                </ul>
                                {showViewMoreModal && log._id === logId ? (
                                  <ViewMore
                                    toggleViewMore={this.toggleViewMore}
                                    data={log}
                                    showViewMoreModal={showViewMoreModal}
                                  />
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Pagination
                      total={totalLogs}
                      currentPage={currentPage}
                      totalPage={totalPage}
                      pageLimit={pageLimit}
                      handleClick={this.handlePagination}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

PaymentLogs.propTypes = {
  getPaymentLogs: PropTypes.func.isRequired,
  clearLogs: PropTypes.func.isRequired,
  logs: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  logs: state.log,
});

export default connect(mapStateToProps, {
  getPaymentLogs,
  clearLogs,
})(PaymentLogs);
