import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import FormGroup from 'reactstrap/lib/FormGroup';
import Wrapper from 'common/modalWrapper';

import Api from 'API';
import Loader from 'common/loader';
import NoData from 'common/noData';
import { capitalizeWord } from 'utils/helper';
import { updateClientOnReset } from 'actions/clientActions';
import { SAVING, FETCHING, GAMIFICATION_INPUT_NAMES } from 'common/constants';

toast.configure();

const GamificationSetting = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { allData } = useSelector(state => state.client.client);
  const [state, setState] = useState({
    flag: FETCHING,
    allowReputationPoints: false,
    allowLearningPathway: false,
    activities: [],
    uncheckedActivites: [],
    components: [],
    uncheckedComponents: [],
    accolades: [],
    uncheckedAccolades: [],
    recommendations: [],
    uncheckedRecommendations: [],
  });

  useEffect(() => {
    const gamificationData = allData.gamification;
    console.log({ gamificationData });

    setState(state => {
      return {
        ...state,
        allowReputationPoints: !!gamificationData.allowReputationPoints,
        allowLearningPathway: !!gamificationData.allowLearningPathway,
      };
    });

    const getActivites = new Api().getActivities({ all: true });
    const getComponents = new Api().getComponents({ all: true });
    const getAccolades = new Api().getAccolades({ all: true });
    const getRecommendations = new Api().getRecommendations({ all: true });

    Promise.all([getActivites, getComponents, getAccolades, getRecommendations])
      .then(([activityRes, componentRes, accoladesRes, recommendationRes]) => {
        const activityUnchecked = [];
        activityRes.data.forEach(activity => {
          if (
            gamificationData.activities.length &&
            !gamificationData.activities.includes(activity._id)
          ) {
            activityUnchecked.push(activity._id);
          }
        });

        const componentUnchecked = [];
        componentRes.data.forEach(component => {
          if (
            gamificationData.components.length &&
            !gamificationData.components.includes(component._id)
          ) {
            componentUnchecked.push(component._id);
          }
        });

        const accoladesUnchecked = [];
        accoladesRes.data.forEach(accolade => {
          if (
            gamificationData.accolades.length &&
            !gamificationData.accolades.includes(accolade._id)
          ) {
            accoladesUnchecked.push(accolade._id);
          }
        });

        const recommendationsUnchecked = [];
        recommendationRes.data.forEach(recommendation => {
          if (
            gamificationData.recommendations.length &&
            !gamificationData.recommendations.includes(recommendation._id)
          ) {
            recommendationsUnchecked.push(recommendation._id);
          }
        });

        console.log({ activityUnchecked, componentUnchecked });

        setState(state => ({
          ...state,
          activities: activityRes.data,
          uncheckedActivites: activityUnchecked,
          components: componentRes.data,
          uncheckedComponents: componentUnchecked,
          accolades: accoladesRes.data,
          uncheckedAccolades: accoladesUnchecked,
          recommendations: recommendationRes.data,
          uncheckedRecommendations: recommendationsUnchecked,

          flag: '',
        }));
      })
      .catch(error => {
        console.log(error);
        setState(state => ({ ...state, flag: '' }));
      });
  }, []);

  const selectUI = name => {
    let data = state.activities;
    let uncheckedData = state.uncheckedActivites;
    let hasRules = false;

    if (name === GAMIFICATION_INPUT_NAMES.components) {
      data = state.components;
      uncheckedData = state.uncheckedComponents;
      hasRules = true;
    } else if (name === GAMIFICATION_INPUT_NAMES.accolades) {
      data = state.accolades;
      uncheckedData = state.uncheckedAccolades;
    } else if (name === GAMIFICATION_INPUT_NAMES.recommendations) {
      data = state.recommendations;
      uncheckedData = state.uncheckedRecommendations;
    }

    return (
      <div className="table-responsive mt-3">
        <h3>Select {capitalizeWord(name)}</h3>
        <table className="career-list table first-col-bordered bt-white">
          <thead>
            <tr>
              <th className="width-50">Select</th>
              <th>Name</th>
              {hasRules ? <th>Rules</th> : null}
            </tr>
          </thead>
          <tbody>
            {data.map((datum, i) => {
              return (
                <tr key={datum._id}>
                  <td>
                    <div className="tbl-checkbox">
                      <div className="styled-input--square">
                        <div className="styled-input-single">
                          <Input
                            type="checkbox"
                            name={name}
                            id={datum._id}
                            checked={!uncheckedData.find(unchecked => unchecked === datum._id)}
                            className="form-chckbox-input"
                            onChange={e => handleSelectCheckbox(e, datum._id)}
                          />
                          <label htmlFor={datum._id}></label>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{datum.name}</td>
                  {hasRules ? <td>{datum.rules.join(', ')}</td> : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const handleAllowCheckbox = e => {
    const { name, checked: value } = e.target;
    setState(state => {
      return { ...state, [name]: value };
    });
  };

  const checkUncheckData = (data, checked, id) => {
    if (checked) data = data.filter(value => value !== id);
    else data.push(id);
    return data;
  };

  const handleSelectCheckbox = (e, id) => {
    const { name, checked } = e.target;

    if (name === GAMIFICATION_INPUT_NAMES.activities) {
      const data = checkUncheckData(state.uncheckedActivites, checked, id);
      setState(state => ({ ...state, uncheckedActivites: data }));
    } else if (name === GAMIFICATION_INPUT_NAMES.components) {
      const data = checkUncheckData(state.uncheckedComponents, checked, id);
      setState(state => ({ ...state, uncheckedComponents: data }));
    } else if (name === GAMIFICATION_INPUT_NAMES.accolades) {
      const data = checkUncheckData(state.uncheckedAccolades, checked, id);
      setState(state => ({ ...state, uncheckedAccolades: data }));
    } else if (name === GAMIFICATION_INPUT_NAMES.recommendations) {
      const data = checkUncheckData(state.uncheckedRecommendations, checked, id);
      setState(state => ({ ...state, uncheckedRecommendations: data }));
    }
  };

  const handleSave = () => {
    setState(state => ({ ...state, flag: SAVING }));

    let payload = {
      clientId: params.id,
      allowReputationPoints: state.allowReputationPoints,
      allowLearningPathway: state.allowLearningPathway,
      uncheckedActivities: state.uncheckedActivites,
      uncheckedComponents: state.uncheckedComponents,
      uncheckedAccolades: state.uncheckedAccolades,
      uncheckedRecommendations: state.uncheckedRecommendations,
    };

    new Api()
      .configureGamification(payload)
      .then(result => {
        setState(state => ({ ...state, flag: '' }));
        const message = result.message || 'Gamification configured';
        toast.success(message, { autoClose: 3000 });

        updateStore(result.data);
      })
      .catch(error => {
        console.log({ SaveGamificationConfiguration: error });
        const err = error.response.data.error || 'Error on configuring gamification';
        toast.error(err, { autoClose: 5000 });
        setState(state => ({ ...state, flag: '' }));
      });
  };

  const updateStore = data => {
    console.log({ gamificatonFromRes: data });
    const gamification = data.gamification;

    const payload = {
      ...allData,
      gamification: {
        ...gamification,
        activities: gamification.activities.map(act => act.activity),
        components: gamification.components.map(comp => comp.component),
        accolades: gamification.accolades.map(acco => acco.accolade),
        recommendations: gamification.recommendations.map(reccom => reccom.recommendation),
      },
    };

    dispatch(updateClientOnReset(payload));
  };

  return (
    <Wrapper title="Gamification">
      {state.flag === FETCHING ? (
        <Loader />
      ) : (
        <>
          <Row>
            <Col>
              <FormGroup>
                <label className="font-weight-normal">
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      name="allowReputationPoints"
                      checked={state.allowReputationPoints}
                      onChange={handleAllowCheckbox}
                    />
                    <label></label>
                  </div>
                  &nbsp; Allow Reputation Points
                </label>
              </FormGroup>

              {state.allowReputationPoints ? (
                !state.activities.length ? (
                  <NoData text="No Activities" />
                ) : (
                  selectUI(GAMIFICATION_INPUT_NAMES.activities)
                )
              ) : null}
            </Col>
            <Col>
              <FormGroup>
                <label className="font-weight-normal">
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      name="allowLearningPathway"
                      checked={state.allowLearningPathway}
                      onChange={handleAllowCheckbox}
                    />
                    <label></label>
                  </div>
                  &nbsp; Allow Learning Pathway
                </label>
              </FormGroup>

              {state.allowLearningPathway ? (
                <>
                  {!state.components.length ? (
                    <NoData text="No Components" />
                  ) : (
                    selectUI(GAMIFICATION_INPUT_NAMES.components)
                  )}

                  {!state.accolades.length ? (
                    <NoData text="No Accolades" />
                  ) : (
                    selectUI(GAMIFICATION_INPUT_NAMES.accolades)
                  )}

                  {!state.recommendations.length ? (
                    <NoData text="No Recommendations" />
                  ) : (
                    selectUI(GAMIFICATION_INPUT_NAMES.recommendations)
                  )}
                </>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className="btn-add float-right"
                onClick={handleSave}
                style={{ width: '115px' }}
              >
                {state.flag === SAVING ? (
                  <>
                    Saving <i className="fa fa-spinner fa-spin" />
                  </>
                ) : (
                  'Save'
                )}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Wrapper>
  );
};

export default GamificationSetting;
