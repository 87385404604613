import React, { useEffect, useState, useRef } from 'react';
import Api from 'API';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Input,
  Row,
  Col,
  Table,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  FormFeedback,
} from 'reactstrap';

// import Loader from 'common/loader';
// import NoData from 'common/noData';
import * as constants from 'common/constants';
// import ModalWrapper from 'common/modalWrapper';

export default function Activities(props) {
  const inputRef=useRef(null)
  const dispatch = useDispatch();
  // const intervalIdRef = React.useRef('');
  // const timeoutIdRef = React.useRef('');
  const { hasActivities } = useSelector(state => state.client.client);

  const [state, setState] = useState({
    activities: [],
    loading: constants.FETCHING,
    pageNumber: 1,
    name: '',
    isInvalid: false,
  });

  const {isInvalid, name } = state;

  const handleSubmit = e => {
    inputRef.current.focus()

    e.preventDefault();

    if (!name) return setState({ ...state, isInvalid: true });


    const payload = {
      name,
    };

    setState(state => ({ ...state, loading: true, name: '' }));

    postActivities(payload);
  };

  const postActivities = payload => {
    new Api()
      .postActivities(payload)
      .then(result => {
        console.log({ result });

        setState(state => ({ ...state, loading: constants.FETCHING }));
        handleActivitiesModal();
      })
      .catch(err => {
        console.log({ postActivitiesError: err });

        setState(state => ({ ...state, loading: false }));
      });
  };

  const handleInput = e => {
    setState({
      ...state,
      name: e.target.value,
      isInvalid: false,
    });
  };

  useEffect(() => {
    if (state.loading !== constants.FETCHING) return;
    new Api()
      .getActivities({ pageNumber: state.pageNumber })
      .then(res => {
        setState(state => ({ ...state, activities: res.data, loading: '' }));
      })
      .catch(error => {
        console.log({ getActivitiesError: error });
        setState(state => ({ ...state, loading: '' }));
      });
  }, [state.loading]);

  const [showActivitiesModal, setModal] = useState(false);
  const handleActivitiesModal = () => setModal(!showActivitiesModal);

  return (
    <>
      <div className="content">
        <div className="title">
          <span>List of</span>
          <h1>Activities</h1>
          <div className="btn-container">
            <button className="btn btn-add" onClick={handleActivitiesModal}>
              <i className="icon icon-plus"></i> Create Activity
            </button>
          </div>
        </div>

        {/* // =============================================================================
        // Modal Popup
        // ============================================================================= */}
        <Modal isOpen={showActivitiesModal} toggle={handleActivitiesModal} size="lg" style={{maxWidth: '80%', width: '40%'}}>
          <ModalHeader toggle={handleActivitiesModal} charCode="×">
            Create new activity
          </ModalHeader>
          <Form className="bp-form modal-form" onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label for="name">
                  Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  value={name}
                  invalid={!name && isInvalid}
                  onChange={handleInput}
                  ref={inputRef} 
                />
                 <FormFeedback>Oh noes! enter the activities name</FormFeedback>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              {/* <Button color="secondary">Submit</Button> */}
              {hasActivities ? (
                <Button color="primary" onClick={handleActivitiesModal}>
                  Cancel
                </Button>
              ) : (
                <Button color="secondary" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>

        {/* <ModalWrapper name="Activities"> */}
        {state.loading === constants.FETCHING ? (
          <span>
            <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...
          </span>
        ) : (
          <>
            {!state.activities.length ? (
              <span>No activities data</span>
            ) : (
              <div className="table-responsive mt-3">
                <Table className="base-table clients-table" responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Created At</th>
                      {/* <th className="text-center" width="10%">
                          Actions
                        </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {state.activities.map((activity, i) => {
                      return (
                        <tr key={activity._id}>
                          <td>{i + 1}</td>
                          <td>{activity.name}</td>
                          <td>{activity.createdAt}</td>
                          {/* <td className="text-center">
                              <ul>
                                <li className="btn-setting">Delete</li>
                              </ul>
                            </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </>
        )}
        {/* </ModalWrapper> */}
      </div>
    </>
  );
}
