import {
  GET_VOUCHERS,
  VOUCHER_LOADING,
  UPDATE_VOUCHER,
  GET_VOUCHER_LOGS,
  VOUCHER_LOG_LOADING,
  VOUCHER_UPDATE_CURRENCY
} from 'actions/types';

const initialState = {
  vouchers: [],
  isLoading: false,
  totalVouchers: 0,
  totalPage: 0,
  voucherLogs: [],
  isLogLoading: false,
  totalVoucherLogs: 0,
  totalLogPage: 0,
  currency: '',
  membership: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case VOUCHER_LOADING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };

    case GET_VOUCHERS:
      return {
        ...state,
        vouchers: action.payload.vouchers,
        totalVouchers: action.payload.total,
        totalPage: Math.ceil(
          action.payload.total / action.payload.pageLimit
        ),
        isLoading: false,
        currency: action.payload.currency,
        membership: action.payload.membership,
      };

    case UPDATE_VOUCHER:
      {
        let vouchers = JSON.parse(JSON.stringify(state.vouchers));
        const idx = vouchers.findIndex(n => (n._id === action.payload._id));
        if(idx > -1){
          vouchers[idx] = { ...action.payload };
        }
        return {
          ...state,
          vouchers: vouchers,
        };
      }

    case VOUCHER_LOG_LOADING:
      return {
        ...state,
        isLogLoading: !state.isLogLoading,
      };

    case GET_VOUCHER_LOGS:
      return {
        ...state,
        voucherLogs: action.payload.voucherLogs,
        totalVoucherLogs: action.payload.total,
        totalLogPage: Math.ceil(
          action.payload.total / action.payload.pageLimit
        ),
        isLogLoading: false,
        currency: action.payload.currency,
      };

    case VOUCHER_UPDATE_CURRENCY:
      return {
        ...state,
        currency: action.payload.currency
      };

    default:
      return state;
  }
}
