import React from 'react';
import { useSelector } from 'react-redux';
import Button from 'reactstrap/lib/Button';

import AuthForm from './authForm';
import Wrapper from 'common/modalWrapper';

export default function ApiCredentials() {
  const { hasApiCredentials } = useSelector(state => state.client.client);
  const [state, setState] = React.useState({
    isInvalid: false,
    password: '',
    outerButtonClicked: false,
  });

  const { outerButtonClicked } = state;

  const handleOuterButton = () => {
    setState({ ...state, outerButtonClicked: true });
  };

  return (
    <Wrapper title="API Credentials">
      {!outerButtonClicked ? (
        <Button className="btn-add" onClick={handleOuterButton}>
          {hasApiCredentials ? 'Show API Credentials' : 'Create API Credentials'}
        </Button>
      ) : (
        <>
          {hasApiCredentials ? (
            <AuthForm buttonText="Authorize" showApiCred />
          ) : (
            <AuthForm buttonText="Authorize &amp; Create" />
          )}
        </>
      )}
    </Wrapper>
  );
}
