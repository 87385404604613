import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Wrapper from 'common/modalWrapper';
import {
  UPDATE_FAMILY_MEMBERSHIP_ON_INPUT_CHANGE,
  UPDATE_PAYMENT_ON_INPUT_CHANGE,
  UPDATE_CLIENT_ON_INPUT_CHANGE,
} from 'actions/types';
import { toast } from 'react-toastify';
import CreditSetting from './credit';
import StripeKeysInput from './stripeKeysInput';

const Payment = props => {
  const dispatch = useDispatch();
  const { skillsHubSectionToPublic } = useSelector(state => state.client.client);

  const {
    payment,
    hasDefaultStripeKeys,
    hasStripeKeys,
    stripeKeys,
    capitalize,
    getMembershipOptions,
    handleStripeCheckbox,
    handlestripeKeys,
    handleMembershipChargeReset,
    handleMembershipChargeSave,
    handleFamilyMembershipCheckbox,
    handleFamilyMembership,
    allowTrialPeriod,
    handleTrialPeriodCheckbox,
    allowB2CSellingOfCourse,
    canMemberRegisterWithoutPayment,
    handleB2CSellingCheckbox,
    coursePurchaseInstallmentPlan,
    handleCoursePurchaseInstallmentPlanCheckbox,
  } = props;
  const { membership, subscription, familyMembership, trialPeriod } = payment;
  const monthlySubscription = subscription.monthly?.isActive;
  const yearlySubscription = subscription.yearly?.isActive;
  const freeMembership = membership === 'free';
  const oneTimeMembership = membership === 'oneTime';
  const subscriptionMembership = membership === 'subscription';

  const { allowFamilyMembership, totalFamilyMembers, price } = familyMembership;
  const {
    allowAutomaticInstallmentPayment,
    allowManualInstallmentPayment,
  } = coursePurchaseInstallmentPlan;

  // TAB SETTING ===========================================================================
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleSubscription = (duration, key, value) => {
    if (key === 'price') value = Math.abs(value) || '';
    const subscriptionData = {
      ...subscription,
      [duration]: {
        ...subscription[duration],
        [key]: value,
      },
    };

    const payload = {
      name: 'subscription',
      value: subscriptionData,
    };

    dispatch({ type: UPDATE_PAYMENT_ON_INPUT_CHANGE, payload });
  };

  const handleFamilyMembershipPrice = (duration, value) => {
    let price = { ...familyMembership.price, [duration]: Math.abs(value) || '' };
    if (duration === 'oneTime') {
      price = {
        ...price,
        monthly: '',
        yearly: '',
      };
    } else {
      price = {
        ...price,
        oneTime: '',
      };
    }

    const payload = {
      name: 'price',
      value: price,
    };

    dispatch({ type: UPDATE_FAMILY_MEMBERSHIP_ON_INPUT_CHANGE, payload });
  };

  const familyMembershipCol = () => {
    if (oneTimeMembership) return 4;
    if (subscriptionMembership) {
      if (monthlySubscription && yearlySubscription) return 4;
      if (monthlySubscription || yearlySubscription) return 5;
    }

    return 12;
  };

  const handleSave = () => {
    const { price } = payment;
    const { price: familyPrice } = familyMembership;

    if (allowFamilyMembership) {
      if (!totalFamilyMembers) {
        return toast.error('Total family members is required', {
          autoClose: 7000,
        });
      } else if (
        (membership === 'oneTime' && !familyPrice?.oneTime) ||
        (membership === 'subscription' &&
          ((subscription?.monthly?.isActive && !familyPrice?.monthly) ||
            (subscription?.yearly?.isActive && !familyPrice?.yearly)))
      )
        return toast.error('Family Price is required', {
          autoClose: 7000,
        });
    }

    if (
      membership === 'subscription' &&
      !subscription.monthly.isActive &&
      !subscription.yearly.isActive
    ) {
      return toast.error('Please select at least one subscription duration', {
        autoClose: 7000,
      });
    }
    if (
      (membership === 'oneTime' && !price) ||
      (membership === 'subscription' &&
        ((subscription.monthly.isActive && !subscription.monthly.price) ||
          (subscription.yearly.isActive && !subscription.yearly.price)))
    ) {
      return toast.error('Price is required', {
        autoClose: 7000,
      });
    }

    if (
      (stripeKeys.publicKey && stripeKeys.secretKey && hasDefaultStripeKeys) ||
      (!stripeKeys.publicKey && !stripeKeys.secretKey && !hasDefaultStripeKeys && !hasStripeKeys)
    ) {
      return toast.error(
        'Please use either default or custom stripe keys. Use checkbox for default.',
        {
          autoClose: 7000,
        }
      );
    }

    if (!allowAutomaticInstallmentPayment && !allowManualInstallmentPayment) {
      return toast.error('Atleast one of the manual and automatic payment should be checked', {
        autoClose: 7000,
      });
    }

    handleMembershipChargeSave();
  };

  const handleCheckbox = e => {
    const { checked, name } = e.target;

    const payload = {
      name,
      value: checked,
    };

    dispatch({ type: UPDATE_CLIENT_ON_INPUT_CHANGE, payload });
  };

  return (
    <Wrapper title="Membership Charge Settings">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
            }}
          >
            Membership charge
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            Credit Payment
          </NavLink>
        </NavItem>
      </Nav>
      <div className="settings-container">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="p-3">
            <Row>
              <Col sm="12">
                <Form className="log-form form-inline">
                  <Row className="width-100">
                    <Col md="4 mt-2">
                      <FormGroup>
                        <Label>Membership Options</Label>
                        <span className="sm-selectWrap">
                          <Input
                            className="custom-select"
                            type="select"
                            name="membership"
                            id="membershipOptions"
                            value={payment.membership}
                            onChange={getMembershipOptions}
                          >
                            <option value="">Select</option>
                            <option value="free">No member ship charge</option>
                            <option value="oneTime">One time membership charge</option>
                            <option value="subscription">
                              Subscription based membership charge
                            </option>
                          </Input>
                        </span>
                      </FormGroup>
                    </Col>

                    {!freeMembership ? (
                      <>
                        {oneTimeMembership ? (
                          <Col md="4 mt-2">
                            <FormGroup>
                              <Label>{capitalize(payment.membership)} Subscription Price</Label>
                              <Input
                                name="price"
                                id="priceId"
                                type="number"
                                className="form-control"
                                value={payment.price}
                                onChange={getMembershipOptions}
                                onKeyDown={getMembershipOptions}
                              />
                              {payment.price && payment.price < 1 ? (
                                <small
                                  style={{
                                    color: 'red',
                                    fontSize: '80%',
                                  }}
                                >
                                  Price must be positive
                                </small>
                              ) : null}
                            </FormGroup>
                          </Col>
                        ) : null}
                        <Col md="4 mt-2">
                          <FormGroup>
                            <Label>Currency</Label>
                            <span className="sm-selectWrap">
                              <Input
                                className="custom-select"
                                type="select"
                                name="currency"
                                id="currency"
                                value={payment.currency}
                                onChange={getMembershipOptions}
                              >
                                <option value="">Select</option>
                                <option value="usd">USD - $</option>
                                <option value="eur">EURO - €</option>
                                <option value="gbp">POUND - £</option>
                                <option value="aud">AUD - A$</option>
                              </Input>
                            </span>
                          </FormGroup>
                        </Col>
                        {subscriptionMembership || membership === '' ? (
                          <>
                            <Col md={4}></Col>
                            <Col md={4} className="mt-5">
                              <FormGroup check style={{ justifyContent: 'start' }}>
                                <span className="pretty p-default">
                                  <Input
                                    type="checkbox"
                                    name="monthlySubscription"
                                    id="monthlySubscription"
                                    checked={monthlySubscription}
                                    onChange={e =>
                                      handleSubscription('monthly', 'isActive', e.target.checked)
                                    }
                                  />
                                  <span className="state">
                                    <Label for="monthlySubscription" check>
                                      Monthly Subscription
                                    </Label>
                                  </span>
                                </span>
                              </FormGroup>
                            </Col>
                            {monthlySubscription ? (
                              <Col md="4" className="mt-3">
                                <FormGroup>
                                  <Label> Monthly Subscription Price</Label>
                                  <Input
                                    name="price"
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    value={subscription.monthly.price}
                                    onChange={e =>
                                      handleSubscription('monthly', 'price', e.target.value)
                                    }
                                    onKeyDown={e =>
                                      handleSubscription('monthly', 'price', e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            ) : null}
                            <Col md={monthlySubscription ? 4 : 5}></Col>
                            <Col md={4} className="mt-5">
                              <FormGroup check style={{ justifyContent: 'start' }}>
                                <span className="pretty p-default">
                                  <Input
                                    type="checkbox"
                                    name="yearlySubscription"
                                    id="yearlySubscription"
                                    checked={yearlySubscription}
                                    onChange={e =>
                                      handleSubscription('yearly', 'isActive', e.target.checked)
                                    }
                                  />
                                  <span className="state">
                                    <Label for="yearlySubscription" check>
                                      Yearly Subscription
                                    </Label>
                                  </span>
                                </span>
                              </FormGroup>
                            </Col>
                            {yearlySubscription ? (
                              <Col md="4" className="mt-3">
                                <FormGroup>
                                  <Label> Yearly Subscription Price</Label>
                                  <Input
                                    name="price"
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    value={subscription.yearly.price}
                                    onChange={e =>
                                      handleSubscription('yearly', 'price', e.target.value)
                                    }
                                    onKeyDown={e =>
                                      handleSubscription('yearly', 'price', e.target.value)
                                    }
                                  />
                                  {price && price < 1 ? (
                                    <small
                                      style={{
                                        color: 'red',
                                        fontSize: '80%',
                                      }}
                                    >
                                      Price must be positive
                                    </small>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            ) : null}{' '}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </Row>
                  <div className="m-3">
                    {!freeMembership ? (
                      <>
                        <Row className="mt-3">
                          <FormGroup check className="mt-2 mb-2">
                            <span className="pretty p-default">
                              <Input
                                type="checkbox"
                                name="allowFamilyMembership"
                                id="allowFamilyMembership"
                                checked={allowFamilyMembership}
                                onChange={handleFamilyMembershipCheckbox}
                              />
                              <span className="state">
                                <Label for="allowFamilyMembership" check>
                                  Family Membership
                                </Label>
                              </span>
                            </span>
                          </FormGroup>
                        </Row>
                        {allowFamilyMembership ? (
                          <Row className="ml-1">
                            <Col md={familyMembershipCol()}>
                              <FormGroup>
                                <Label>Total Family Members</Label>
                                <Input
                                  name="totalFamilyMembers"
                                  id="totalFamilyMembers"
                                  type="number"
                                  className="form-control"
                                  value={totalFamilyMembers}
                                  onChange={handleFamilyMembership}
                                  onKeyDown={handleFamilyMembership}
                                />
                                {totalFamilyMembers && totalFamilyMembers < 1 ? (
                                  <small
                                    style={{
                                      color: 'red',
                                      fontSize: '80%',
                                    }}
                                  >
                                    Please add 1 or above members
                                  </small>
                                ) : null}
                              </FormGroup>
                            </Col>
                            {oneTimeMembership ? (
                              <Col md="4">
                                <FormGroup>
                                  <Label>Family Membership Price</Label>
                                  <Input
                                    name="price"
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    value={familyMembership.price.oneTime}
                                    onChange={e =>
                                      handleFamilyMembershipPrice('oneTime', e.target.value)
                                    }
                                    onKeyDown={e =>
                                      handleFamilyMembershipPrice('oneTime', e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            ) : null}
                            {subscriptionMembership ? (
                              <>
                                {monthlySubscription ? (
                                  <Col md={familyMembershipCol()}>
                                    <FormGroup>
                                      <Label>Monthly Family Membership Price</Label>
                                      <Input
                                        name="price"
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        value={familyMembership.price.monthly}
                                        onChange={e =>
                                          handleFamilyMembershipPrice('monthly', e.target.value)
                                        }
                                        onKeyDown={e =>
                                          handleFamilyMembershipPrice('monthly', e.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                ) : null}
                                {yearlySubscription ? (
                                  <Col md={familyMembershipCol()}>
                                    <FormGroup>
                                      <Label>Yearly Family Membership Price</Label>
                                      <Input
                                        name="price"
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        value={familyMembership.price.yearly}
                                        onChange={e =>
                                          handleFamilyMembershipPrice('yearly', e.target.value)
                                        }
                                        onKeyDown={e =>
                                          handleFamilyMembershipPrice('yearly', e.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                ) : null}
                              </>
                            ) : null}
                          </Row>
                        ) : null}
                        <StripeKeysInput
                          hasDefaultStripeKeys={hasDefaultStripeKeys}
                          handleStripeCheckbox={handleStripeCheckbox}
                          hasStripeKeys={hasStripeKeys}
                          stripeKeys={stripeKeys}
                          handlestripeKeys={handlestripeKeys}
                        />
                        {subscriptionMembership ? (
                          <>
                            <Row className="mt-2">
                              <FormGroup check className="mt-2 mb-2">
                                <span className="pretty p-default">
                                  <Input
                                    type="checkbox"
                                    name="allowTrialPeriod"
                                    id="allowTrialPeriod"
                                    checked={allowTrialPeriod}
                                    onChange={handleTrialPeriodCheckbox}
                                  />
                                  <span className="state">
                                    <Label for="allowTrialPeriod" check>
                                      Trial Period
                                    </Label>
                                  </span>
                                </span>
                              </FormGroup>
                            </Row>
                            <Row className="ml-1">
                              {allowTrialPeriod ? (
                                <Col md="4">
                                  <FormGroup>
                                    <Label>Days</Label>
                                    <Input
                                      name="trialPeriod"
                                      id="trialPeriod"
                                      type="number"
                                      className="form-control"
                                      value={trialPeriod}
                                      onChange={getMembershipOptions}
                                      onKeyDown={getMembershipOptions}
                                    />
                                    {trialPeriod && trialPeriod < 1 ? (
                                      <small
                                        style={{
                                          color: 'red',
                                          fontSize: '80%',
                                        }}
                                      >
                                        Please add 1 or above days
                                      </small>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              ) : null}
                            </Row>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    <Row className="mt-2">
                      <FormGroup check className="mt-2 mb-2">
                        <span className="pretty p-default">
                          <Input
                            type="checkbox"
                            name="allowB2CSellingOfCourse"
                            id="allowB2CSellingOfCourse"
                            checked={allowB2CSellingOfCourse}
                            onChange={handleB2CSellingCheckbox}
                          />
                          <span className="state">
                            <Label for="allowB2CSellingOfCourse">Allow B2C selling of course</Label>
                          </span>
                        </span>
                      </FormGroup>
                      {allowB2CSellingOfCourse && (oneTimeMembership || subscriptionMembership) && (
                        <FormGroup check className="mt-2 mb-2 ml-5">
                          <span className="pretty p-default">
                            <Input
                              type="checkbox"
                              name="canMemberRegisterWithoutPayment"
                              id="canMemberRegisterWithoutPayment"
                              checked={canMemberRegisterWithoutPayment}
                              onChange={handleB2CSellingCheckbox}
                            />
                            <span className="state">
                              <Label for="canMemberRegisterWithoutPayment">
                                Can Member Register without Payment
                              </Label>
                            </span>
                          </span>
                        </FormGroup>
                      )}
                      {payment.membership === 'free' || canMemberRegisterWithoutPayment ? (
                        <FormGroup check className="mt-2 mb-2 ml-5">
                          <span className="pretty p-default">
                            <Input
                              type="checkbox"
                              name="skillsHubSectionToPublic"
                              id="skillsHubSectionToPublic"
                              checked={skillsHubSectionToPublic}
                              onChange={handleCheckbox}
                            />
                            <span className="state">
                              <Label for="skillsHubSectionToPublic">Public Skills Hub</Label>
                            </span>
                          </span>
                        </FormGroup>
                      ) : null}{' '}
                    </Row>
                    {allowB2CSellingOfCourse && (
                      <Row className="mt-2">
                        <FormGroup check className="mt-2 mb-2">
                          <span className="pretty p-default">
                            <Input
                              type="checkbox"
                              name="allowAutomaticInstallmentPayment"
                              id="allowAutomaticInstallmentPayment"
                              checked={allowAutomaticInstallmentPayment}
                              onChange={handleCoursePurchaseInstallmentPlanCheckbox}
                            />
                            <span className="state">
                              <Label for="allowAutomaticInstallmentPayment">
                                Allow Automatic Installment Payment
                              </Label>
                            </span>
                          </span>
                        </FormGroup>

                        <FormGroup check className="mt-2 mb-2 ml-5">
                          <span className="pretty p-default">
                            <Input
                              type="checkbox"
                              name="allowManualInstallmentPayment"
                              id="allowManualInstallmentPayment"
                              checked={allowManualInstallmentPayment}
                              onChange={handleCoursePurchaseInstallmentPlanCheckbox}
                            />
                            <span className="state">
                              <Label for="allowManualInstallmentPayment">
                                Allow Manual Installment Payment
                              </Label>
                            </span>
                          </span>
                        </FormGroup>
                      </Row>
                    )}
                    {freeMembership && allowB2CSellingOfCourse ? (
                      <StripeKeysInput
                        hasDefaultStripeKeys={hasDefaultStripeKeys}
                        handleStripeCheckbox={handleStripeCheckbox}
                        hasStripeKeys={hasStripeKeys}
                        stripeKeys={stripeKeys}
                        handlestripeKeys={handlestripeKeys}
                      />
                    ) : null}
                  </div>
                </Form>
                <div className="settings-footer">
                  <div className="btn btn-add" onClick={handleMembershipChargeReset}>
                    Cancel
                  </div>
                  <div className="btn btn-add" onClick={handleSave}>
                    Save
                  </div>
                </div>
                {/* </fieldset> */}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2" className="p-3 credit-payment">
            <Row>
              <CreditSetting
                credit={props.credit}
                handleChangeForCredit={props.handleChangeForCredit}
                totalCreditError={props.totalCreditError}
                onChangePassword={props.onChangePassword}
                onGenerateNewPin={props.onGenerateNewPin}
                handlePaymentCreditSystemSave={props.handlePaymentCreditSystemSave}
                handleChangeCreditMonetaryMappingError={
                  props.handleChangeCreditMonetaryMappingError
                }
              />
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </Wrapper>
  );
};

export default Payment;
