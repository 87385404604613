import dompurify from 'dompurify';

export const pathNameAsTitle = () => {
  const pathName = window.location.pathname;
  const titleName = pathName.split('/')[1];
  return capitalizeWord(titleName);
};

export const envUrl = {
  mycommunityBaseUrl: process.env.REACT_APP_COMMUNITY_BASE_URL,
  reactAppApiUrl: process.env.REACT_APP_API_URL,
};

export const capitalizeWord = word => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};

export const clientUrl = (subdomain, route) => {
  const url = window.location.hostname;
  const domain = url.split('.')[0];

  if (url.includes('localhost')) {
    if (route === 'login') {
      return `http://${subdomain}.localhost:3001/admin/${route}`;
    }

    return `http://${subdomain}.localhost:3001/learner/${route}`;
  }

  if (domain === 'sandbox') {
    if (route === 'login') {
      return `https://${subdomain}.sandbox.${envUrl.mycommunityBaseUrl}/admin/${route}`;
    }

    if (route.includes('guestToken')) {
      return `https://${subdomain}.sandbox.${envUrl.mycommunityBaseUrl}/hub/skillsbase/learnings?${route}`;
    }
    return `https://${subdomain}.sandbox.${envUrl.mycommunityBaseUrl}/learner/${route}`;
  }

  if (domain === 'uat') {
    if (route === 'login') {
      return `https://${subdomain}.uat.${envUrl.mycommunityBaseUrl}/admin/${route}`;
    }

    if (route.includes('guestToken')) {
      return `https://${subdomain}.uat.${envUrl.mycommunityBaseUrl}/hub/skillsbase/learnings?${route}`;
    }

    return `https://${subdomain}.uat.${envUrl.mycommunityBaseUrl}/learner/${route}`;
  }

  if (route === 'login') {
    return `https://${subdomain}.${envUrl.mycommunityBaseUrl}/admin/${route}`;
  }

  if (route.includes('guestToken')) {
    return `https://${subdomain}.${envUrl.mycommunityBaseUrl}/hub/skillsbase/learnings?${route}`;
  }

  return `https://${subdomain}.${envUrl.mycommunityBaseUrl}/learner/${route}`;
};

export const sanitize = data => {
  dompurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'iframe') {
      const src = node.getAttribute('src') || '';

      if (
        src.startsWith('https://www.youtube.com/embed/') ||
        src.startsWith('https://player.vimeo.com/video/')
      ) {
      } else {
        return node.parentNode?.removeChild(node);
      }
    }
  });

  return dompurify.sanitize(data, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'target'],
  });
};

export const decrypt = data => {
  const salt = data.split(':')[1];
  const encoded = data.split(':')[0];

  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = c => textToChars(salt).reduce((a, b) => a ^ b, c);

  return encoded
    .match(/.{1,2}/g)
    .map(hex => parseInt(hex, 16))
    .map(applySaltToChar)
    .map(charCode => String.fromCharCode(charCode))
    .join('');
};

export const copyText = text => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const getFromISODate = date => {
  return date ? new Date(`${date}T00:00:00`).toISOString() : date;
};

export const getToISODate = date => {
  return date ? new Date(`${date}T23:59:59`).toISOString() : date;
};

export const resetFamilyMembership = payload => {
  return payload.name === 'allowFamilyMembership' && !payload.value;
};

export const notSubscriptionMembership = payload => {
  return payload.value === 'oneTime' || payload.value === 'free';
};

export const formatDate = date => {
  return new Date(date).toLocaleDateString('en-UK', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

export const currencySign = currency => {
  if (currency === 'usd') return '$';
  if (currency === 'eur') return '€';
  if (currency === 'gbp') return '£';
  if (currency === 'aud') return 'A$';
  return '$';
};

export const getFileNameFromUrl = url => {
  const certUrl = decodeURI(url);

  const splitUrl = certUrl.split('/');
  const lastString = splitUrl[splitUrl.length - 1];
  let certName = '';
  for (let i = 0; i < lastString.length; i++) {
    if (
      (lastString[i] >= 'A' && lastString[i] <= 'Z') ||
      (lastString[i] >= 'a' && lastString[i] <= 'z')
    ) {
      certName = lastString.slice(i);
      break;
    }
  }

  return certName;
};

//price upto two decimal
export const priceToDecimal = price => {
  //check if it has decimal
  if (price && price % 1 !== 0) {
    price = price.toFixed(2);
  }
  return price;
};
