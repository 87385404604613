import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Input from 'reactstrap/lib/Input';
import { Label, CardHeader } from 'reactstrap';
import FormGroup from 'reactstrap/lib/FormGroup';
import { toast } from 'react-toastify';
import { sanitize } from 'utils/helper';
import Wrapper from 'common/modalWrapper';
import Editor from 'components/TinyMceEditor';
import Api from 'API';
import { closeSidebarModal } from '../../actions/clientActions';
import { SAVING } from '../../common/constants';

toast.configure();

const commonTags = [
  { value: '<<<{{ First Name }}>>> ', key: 'First Name' },
  { value: '<<<{{ Last Name }}>>> ', key: 'Last Name' },
  { value: '<<<{{ Client Name }}>>> ', key: 'Client Name' },
  { value: '<<<{{ Login Button }}>>> ', key: 'Login Button' },
];
const learnerIdTag = [{ value: '<<<{{ Learner Id/Username }}>>> ', key: 'Learner Id/Username' }];

const normalUserTags = [
  ...commonTags,
  ...learnerIdTag,
  { value: '<<<{{ Learner Email }}>>> ', key: 'Learner Email' },
];
const adminInvitedUserTags = [
  ...commonTags,
  {
    value: '<<<{{ Complete Your Profile Button }}>>> ',
    key: 'Complete Your Profile Button',
  },
];
const familyUserTags = [
  ...commonTags,
  ...learnerIdTag,
  {
    value: '<<<{{ Main Member }}>>> ',
    key: 'Main Member',
  },
  {
    value: '<<<{{ Password }}>>> ',
    key: 'Password',
  },
  {
    value: '<<<{{ Magic Link Button }}>>> ',
    key: 'Magic Link Button',
  },
];

const userAddedToCommunity = [
  { value: '<<<{{ First Name }}>>> ', key: 'First Name' },
  { value: '<<<{{ Last Name }}>>> ', key: 'Last Name' },
  {
    value: '<<<{{Community}}>>>',
    key: 'Community',
  },
  {
    value: '<<<{{Creator}}>>>',
    key: 'Creator',
  },
  { value: '<<<{{Platform Name}}>>> ', key: 'Platform Name' },
];

const userJoinedPublicCommunity = [
  { value: '<<<{{ First Name }}>>> ', key: 'First Name' },
  { value: '<<<{{ Last Name }}>>> ', key: 'Last Name' },
  {
    value: '<<<{{Community}}>>>',
    key: 'Community',
  },
  { value: '<<<{{Platform Name}}>>> ', key: 'Platform Name' },
];

const userTaggedToCommunityPost = [
  { value: '<<<{{ First Name }}>>> ', key: 'First Name' },
  { value: '<<<{{ Last Name }}>>> ', key: 'Last Name' },
  {
    value: '<<<{{Community}}>>>',
    key: 'Community',
  },
  {
    value: '<<<{{Creator}}>>>',
    key: 'Creator',
  },
  {
    value: '<<<{{Post}}>>>',
    key: 'Post',
  },
  { value: '<<<{{Platform Name}}>>> ', key: 'Platform Name' },
];

const userTaggedToScheduledCommunityPost = [
  { value: '<<<{{ First Name }}>>> ', key: 'First Name' },
  { value: '<<<{{ Last Name }}>>> ', key: 'Last Name' },
  {
    value: '<<<{{Community}}>>>',
    key: 'Community',
  },
  {
    value: '<<<{{Creator}}>>>',
    key: 'Creator',
  },
  {
    value: '<<<{{Post}}>>>',
    key: 'Post',
  },
  {
    value: '<<<{{Schedule Date}}>>>',
    key: 'Schedule Date',
  },
  { value: '<<<{{Platform Name}}>>> ', key: 'Platform Name' },
];

const requestCreditTags = [
  { value: '<<<{{Learner Name}}>>>', key: 'Learner Name' },
  { value: '<<<{{Platform Name}}>>>', key: 'Platform Name' },
];

const commonVendorCourseBookingTags = [
  { value: '<<<{{Booking Learner Name}}>>>', key: 'Booking Learner Name' },
  { value: '<<<{{Course Title}}>>>', key: 'Course Title' },
  { value: '<<<{{Platform Name}}>>>', key: 'Platform Name' },
];

/* Grand total amounts of group booking of vendor course */
const grandTotalTags = [
  {
    value: '<<<{{Grand Total Course Amount}}>>>',
    key: 'Grand Total Course Amount',
  },
  {
    value: '<<<{{Grand Total Amount Paid}}>>>',
    key: 'Grand Total Amount Paid',
  },
  {
    value: '<<<{{Grand Total Pending Amount}}>>>',
    key: 'Grand Total Pending Amount',
  },
];

//Forgot Password tags
const forgotPasswordTags = [
  { value: '<<<{{First Name}}>>> ', key: 'First Name' },
  { value: '<<<{{Last Name}}>>> ', key: 'Last Name' },
  { value: '<<<{{Client Name}}>>> ', key: 'Client Name' },
  { value: '<<<{{Change Password Button}}>>> ', key: 'Change Password Button' },
];

const vendorCourseBookingTags = {
  bookingEmailToVendor: [
    ...commonVendorCourseBookingTags,
    { value: '<<<{{Vendor Name}}>>>', key: 'Vendor Name' },
    { value: '<<<{{Booking Learner Email}}>>>', key: 'Booking Learner Email' },
    { value: '<<<{{Booking Learner Phone}}>>>', key: 'Booking Learner Phone' },
    { value: '<<<{{Booking ID}}>>>', key: 'Booking ID' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Price}}>>>', key: 'Price' },
    { value: '<<<{{Total Amount}}>>>', key: 'Total Amount' },
    { value: '<<<{{Amount Still Owed}}>>>', key: 'Amount Still Owed' },
  ],
  groupBookingEmailToVendor: [
    ...commonVendorCourseBookingTags,
    { value: '<<<{{Vendor Name}}>>>', key: 'Vendor Name' },
    { value: '<<<{{Booking Learner Email}}>>>', key: 'Booking Learner Email' },
    { value: '<<<{{Booking Learner Phone}}>>>', key: 'Booking Learner Phone' },
    { value: '<<<{{Other Participants}}>>>', key: 'Other Participants' },
    { value: '<<<{{Booking ID}}>>>', key: 'Booking ID' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Price}}>>>', key: 'Price' },
    { value: '<<<{{Total Amount}}>>>', key: 'Total Amount' },
    { value: '<<<{{Amount Still Owed}}>>>', key: 'Amount Still Owed' },
  ],
  bookingEmailToBookingUser: [
    ...commonVendorCourseBookingTags,
    { value: '<<<{{Vendor Name}}>>>', key: 'Vendor Name' },
    { value: '<<<{{Vendor Website}}>>>', key: 'Vendor Website' },
    { value: '<<<{{Vendor Email}}>>>', key: 'Vendor Email' },
    { value: '<<<{{Vendor Phone}}>>>', key: 'Vendor Phone' },
    { value: '<<<{{Vendor Location}}>>>', key: 'Vendor Location' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Price}}>>>', key: 'Price' },
    { value: '<<<{{Total Amount}}>>>', key: 'Total Amount' },
    { value: '<<<{{Amount Still Owed}}>>>', key: 'Amount Still Owed' },
  ],
  groupBookingEmailToBookingUser: [
    ...commonVendorCourseBookingTags,
    { value: '<<<{{Vendor Name}}>>>', key: 'Vendor Name' },
    { value: '<<<{{Vendor Website}}>>>', key: 'Vendor Website' },
    { value: '<<<{{Vendor Email}}>>>', key: 'Vendor Email' },
    { value: '<<<{{Vendor Phone}}>>>', key: 'Vendor Phone' },
    { value: '<<<{{Vendor Location}}>>>', key: 'Vendor Location' },

    { value: '<<<{{Other Participants}}>>>', key: 'Other Participants' },

    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Price}}>>>', key: 'Price' },
    { value: '<<<{{Total Amount}}>>>', key: 'Total Amount' },
    { value: '<<<{{Amount Still Owed}}>>>', key: 'Amount Still Owed' },
  ],
  bookingEmailToInvitedParticipant: [
    { value: '<<<{{Course Title}}>>>', key: 'Course Title' },
    { value: '<<<{{Platform Name}}>>>', key: 'Platform Name' },

    { value: '<<<{{Participant Name}}>>>', key: 'Participant Name' },
    { value: '<<<{{Vendor Name}}>>>', key: 'Vendor Name' },
    { value: '<<<{{Vendor Website}}>>>', key: 'Vendor Website' },
    { value: '<<<{{Vendor Email}}>>>', key: 'Vendor Email' },
    { value: '<<<{{Vendor Phone}}>>>', key: 'Vendor Phone' },
    { value: '<<<{{Vendor Location}}>>>', key: 'Vendor Location' },
    { value: '<<<{{Group Booking Lead Name}}>>>', key: 'Group Booking Lead Name' },
    { value: '<<<{{Accept Invitation Button}}>>>', key: 'Accept Invitation Button' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Price}}>>>', key: 'Price' },
    { value: '<<<{{Total Amount}}>>>', key: 'Total Amount' },
    { value: '<<<{{Amount Still Owed}}>>>', key: 'Amount Still Owed' },
  ],
  bookingEmailToBookingUserWhenParticipantAccept: [
    ...commonVendorCourseBookingTags,
    { value: '<<<{{Vendor Name}}>>>', key: 'Vendor Name' },
    { value: '<<<{{Vendor Website}}>>>', key: 'Vendor Website' },
    { value: '<<<{{Vendor Email}}>>>', key: 'Vendor Email' },
    { value: '<<<{{Vendor Phone}}>>>', key: 'Vendor Phone' },
    { value: '<<<{{Vendor Location}}>>>', key: 'Vendor Location' },
    { value: '<<<{{Accepted Participant Name}}>>>', key: 'Accepted Participant Name' },
    { value: '<<<{{Acceptance Ratio}}>>>', key: 'Acceptance Ratio' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
  ],
  emailToAllParticipantAboutBookingConfirmationWhenAllAccept: [
    { value: '<<<{{Participant Name}}>>>', key: 'Participant Name' },
    { value: '<<<{{Vendor Name}}>>>', key: 'Vendor Name' },
    { value: '<<<{{Vendor Website}}>>>', key: 'Vendor Website' },
    { value: '<<<{{Vendor Email}}>>>', key: 'Vendor Email' },
    { value: '<<<{{Vendor Phone}}>>>', key: 'Vendor Phone' },
    { value: '<<<{{Vendor Location}}>>>', key: 'Vendor Location' },
    { value: '<<<{{Course Title}}>>>', key: 'Course Title' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Platform Name}}>>>', key: 'Platform Name' },
  ],
  emailToVendorWhenAllParticipantsAccept: [
    ...commonVendorCourseBookingTags,

    { value: '<<<{{Vendor Name}}>>>', key: 'Vendor Name' },
    { value: '<<<{{Booking Learner Email}}>>>', key: 'Booking Learner Email' },
    { value: '<<<{{Booking Learner Phone}}>>>', key: 'Booking Learner Phone' },
    // { value: '<<<{{Other Participants}}>>>', key: 'Other Participants' },
    {
      value: '<<<{{Other Participants With Booking Details}}>>>',
      key: 'Other Participants With Booking Details',
    },
    { value: '<<<{{Booking ID}}>>>', key: 'Booking ID' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Price}}>>>', key: 'Price' },
    { value: '<<<{{Total Amount}}>>>', key: 'Total Amount' },
    { value: '<<<{{Amount Still Owed}}>>>', key: 'Amount Still Owed' },

    ...grandTotalTags,
  ],
  emailToBookingUserWhenSingleBookingIsDeleted: [
    ...commonVendorCourseBookingTags,
    { value: '<<<{{Booking Learner Email}}>>>', key: 'Booking Learner Email' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Deleted Date and Time}}>>>', key: 'Deleted Date and Time' },
    { value: '<<<{{Learner Who Deleted The Booking}}>>>', key: 'Learner Who Deleted The Booking' },
  ],
  emailToVendorWhenSingleBookingIsDeleted: [
    ...commonVendorCourseBookingTags,
    { value: '<<<{{Booking Learner Email}}>>>', key: 'Booking Learner Email' },
    { value: '<<<{{Vendor Name}}>>>', key: 'Vendor Name' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Deleted Date and Time}}>>>', key: 'Deleted Date and Time' },
    { value: '<<<{{Learner Who Deleted The Booking}}>>>', key: 'Learner Who Deleted The Booking' },
  ],
  emailToVendorWhenGroupBookingIsDeleted: [
    ...commonVendorCourseBookingTags,
    { value: '<<<{{Booking Learner Email}}>>>', key: 'Booking Learner Email' },
    { value: '<<<{{Vendor Name}}>>>', key: 'Vendor Name' },

    { value: '<<<{{Other Participants}}>>>', key: 'Other Participants' },

    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Deleted Date and Time}}>>>', key: 'Deleted Date and Time' },
    { value: '<<<{{Learner Who Deleted The Booking}}>>>', key: 'Learner Who Deleted The Booking' },
  ],
  emailToBookingUserWhenGroupBookingIsDeleted: [
    ...commonVendorCourseBookingTags,

    { value: '<<<{{Booking Learner Email}}>>>', key: 'Booking Learner Email' },

    { value: '<<<{{Other Participants}}>>>', key: 'Other Participants' },

    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Deleted Date and Time}}>>>', key: 'Deleted Date and Time' },
    { value: '<<<{{Learner Who Deleted The Booking}}>>>', key: 'Learner Who Deleted The Booking' },
  ],
  emailToParticipantWhenGroupBookingIsDeleted: [
    ...commonVendorCourseBookingTags,
    { value: '<<<{{Booking Learner Email}}>>>', key: 'Booking Learner Email' },
    { value: '<<<{{Participant Name}}>>>', key: 'Participant Name' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Deleted Date and Time}}>>>', key: 'Deleted Date and Time' },
    { value: '<<<{{Learner Who Deleted The Booking}}>>>', key: 'Learner Who Deleted The Booking' },
  ],
  emailToBookingUserWhenGroupBookingIsDeclinedByParticipant: [
    ...commonVendorCourseBookingTags,
    { value: '<<<{{Booking Learner Email}}>>>', key: 'Booking Learner Email' },
    { value: '<<<{{Declined Participant Name}}>>>', key: 'Declined Participant Name' },
    { value: '<<<{{Declined Participant Email}}>>>', key: 'Declined Participant Email' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Declined Date and Time}}>>>', key: 'Declined Date and Time' },
  ],
  emailToParticipantWhenBookingUserRevokeInvitedMembers: [
    ...commonVendorCourseBookingTags,
    { value: '<<<{{Revoked Participant Name}}>>>', key: 'Revoked Participant Name' },
    { value: '<<<{{Revoked By}}>>>', key: 'Revoked By' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Date of Training}}>>>', key: 'Date of Training' },
    { value: '<<<{{Time of Training}}>>>', key: 'Time of Training' },
    { value: '<<<{{Revoked Date and Time}}>>>', key: 'Revoked Date and Time' },
  ],
  emailToVendorWhenGroupBookingParticipantsUpdated: [
    ...commonVendorCourseBookingTags,
    {
      value: '<<<{{Booking Learner Email}}>>>',
      key: 'Booking Learner Email',
    },
    {
      value: '<<<{{Booking Learner Phone}}>>>',
      key: 'Booking Learner Phone',
    },
    {
      value: '<<<{{Booking ID}}>>>',
      key: 'Booking ID',
    },
    { value: '<<<{{Vendor Name}}>>>', key: 'Vendor Name' },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    { value: '<<<{{Price}}>>>', key: 'Price' },
    { value: '<<<{{Total Amount}}>>>', key: 'Total Amount' },
    { value: '<<<{{Amount Still Owed}}>>>', key: 'Amount Still Owed' },
    {
      value: '<<<{{Removed Participants}}>>>',
      key: 'Removed Participants',
    },
    {
      value: '<<<{{Updated Participants}}>>>',
      key: 'Updated Participants',
    },
  ],
  emailToBookingUserWhenGroupBookingParticipantsUpdated: [
    ...commonVendorCourseBookingTags,
    {
      value: '<<<{{Booking Learner Email}}>>>',
      key: 'Booking Learner Email',
    },
    {
      value: '<<<{{Booking Learner Phone}}>>>',
      key: 'Booking Learner Phone',
    },
    {
      value: '<<<{{Booking ID}}>>>',
      key: 'Booking ID',
    },
    { value: '<<<{{Booking Date}}>>>', key: 'Booking Date' },
    {
      value: '<<<{{Removed Participants}}>>>',
      key: 'Removed Participants',
    },
    {
      value: '<<<{{Updated Participants}}>>>',
      key: 'Updated Participants',
    },
  ],
};

const tags = {
  welcomeTemplate: normalUserTags,
  templateForMemberInvitedByAdmin: adminInvitedUserTags,
  templateForFamilyMemberInvitation: familyUserTags,
  userAddedToCommunity,
  userJoinedPublicCommunity,
  userTaggedToCommunityPost,
  userTaggedToScheduledCommunityPost,
  requestCredit: requestCreditTags,
  vendorCourse: vendorCourseBookingTags,
  forgotPassword: forgotPasswordTags,
};

const EmailTemplate = props => {
  const [showEditable, setShowEditable] = useState(false);
  const [template, setTemplate] = useState('welcomeTemplate');
  const [emailTemplate, setEmailTemplate] = useState({});
  const [cacheEmailTemplate, setCacheEmailTemplate] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [flag, setFlag] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const { clientId, name, logo } = props;

  useEffect(() => {
    getEmailTemplate();
  }, []);

  const getEmailTemplate = () => {
    new Api()
      .getEmailTemplate(clientId)
      .then(results => {
        console.log({ results });
        setEmailTemplate(results.data);
        setCacheEmailTemplate(JSON.parse(JSON.stringify(results.data)));
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleEditButton = () => {
    setShowEditable(s => !s);
  };

  const handleTagChange = e => {
    e.persist();

    window.tinymce.activeEditor.execCommand('mceInsertContent', false, e.target.value);
  };

  const handleTemplateInputChange = (name, value) => {
    const newEmailTemplate = { ...emailTemplate };
    const splitTemplate = name.split('-');
    if (splitTemplate[0] === 'vendorCourse') {
      newEmailTemplate['vendorCourse'][splitTemplate[1]] = value;
    } else {
      newEmailTemplate[name] = value;
    }
    setEmailTemplate(newEmailTemplate);
  };

  const handleTemplateChange = e => {
    setTemplate(e.target.value);
  };

  // const handleInputChange = e => {
  //   const { name, value } = e.target;
  //   setEmailTemplate(et => {
  //     return { ...et, [name]: value };
  //   });
  // };

  const handleOnOffChange = e => {
    const { name, checked } = e.target;

    setEmailTemplate(emailTemplate => {
      const newEmailTemplate = { ...emailTemplate };
      newEmailTemplate[name].isOn = checked;
      return newEmailTemplate;
    });
  };

  const handleTemplateUrlChange = e => {
    const { name, value } = e.target;
    setEmailTemplate(emailTemplate => {
      const newEmailTemplate = { ...emailTemplate };
      newEmailTemplate[name].url = value;
      return newEmailTemplate;
    });
  };

  const handleReset = () => {
    setEmailTemplate(JSON.parse(JSON.stringify(cacheEmailTemplate)));
  };

  const handleSave = () => {
    const templatePayload = {
      emailTemplate,
    };
    setFlag(SAVING);
    new Api()
      .saveEmailTemplate(templatePayload, clientId)
      .then(response => {
        setFlag('');
        history.push('/clients');
        toast.success('Saved Successfully !');
        dispatch(closeSidebarModal());
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getTags = selectedTemplate => {
    const splitTemplate = selectedTemplate.split('-');
    if (splitTemplate[0] === 'vendorCourse') {
      return tags['vendorCourse'][splitTemplate[1]];
    }
    return tags[selectedTemplate];
  };

  const getTemplateData = selectedTemplate => {
    const splitTemplate = selectedTemplate.split('-');
    if (splitTemplate[0] === 'vendorCourse') {
      return emailTemplate['vendorCourse'][splitTemplate[1]];
    }
    return emailTemplate[selectedTemplate];
  };

  const {
    // color,
    facebook,
    twitter,
    linkedin,
    youtube,
    support,
    faq,
    privacyPolicy,
    terms,
  } = emailTemplate;

  return (
    <Wrapper title="Email Template Settings">
      {loading ? (
        <span className="loading">
          <i className="fa fa-spinner fa-spin" aria-hidden="true" />
        </span>
      ) : (
        <div className="settings-container">
          <div className="brandsettings">
            <fieldset>
              <legend>Template Preview</legend>
              <CardHeader
                className="d-flex justify-content-end"
                style={{ background: 'none', paddingRight: '0' }}
              >
                <Label className="mr-sm-2 mb-0">
                  <span className="btn btn-add" onClick={handleEditButton}>
                    <i className="icon icon-edit"> </i>
                    Edit Email Template
                  </span>
                </Label>
              </CardHeader>

              <table className="emailTemplate text-center">
                <thead
                  style={{
                    background: '#fff',
                    color: '#fff',
                    border: '1px solid #ddd',
                  }}
                >
                  <tr>
                    <td colSpan="2" className="text-left">
                      <img src={logo} alt="logo" className="logo" />
                    </td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td colSpan="2" style={{ position: 'relative' }}>
                      {showEditable ? (
                        <FormGroup
                          style={{
                            display: 'inline-block',
                            margin: '0 5px 0 0',
                            position: 'absolute',
                            zIndex: '9',
                            right: '25px',
                            top: '0',
                          }}
                        >
                          <Input
                            type="select"
                            name="tag"
                            id="tag"
                            value=""
                            bsSize="sm"
                            onChange={handleTagChange}
                          >
                            <option value="">Select Tag</option>
                            {getTags(template).map(tag => (
                              <option key={tag.key} value={tag.value}>
                                {tag.key}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      ) : null}{' '}
                      {showEditable ? (
                        <Editor
                          value={getTemplateData(template)}
                          onChange={handleTemplateInputChange}
                          updateTo={template}
                        />
                      ) : (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: sanitize(getTemplateData(template)),
                          }}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td colSpan="2">
                      <p className="copyRight">
                        Copyright © 2020 {name} Online Learning Platform. All rights reserved.
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </fieldset>
          </div>
          <div className="menusettings">
            <form className="bp-form">
              <div className="menu-content emailSetting">
                <fieldset>
                  <legend>Select Email Template</legend>
                  <div className="log-form">
                    <FormGroup>
                      <span className="sm-selectWrap">
                        <Input
                          className="custom-select px-2"
                          type="select"
                          name="select"
                          value={template}
                          onChange={handleTemplateChange}
                        >
                          <option disabled>Select Template</option>
                          <option value="welcomeTemplate">
                            New Users On-boarding Email Template
                          </option>
                          <option value="templateForMemberInvitedByAdmin">
                            Admin Invited and admin bulk uploaded Users Email Template
                          </option>
                          <option value="templateForFamilyMemberInvitation">
                            Admin Invited, admin bulk upload and user invited family members email
                            template
                          </option>
                          <option value="forgotPassword">Forgot Password</option>
                          <option value="userAddedToCommunity">User Added To Community</option>
                          <option value="userJoinedPublicCommunity">
                            User Joined Public Community
                          </option>
                          <option value="userTaggedToCommunityPost">
                            User Tagged To Community Post
                          </option>
                          <option value="userTaggedToScheduledCommunityPost">
                            User Tagged To Scheduled Community Post
                          </option>

                          <option value="requestCredit">Request For Credit</option>

                          <option value="vendorCourse-bookingEmailToVendor">
                            Vendor Course Booking Email To Vendor
                          </option>
                          <option value="vendorCourse-groupBookingEmailToVendor">
                            Vendor Course Group Booking Email To Vendor
                          </option>
                          <option value="vendorCourse-bookingEmailToBookingUser">
                            Vendor Course Booking Email To Booking User
                          </option>
                          <option value="vendorCourse-groupBookingEmailToBookingUser">
                            Vendor Course Group Booking Email To Booking User
                          </option>
                          <option value="vendorCourse-bookingEmailToInvitedParticipant">
                            Vendor Course Booking Email To Invited Participant
                          </option>
                          <option value="vendorCourse-bookingEmailToBookingUserWhenParticipantAccept">
                            Vendor Course Booking Email To Booking User When Participant Accept
                          </option>
                          <option value="vendorCourse-emailToAllParticipantAboutBookingConfirmationWhenAllAccept">
                            Vendor Course Booking Email To All Participant About Booking
                            Confirmation When All Accept
                          </option>
                          <option value="vendorCourse-emailToVendorWhenAllParticipantsAccept">
                            Vendor Course Booking Email To Vendor When All Participants Accept
                          </option>
                          <option value="vendorCourse-emailToBookingUserWhenSingleBookingIsDeleted">
                            Vendor Course Booking Email To Booking User When Single Booking Is
                            Deleted
                          </option>
                          <option value="vendorCourse-emailToVendorWhenSingleBookingIsDeleted">
                            Vendor Course Booking Email To Vendor When Single Booking Is Deleted
                          </option>
                          <option value="vendorCourse-emailToVendorWhenGroupBookingIsDeleted">
                            Vendor Course Booking Email To Vendor When Group Booking Is Deleted
                          </option>
                          <option value="vendorCourse-emailToBookingUserWhenGroupBookingIsDeleted">
                            Vendor Course Booking Email To Booking User When Group Booking Is
                            Deleted
                          </option>
                          <option value="vendorCourse-emailToParticipantWhenGroupBookingIsDeleted">
                            Vendor Course Booking Email To Participant When Group Booking Is Deleted
                          </option>
                          <option value="vendorCourse-emailToBookingUserWhenGroupBookingIsDeclinedByParticipant">
                            Vendor Course Booking Email To Booking User When Group Booking Is
                            Declined By Participant
                          </option>
                          <option value="vendorCourse-emailToParticipantWhenBookingUserRevokeInvitedMembers">
                            Vendor Course Booking Email To Participant When Booking User Revoke
                            Invited Members
                          </option>
                          <option value="vendorCourse-emailToVendorWhenGroupBookingParticipantsUpdated">
                            Vendor Course Booking Email To Vendor When Group Booking Participants
                            Updated
                          </option>
                          <option value="vendorCourse-emailToBookingUserWhenGroupBookingParticipantsUpdated">
                            Vendor Course Booking Email To Booking User When Group Booking
                            Participants Updated
                          </option>
                        </Input>
                      </span>
                    </FormGroup>
                  </div>
                </fieldset>
                {/* <fieldset className="form-group mt-3">
                  <legend> Color Settings </legend>
                  <label>background color</label>
                  <span className="customcolorpicker">
                    <input
                      name="color"
                      placeholder="brandingColor"
                      type="color"
                      className="form-control"
                      // value={color}
                      onChange={handleInputChange}
                    />
                    <span className="color" style={{ background: color }}></span>
                    <span className="colorcode">{color}</span>
                  </span>
                </fieldset> */}

                <fieldset className="form-group">
                  <legend> Footer Menu </legend>
                  <label>Support</label>{' '}
                  <span className="checkbox float-right">
                    <input
                      type="checkbox"
                      name="support"
                      checked={support && support.isOn}
                      onChange={handleOnOffChange}
                    />
                    <label></label>
                  </span>
                  <input
                    name="support"
                    type="text"
                    className="form-control"
                    placeholder="https://"
                    value={support && support.url}
                    onChange={handleTemplateUrlChange}
                  />
                  <label>FAQ</label>{' '}
                  <span className="checkbox float-right">
                    <input
                      type="checkbox"
                      name="faq"
                      checked={faq && faq.isOn}
                      onChange={handleOnOffChange}
                    />
                    <label></label>
                  </span>
                  <input
                    name="faq"
                    type="text"
                    className="form-control"
                    placeholder="https://"
                    value={faq && faq.url}
                    onChange={handleTemplateUrlChange}
                  />
                  <label>Privacy Policy</label>{' '}
                  <span className="checkbox float-right">
                    <input
                      type="checkbox"
                      name="privacyPolicy"
                      checked={privacyPolicy && privacyPolicy.isOn}
                      onChange={handleOnOffChange}
                    />
                    <label></label>
                  </span>
                  <input
                    name="privacyPolicy"
                    type="text"
                    className="form-control"
                    placeholder="https://"
                    value={privacyPolicy && privacyPolicy.url}
                    onChange={handleTemplateUrlChange}
                  />
                  <label>Terms & Condition</label>{' '}
                  <span className="checkbox float-right">
                    <input
                      type="checkbox"
                      name="terms"
                      checked={terms && terms.isOn}
                      onChange={handleOnOffChange}
                    />
                    <label></label>
                  </span>
                  <input
                    name="terms"
                    type="text"
                    className="form-control"
                    placeholder="https://"
                    value={terms && terms.url}
                    onChange={handleTemplateUrlChange}
                  />
                </fieldset>

                <fieldset className="form-group">
                  <legend> Social Links </legend>
                  <label>Facebook</label>{' '}
                  <span className="checkbox float-right">
                    <input
                      type="checkbox"
                      name="facebook"
                      checked={facebook && facebook.isOn}
                      onChange={handleOnOffChange}
                    />
                    <label></label>
                  </span>
                  <input
                    name="facebook"
                    type="text"
                    className="form-control"
                    placeholder="https://"
                    value={facebook && facebook.url}
                    onChange={handleTemplateUrlChange}
                  />
                  <label>Twitter</label>{' '}
                  <span className="checkbox float-right">
                    <input
                      type="checkbox"
                      name="twitter"
                      checked={twitter && twitter.isOn}
                      onChange={handleOnOffChange}
                    />
                    <label></label>
                  </span>
                  <input
                    name="twitter"
                    type="text"
                    className="form-control"
                    placeholder="https://"
                    value={twitter && twitter.url}
                    onChange={handleTemplateUrlChange}
                  />
                  <label>Linkedin</label>{' '}
                  <span className="checkbox float-right">
                    <input
                      type="checkbox"
                      name="linkedin"
                      checked={linkedin && linkedin.isOn}
                      onChange={handleOnOffChange}
                    />
                    <label></label>
                  </span>
                  <input
                    name="linkedin"
                    type="text"
                    className="form-control"
                    placeholder="https://"
                    value={linkedin && linkedin.url}
                    onChange={handleTemplateUrlChange}
                  />
                  <label>Youtube</label>{' '}
                  <span className="checkbox float-right">
                    <input
                      type="checkbox"
                      name="youtube"
                      checked={youtube && youtube.isOn}
                      onChange={handleOnOffChange}
                    />
                    <label></label>
                  </span>
                  <input
                    name="youtube"
                    type="text"
                    className="form-control"
                    placeholder="https://"
                    value={youtube && youtube.url}
                    onChange={handleTemplateUrlChange}
                  />
                </fieldset>
              </div>
            </form>
            <div className="settings-footer">
              <div className="btn btn-add" onClick={handleReset}>
                Cancel
              </div>
              <div className="btn btn-add" onClick={handleSave}>
                {flag === SAVING ? (
                  <>
                    Saving <i className="fa fa-spinner fa-spin" />
                  </>
                ) : (
                  'Save'
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default EmailTemplate;
