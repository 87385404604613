import {
  SET_CURRENT_USER,
  AUTH_LOADING,
  RESET_PASSWORD,
  RESET_PASSWORD_LOADING,
} from 'actions/types';

const initialState = {
  user: {},
  isLoading: false,
  isResetPasswordLoading: false,
  resetPasswordEmail: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case AUTH_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        isResetPasswordLoading: !state.isResetPasswordLoading,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        resetPasswordEmail: action.payload.email,
        isResetPasswordLoading: !state.isResetPasswordLoading,
      };

    default:
      return state;
  }
}
