import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

export default function StripeKeysInput(props) {
  return (
    <>
      <Row className="mt-3 mb-2">
        <FormGroup check>
          <span className="pretty p-default">
            <Input
              type="checkbox"
              name="hasDefaultStripeKeys"
              id="hasDefaultStripeKeys"
              checked={props.hasDefaultStripeKeys}
              onChange={props.handleStripeCheckbox}
            />
            <span className="state">
              <Label for="hasDefaultStripeKeys" check>
                Use Olive Stripe Keys
              </Label>
            </span>
          </span>
        </FormGroup>
      </Row>
      <Row className="ml-1">
        {!props.hasDefaultStripeKeys ? (
          <>
            <Col md="6">
              <FormGroup>
                <Label>Stripe Public Key</Label>
                <Input
                  name="publicKey"
                  id="publicKey"
                  type="text"
                  className="form-control"
                  placeholder={props.hasStripeKeys ? '***************' : ''}
                  value={props.stripeKeys.publicKey}
                  onChange={props.handlestripeKeys}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Stripe Secret Key</Label>
                <Input
                  name="secretKey"
                  id="secretKey"
                  type="text"
                  className="form-control"
                  placeholder={props.hasStripeKeys ? '***************' : ''}
                  value={props.stripeKeys.secretKey}
                  onChange={props.handlestripeKeys}
                />
              </FormGroup>
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
}
