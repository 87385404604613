import Api from 'API';
import {
  GET_SUPER_ADMINS,
  SUPER_ADMIN_LOADING,
  CREATE_SUPER_ADMIN,
  GET_ERROR,
  CLEAR_ERROR,
  TOGGLE_SUPER_ADMIN_CREATE_MODAL,
  CLEAR_UPLOAD_URL,
  DELETE_SUPER_ADMIN_LOADING,
  TOGGLE_SUPER_ADMIN_DELETE_MODAL,
  GET_SINGLE_SUPER_ADMIN,
} from './types';
import { logout } from 'actions/authActions';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
toast.configure();

const fetchPayload = { currentPage: 1, onlyActive: true };

export const setSuperAdminLoading = value => {
  return {
    type: SUPER_ADMIN_LOADING,
    payload: value,
  };
};

export const setSuperAdminDeleteLoading = () => {
  return {
    type: DELETE_SUPER_ADMIN_LOADING,
  };
};

export const getSuperAdmins = payload => dispatch => {
  dispatch(setSuperAdminLoading(true));

  new Api()
    .fetchSuperAdmins(payload)
    .then(result => {
      dispatch({
        type: GET_SUPER_ADMINS,
        payload: result.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_SUPER_ADMINS,
        payload: [],
      });
    });
};

export const getSingleSuperAdmin = id => {
  return {
    type: GET_SINGLE_SUPER_ADMIN,
    payload: id,
  };
};

export const toggleSuperAdminCreateModal = () => {
  return {
    type: TOGGLE_SUPER_ADMIN_CREATE_MODAL,
  };
};

export const createSuperAdmin = payload => dispatch => {
  dispatch(setSuperAdminLoading(true));
  dispatch(clearErrors());
  new Api()
    .createSuperAdmin(payload)
    .then(response => {
      dispatch({
        type: CREATE_SUPER_ADMIN,
        payload: response.data,
      });

      // Clear uploaded image url to prevent being used in other components
      dispatch({
        type: CLEAR_UPLOAD_URL,
      });

      toast.success('Super admin created successfully !');
      dispatch(toggleSuperAdminCreateModal());

      // Fetch data to get the newly updated data
      dispatch(getSuperAdmins(fetchPayload));
    })
    .catch(err => {
      dispatch(setSuperAdminLoading(false));
      dispatch({
        type: GET_ERROR,
        payload:
          err && err.response && err.response.data
            ? err.response.data.error
            : '',
      });
    });
};

export const toggleSuperAdminDeleteModal = () => {
  return {
    type: TOGGLE_SUPER_ADMIN_DELETE_MODAL,
  };
};

export const deleteSuperAdmin = (id, isLogout, history) => dispatch => {
  dispatch(setSuperAdminDeleteLoading());
  new Api()
    .deleteSuperAdmin(id)
    .then(response => {
      toast.success('Super admin deleted successfully !');

      dispatch(toggleSuperAdminDeleteModal());
      dispatch(setSuperAdminDeleteLoading());

      if (isLogout) {
        return dispatch(logout(history));
      }

      // Fetch data to get the newly updated data
      dispatch(getSuperAdmins(fetchPayload));
    })
    .catch(err => {
      dispatch(setSuperAdminDeleteLoading());
      console.log('error', err);
    });
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERROR,
  };
};
