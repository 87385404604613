import React from 'react';
import { Link } from 'react-router-dom';
import { pathNameAsTitle } from 'utils/helper';

const Breadcrumb = () => {
  return (
    <ul className="breadcrumb">
      <li>
        <Link to="/dashboard">
          <i className="icon icon-home" />
          Home
        </Link>
      </li>
      <li>
        {pathNameAsTitle()}
      </li>
    </ul>
  );
};
export default Breadcrumb;
