import React, { Component } from 'react';

import { Row, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Pagination from 'components/Pagination';
import { getClient, clearClient } from 'actions/clientActions';
import ViewMore from 'components/CoursePurchaseLog/ViewMore';
import Filter from 'components/CoursePurchaseLog/LogFilter';
import API from 'API';
import { getFromISODate, getToISODate, formatDate, currencySign } from '../../utils/helper';

class CoursePurchaseLogs extends Component {
  actionValues = { 
    fullPayment: 'Full Payment', 
    downPayment: 'Down Payment', 
    installmentPayment: 'Installment Payment', 
  }
  state = {
    isError: false,

    currentPage: 1,
    pageSize: 20,

    userSearch: '',
    courseSearch: '',
    voucherCode: '',
    actionType: '',
    fromDate: '',
    toDate: '',
    status: '',

    logId: '',
    showViewMoreModal: false,
    paymentLogs: [],
    totalPaymentLogs: 0,
    totalLogPage: 0,
    isLogLoading: false,
  };

  componentDidMount() {
    // this.props.getClient(this.props.match.params.id);
    this.fetchCoursePurchaseLogs();
  }

  componentWillUnmount() {
    // this.props.clearClient();
  }

  fetchCoursePurchaseLogs = async () => {
    try {
      this.setState({ isLogLoading: true });
      const { currentPage, pageSize, userSearch, courseSearch, voucherCode, actionType, fromDate, toDate, status } = this.state;
  
      const payload = {
        currentPage,
        pageSize,
        userSearch, 
        courseSearch,
        voucherCode,
        actionType, 
        status,
        fromDate: getFromISODate(fromDate), 
        toDate: getToISODate(toDate),
        subdomain: this.props.match.params.subdomain,
      };
  
      const result = await new API().fetchCoursePurchaseLogs(payload);
      this.setState({ 
        paymentLogs: result.logs,
        totalPaymentLogs: result.total,
        totalLogPage: Math.ceil( result.total / pageSize ),
      })
    } catch(e) {
      console.log(e)
    }
    this.setState({ isLogLoading: false });
  };

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({ currentPage: index }, () => {
      this.fetchCoursePurchaseLogs();
    });
  };

  handleDateChange = (moment, name) => {
    this.setState({ [name]: '' });
    if (moment && typeof moment !== 'object') { 
      return;
    }
    if (moment) {
      const value = moment.format('YYYY-MM-DD')
      this.setState({ [name]: value });
    }
  };

  handleFilterChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleFilter = () => {
    this.setState({ currentPage: 1 }, () => {
      this.fetchCoursePurchaseLogs();
    });
  };

  toggleViewMore = (id, e) => {
    this.setState(prevState => ({
      showViewMoreModal: !prevState.showViewMoreModal,
      logId: id,
    }));
  };

  render() {
    const { 
      currentPage, 
      pageSize, 
      showViewMoreModal, 
      logId, 
      paymentLogs,
      isLogLoading,
      totalPaymentLogs,
      totalLogPage,
      fromDate,
      toDate,
    } = this.state;
    // const { client } = this.props.clients;

    const subdomain = this.props.match.params.subdomain;

    return (
      <>
        <div className="content">
          <div className="title">
            <span>List of</span>
            <h1>Learner Course Purchase Logs - {subdomain}</h1>
          </div>

          <Filter
            handleDateChange={this.handleDateChange}
            handleFilterChange={this.handleFilterChange}
            handleFilter={this.handleFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
          {isLogLoading ? (
            <span className="loading"><i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...</span>
          ) : (
            <>
              {!paymentLogs || !paymentLogs.length ? (
                <h2>No course purchase logs to display</h2>
              ) : (
                <Row>
                  <Col md="12">
                    <Table className="base-table clients-table" responsive>
                      <thead>
                        <tr>
                          <th>S.N</th>
                          <th>Learner Id</th>
                          <th>Course</th>
                          <th>Created At</th>
                          <th>Type</th>
                          <th>Gross Amount</th>
                          <th>Discount Amount</th>
                          <th>VAT</th>
                          <th>Voucher Code</th>
                          <th>Voucher Discount</th>
                          <th>Credit Used (Equivalent Price)</th>
                          <th>Amount Paid</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentLogs.map((paymentLog, index) => {
                          let voucherDiscount = parseFloat(paymentLog.voucherDiscountAmount) ? `${currencySign(paymentLog.currency)} ${paymentLog.voucherDiscountAmount}` : '0';
                          let discountAmount = parseFloat(paymentLog.discountAmount) ? `${currencySign(paymentLog.currency)} ${paymentLog.discountAmount}` : '0';
                          let grossAmount = parseFloat(paymentLog.grossAmount) ? `${currencySign(paymentLog.currency)} ${paymentLog.grossAmount}` : '0';
                          let amountPaid = parseFloat(paymentLog.netAmount) ? `${currencySign(paymentLog.currency)} ${paymentLog.netAmount}` : '0';
                          let creditUsed = paymentLog.creditUsed ? `${paymentLog.creditUsed.creditBalance} (${currencySign(paymentLog.currency)} ${paymentLog.creditUsed.equivalentPrice})` : '';
                          return (
                            <tr key={paymentLog._id}>
                              <td>
                                {currentPage * pageSize - pageSize + (index + 1)}
                              </td>
                              <td>{paymentLog.learner ? paymentLog.learner.userId : ''}</td>
                              <td>{paymentLog.course ? paymentLog.course.name : ''}</td>
                              <td>{formatDate(paymentLog.createdAt)}</td>
                              <td>{this.actionValues[paymentLog.action]}</td>
                              <td>{grossAmount}</td>
                              <td>{discountAmount}</td>
                              <td>{parseFloat(paymentLog.vat) ? `${paymentLog.vat}%` : 0}</td>
                              <td>{paymentLog.voucherCode}</td>
                              <td>{voucherDiscount}</td>
                              <td>{creditUsed}</td>
                              <td>{amountPaid}</td>
                              <td>{paymentLog.isPaymentSucceeded ? 'Payment Successful' : 'Payment Failed'}</td>
                              <td>
                                <ul>
                                  <li className="btn-setting" onClick={this.toggleViewMore.bind(this, paymentLog._id)}> View </li>
                                </ul>
                                {showViewMoreModal && paymentLog._id === logId ? (
                                  <ViewMore
                                    toggleViewMore={this.toggleViewMore}
                                    paymentLog={paymentLog}
                                    showViewMoreModal={showViewMoreModal}
                                  />
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Pagination
                      total={totalPaymentLogs}
                      currentPage={currentPage}
                      totalPage={totalLogPage}
                      pageLimit={pageSize}
                      handleClick={this.handlePagination}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

CoursePurchaseLogs.propTypes = {
  getClient: PropTypes.func.isRequired,
  clearClient: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  clients: state.client
});

export default connect(mapStateToProps, {
  getClient,
  clearClient
})(CoursePurchaseLogs);
