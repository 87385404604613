import React, { Component } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class PaginationComponent extends Component {
  state = { pager: {} };

  UNSAFE_componentWillMount() {
    this.setPage({}, this.props.currentPage);
  }

  componentDidUpdate(prevProps) {
    // reset page if items array has changed
    if (this.props.totalPage !== prevProps.totalPage) {
      this.setPage(this.props.currentPage);
    }
  }

  handlePrev = (e, page) => {
    const { pager } = this.state;
    if (pager.currentPage <= 1) return;

    this.setPage(e, page);
  };

  handleNext = (e, page) => {
    const { pager } = this.state;
    if (pager.currentPage >= pager.totalPages) return;

    this.setPage(e, page);
  };

  setPage = (e, page) => {
    let pager = this.state.pager;

    // get new pager object for specified page
    pager = this.getPager(this.props.totalPage, page);

    // update state
    this.setState({ pager: pager });

    if (this.props.currentPage === page) return;

    if (e.target) {
      this.props.handleClick(e, page);
    }
  };

  getPager = (totalPages, currentPage) => {
    // default to first page
    currentPage = currentPage || 1;

    let startPage, endPage;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // create an array of pages to n-repeat in the pager control
    let pages = [...Array(endPage + 1 - startPage).keys()].map(
      i => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      currentPage: currentPage,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      pages: pages,
    };
  };

  render() {
    const pager = this.state.pager;
    const { currentPage, total, totalPage, pageLimit } = this.props;

    return (
      <div className="pagination">
        <span>
          {currentPage * pageLimit - (pageLimit - 1)} -{' '}
          {currentPage !== totalPage ? currentPage * pageLimit : total} of{' '}
          {total}
        </span>
        <ul>
          <li>
            <Link
              onClick={e => this.handlePrev(e, pager.currentPage - 1)}
              href="#"
              className={pager.currentPage <= 1 ? 'disable' : ''}
            >
              <i className="icon icon-back-1 pull-left"> </i> Prev
            </Link>
          </li>
          {pager.pages[0] !== 1 ? (
            <li disabled={pager.currentPage <= 1}>
              <Link onClick={e => this.setPage(e, 1)} href="#">
                1 <span>...</span>
              </Link>
            </li>
          ) : null}
          {pager.pages.map((page, i) => (
            <li key={i}>
              <Link
                onClick={e => this.setPage(e, page)}
                href="#"
                className={page === pager.currentPage ? 'active' : ''}
              >
                {page}
              </Link>
            </li>
          ))}
          {pager.pages[pager.pages.length - 1] !== pager.totalPages ? (
            <li disabled={pager.currentPage >= pager.totalPages}>
              <Link onClick={e => this.setPage(e, pager.totalPages)} href="#">
                <span>...</span> {this.props.totalPage}
              </Link>
            </li>
          ) : null}
          <li>
            <Link
              onClick={e => this.handleNext(e, pager.currentPage + 1)}
              href="#"
              className={pager.currentPage >= pager.totalPages ? 'disable' : ''}
            >
             Next <i className="icon icon-arrow pull-right"> </i>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}
export default PaginationComponent;
