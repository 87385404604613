import {
  GET_SUPER_ADMINS,
  SUPER_ADMIN_LOADING,
  CREATE_SUPER_ADMIN,
  TOGGLE_SUPER_ADMIN_CREATE_MODAL,
  TOGGLE_SUPER_ADMIN_DELETE_MODAL,
  DELETE_SUPER_ADMIN_LOADING,
  GET_SINGLE_SUPER_ADMIN,
} from 'actions/types';
import {
  getSingleData,
  getSingleDataWhenDataFetched,
} from 'utils/reducerHelper';

const initialState = {
  data: [],
  singleData: [],
  isLoading: false,
  showModal: false,
  showDeleteModal: false,
  isDeleting: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SUPER_ADMIN_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case GET_SUPER_ADMINS:
      return {
        ...state,
        data: action.payload,
        singleData: getSingleDataWhenDataFetched(
          action.payload,
          state.singleData
        ),
        isLoading: false,
      };

    case GET_SINGLE_SUPER_ADMIN:
      return {
        ...state,
        singleData: getSingleData(state.data, action.payload),
      };

    case TOGGLE_SUPER_ADMIN_CREATE_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
      };

    case CREATE_SUPER_ADMIN:
      return {
        ...state,
        isLoading: false,
        data: [action.payload, ...state.data],
      };

    case TOGGLE_SUPER_ADMIN_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: !state.showDeleteModal,
      };

    case DELETE_SUPER_ADMIN_LOADING:
      return {
        ...state,
        isDeleting: !state.isDeleting,
      };

    default:
      return state;
  }
}
