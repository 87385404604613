import React, { useState } from 'react';
import { Alert } from 'reactstrap';

export default function Banner({ cloneWhat }) {
  const [open, setOpen] = useState(true);
  const onDismiss = () => {
    setOpen(false);
  };

  const message = () => {
    // let message = 'This client must have at least one';
    // if (cloneWhat === 'Courses') {
    //   message = `${message} Learning Topic, Sub Section, Category and Course.`;
    // } else if (cloneWhat === 'Jobs') {
    //   message = `${message} Job Sector and Job.`;
    // } else if (cloneWhat === 'Regions') {
    //   message = `${message} Region.`;
    // } else if (cloneWhat === 'Menus') {
    //   message = `${message} Menu.`;
    // }

    // return `${message} You can delete these dummy data after the clone completion.`;
    return 'Before clonning, you must have atleast one admin in your client. Otherwise clonning process may gives an error.';
  };

  return (
    <Alert
      color="info"
      isOpen={open}
      toggle={onDismiss}
      style={{ background: '#ccf2f6' }}
    >
      {message()}
    </Alert>
  );
}
