import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Badge from 'reactstrap/lib/Badge';
import { Button, FormGroup, Input, Label } from 'reactstrap';

import { fetchClientCourses, onSubmitCourseClone } from 'actions/cloneActions';
import { COURSE_SEARCH_KEY } from 'actions/types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Pagination from 'components/Pagination';
import CloneAllPopup from './cloneAllPopup';
import Banner from 'components/Banner';

export default function ClientCourses({ cloneFrom, cloneFromDomain, cloneWhat }) {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const [coursesToClone, setCoursesToClone] = useState([]);
  const [showCloneAllModal, setShowCloneAllModal] = useState(false);
  const {
    client: { subdomain },
  } = useSelector(state => state.client);
  const { isCourseFetching, clientCourses, isClonning, courseSearchKey } = useSelector(
    state => state.clone
  );

  const dispatchCourses = (page_number = pageNumber) => {
    const payload = { pageNumber: page_number, cloneFrom, courseSearchKey };

    dispatch(fetchClientCourses(payload));
  };

  useEffect(() => {
    if (!pageNumber) return;

    dispatchCourses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    /* Fetch first page(1) courses on fetch changes */
    dispatchCourses(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCoursesToClone([]);
  }, [cloneFrom]);

  const handleCloneAll = () => {
    setShowCloneAllModal(showCloneAllModal => !showCloneAllModal);
  };

  const handleClone = () => {
    if (isClonning) return;

    const payload = {
      fromSubdomain: cloneFromDomain,
      toSubdomain: subdomain,
      courseIdsToClone: coursesToClone,
      cloneWhat,
    };

    dispatch(onSubmitCourseClone(payload));
  };

  const onCheckboxChange = id => {
    let selectedCourses = [...coursesToClone];
    if (coursesToClone.includes(id)) {
      selectedCourses = selectedCourses.filter(s => s !== id);
    } else {
      selectedCourses = [...selectedCourses, id];
    }

    setCoursesToClone(selectedCourses);
  };

  const getCourses = () => {
    setPageNumber(0);
    const payload = { pageNumber: 1, cloneFrom, courseSearchKey };

    dispatch(fetchClientCourses(payload));
  };

  const handlePagination = (e, index) => {
    e.preventDefault();
    setPageNumber(index);
  };

  const handleSearchKey = e => {
    dispatch({
      type: COURSE_SEARCH_KEY,
      payload: e.target.value,
    });
    if (e.key === 'Enter') {
      getCourses();
    }
  };

  const page_number = pageNumber || 1;

  return (
    <>
      <Banner cloneWhat="Courses" />
      {isCourseFetching ? (
        <span>
          <i className="fa fa-spinner fa-spin" aria-hidden="true" />
        </span>
      ) : (
        <div className="home r-content mt-70">
          <Row>
            <Col md="12">
              <div className="table-responsive-material mt-4">
                <Row form>
                  <Col sm={3}>
                    {clientCourses?.courses.length ? (
                      <>
                        {coursesToClone.length ? (
                          <Button
                            color="secondary"
                            onClick={handleClone}
                            disabled={isClonning}
                            block
                            outline
                            className="rounded-0"
                          >
                            {isClonning ? 'Clonning...' : 'Clone Courses'}
                          </Button>
                        ) : (
                          <Button
                            color="secondary"
                            onClick={handleCloneAll}
                            block
                            outline
                            className="rounded-0"
                          >
                            Clone All Courses
                          </Button>
                        )}
                      </>
                    ) : null}
                  </Col>
                  <Col sm={5}></Col>
                  <Col sm={4}>
                    {/* {clientCourses?.courses.length ? ( */}
                    <div className="searchbox">
                      <input
                        type="text"
                        placeholder="Search"
                        value={courseSearchKey}
                        onChange={handleSearchKey}
                        onKeyDown={handleSearchKey}
                        className="form-control rounded-0"
                      />
                    </div>
                    {/* ) : null} */}
                  </Col>
                </Row>

                {isCourseFetching ? (
                  <span>
                    <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                    Fetching Courses...
                  </span>
                ) : (
                  <>
                    {!clientCourses?.courses.length ? (
                      <div className="no-item">
                        <div>
                          <i className="icon icon-danger fa-5x"></i>
                          <h3>No course to display</h3>
                        </div>
                      </div>
                    ) : (
                      <div className="table-responsive mt-3">
                        <table className="career-list table first-col-bordered bg-white">
                          <thead>
                            <tr>
                              <th className="width-50">Select</th>
                              <th className="width-50">ID</th>
                              <th>Name</th>
                              <th>Learning Topic</th>
                              <th>Sub Sections</th>
                              <th>Category</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {clientCourses?.courses.map((course, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    {/* <input
                                      type="checkbox"
                                      className="haslink"
                                      checked={coursesToClone.includes(course._id)}
                                      onChange={() => onCheckboxChange(course._id)}
                                    /> */}

                                    <div class="tbl-checkbox">
                                      <div className="styled-input--square">
                                        <div className="styled-input-single">
                                          <Input
                                            type="checkbox"
                                            checked={coursesToClone.includes(course._id)}
                                            onChange={() => onCheckboxChange(course._id)}
                                            className="form-checkbox-input"
                                            id={page_number * 20 - 20 + (i + 1)}
                                          />
                                          <label htmlFor={page_number * 20 - 20 + (i + 1)}>
                                            
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{page_number * 20 - 20 + (i + 1)}</td>
                                  <td>{course.name}</td>
                                  <td>
                                    {course.module === null ? 'N/A' : course.module.topic_name}
                                  </td>
                                  <td>
                                    {course.course_group === null
                                      ? 'N/A'
                                      : course.course_group.name}
                                  </td>
                                  <td>
                                    {course.category_name === null
                                      ? 'N/A'
                                      : course.category_name.name}
                                  </td>
                                  <td>
                                    {course.is_published ? (
                                      <Badge color="success">Published</Badge>
                                    ) : (
                                      <Badge color="warning">Draft</Badge>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                )}
              </div>

              {clientCourses?.courses.length && !isCourseFetching ? (
                <Pagination
                  total={clientCourses.totalCourses}
                  currentPage={page_number}
                  totalPage={Math.ceil(clientCourses.totalCourses / 20)}
                  pageLimit={20}
                  handleClick={handlePagination}
                />
              ) : null}
            </Col>
          </Row>
        </div>
      )}
      <CloneAllPopup
        cloneFromDomain={cloneFromDomain}
        isOpen={showCloneAllModal}
        handleCloneAll={handleCloneAll}
        cloneWhat={cloneWhat}
      />
    </>
  );
}
