import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Sidebar from 'components/Sidebar';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Dashboard from './Dashboard';
import Clients from './Clients';
import ClientSettings from './ClientSettings';
import ClientAdmin from './ClientAdmin';
import SuperAdmin from './Superadmin';
import Logs from './Logs';
import Activities from './Activities';
import ChangePassword from './Profile/changePassword';
import PaymentLogs from './Logs/paymentLogs';
import VoucherSettings from './VoucherSettings';
import VoucherLogs from './VoucherSettings/log';
import RegistrationLogs from './RegistrationLog';
import CoursePurchaseLogs from './CoursePurchaseLog';
import CreditLogs from './CreditLogs';

class AuthenticatedRoute extends Component {
  render() {
    return (
      <div className="page">
        <Sidebar propsData={this.props} />
        <div className="main-page">
          <Header propsData={this.props} />
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/clients" component={Clients} />
            <Route
              exact
              path="/client-settings/:id"
              component={ClientSettings}
              key={Math.random()}
            />
            <Route
              exact
              path="/clientadmin/:subdomain"
              component={ClientAdmin}
            />
            <Route exact path="/superadmin" component={SuperAdmin} />
            <Route exact path="/logs" component={Logs} />
            <Route exact path="/activities" component={Activities} />
            <Route exact path="/change-password" component={ChangePassword} />
            <Route
              exact
              path="/:subdomain/payment-logs"
              component={PaymentLogs}
            />
            <Route
              exact
              path="/:subdomain/:id/voucher-settings"
              component={VoucherSettings}
            />
            <Route
              exact
              path="/:subdomain/:id/voucher-logs"
              component={VoucherLogs}
            />
            <Route
              exact
              path="/:subdomain/:id/registration-logs"
              component={RegistrationLogs}
            />
            <Route
              exact
              path="/:subdomain/:id/credit-logs"
              component={CreditLogs}
            />
            <Route
              exact
              path="/:subdomain/:id/course-purchase-logs"
              component={CoursePurchaseLogs}
            />
            <Redirect to="/dashboard" />
          </Switch>

          <Footer />
        </div>
      </div>
    );
  }
}

export default AuthenticatedRoute;
