import React from 'react';
import { FormGroup } from 'reactstrap';

export default function Checkbox(props) {
	const { label, name, checked, onChange } = props;

	return (
		<FormGroup>
			<label>{label}</label>
			<div className="checkbox float-right">
				<input type="checkbox" name={name} checked={checked} onChange={onChange} />
				<label></label>
			</div>
		</FormGroup>
	);
}
