import React, { Component } from 'react';

class Title extends Component {
    render() {
        return (
            <div className="title">
                <span>Dashboard</span>
                <h1>Overview</h1>
                {/* <button className="btn btn-base">Overview</button> */}
            </div>
        );
    }
}
export default Title;