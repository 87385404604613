import React from 'react';
import { Modal, Row, Col, ModalHeader } from 'reactstrap';

const ViewMore = ({ showViewMoreModal, toggleViewMore, data }) => {
  return (

    <Modal style={{width : '500px'}} className="log-details" isOpen={showViewMoreModal} toggle={toggleViewMore}>
      <ModalHeader toggle={toggleViewMore} className="mb-3">Credit Log Details</ModalHeader>
      <Row>
        <Col md="3">
          <div>
            <strong>User Id</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{data.userId}</small>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <div>
            <strong>Action Type</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{data.actionType}</small>
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <div>
            <strong>Old Data</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{JSON.stringify(data?.oldValue)}</small>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <div>
            <strong>New Data</strong>
          </div>
        </Col>
        <Col md="9">
          <small>{JSON.stringify(data?.newValue)}</small>
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewMore;
