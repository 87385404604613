import * as actions from 'actions/types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { resetFamilyMembership, notSubscriptionMembership, priceToDecimal } from 'utils/helper';
toast.configure();

const initialState = {
  clients: [],
  isLoading: false,
  totalClients: 0,
  totalPage: 0,

  activeClients: [],
  allActiveClients: [],
  isOnlyActiveLoading: false,
  node: '',

  showGrantAccessModal: false,
  grantAccessId: '',
  isGrantAccessLoading: false,

  client: {
    /**
     * Earlier we used redux extensively but later on we realized
     * that the redux is increasing the projet complexities.
     * So, now we are using states to handle the data as much as possible.
     * Here, the allData state holds the data of the respective client which
     * can be used to get the any specific data whenever required like in SSO-SAML component.
     */
    allData: {},
    //mobile banners
    primaryMobileBanner: '',
    secondaryMobileBanner: '',
    name: '',
    subdomain: '',
    appId: '',
    ahqId: '',
    appSecretKey: '',

    hasApiCredentials: false,
    logo: '',
    backgroundImage: '',
    backgroundImageOpacity: 0.8,
    color: {
      primaryBrandingColor: '',
      secondaryBrandingColor: '',
      primaryIconColor: '',
      secondaryIconColor: '',
    },
    favicon: '',
    brandingVideo: '',
    menu: [],
    payment: {
      membership: '',
      subscription: {
        monthly: {
          isActive: false,
          price: '',
        },
        yearly: {
          isActive: false,
          price: '',
        },
      },
      price: '',
      currency: 'usd',
      familyMembership: {
        allowFamilyMembership: false,
        totalFamilyMembers: 0,
        price: {
          oneTime: '',
          monthly: '',
          yearly: '',
        },
      },
      trialPeriod: 0,
    },
    allowB2CSellingOfCourse: false,
    canMemberRegisterWithoutPayment: false,
    skillsHubSectionToPublic: false,
    coursePurchaseInstallmentPlan: {
      allowAutomaticInstallmentPayment: true,
      allowManualInstallmentPayment: false,
    },
    stripeKeys: {
      publicKey: '',
      secretKey: '',
    },
    hasStripeKeys: false,
    hasDefaultStripeKeys: false,
    allowTrialPeriod: false,
    hideForgotPassword: false,
    registrationDetails: {
      allowUserRegistration: true,
      memberIdLabel: '',
      memberIdPlaceholder: '',
      familySignupMessage: '',
      signUpMessage: '',
      familyMemberInviteStep: true,
      customSignupMessage: '',
      paymentMessage: '',
      paymentCancelMessage: '',
      inviteFamilyAndAddNameMessage: '',
      familyAddNameMessage: '',
      shareLinkMessage: '',
      welcomeMessage: '',
      preferenceMessage: '',
    },
    analytics: {
      hjid: '',
      hjsv: '',
      gaTrackingId: '',
      fbPixelCode: '',
      googleTagId: '',
      googleWebmasterCode: '',
    },
    policies: {
      privacyPolicy: '',
      termsAndConditions: '',
      cookiePolicy: '',
      userLicense: '',
    },
    recruiterDetails: {
      jobsPageMessage: '',
      recruiterPageMessage: '',
      sectorPageMessasge: '',
      enableRecruiter: false,
    },

    /*  credit part  */
    credit: {
      name: 'credit',
      isPrivate: false,
      totalAssignedBalance: 0,
      usedBalance: 0,
      availableBalance: 0,
      monetaryMapping: {
        creditValue: 0,
        mapping: [
          {
            currency: 'usd',
            monetaryValue: 0,
          },
          {
            currency: 'eur',
            monetaryValue: 0,
          },
          {
            currency: 'aud',
            monetaryValue: 0,
          },
          {
            currency: 'gbp',
            monetaryValue: 0,
          },
        ],
      },
      isActive: true,
      isAppliedToExistingLearner: false,
      automaticAssignment: {
        isEnabled: false,
        assignedBalance: 0,
        isAutomaticAssignmentAllowedToAdmin: true,
      },
      pin: 0,
    },
    allowLearnerCommunityForum: false,
    allowAdminCommunityForum: false,
    enableAdvertisement: false,

    allowGamification: false,
  },

  updateClientLoader: false,
  showDeleteClientModal: false,
  sidebarModal: '',

  clientAdmin: [],
  totalClientAdmin: 0,
  totalClientAdminPage: 0,
  showCreateClientAdminModal: false,
  isClientAdminLoading: false,

  emailTemplate: {
    welcomeTemplate: '',
    templateForFamilyMemberInvitation: '',
    templateForMemberInvitedByAdmin: '',
    userTaggedToCommunityPost: '',
    userTaggedToScheduledCommunityPost: '',
    userAddedToCommunity: '',
    userJoinedPublicCommunity: '',
    color: '#4287ea',
    email: '',
    buttonUrl: '',
    facebook: {
      url: '',
      isOn: true,
    },
    twitter: {
      url: '',
      isOn: true,
    },
    linkedin: {
      url: '',
      isOn: true,
    },
    youtube: {
      url: '',
      isOn: true,
    },
    support: {
      url: '',
      isOn: true,
    },
    faq: {
      url: '',
      isOn: true,
    },
    privacyPolicy: {
      url: '',
      isOn: true,
    },
    terms: {
      url: '',
      isOn: true,
    },
  },
  countries: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.CLIENT_LOADING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };

    case actions.ACTIVE_CLIENT_LOADING:
      return {
        ...state,
        isOnlyActiveLoading: true,
      };

    case actions.GET_CLIENTS:
      return {
        ...state,
        clients: action.payload.clients,
        totalClients: action.payload.totalClients,
        totalPage: Math.ceil(action.payload.totalClients / action.payload.pageLimit),
        isLoading: false,
      };
    case actions.ON_CHANGE_CREDIT_PAYMENT_SETTING_VALUES:
      return {
        ...state,
        client: {
          ...state.client,
          credit: {
            ...state.client.credit,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case actions.ON_CHANGE_AUTOMATIC_ASSIGNMENT_OF_CREDIT:
      return {
        ...state,
        client: {
          ...state.client,
          credit: {
            ...state.client.credit,
            automaticAssignment: {
              ...state.client.credit.automaticAssignment,
              [action.payload.name]: action.payload.value,
            },
          },
        },
      };
    case actions.ON_CHANGE_MONETARY_VALUE_MAPPING:
      return {
        ...state,
        client: {
          ...state.client,
          credit: {
            ...state.client.credit,
            monetaryMapping: {
              ...state.client.credit.monetaryMapping,
              [action.payload.name]: action.payload.value,
            },
          },
        },
      };
    case actions.GET_CLIENT:
      return {
        ...state,
        client: {
          ...state.client,
          allData: action.payload,
          name: action.payload.name,
          subdomain: action.payload.subdomain,
          appId: action.payload.appId,
          ahqId: action.payload.ahqId,
          appSecretKey: action.payload.appSecretKey,
          hasApiCredentials: action.payload.hasApiCredentials,
          primaryMobileBanner: action.payload.primaryMobileBanner,
          secondaryMobileBanner: action.payload.secondaryMobileBanner,
          logo: action.payload.logo,
          backgroundImage: action.payload.backgroundImage,
          backgroundImageOpacity: action.payload.backgroundImageOpacity || 0.8,
          color: {
            ...state.client.color,
            primaryBrandingColor: action.payload.color.primaryBrandingColor,
            secondaryBrandingColor: action.payload.color.secondaryBrandingColor,
            primaryIconColor: action.payload.color.primaryIconColor,
            secondaryIconColor: action.payload.color.secondaryIconColor,
          },
          favicon: action.payload.favicon,
          brandingVideo: action.payload.brandingVideo,
          menu: action.payload.menu,
          credit: action.payload.credit,
          payment: {
            ...state.client.payment,
            membership: action.payload.paymentDetails.membership,
            subscription: {
              monthly: {
                isActive: !!action.payload.paymentDetails.subscription.monthly?.isActive,
                price:
                  priceToDecimal(action.payload.paymentDetails.subscription.monthly?.price) || '',
              },
              yearly: {
                isActive: !!action.payload.paymentDetails.subscription.yearly?.isActive,
                price:
                  priceToDecimal(action.payload.paymentDetails.subscription.yearly?.price) || '',
              },
            },
            price: priceToDecimal(action.payload.paymentDetails?.price),
            currency: action.payload.paymentDetails.currency,
            familyMembership: {
              allowFamilyMembership: !!action.payload.paymentDetails.familyMembership
                .allowFamilyMembership,
              totalFamilyMembers:
                action.payload.paymentDetails.familyMembership.totalFamilyMembers || '',
              price: {
                oneTime:
                  priceToDecimal(action.payload.paymentDetails.familyMembership?.price?.oneTime) ||
                  '',
                monthly:
                  priceToDecimal(action.payload.paymentDetails.familyMembership?.price?.monthly) ||
                  '',
                yearly:
                  priceToDecimal(action.payload.paymentDetails.familyMembership?.price?.yearly) ||
                  '',
              },
            },
            trialPeriod: action.payload.paymentDetails.trialPeriod,
          },
          allowB2CSellingOfCourse: action.payload.allowB2CSellingOfCourse || false,
          canMemberRegisterWithoutPayment: action.payload.canMemberRegisterWithoutPayment || false,
          skillsHubSectionToPublic: !!action.payload.guestToken,
          allowLearnerCommunityForum: !!action.payload.allowLearnerCommunityForum,
          allowAdminCommunityForum: !!action.payload.allowAdminCommunityForum,
          allowGamification: !!action.payload.allowGamification,
          enableAdvertisement: !!action.payload.enableAdvertisement,
          coursePurchaseInstallmentPlan: {
            allowAutomaticInstallmentPayment:
              action.payload.coursePurchaseInstallmentPlan?.allowAutomaticInstallmentPayment,
            allowManualInstallmentPayment:
              action.payload.coursePurchaseInstallmentPlan?.allowManualInstallmentPayment,
          },
          hasDefaultStripeKeys: action.payload.hasDefaultStripeKeys,
          hasStripeKeys: action.payload.hasStripeKeys ? true : false,
          allowTrialPeriod: action.payload.paymentDetails.trialPeriod ? true : false,
          hideForgotPassword: !!action.payload.hideForgotPassword,
          registrationDetails: {
            ...state.client.registrationDetails,
            allowUserRegistration: action.payload.registrationDetails.allowUserRegistration,
            memberIdLabel: action.payload.registrationDetails.memberIdLabel,
            memberIdPlaceholder: action.payload.registrationDetails.memberIdPlaceholder,
            familySignupMessage: action.payload.registrationDetails.familySignupMessage,
            signUpMessage: action.payload.registrationDetails.signUpMessage,
            familyMemberInviteStep: action.payload.registrationDetails.familyMemberInviteStep,
            customSignupMessage: action.payload.registrationDetails.customSignupMessage,
            paymentMessage: action.payload.registrationDetails.paymentMessage,
            paymentCancelMessage: action.payload.registrationDetails.paymentCancelMessage,
            inviteFamilyAndAddNameMessage:
              action.payload.registrationDetails.inviteFamilyAndAddNameMessage,
            familyAddNameMessage: action.payload.registrationDetails.familyAddNameMessage,
            shareLinkMessage: action.payload.registrationDetails.shareLinkMessage,
            welcomeMessage: action.payload.registrationDetails.welcomeMessage,
            preferenceMessage: action.payload.registrationDetails.preferenceMessage,
          },
          analytics: {
            ...state.client.analytics,
            hjid: action.payload.hotjar.hjid,
            hjsv: action.payload.hotjar.hjsv,
            gaTrackingId: action.payload.gaTrackingId || '',
            fbPixelCode: action.payload.fbPixelCode || '',
            googleTagId: action.payload.googleTagId || '',
            googleWebmasterCode: action.payload.googleWebmasterCode || '',
          },
          policies: {
            ...state.client.policies,
            privacyPolicy: action.payload.policies.privacyPolicy || '',
            termsAndConditions: action.payload.policies.termsAndConditions || '',
            cookiePolicy: action.payload.policies.cookiePolicy || '',
            userLicense: action.payload.policies.userLicense || '',
          },
          recruiterDetails: {
            ...state.client.recruiterDetails,
            enableRecruiter: action.payload.recruiterDetails.enableRecruiter,
            jobsPageMessage: action.payload.recruiterDetails.jobsPageMessage,
            recruiterPageMessage: action.payload.recruiterDetails.recruiterPageMessage,
            sectorPageMessage: action.payload.recruiterDetails.sectorPageMessage,
          },
        },
        emailTemplate: action.payload.emailTemplate
          ? action.payload.emailTemplate
          : state.emailTemplate,

        isLoading: false,
      };

    case actions.UPDATE_CLIENT_ON_INPUT_CHANGE:
      return {
        ...state,
        client: {
          ...state.client,
          [action.payload.name]: action.payload.value,
        },
      };

    case actions.CLEAR_CLIENT:
      return {
        ...state,
        client: {
          ...state.client,
          ...Object.keys(state.client).forEach(key => {
            if (key === 'menu') {
              return (state.client.menu = []);
            }

            if (key === 'payment') {
              return Object.keys(state.client.payment).forEach(key => {
                state.client.payment[key] = '';
              });
            }

            if (key === 'stripeKeys') {
              return Object.keys(state.client.stripeKeys).forEach(key => {
                state.client.stripeKeys[key] = '';
              });
            }

            if (key === 'color') {
              return Object.keys(state.client.color).forEach(key => {
                state.client.color[key] = '';
              });
            }

            state.client[key] = '';
          }),
        },
        emailTemplate: {
          ...Object.keys(state.emailTemplate).forEach(key => {
            if (typeof key === 'object') {
              state.emailTemplate[key].url = '[]';
              return (state.emailTemplate[key].isOn = true);
            }

            if (key === 'color') {
              return (state.emailTemplate[key] = '#4287ea');
            }
            state.emailTemplate[key] = '';
          }),
        },
      };

    case actions.GET_ACTIVE_CLIENTS:
      return {
        ...state,
        activeClients: action.payload,
        isOnlyActiveLoading: false,
      };

    case actions.GET_ALL_ACTIVE_CLIENTS:
      return {
        ...state,
        allActiveClients: action.payload,
        isOnlyActiveLoading: false,
      };

    case actions.TOGGLE_GRANT_ACCESS_MODAL:
      return {
        ...state,
        showGrantAccessModal: !state.showGrantAccessModal,
        grantAccessId: action.payload,
      };

    case actions.GRANT_ACCESS_LOADING:
      return {
        ...state,
        isGrantAccessLoading: !state.isGrantAccessLoading,
      };

    case actions.TOGGLE_DELETE_CLIENT_MODAL:
      return {
        ...state,
        showDeleteClientModal: !state.showDeleteClientModal,
      };

    case actions.TOGGLE_CREATE_CLIENT_ADMIN_MODAL:
      return {
        ...state,
        showCreateClientAdminModal: !state.showCreateClientAdminModal,
      };

    case actions.CLIENT_ADMIN_LOADING:
      return {
        ...state,
        isClientAdminLoading: !state.isClientAdminLoading,
      };

    case actions.GET_CLIENT_ADMIN:
      return {
        ...state,
        clientAdmin: action.payload.users,
        totalClientAdmin: action.payload.totalCount,
        totalClientAdminPage: Math.ceil(action.payload.totalCount / action.payload.pageLimit),
        isLoading: false,
      };

    case actions.COPY_URL:
      if (action.payload.isDeleted) return;
      let range = document.createRange();
      range.selectNode(document.getElementById(action.payload.id));
      window.getSelection().removeAllRanges(); // clear current selection
      window.getSelection().addRange(range); // to select text
      document.execCommand('copy');
      window.getSelection().removeAllRanges(); // to deselect

      toast.success('URL copied.', {
        autoClose: 2000,
        hideProgressBar: true,
      });
      return { ...state };

    case actions.UPDATE_EMAIL_TEMPLATE_ON_INPUT_CHANGE:
      return {
        ...state,
        emailTemplate: action.payload,
      };

    case actions.UPDATE_PAYMENT_ON_INPUT_CHANGE:
      return {
        ...state,
        client: {
          ...state.client,
          payment: {
            ...state.client.payment,
            subscription: {
              monthly: {
                isActive: notSubscriptionMembership(action.payload)
                  ? false
                  : state.client.payment.subscription.monthly.isActive,
                price: notSubscriptionMembership(action.payload)
                  ? ''
                  : state.client.payment.subscription.monthly.price,
              },
              yearly: {
                isActive: notSubscriptionMembership(action.payload)
                  ? false
                  : state.client.payment.subscription.yearly.isActive,
                price: notSubscriptionMembership(action.payload)
                  ? ''
                  : state.client.payment.subscription.yearly.price,
              },
            },
            price:
              action.payload.value === 'free' || action.payload.value === 'subscription'
                ? ''
                : state.client.payment.price,
            // currency: action.payload.value === 'free' ? '' : state.client.payment.currency,
            trialPeriod:
              action.payload.value === 'free' || action.payload.value === 'oneTime'
                ? 0
                : state.client.payment.trialPeriod,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case actions.UPDATE_STRIPE_KEY_ON_INPUT_CHANGE:
      return {
        ...state,
        client: {
          ...state.client,
          stripeKeys: {
            ...state.client.stripeKeys,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case actions.RESET_MEMBERSHIP_CHARGE:
      return {
        ...state,
        client: {
          ...state.client,
          payment: {
            ...state.client.payment,
            membership: action.payload.paymentDetails
              ? action.payload.paymentDetails.membership
              : '',
            subscription: {
              monthly: {
                isActive: !!action.payload.paymentDetails.subscription?.monthly?.isActive,
                price: action.payload.paymentDetails.subscription?.monthly
                  ? action.payload.paymentDetails.subscription.monthly?.price
                  : '',
              },
              yearly: {
                isActive: !!action.payload.paymentDetails.subscription?.yearly?.isActive,
                price: action.payload.paymentDetails.subscription?.yearly
                  ? action.payload.paymentDetails.subscription.yearly?.price
                  : '',
              },
            },
            price: action.payload.paymentDetails ? action.payload.paymentDetails.price : '',
            currency: action.payload.paymentDetails ? action.payload.paymentDetails.currency : '',
            familyMembership: {
              ...state.client.payment.familyMembership,
              allowFamilyMembership:
                action.payload.paymentDetails.familyMembership.allowFamilyMembership,
              totalFamilyMembers: action.payload.paymentDetails.familyMembership.totalFamilyMembers,
              price: {
                oneTime: action.payload.paymentDetails.familyMembership.oneTime || '',
                monthly: action.payload.paymentDetails.familyMembership.monthly || '',
                yearly: action.payload.paymentDetails.familyMembership.yearly || '',
              },
            },
            trialPeriod: action.payload.paymentDetails
              ? action.payload.paymentDetails.trialPeriod
              : 0,
          },
          allowTrialPeriod: action.payload.paymentDetails.trialPeriod ? true : false,
          stripeKeys: {
            publicKey: '',
            appSecretKey: '',
          },
          hasDefaultStripeKeys: action.payload.hasDefaultStripeKeys,
          hasStripeKeys: action.payload.hasStripeKeys ? true : false,
        },
        isLoading: false,
      };

    case actions.UPDATE_FAMILY_MEMBERSHIP_ON_INPUT_CHANGE:
      return {
        ...state,
        client: {
          ...state.client,
          payment: {
            ...state.client.payment,
            familyMembership: {
              ...state.client.payment.familyMembership,
              totalFamilyMembers: resetFamilyMembership(action.payload)
                ? ''
                : state.client.payment.familyMembership.totalFamilyMembers,
              price: {
                ...state.client.payment.familyMembership.price,
                oneTime: resetFamilyMembership(action.payload)
                  ? ''
                  : state.client.payment.familyMembership.price.oneTime,
                monthly: resetFamilyMembership(action.payload)
                  ? ''
                  : state.client.payment.familyMembership.price.monthly,
                yearly: resetFamilyMembership(action.payload)
                  ? ''
                  : state.client.payment.familyMembership.price.yearly,
              },
              [action.payload.name]: action.payload.value,
            },
          },
        },
      };

    case actions.UPDATE_COLOR_ON_INPUT_CHANGE:
      return {
        ...state,
        client: {
          ...state.client,
          color: {
            ...state.client.color,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case actions.ON_CHANGE_CLIENT_REGISTER_INPUT:
      return {
        ...state,
        client: {
          ...state.client,
          hideForgotPassword:
            action.payload.name === 'hideForgotPassword'
              ? action.payload.value
              : state.client.hideForgotPassword,
          registrationDetails: {
            ...state.client.registrationDetails,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case actions.RESET_REGISTER_DETAILS:
      return {
        ...state,
        client: {
          ...state.client,
          hideForgotPassword: action.payload.hideForgotPassword
            ? action.payload.hideForgotPassword
            : !!action.payload.hideForgotPassword,
          registrationDetails: {
            ...state.client.registrationDetails,
            allowUserRegistration: action.payload.registrationDetails.allowUserRegistration
              ? action.payload.registrationDetails.allowUserRegistration
              : !!action.payload.registrationDetails.allowUserRegistration,
            memberIdLabel: action.payload.registrationDetails
              ? action.payload.registrationDetails.memberIdLabel
              : '',
            memberIdPlaceholder: action.payload.registrationDetails
              ? action.payload.registrationDetails.memberIdPlaceholder
              : '',
            familySignupMessage: action.payload.registrationDetails.familySignupMessage
              ? action.payload.registrationDetails.familySignupMessage
              : '',
            signUpMessage: action.payload.registrationDetails.signUpMessage
              ? action.payload.registrationDetails.signUpMessage
              : '',
            familyMemberInviteStep: action.payload.registrationDetails.familyMemberInviteStep
              ? action.payload.registrationDetails.familyMemberInviteStep
              : !!action.payload.registrationDetails.familyMemberInviteStep,
            customSignupMessage: action.payload.registrationDetails.customSignupMessage
              ? action.payload.registrationDetails.customSignupMessage
              : '',
            paymentMessage: action.payload.registrationDetails.paymentMessage
              ? action.payload.registrationDetails.paymentMessage
              : '',
            paymentCancelMessage: action.payload.registrationDetails.paymentCancelMessage
              ? action.payload.registrationDetails.paymentCancelMessage
              : '',
            inviteFamilyAndAddNameMessage: action.payload.registrationDetails
              .inviteFamilyAndAddNameMessage
              ? action.payload.registrationDetails.inviteFamilyAndAddNameMessage
              : '',
            familyAddNameMessage: action.payload.registrationDetails.familyAddNameMessage
              ? action.payload.registrationDetails.familyAddNameMessage
              : '',
            shareLinkMessage: action.payload.registrationDetails.shareLinkMessage
              ? action.payload.registrationDetails.shareLinkMessage
              : '',
            welcomeMessage: action.payload.registrationDetails.welcomeMessage
              ? action.payload.registrationDetails.welcomeMessage
              : '',
            preferenceMessage: action.payload.registrationDetails.preferenceMessage
              ? action.payload.registrationDetails.preferenceMessage
              : '',
          },
        },
      };

    case actions.UPDATE_CLIENT_LOADER:
      return {
        ...state,
        updateClientLoader: !state.updateClientLoader,
      };

    case actions.ON_CHANGE_ANALYTICS_INPUT:
      return {
        ...state,
        client: {
          ...state.client,
          analytics: {
            ...state.client.analytics,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case actions.ON_CHANGE_POLICY_INPUT:
      return {
        ...state,
        client: {
          ...state.client,
          policies: {
            ...state.client.policies,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case actions.ON_CHANGE_RECRUITER_INPUT:
      return {
        ...state,
        client: {
          ...state.client,
          recruiterDetails: {
            ...state.client.recruiterDetails,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case actions.API_CREDENTIALS_CREATED:
      return {
        ...state,
        client: {
          ...state.client,
          hasApiCredentials: true,
        },
      };

    case actions.UPDATE_COURSE_PURCHASE_INSTALLMENT_PLAN:
      return {
        ...state,
        client: {
          ...state.client,
          coursePurchaseInstallmentPlan: {
            ...state.client.coursePurchaseInstallmentPlan,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case actions.UPDATE_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };

    case actions.TOGGLE_SIDEBAR_MODAL:
      return {
        ...state,
        sidebarModal: action.payload,
      };
    default:
      return state;
  }
}
