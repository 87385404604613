import React from 'react';
import { Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

const ClientDropdown = props => {
  return (
    <PerfectScrollbar className="client-dropdown">
      {props.isLoading ? (
        <span>
          <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...
        </span>
      ) : (
        <>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search client by name"
              value={props.searchKey}
              onChange={props.handleSearch}
              onKeyDown={props.handleSearch}
            />
          </div>
          {props.clients.length ? (
            <div className="client-list-dropdown">
              {props.clients.map(client => (
                <div className="list" key={client._id}>
                  <Link
                    to={`/client-settings/${client._id}`}
                    className="active"
                  >
                    <img className="logo" src={client.logo} alt="logo" />
                    <span>{client.name}</span>
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <span>No clients to display</span>
          )}
        </>
      )}
    </PerfectScrollbar>
  );
};
export default ClientDropdown;
