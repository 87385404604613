import Api from 'API';
import {
  TOGGLE_COURSE_FETCH_LOADER,
  GET_CLIENT_COURSES,
  CLONE_LOADING,
  TOGGLE_COURSES_MODAL_TO_CLONE,
  TOGGLE_SIDEBAR_MODAL,
} from './types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
toast.configure();

export const fetchClientCourses = payload => dispatch => {
  dispatch({ type: TOGGLE_COURSE_FETCH_LOADER });

  new Api()
    .fetchClientCourses(payload)
    .then(result => {
      return dispatch({
        type: GET_CLIENT_COURSES,
        payload: result.data,
      });
    })
    .catch(err => {
      const data = {
        totalCourses: 0,
        courses: [],
      };
      dispatch({
        type: GET_CLIENT_COURSES,
        payload: data,
      });
    });
};

export const onSubmitCourseClone = payload => dispatch => {
  dispatch({ type: CLONE_LOADING });

  new Api()
    .courseClone(payload)
    .then(() => {
      dispatch({ type: CLONE_LOADING });
      dispatch({ type: TOGGLE_COURSES_MODAL_TO_CLONE });
      dispatch({ type: TOGGLE_SIDEBAR_MODAL, payload: '' });

      toast.success('Clonning in progress. You will get an email after the completion', {
        autoClose: false,
      });
    })
    .catch(err => {
      dispatch({ type: CLONE_LOADING });

      const error = err.response?.data?.message;
      toast.error(error);
    });
};
