import React from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

const Filter = props => {
  const {
    handleFilter,
    handleFilterChange,
    handleDateChange,
    logsComponent,
    searchComponent,
  } = props;

  return (
    <Form
      inline
      className="log-form mt-2 mb-4"
      onSubmit={e => e.preventDefault()}
    >
      <Row form className="width-100">
        <Col md={2}>
          <FormGroup>
            <Input
              type="text"
              placeholder="Type userID"
              name="searchUserId"
              id="searchUserId"
              required
              onChange={handleFilterChange}
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <span className="sm-selectWrap">
              <select
                className="custom-select"
                type="select"
                name="searchComponent"
                id="searchComponent"
                onChange={handleFilterChange}
              >
                <option value="">Select component</option>
                {logsComponent.map(component => (
                  <option value={component.name} key={component.name}>
                    {component.name}
                  </option>
                ))}
              </select>
            </span>
          </FormGroup>
        </Col>

        <Col md={2}>
          <FormGroup>
            <span className="sm-selectWrap">
              <select
                className="custom-select sm-select"
                type="select"
                name="searchSubComponent"
                id="searchSubComponent"
                onChange={handleFilterChange}
              >
                <option value="">Select operation</option>
                {logsComponent.map((component, i) => {
                  if (component.name === searchComponent) {
                    return component.subComponent.map(sub => (
                      <option value={sub.name} key={sub.name}>
                        {sub.name}
                      </option>
                    ));
                  }
                  return i;
                })}
              </select>
            </span>
          </FormGroup>
        </Col>

        <Col md={2}>
          <FormGroup>
            <DateTimePicker
              defaultValue="From Date"
              className="date"
              closeOnSelect={true}
              onChange={moment => handleDateChange(moment, 'searchgte')}
            />
          </FormGroup>
        </Col>

        <Col md={2}>
          <FormGroup>
            <DateTimePicker
              defaultValue="To Date"
              className="date"
              closeOnSelect={true}
              onChange={moment => handleDateChange(moment, 'searchlte')}
            />
          </FormGroup>
        </Col>
        <FormGroup>
          <Button
            id="search"
            className="btn btn-add"
            onClick={e => {
              handleFilter(e);
            }}
          >
            <i className="icon icon-search"></i> Filter
          </Button>
        </FormGroup>
      </Row>
    </Form>
  );
};

export default Filter;
