import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, FormFeedback, Tooltip } from 'reactstrap';

import SSO from 'components/SSO';
import Policy from 'components/Policy';
import Payment from 'components/Payment';
import Analytics from 'components/Analytics';
import * as constants from 'common/constants';
import ClientModal from 'components/ClientModal';
import EmailTemplate from 'components/EmailTemplate';
import CoursesToClone from 'components/CoursesToClone';
import * as clientActions from 'actions/clientActions';
import ApiCredentials from 'components/ApiCredentials';
import * as uploadActions from 'actions/uploadActions';
import { capitalizeWord, clientUrl } from 'utils/helper';
import Gamification from 'components/Gamification';
import Activities from 'components/Gamification/Activities';
import RecruiterSettings from 'components/RecruiterSettings';
import ClientSettingsSidebar from 'components/ClientSettingsSidebar';
import ClientRegisterSettings from 'components/ClientRegisterSettings';
import CustomOnOffCheckbox from 'components/ClientSettingsSidebar/customOnOffCheckbox';

toast.configure();

class ClientsSettings extends Component {
  state = {
    uploadTargetName: '',
    isInvalid: false,
    clientId: '',
    videoUploadErrorMessage: '',
    imageExtensionError: false,
    showDropDown: false,
    faviconDimensionError: false,
    cacheData: {},
    searchKey: '',
    cacheMenu: [],
    hideMenu: false,
    hideOnOffMenu: false,
    brandingVideoToolTip: false,
    faviconToolTip: false,
    // cacheEmailTemplate: {},
    isMenuContentEmpty: false,

    // credit error values
    totalCreditError: false,
    totalAssignedCreditError: false,
    isMonetaryMappingInValid: false,

    //mobile banner images
    primaryMobileBanner: null,
    secondaryMobileBanner: null,
    primaryMobileTooltip: false,
    secondaryMobileTooltip: false,

    //password to generate pin
    password: '',
  };

  componentDidMount() {
    this.setState({ clientId: this.props.match.params.id }, () => {
      this.props.getClient(this.state.clientId);
    });
  }

  componentWillUnmount() {
    this.props.clearClient();
    toast.dismiss();
  }

  componentDidUpdate(prevProps) {
    const { name, menu } = this.props.clients.client;

    if (prevProps.clients.client.name !== name) {
      this.setState({
        cacheData: this.props.clients.client,
        cacheMenu: JSON.parse(JSON.stringify(menu)),
        // cacheEmailTemplate: JSON.parse(JSON.stringify(this.props.clients.emailTemplate)),
      });
    }

    const { url, name: imageName } = this.props.upload;
    if (prevProps.upload.url !== url || prevProps.upload.name !== imageName) {
      this.props.updateClientOnInputChange({ name: imageName, value: url });
    }
  }

  handleDropDown = () => {
    if (!this.state.showDropDown) {
      if (!this.props.clients.allActiveClients.length) {
        this.fetchClients();
      }

      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      showDropDown: !prevState.showDropDown,
    }));
  };

  handleOutsideClick = e => {
    // ignore clicks on the component itself
    const { node } = this.props.clients;
    if (node && node.contains(e.target)) {
      return;
    }

    this.handleDropDown();
  };

  fetchClients = () => {
    const payload = {
      onlyActive: true,
      searchKey: this.state.searchKey,
    };

    this.props.getClients(payload);
  };

  handleChangeCreditMonetaryMappingError = value => {
    this.setState({ isMonetaryMappingInValid: value });
  };

  handleChangeForCredit = e => {
    console.log(e.target.value, e.target.name, this.props?.clients?.client?.credit?.isPrivate);

    switch (e.target.name) {
      case 'credit-name':
        this.props.handleChangeCreditPaymentSettings({
          name: 'name',
          value: e.target.value,
        });
        break;
      case 'totalAssignedBalance':
        if (Number(e.target.value) < 1) {
          this.setState({ totalCreditError: true });
        } else {
          this.setState({ totalCreditError: false });
        }
        this.props.handleChangeCreditPaymentSettings({
          name: 'totalAssignedBalance',
          value: Number(e.target.value),
        });
        break;
      case 'isPrivate':
        this.props.handleChangeCreditPaymentSettings({
          name: 'isPrivate',
          value: !this.props?.clients?.client?.credit?.isPrivate,
        });
        break;
      case 'automaticAssignment':
        this.props.handleChangeAutomaticAssignmentCredit({
          name: 'isEnabled',
          value: !this.props?.clients?.client?.credit?.automaticAssignment?.isEnabled,
        });
        break;
      case 'isAppliedToExistingLearner':
        this.props.handleChangeCreditPaymentSettings({
          name: 'isAppliedToExistingLearner',
          value: !this.props?.clients?.client?.credit?.isAppliedToExistingLearner,
        });
        break;
      case 'isActive':
        this.props.handleChangeCreditPaymentSettings({
          name: 'isActive',
          value: !this.props?.clients?.client?.credit?.isActive,
        });
        break;
      case 'totalAssignedAutomatic':
        if (Number(e.target.value) < 1) {
          this.setState({ totalAssignedCreditError: true });
        } else {
          this.setState({ totalAssignedCreditError: false });
        }
        this.props.handleChangeAutomaticAssignmentCredit({
          name: 'assignedBalance',
          value: Number(e.target.value),
        });
        break;
      case 'isAutomaticAssignmentAllowedToAdmin':
        this.props.handleChangeAutomaticAssignmentCredit({
          name: 'isAutomaticAssignmentAllowedToAdmin',
          value: !this.props?.clients?.client?.credit?.automaticAssignment
            ?.isAutomaticAssignmentAllowedToAdmin,
        });
        break;
      case 'monetaryValueCreditValue':
        this.props.handleChangeMontaryValueMapping({
          name: 'creditValue',
          value: Number(e.target.value),
        });
        break;
      case 'monetaryMapping':
        this.props.handleChangeCreditPaymentSettings({
          name: 'mapping',
          value: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  handleInput = e => {
    this.props.updateClientOnInputChange({
      name: e.target.name,
      value: e.target.value,
    });
  };

  imageDimension = file => {
    const reader = new FileReader();

    //Read the contents of Image File.
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = function(e) {
        //Initiate the JavaScript Image object.
        const image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;
        image.onload = function() {
          //Determine the Height and Width.
          const height = this.height;
          const width = this.width;
          console.log('Hight', height);
          console.log('width', width);

          resolve([height, width]);
        };
      };
    });
  };

  handleImageUpload = async e => {
    e.persist();
    this.setState({
      uploadTargetName: e.target.name,
      imageExtensionError: false,
      faviconDimensionError: false,
    });

    const imageName = e.target.files[0].name;
    const lastDotIndex = imageName.lastIndexOf('.');
    const imageExtension = imageName.slice(lastDotIndex + 1, imageName.length);

    if (
      imageExtension === 'jpg' ||
      imageExtension === 'jpeg' ||
      imageExtension === 'png' ||
      imageExtension === 'ico'
    ) {
      if (e.target.name === 'favicon') {
        const [height, width] = await this.imageDimension(e.target.files[0]);

        if (height > 64 || width > 64) {
          return this.setState({
            faviconDimensionError: true,
          });
        }
      }

      this.props.uploadImage(e.target.files[0], e.target.name);
    } else {
      this.setState({ imageExtensionError: true });
    }
  };

  handleVideoUpload = async event => {
    this.setState({
      videoUploadErrorMessage: '',
      uploadTargetName: event.target.name,
    });
    const fileInBytes = event.target.files[0] && event.target.files[0].size;
    const fileInKB = fileInBytes / 1000;
    const fileInMB = fileInKB / 1000;

    if (fileInMB > 512) {
      return this.setState({
        videoUploadErrorMessage: 'Video size must be less than 512 mb.',
      });
    }

    this.props.uploadVideo(event.target.files[0], event.target.name);
  };

  handleMenuInput = (e, id, url, menuId) => {
    if (!e.target.value) {
      this.setState({ isMenuContentEmpty: true });
    } else {
      this.setState({ isMenuContentEmpty: false });
    }

    const { menu: menuFromStore } = this.props.clients.client;
    menuFromStore.map((menuContent, index) => {
      let menu = [...menuFromStore];
      if (menuContent._id === id) {
        let name = e.target.value;
        // let splitName = name
        //   .toLowerCase()
        //   .trim()
        //   .split(' ');
        // let joinNameWithHyphen = splitName.join('-');
        // let url = `/${joinNameWithHyphen}`;
        // let item = { ...menu[index], name, url };

        let item = { ...menu[index], name };

        menu[index] = item;
        return this.props.updateClientOnInputChange({
          name: 'menu',
          value: menu,
        });
      }

      if (menuId && menuContent._id === menuId) {
        menuContent.subMenu.map((sub, i) => {
          if ((id && sub._id === id) || sub.url === url) {
            let subMenu = [...menuContent.subMenu];
            let name = e.target.value;
            // let splitName = name
            //   .toLowerCase()
            //   .trim()
            //   .split(' ');
            // let joinNameWithHyphen = splitName.join('-');
            // let url = `/${joinNameWithHyphen}`;
            // let subItem = { ...subMenu[i], name, url };

            let subItem = { ...subMenu[i], name };

            subMenu[i] = subItem;
            menu[index].subMenu = subMenu;
            return this.props.updateClientOnInputChange({
              name: 'menu',
              value: menu,
            });
          }
          return i;
        });
      }
      return index;
    });
  };

  handleCheckBox = (e, id, url, menuId) => {
    const { menu: menuFromStore } = this.props.clients.client;

    /**
     * We can replace it with findIndex/find.
     * But at this moment we have just 10/15 data
     * which doesn't change any performace.
     * So we are good to go with map but it's better to do with find
     */
    menuFromStore.map((menuContent, index) => {
      if (url === '/third-party-jobs') {
        const jobs = menuFromStore.find(m => m.url === '/jobs');

        if (!jobs.isActive) return null;
      }

      let menu = [...menuFromStore];
      if (menuContent.isOnOff && menuContent._id === id) {
        let isActive = e.target.checked;
        let item = { ...menu[index], isActive };

        menu[index] = item;

        /* Deactive thrid party jobs if main jobs is deactive */
        if (url === '/jobs' && !e.target.checked) {
          this.toggleThirdPartyJobsForMainJobs({
            menu,
            contentSubMenu: menuContent.subMenu,
            index,
          });
        }
        console.log({ menu });

        return this.props.updateClientOnInputChange({
          name: 'menu',
          value: menu,
        });
      }

      if (menuId && menuContent._id === menuId) {
        menuContent.subMenu.map((sub, i) => {
          if ((id && sub._id === id) || sub.url === url) {
            let subMenu = [...menuContent.subMenu];
            let isActive = e.target.checked;
            let subItem = { ...subMenu[i], isActive };

            subMenu[i] = subItem;
            menu[index].subMenu = subMenu;
            return this.props.updateClientOnInputChange({
              name: 'menu',
              value: menu,
            });
          }
          return i;
        });
      }
      return index;
    });
  };

  toggleThirdPartyJobsForMainJobs = ({ menu, contentSubMenu, index }) => {
    let subMenu = { ...contentSubMenu[0], isActive: false };

    menu[index].subMenu = [subMenu];
  };

  handleSave = () => {
    const {
      logo,
      backgroundImage,
      backgroundImageOpacity,
      color,
      favicon,
      brandingVideo,
      menu,
      allowLearnerCommunityForum,
      allowAdminCommunityForum,
      enableAdvertisement,
      primaryMobileBanner,
      secondaryMobileBanner,
    } = this.props.clients.client;

    if (!logo || this.state.isMenuContentEmpty) {
      return this.setState({ isInvalid: true });
    }

    let payload = {
      logo,
      backgroundImage,
      backgroundImageOpacity,
      color,
      favicon,
      brandingVideo,
      menu,
      clientId: this.state.clientId,
      allowLearnerCommunityForum,
      allowAdminCommunityForum,
      enableAdvertisement,
      primaryMobileBanner: primaryMobileBanner,
      secondaryMobileBanner: secondaryMobileBanner,
    };

    this.props.updateClientOnSave(payload, this.props.history);
  };

  onChangePassword = e => {
    this.setState({ password: e.target.value });
  };

  onGenerateNewPin = () => {
    if (!this.state.password) {
      return;
    }

    let payload = {
      clientId: this.state.clientId,
      password: this.state.password,
    };

    console.log(payload, 'payload');

    this.props.generateNewPin(payload, this.props.history);
  };

  // url is used coz newly created client submenu doesn't has _id
  showMenuContent = (id, name, url, style, isEditable, menuId) => {
    return (
      <FormGroup key={id || url} style={style}>
        <Input
          type="text"
          name={name}
          value={name}
          disabled={!isEditable}
          onChange={e => this.handleMenuInput(e, id, url, menuId)}
          invalid={!name}
        />
        <FormFeedback>Field is required</FormFeedback>
      </FormGroup>
    );
  };

  showOnOffContent = (id, name, url, isActive, menuId) => {
    return (
      <FormGroup key={id || url}>
        <label>{name}</label>
        <div className="checkbox float-right">
          <input
            type="checkbox"
            name={name}
            checked={isActive}
            onChange={e => this.handleCheckBox(e, id, url, menuId)}
          />
          <label></label>
        </div>
      </FormGroup>
    );
  };

  handleReset = () => {
    const payload = {
      ...this.state.cacheData,
      paymentDetails: this.state.cacheData.payment,
      hotjar: {
        hjid: this.state.cacheData.analytics.hjid,
        hjsv: this.state.cacheData.analytics.hjsv,
      },
      gaTrackingId: this.state.cacheData.analytics.gaTrackingId,
      fbPixelCode: this.state.cacheData.analytics.fbPixelCode,
      menu: JSON.parse(JSON.stringify(this.state.cacheMenu)),
      // emailTemplate: JSON.parse(JSON.stringify(this.state.cacheEmailTemplate)),
    };

    this.props.updateClientOnReset(payload);
  };

  handleSearch = e => {
    const eventKey = e.key;
    this.setState({ searchKey: e.target.value }, () => {
      if (eventKey === 'Enter') {
        this.fetchClients();
      }
    });
  };

  toggleMenu = name => {
    this.setState(prevState => ({
      [name]: !prevState[name],
    }));
  };

  toggle = e => {
    this.setState(prevState => ({
      [`${e.target.id}ToolTip`]: !prevState[`${e.target.id}ToolTip`],
    }));
  };

  handleUploadClick = id => {
    document.getElementById(id).click();
  };

  // onTemplateInputChange = e => {
  //   const { emailTemplate } = this.props.clients;
  //   emailTemplate[e.target.name] = e.target.value;

  //   this.props.updateTemplateOnInputChange(emailTemplate);
  // };

  // onTemplateUrlChange = e => {
  //   const { emailTemplate } = this.props.clients;
  //   emailTemplate[e.target.name].url = e.target.value;

  //   this.props.updateTemplateOnInputChange(emailTemplate);
  // };

  // onTemplateOnOffChange = e => {
  //   const { emailTemplate } = this.props.clients;
  //   emailTemplate[e.target.name].isOn = e.target.checked;

  //   this.props.updateTemplateOnInputChange(emailTemplate);
  // };

  // handleTemplateSave = () => {
  //   const { emailTemplate } = this.props.clients;

  //   const payload = {
  //     emailTemplate,
  //     clientId: this.state.clientId,
  //   };

  //   this.props.updateClientOnSave(payload, this.props.history);
  // };

  getMembershipOptions = e => {
    var charCode = e.which ? e.which : e.keyCode;
    if (
      (e.target.name === 'price' &&
        charCode > 31 &&
        charCode !== 46 &&
        (charCode < 48 || charCode > 57)) ||
      e.target.value < 0
    ) {
      return;
    }

    this.props.updatePaymentOnInputChange({
      name: e.target.name,
      value: e.target.value,
    });

    if (e.target.value === 'free') {
      this.props.updateFamilyMembershipOnInputChange({
        name: 'allowFamilyMembership',
        value: false,
      });
    }
  };

  capitalize = (membership, subscription) => {
    if (membership === 'oneTime') {
      return 'One Time';
    }

    if (subscription) {
      return capitalizeWord(subscription);
    }
  };

  handlestripeKeys = e => {
    this.props.updatestripeKeysOnInputChange({
      name: e.target.name,
      value: e.target.value,
    });
  };

  handleStripeCheckbox = e => {
    this.props.updateClientOnInputChange({
      name: 'stripeKeys',
      value: { publicKey: '', secretKey: '' },
    });

    this.props.updateClientOnInputChange({
      name: e.target.name,
      value: e.target.checked,
    });
  };

  handleMembershipChargeReset = () => {
    const payload = {
      ...this.state.cacheData,
      paymentDetails: this.state.cacheData.payment,
    };

    this.props.resetMembershipcharge(payload);
  };

  handlePaymentCreditSystemSave = () => {
    const { credit } = this.props.clients.client;

    let { totalCreditError, totalAssignedCreditError, isMonetaryMappingInValid } = this.state;
    if (totalCreditError || totalAssignedCreditError || isMonetaryMappingInValid) {
      return;
    }
    let { totalAssignedBalance, automaticAssignment, monetaryMapping } = credit;
    if (totalAssignedBalance < 0) {
      this.setState({ totalCreditsAssigned: true });
      return;
    }
    if (automaticAssignment.assignedBalance < 0) {
      this.setState({ totalAssignedCreditError: true });
      return;
    }
    let isInvalid = monetaryMapping.mapping.filter(ite => ite.monetaryValue < 1);

    console.log(isInvalid, 'isInvalid');
    if (Array.isArray(isInvalid) && isInvalid.length) {
      this.setState({ isMonetaryMappingInValid: true });
      return;
    }

    let payload = {
      ...credit,
      clientId: this.state.clientId,
    };

    this.props.updateCreditOnSave(payload, this.props.history);
  };

  handleMembershipChargeSave = () => {
    const {
      payment,
      allowB2CSellingOfCourse,
      canMemberRegisterWithoutPayment,
      coursePurchaseInstallmentPlan,
      stripeKeys,
      hasDefaultStripeKeys,
      hasStripeKeys,
      skillsHubSectionToPublic,
    } = this.props.clients.client;

    const { price, familyMembership, trialPeriod } = payment;
    const {
      allowAutomaticInstallmentPayment,
      allowManualInstallmentPayment,
    } = coursePurchaseInstallmentPlan;

    if (
      (price && price < 1) ||
      (familyMembership.price && familyMembership.price < 1) ||
      (trialPeriod && trialPeriod < 1)
    ) {
      return;
    }

    if (
      (stripeKeys.publicKey && stripeKeys.secretKey && hasDefaultStripeKeys) ||
      (!stripeKeys.publicKey && !stripeKeys.secretKey && !hasDefaultStripeKeys && !hasStripeKeys)
    ) {
      return toast.error(
        'Please use either default or custom stripe keys. Use checkbox for default.',
        {
          autoClose: 7000,
        }
      );
    }

    if (!allowAutomaticInstallmentPayment && !allowManualInstallmentPayment) {
      return toast.error('Atleast one of the manual and automatic payment should be checked', {
        autoClose: 7000,
      });
    }

    let payload = {
      payment,
      allowB2CSellingOfCourse,
      coursePurchaseInstallmentPlan,
      clientId: this.state.clientId,
      hasDefaultStripeKeys,
      stripeKeys,
      hasStripeKeys,
      skillsHubSectionToPublic,
    };

    if (
      payment.membership !== 'free' &&
      (!allowB2CSellingOfCourse || !canMemberRegisterWithoutPayment)
    ) {
      payload = { ...payload, skillsHubSectionToPublic: false };
    }

    if (allowB2CSellingOfCourse && payment.membership !== 'free') {
      payload = { ...payload, canMemberRegisterWithoutPayment };
    }

    this.props.updateMembershipChargeOnSave(payload, this.props.history);
  };

  handleFamilyMembershipCheckbox = e => {
    this.props.updateFamilyMembershipOnInputChange({
      name: e.target.name,
      value: e.target.checked,
    });
  };

  handleFamilyMembership = e => {
    this.props.updateFamilyMembershipOnInputChange({
      name: e.target.name,
      value: e.target.value,
    });
  };

  handleColorInput = e => {
    this.props.updateColorOnInputChange({
      name: e.target.name,
      value: e.target.value,
    });
  };

  handleTrialPeriodCheckbox = e => {
    if (!e.target.checked) {
      this.props.updatePaymentOnInputChange({
        name: 'trialPeriod',
        value: 0,
      });
    }

    this.props.updateClientOnInputChange({
      name: e.target.name,
      value: e.target.checked,
    });
  };

  handleCheckbox = e => {
    const { name, checked } = e.target;
    this.props.updateClientOnInputChange({
      name,
      value: checked,
    });
  };

  handleCoursePurchaseInstallmentPlanCheckbox = e => {
    const { name, checked } = e.target;
    this.props.updateCoursePurchaseInstallmentPlan({
      name,
      value: checked,
    });
  };

  render() {
    const {
      clientId,
      isInvalid,
      videoUploadErrorMessage,
      uploadTargetName,
      imageExtensionError,
      faviconDimensionError,
      searchKey,
      hideMenu,
      hideOnOffMenu,
    } = this.state;

    const { isLoading, client, sidebarModal } = this.props.clients;
    const { isUploading } = this.props.upload;

    const {
      name,
      logo,
      backgroundImage,
      color,
      favicon,
      brandingVideo,
      menu,
      subdomain,
      payment,
      allowB2CSellingOfCourse,
      canMemberRegisterWithoutPayment,
      coursePurchaseInstallmentPlan,
      stripeKeys,
      hasStripeKeys,
      hasDefaultStripeKeys,
      allowTrialPeriod,
      backgroundImageOpacity,
      credit,
      allData,
      primaryMobileBanner,
      secondaryMobileBanner,
    } = client;

    const {
      primaryBrandingColor,
      secondaryBrandingColor,
      primaryIconColor,
      secondaryIconColor,
    } = color;

    const dropDownArrow = 'icon icon-down-arrow haslink';

    if (
      typeof this.props.error === 'string' &&
      this.props.error.toLowerCase().includes('invalid')
    ) {
      return (
        <div className="content client-settings-container">
          <h4 className="mt-5">Client Doesn't exists</h4>
        </div>
      );
    }

    return (
      <>
        <div className="content client-settings-container">
          {sidebarModal === constants.CREATE_EDIT_CLIENT ? (
            <ClientModal clientId={clientId} />
          ) : null}
          {isLoading ? (
            <span
              style={{
                width: '100%',
                maxWidth: '100px',
                maxHeight: '30px',
                padding: '15px',
                margin: 'auto',
              }}
            >
              <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading...
            </span>
          ) : (
            <>
              <ClientSettingsSidebar
                handleCreateClientAdminModal={this.handleCreateClientAdminModal}
                handleDropDown={this.handleDropDown}
                showDropDown={this.state.showDropDown}
                searchKey={searchKey}
                handleSearch={this.handleSearch}
                name={name}
                logo={logo}
                subdomain={subdomain}
                clientId={clientId}
              />
              {sidebarModal === constants.GAMIFICATION ? <Gamification /> : null}

              {/* {sidebarModal === constants.ACTIVITIES ? <Activities /> : null} */}
              {sidebarModal === constants.API_CREDENTIALS ? <ApiCredentials /> : null}
              {sidebarModal === constants.SSO ? <SSO /> : null}

              {sidebarModal === constants.PAYMENT_SETTINGS ? (
                <Payment
                  payment={payment}
                  credit={credit}
                  hasDefaultStripeKeys={hasDefaultStripeKeys}
                  hasStripeKeys={hasStripeKeys}
                  stripeKeys={stripeKeys}
                  name={name}
                  onChangePassword={this.onChangePassword}
                  password={this.state.password}
                  onGenerateNewPin={this.onGenerateNewPin}
                  totalCreditError={this.state.totalCreditError}
                  totalAssignedCreditError={this.state.totalAssignedCreditError}
                  handleChangeCreditMonetaryMappingError={
                    this.handleChangeCreditMonetaryMappingError
                  }
                  handleChangeForCredit={this.handleChangeForCredit}
                  capitalize={this.capitalize}
                  getMembershipOptions={this.getMembershipOptions}
                  handleStripeCheckbox={this.handleStripeCheckbox}
                  handlestripeKeys={this.handlestripeKeys}
                  handleMembershipChargeReset={this.handleMembershipChargeReset}
                  handleMembershipChargeSave={this.handleMembershipChargeSave}
                  handleFamilyMembershipCheckbox={this.handleFamilyMembershipCheckbox}
                  handlePaymentCreditSystemSave={this.handlePaymentCreditSystemSave}
                  handleFamilyMembership={this.handleFamilyMembership}
                  allowTrialPeriod={allowTrialPeriod}
                  handleTrialPeriodCheckbox={this.handleTrialPeriodCheckbox}
                  allowB2CSellingOfCourse={allowB2CSellingOfCourse}
                  canMemberRegisterWithoutPayment={canMemberRegisterWithoutPayment}
                  handleB2CSellingCheckbox={this.handleCheckbox}
                  coursePurchaseInstallmentPlan={coursePurchaseInstallmentPlan}
                  handleCoursePurchaseInstallmentPlanCheckbox={
                    this.handleCoursePurchaseInstallmentPlanCheckbox
                  }
                />
              ) : null}

              {sidebarModal === constants.EMAIL_TEMPLATE ? (
                <EmailTemplate
                  clientId={clientId}
                  name={name}
                  logo={logo}
                  // emailTemplate={emailTemplate}
                  // onInputChange={this.onTemplateInputChange}
                  // onUrlChange={this.onTemplateUrlChange}
                  // onOnOffChange={this.onTemplateOnOffChange}
                  // handleReset={this.handleReset}
                  // handleSave={this.handleTemplateSave}
                />
              ) : null}

              {sidebarModal === constants.REGISTER_SETTINGS ? (
                <ClientRegisterSettings cacheData={this.state.cacheData} clientId={clientId} />
              ) : null}

              {sidebarModal === constants.CLONE ? <CoursesToClone clientId={clientId} /> : null}

              {sidebarModal === constants.RECURITER_SETTINGS ? (
                <RecruiterSettings clientId={clientId} />
              ) : null}

              {sidebarModal === constants.ANALYTICS_SETTINGS ? (
                <Analytics clientId={clientId} />
              ) : null}

              {sidebarModal === constants.POLICY_SETTINGS ? <Policy clientId={clientId} /> : null}

              {!sidebarModal ? (
                <div className="content client-settings">
                  <div className="title">
                    <span>{name}</span>
                    <h1>Settings</h1>
                  </div>
                  <Row className="copy mb-4 url">
                    <Col md="6">
                      <fieldset>
                        <legend>Registration page URL:</legend>
                        <span
                          id={`${subdomain}_register`}
                          onClick={() => this.props.copyUrl(`${subdomain}_register`)}
                        >
                          {clientUrl(subdomain, 'register')}
                        </span>
                      </fieldset>
                    </Col>
                    <Col md="6">
                      <fieldset>
                        <legend>Login page URL:</legend>
                        <span
                          id={`${subdomain}_login`}
                          onClick={() => this.props.copyUrl(`${subdomain}_login`)}
                        >
                          {clientUrl(subdomain, 'login')}
                        </span>
                      </fieldset>
                    </Col>
                    {allData.guestToken ? (
                      <Col>
                        <fieldset>
                          <legend>Public Skillshub URL:</legend>
                          <span
                            id={`${subdomain}_query_token`}
                            onClick={() => this.props.copyUrl(`${subdomain}_query_token`)}
                          >
                            {clientUrl(subdomain, `n=o&guestToken=${allData.guestToken}`)}
                          </span>
                        </fieldset>
                      </Col>
                    ) : null}
                  </Row>

                  <div className="settings-container">
                    <div className="brandsettings">
                      <fieldset>
                        <legend>Brand Settings</legend>
                        <Form className="bp-form">
                          <Row>
                            <Col xs="6" md="3" className="pr-0">
                              <FormGroup>
                                <Label for="name">
                                  Favicon <i className="icon icon-info" id="favicon"></i>
                                  <Tooltip
                                    placement="right"
                                    isOpen={this.state.faviconToolTip}
                                    target="favicon"
                                    toggle={this.toggle}
                                  >
                                    Favicon dimension must be below 64x64.
                                  </Tooltip>
                                </Label>
                                {isUploading && uploadTargetName === 'favicon' ? (
                                  <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                  <div className="img-uploader favicon">
                                    <Input
                                      type="file"
                                      name="favicon"
                                      id="uploadFavicon"
                                      onChange={this.handleImageUpload}
                                    />
                                    <img
                                      src={favicon ? favicon : '/images/upload.png'}
                                      alt="favicon"
                                    />

                                    <div
                                      className="img-upload-hover"
                                      onClick={() => this.handleUploadClick('uploadFavicon')}
                                    >
                                      <img src="/images/upload.png" alt="upload" />
                                    </div>
                                  </div>
                                )}
                                {faviconDimensionError ? (
                                  <span className="text-danger" style={{ fontSize: '80%' }}>
                                    Favicon dimension must be below 64x64
                                  </span>
                                ) : null}
                              </FormGroup>

                              <FormGroup>
                                <label>Logo</label>
                                {isUploading && uploadTargetName === 'logo' ? (
                                  <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                  <div className="img-uploader">
                                    <Input
                                      type="file"
                                      name="logo"
                                      id="uploadLogo"
                                      onChange={this.handleImageUpload}
                                      invalid={!logo && isInvalid}
                                    />
                                    <FormFeedback>Logo is required</FormFeedback>
                                    <img src={logo} alt="logo" />
                                    <div
                                      className="img-upload-hover"
                                      onClick={() => this.handleUploadClick('uploadLogo')}
                                    >
                                      <img src="/images/upload.png" alt="upload" />
                                    </div>
                                  </div>
                                )}
                                {imageExtensionError && uploadTargetName === 'logo' ? (
                                  <span className="text-danger" style={{ fontSize: '80%' }}>
                                    Image file not supported
                                  </span>
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col xs="6" md="9">
                              <FormGroup>
                                <Row>
                                  <Col xs="7">
                                    <label>Background image</label>
                                    <div>
                                      {isUploading && uploadTargetName === 'backgroundImage' ? (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      ) : (
                                        <div className="custom-inputbox">
                                          <Input
                                            type="file"
                                            name="backgroundImage"
                                            id="uploadbackgroundImage"
                                            onChange={this.handleImageUpload}
                                          />
                                          Upload Image
                                          {/* 
                                    <img
                                      src={
                                        backgroundImage
                                          ? backgroundImage
                                          : '/images/upload.png'
                                      }
                                      alt="backgroundImage"
                                    /> */}
                                          {/* <div
                                      className="img-upload-hover"
                                      onClick={() =>
                                        this.handleUploadClick(
                                          'uploadbackgroundImage'
                                        )
                                      }
                                    >
                                      <img
                                        src="/images/upload.png"
                                        alt="upload"
                                      />
                                    </div> */}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs="5">
                                    <Label for="backgroundImageOpacity">
                                      Opacity ({backgroundImageOpacity}){' '}
                                    </Label>
                                    <div>
                                      <Input
                                        type="range"
                                        min="0"
                                        max="1"
                                        step="0.1"
                                        value={backgroundImageOpacity}
                                        name="backgroundImageOpacity"
                                        id="backgroundImageOpacity"
                                        onChange={this.handleInput}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md="12" className="mt-3">
                                    {imageExtensionError &&
                                    uploadTargetName === 'backgroundImage' ? (
                                      <span className="text-danger" style={{ fontSize: '80%' }}>
                                        Image file not supported
                                      </span>
                                    ) : null}
                                    <div
                                      className="bg-image"
                                      style={{
                                        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, ${backgroundImageOpacity}), rgba(0, 0, 0, ${backgroundImageOpacity})), url(${backgroundImage})`,
                                      }}
                                    ></div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col xs="6" md="6">
                              <FormGroup>
                                <Label for="name">Primary Mobile Banner</Label>
                                <div className="custom-inputbox float-right">
                                  {isUploading && uploadTargetName === 'primaryMobileBanner' ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                  ) : (
                                    <>
                                      <Input
                                        type="file"
                                        name="primaryMobileBanner"
                                        placeholder="Primary Mobile Banner"
                                        onChange={this.handleImageUpload}
                                      />
                                      Upload Image
                                    </>
                                  )}
                                </div>
                              </FormGroup>
                              {primaryMobileBanner ? (
                                <img src={primaryMobileBanner} alt="Primary Mobile Banner" />
                              ) : (
                                <div
                                  className="bg-image"
                                  style={{
                                    backgroundImage:
                                      'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))',
                                  }}
                                ></div>
                              )}

                              {imageExtensionError && uploadTargetName === 'primaryMobileBanner' ? (
                                <span className="text-danger" style={{ fontSize: '80%' }}>
                                  Image file not supported
                                </span>
                              ) : null}
                            </Col>
                            <Col xs="6" md="6">
                              <FormGroup>
                                <Label for="name">Secondary Mobile Banner</Label>
                                <div className="custom-inputbox float-right">
                                  {isUploading && uploadTargetName === 'secondaryMobileBanner' ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                  ) : (
                                    <>
                                      <Input
                                        type="file"
                                        name="secondaryMobileBanner"
                                        placeholder="Secondary Mobile Banner"
                                        onChange={this.handleImageUpload}
                                      />
                                      Upload Image
                                    </>
                                  )}
                                </div>
                              </FormGroup>
                              {secondaryMobileBanner ? (
                                <img
                                  src={secondaryMobileBanner}
                                  alt="Secondary Mobile Banner"
                                ></img>
                              ) : (
                                <div
                                  className="bg-image"
                                  style={{
                                    backgroundImage:
                                      'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))',
                                  }}
                                ></div>
                              )}
                              {imageExtensionError &&
                              uploadTargetName === 'secondaryMobileBanner' ? (
                                <span className="text-danger" style={{ fontSize: '80%' }}>
                                  Image file not supported
                                </span>
                              ) : null}
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col xs="6" md="6">
                              <FormGroup>
                                <Label for="name">Primary color </Label>
                                <div className="customcolorpicker">
                                  <Input
                                    type="color"
                                    name="primaryBrandingColor"
                                    placeholder="primaryBrandingColor"
                                    onChange={this.handleColorInput}
                                    value={primaryBrandingColor}
                                  />
                                  <span
                                    className="color"
                                    style={{ background: primaryBrandingColor }}
                                  />
                                  <span className="colorcode">{primaryBrandingColor}</span>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col xs="6" md="6">
                              <FormGroup>
                                <Label for="name">Secondary color </Label>
                                <div className="customcolorpicker">
                                  <Input
                                    type="color"
                                    name="secondaryBrandingColor"
                                    placeholder="secondaryBrandingColor"
                                    onChange={this.handleColorInput}
                                    value={secondaryBrandingColor}
                                  />
                                  <span
                                    className="color"
                                    style={{
                                      background: secondaryBrandingColor,
                                    }}
                                  />
                                  <span className="colorcode">{secondaryBrandingColor}</span>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" md="6">
                              <FormGroup>
                                <Label for="name">Primary icon color</Label>
                                <div className="customcolorpicker">
                                  <Input
                                    type="color"
                                    name="primaryIconColor"
                                    placeholder="primaryIconColor"
                                    onChange={this.handleColorInput}
                                    value={primaryIconColor}
                                  />
                                  <span
                                    className="color"
                                    style={{ background: primaryIconColor }}
                                  />
                                  <span className="colorcode">{primaryIconColor}</span>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col xs="6" md="6">
                              <FormGroup>
                                <Label for="name">Secondary icon color</Label>
                                <div className="customcolorpicker">
                                  <Input
                                    type="color"
                                    name="secondaryIconColor"
                                    placeholder="secondaryIconColor"
                                    onChange={this.handleColorInput}
                                    value={secondaryIconColor}
                                  />
                                  <span
                                    className="color"
                                    style={{ background: secondaryIconColor }}
                                  />
                                  <span className="colorcode">{secondaryIconColor}</span>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup>
                            <Label for="name">
                              Welcome video <i className="icon icon-info" id="brandingVideo"></i>
                              <Tooltip
                                placement="right"
                                isOpen={this.state.brandingVideoToolTip}
                                target="brandingVideo"
                                toggle={this.toggle}
                              >
                                Copy and paste the src URL of youtube or vimeo embedded video.
                              </Tooltip>
                            </Label>
                            <div className="video-upload">
                              <div className="video-url">
                                <Input
                                  type="text"
                                  name="brandingVideo"
                                  placeholder="Video URL"
                                  className="float-right"
                                  value={brandingVideo}
                                  onChange={this.handleInput}
                                  // invalid={!brandingVideo && isInvalid}
                                />
                                {/* <FormFeedback> */}
                                {/* Branding video is required */}
                                {/* </FormFeedback> */}
                                {videoUploadErrorMessage ? (
                                  <span
                                    className="text-danger"
                                    style={{
                                      fontSize: '80%',
                                      width: '100%',
                                      marginLeft: '0',
                                    }}
                                  >
                                    Video size must be less than 512mb
                                  </span>
                                ) : null}
                              </div>

                              <span>OR</span>
                              <div className="custom-inputbox float-right">
                                <Input
                                  type="file"
                                  name="brandingVideo"
                                  placeholder="brandingVideo"
                                  onChange={this.handleVideoUpload}
                                />
                                Upload video
                              </div>
                              {isUploading && uploadTargetName === 'brandingVideo' ? (
                                <span>
                                  <i className="fa fa-spinner fa-spin"></i>
                                </span>
                              ) : null}
                            </div>
                          </FormGroup>
                          {brandingVideo &&
                          (brandingVideo.includes('youtube') || brandingVideo.includes('vimeo')) ? (
                            <iframe
                              src={brandingVideo}
                              title={`${name} video`}
                              width="100%"
                              height="300"
                            />
                          ) : (
                            <video controls width="100%" height="300">
                              <source src={brandingVideo} type="video/mp4" />
                            </video>
                          )}
                        </Form>
                      </fieldset>
                    </div>
                    <div className="menusettings">
                      <fieldset>
                        <legend>Menu settings</legend>
                        <Form className="bp-form">
                          <h3>
                            Rename menu{' '}
                            <i
                              className={
                                hideMenu ? dropDownArrow : `${dropDownArrow} reverseDropDownArrow`
                              }
                              onClick={() => this.toggleMenu('hideMenu')}
                            />
                          </h3>
                          {!hideMenu
                            ? menu.map(menuContent => (
                                <div key={menuContent._id} className="menu-content">
                                  {this.showMenuContent(
                                    menuContent._id,
                                    menuContent.name,
                                    menuContent.url,
                                    {},
                                    menuContent.isEditable,
                                    ''
                                  )}
                                  <>
                                    {menuContent.subMenu.map(sub =>
                                      this.showMenuContent(
                                        sub._id,
                                        sub.name,
                                        sub.url,
                                        {
                                          paddingLeft: '5%',
                                        },
                                        sub.isEditable,
                                        menuContent._id
                                      )
                                    )}
                                  </>
                                </div>
                              ))
                            : null}
                          <div className="on-off-menu">
                            <h3>
                              On/Off menu{' '}
                              <i
                                className={
                                  hideOnOffMenu
                                    ? dropDownArrow
                                    : `${dropDownArrow} reverseDropDownArrow`
                                }
                                onClick={() => this.toggleMenu('hideOnOffMenu')}
                              />
                            </h3>
                            {!hideOnOffMenu ? (
                              <>
                                {menu.map(menuContent => (
                                  <div key={menuContent._id}>
                                    {menuContent.isOnOff
                                      ? this.showOnOffContent(
                                          menuContent._id,
                                          menuContent.name,
                                          menuContent.url,
                                          menuContent.isActive,
                                          ''
                                        )
                                      : null}
                                    <>
                                      {menuContent.subMenu.length
                                        ? menuContent.subMenu.map(sub =>
                                            sub.isOnOff
                                              ? this.showOnOffContent(
                                                  sub._id,
                                                  sub.name,
                                                  sub.url,
                                                  sub.isActive,
                                                  menuContent._id
                                                )
                                              : null
                                          )
                                        : null}
                                    </>
                                  </div>
                                ))}
                                <CustomOnOffCheckbox
                                  client={client}
                                  handleCheckbox={this.handleCheckbox}
                                />
                              </>
                            ) : null}
                          </div>
                        </Form>
                      </fieldset>
                    </div>
                  </div>
                  <div className="settings-footer">
                    <div className="btn btn-add" onClick={this.handleSave}>
                      Save
                    </div>
                    <div className="btn btn-add" onClick={this.handleReset}>
                      Cancel
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
      </>
    );
  }
}

ClientsSettings.propTypes = {
  getClients: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
  getClient: PropTypes.func.isRequired,
  updateClientOnInputChange: PropTypes.func.isRequired,
  updateClientOnReset: PropTypes.func.isRequired,
  updateClientOnSave: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  uploadVideo: PropTypes.func.isRequired,
  updateTemplateOnInputChange: PropTypes.func.isRequired,
  updatePaymentOnInputChange: PropTypes.func.isRequired,
  updatestripeKeysOnInputChange: PropTypes.func.isRequired,
  copyUrl: PropTypes.func.isRequired,
  clearClient: PropTypes.func.isRequired,
  resetMembershipcharge: PropTypes.func.isRequired,
  updateMembershipChargeOnSave: PropTypes.func.isRequired,
  updateCreditOnSave: PropTypes.func.isRequired,
  updateFamilyMembershipOnInputChange: PropTypes.func.isRequired,
  updateColorOnInputChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  updateCoursePurchaseInstallmentPlan: PropTypes.func.isRequired,
  handleChangeCreditPaymentSettings: PropTypes.func.isRequired,
  handleChangeAutomaticAssignmentCredit: PropTypes.func.isRequired,
  handleChangeMontaryValueMapping: PropTypes.func.isRequired,
  generateNewPin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  clients: state.client,
  upload: state.upload,
  error: state.error,
});

export default connect(mapStateToProps, {
  getClients: clientActions.getClients,
  getClient: clientActions.getClient,
  updateClientOnInputChange: clientActions.updateClientOnInputChange,
  updateClientOnReset: clientActions.updateClientOnReset,
  updateClientOnSave: clientActions.updateClientOnSave,
  uploadImage: uploadActions.uploadImage,
  uploadVideo: uploadActions.uploadVideo,
  updateTemplateOnInputChange: clientActions.updateTemplateOnInputChange,
  updatePaymentOnInputChange: clientActions.updatePaymentOnInputChange,
  updatestripeKeysOnInputChange: clientActions.updatestripeKeysOnInputChange,
  copyUrl: clientActions.copyUrl,
  clearClient: clientActions.clearClient,
  resetMembershipcharge: clientActions.resetMembershipcharge,
  updateMembershipChargeOnSave: clientActions.updateMembershipChargeOnSave,
  updateCreditOnSave: clientActions.updateCreditOnSave,
  updateFamilyMembershipOnInputChange: clientActions.updateFamilyMembershipOnInputChange,
  updateColorOnInputChange: clientActions.updateColorOnInputChange,
  updateCoursePurchaseInstallmentPlan: clientActions.updateCoursePurchaseInstallmentPlan,
  handleChangeCreditPaymentSettings: clientActions.handleChangeCreditPaymentSettings,
  handleChangeMontaryValueMapping: clientActions.handleChangeMontaryValueMapping,
  handleChangeAutomaticAssignmentCredit: clientActions.handleChangeAutomaticAssignmentCredit,
  generateNewPin: clientActions.generateNewPin,
})(ClientsSettings);
