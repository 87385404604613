import React from 'react';
import { Row } from 'reactstrap';

import Title from 'components/Title';
import Doughnut from 'components/DoughnutChart';
import Bar from 'components/BarChart';
import Pie from 'components/PieChart';
import ActiveClients from 'components/ActiveClients';

function Dashboard() {
  return (
    <>
      <div className="content">
        <Title />
        <Row>
          <Doughnut />
          <ActiveClients />
          <Bar />
          <Pie />
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
