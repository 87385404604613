import { GET_ERROR, CLEAR_ERROR } from './types';

export const setError = error => {
  return {
    type: GET_ERROR,
    payload: error,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
    payload: '',
  };
};
