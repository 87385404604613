import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import React, { useState } from 'react';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import Courses from './courses';
import Wrapper from 'common/modalWrapper';
import CloneInstance from './cloneInstance';
import { getClients } from 'actions/clientActions';
import { COURSES, JOBS, REST } from 'common/constants';
import { onSubmitCourseClone } from 'actions/cloneActions';
import { GET_CLIENT_COURSES, TOGGLE_COURSES_MODAL_TO_CLONE } from 'actions/types';

export default function Clone({ clientId }) {
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState('');
  const [cloneFrom, setCloneFrom] = useState('');
  const [cloneFromDomain, setCloneFromDomain] = useState('');
  const [cloneFromName, setCloneFromName] = useState('');
  const [cloneWhat, setCloneWhat] = useState('');
  const [cloneInstance, setCloneCompleteInstance] = useState(false);
  const {
    activeClients,
    isOnlyActiveLoading: isLoading,
    client: { subdomain },
  } = useSelector(state => state.client);
  const { isClonning } = useSelector(state => state.clone);

  const activeFilteredClients = activeClients.filter(c => c._id !== clientId);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = e => {
    setSearchKey(e.target.value);
    if (e.key === 'Enter') {
      const payload = {
        currentPage: 1,
        pageLimit: 5,
        onlyActive: true,
        searchKey,
      };

      dispatch(getClients(payload));
    }
  };

  const handleSelect = client => {
    setCloneFrom(client._id);
    setCloneFromDomain(client.subdomain);
    setCloneFromName(client.name);
    setCloneWhat('');

    const data = {
      totalCourses: 0,
      courses: [],
    };
    dispatch({
      type: GET_CLIENT_COURSES,
      payload: data,
    });
  };

  const closeModal = () => {
    dispatch({ type: TOGGLE_COURSES_MODAL_TO_CLONE });
  };

  const handleCloneWhatChange = value => {
    setCloneWhat(value);
  };

  const handleCheckbox = e => {
    setCloneCompleteInstance(e.target.checked);
  };

  const handleCloneInstance = () => {
    if (isClonning) return;

    const payload = {
      fromSubdomain: cloneFromDomain,
      toSubdomain: subdomain,
      cloneWhat,
      cloneInstance,
    };

    dispatch(onSubmitCourseClone(payload));
  };

  return (
    <Wrapper title="Clone" close={closeModal}>
      <FormGroup check className="mt-2 mb-2 pl-0">
        <span className="pretty p-default">
          <Input
            type="checkbox"
            name="cloneInstance"
            id="cloneInstance"
            checked={cloneInstance}
            onChange={handleCheckbox}
          />
          <span className="state ml-3">
            <Label for="cloneInstance" className="mb-1">
              Clone complete instance <small>(Suitable for new clients)</small>
            </Label>
          </span>
        </span>
      </FormGroup>

      {/* <Row className="mt-3 ml-1">
        <FormGroup check>
          <Input
            bssize="lg"
            type="checkbox"
            name="cloneInstance"
            id="cloneInstance"
            checked={cloneInstance}
            onChange={handleCheckbox}
          />
          <Label for="cloneInstance" check>
            Clone complete instance <small>(Suitable for new clients)</small>
          </Label>
        </FormGroup>
      </Row> */}

      <Row>
        <Col sm="4">
          <UncontrolledDropdown>
            <DropdownToggle caret className="btn-block text-left bg-white text-muted">
              <strong> Clone from {cloneFromName}</strong>
            </DropdownToggle>

            <DropdownMenu>
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search client by name"
                  value={searchKey}
                  onChange={handleSearch}
                  onKeyDown={handleSearch}
                />
              </div>
              {isLoading ? (
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <span>
                    <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                    Loading...
                  </span>
                </div>
              ) : (
                <>
                  {activeFilteredClients.map(client => (
                    <div
                      key={client._id}
                      style={{
                        background: client.subdomain === cloneFromDomain ? '#bfbfbf' : '',
                      }}
                    >
                      <DropdownItem onClick={() => handleSelect(client)}>
                        <span
                          className="haslink d-flex align-items-center"
                          onClick={() => handleSelect(client)}
                        >
                          <img
                            className="logo"
                            src={client.logo}
                            alt="logo"
                            width={36}
                            height={36}
                            style={{
                              borderRadius: '50%',
                              marginRight: '5px',
                            }}
                          />
                          <span>{client.name}</span>
                        </span>
                      </DropdownItem>
                    </div>
                  ))}
                </>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Col sm="4">
          <UncontrolledDropdown>
            <DropdownToggle
              caret
              className="btn-block text-left bg-white text-muted"
              onChange={handleCloneWhatChange}
            >
              <strong> Clone What? {cloneWhat}</strong>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Clone What?</DropdownItem>
              <DropdownItem onClick={() => handleCloneWhatChange(COURSES)} disabled={!cloneFrom}>
                Courses
              </DropdownItem>
              {cloneInstance ? (
                <>
                  <DropdownItem onClick={() => handleCloneWhatChange(JOBS)} disabled={!cloneFrom}>
                    Jobs
                  </DropdownItem>
                  <DropdownItem onClick={() => handleCloneWhatChange(REST)} disabled={!cloneFrom}>
                    All Contents
                  </DropdownItem>
                </>
              ) : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Col sm="4"></Col>
      </Row>

      {cloneFrom && cloneWhat ? (
        <>
          {cloneWhat === COURSES && !cloneInstance ? (
            <Courses
              cloneFrom={cloneFrom}
              cloneFromDomain={cloneFromDomain}
              cloneWhat={cloneWhat}
            />
          ) : null}
          {cloneWhat && cloneInstance ? (
            <CloneInstance
              cloneWhat={cloneWhat}
              isCloning={isClonning}
              handleClone={handleCloneInstance}
            />
          ) : null}
        </>
      ) : null}
    </Wrapper>
  );
}
