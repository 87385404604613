import { getLocalStorage } from 'utils/localStorage';

export const getSingleData = (data, id) => {
  return data.filter(datum => datum._id === id);
};

export const getSingleDataWhenDataFetched = (data, singleData) => {
  if (singleData.length) {
    const hasData = getSingleData(data, singleData[0]._id);

    return hasData.length ? hasData : checkArray(data);
  }

  const decoded = getLocalStorage();
  return getSingleData(data, decoded.data._id);
};

const checkArray = data => (data[0] ? [data[0]] : []);
