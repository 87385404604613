import React from 'react';
import { Modal, Row, Col, ModalHeader } from 'reactstrap';

const showMenu = data => <small>{JSON.stringify(data)}</small>;

const ViewMore = props => {
  return (
    <Modal className="log-details" isOpen={props.showViewMoreModal} toggle={props.toggleViewMore}>
      <ModalHeader className="mb-3">
        Client Name: {props.data.data.name ? props.data.data.name : 'N/A'}{' '}
      </ModalHeader>
      {props.data.name === 'Updated' ? (
        <Row>
          <Col xs="6">
            <h5>Old Data</h5>
            {JSON.stringify(props.data.data.oldData)}
            <br />
            {props.data.data.menu &&
              props.data.data.menu.map(menu => (
                <>
                  {showMenu(menu.oldData)}
                  <>
                    {menu.subMenu &&
                      menu.subMenu.map(sub => {
                        {
                          showMenu(sub.oldData);
                        }
                      })}
                  </>
                </>
              ))}
          </Col>
          <Col xs="6">
            <h5>Updated Data</h5>
            {JSON.stringify(props.data.data.updatedData)}
            <br />
            {props.data.data.menu &&
              props.data.data.menu.map(menu => (
                <>
                  {showMenu(menu.updatedData)}
                  <>
                    {menu.subMenu &&
                      menu.subMenu.map(sub => {
                        {
                          showMenu(sub.updatedData);
                        }
                      })}
                  </>
                </>
              ))}
          </Col>
        </Row>
      ) : (
        <p>{JSON.stringify(props.data.data)}</p>
      )}
    </Modal>
  );
};

export default ViewMore;
