import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { onSubmitCourseClone } from 'actions/cloneActions';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { COURSES } from 'common/constants';

export default function CoursesToClone({ cloneFromDomain, isOpen, handleCloneAll, cloneWhat }) {
  const dispatch = useDispatch();
  const {
    client: { subdomain },
  } = useSelector(state => state.client);
  const { isClonning } = useSelector(state => state.clone);

  const cloneAll = () => {
    if (isClonning) return;

    const payload = {
      fromSubdomain: cloneFromDomain,
      toSubdomain: subdomain,
      cloneWhat: COURSES,
    };

    if (cloneWhat === COURSES) {
      dispatch(onSubmitCourseClone(payload));
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCloneAll}>
      <ModalHeader toggle={handleCloneAll}>Please be cautious!</ModalHeader>
      <ModalBody>
        <p>
          This will clone all of the {cloneWhat.toLowerCase()} from{' '}
          <strong>{cloneFromDomain}</strong> to <strong>{subdomain}</strong>.
        </p>
        <p>
          This will take time. So, it's better to select certain amount of courses and clone them
          which is faster and more efficient. :)
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={cloneAll} disabled={isClonning}>
          {isClonning ? 'Clonning...' : 'Clone All'}
        </Button>
        <Button color="danger" onClick={handleCloneAll}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
