import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownItem,
  InputGroup,
  DropdownMenu,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateTimePicker from 'react-datetime';

import { createVoucher, checkVoucherTitle } from 'actions/voucherActions';

class CreateVoucher extends Component {
  errorObj = {};
  state = {
    title: '',
    discount: '',
    maxUsage: '',
    expiresAt: '',
    applicableTo: 'memberSubscription',
    isInvalid: false,
    dropdownOpen: false,
    isPercentage: true,
    isTrialDays: false,
    expiryEnabled: true,
    messageFromAPI: '',
    isSubmitting: false,
    currency: 'usd',
    isCombined: false,
    combinedDiscount: '',
    discountType: 'percentage',
  };

  componentDidMount() {
    this.mounted = true;
    const { currency } = this.props;
    this.setState({ currency });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    // if(this.mounted && (prevState.title !== this.state.title)) {
    //   this.props.checkVoucherTitle({ title: this.state.title })
    //   .then(result => {
    //     if(!this.mounted){ return; }
    //     if(result.success) {
    //       this.setState({ isInvalid: false });
    //       delete this.errorObj['title']
    //     } else if(result.message) {
    //       this.setState({ isInvalid: true });
    //       this.errorObj['title'] = result.message;
    //     }
    //   }).catch(console.log);
    // }
  }

  handleInput = e => {
    const name = e.target.name;
    let value = e.target.value;
    let stateObj = {};
    if (name === 'maxUsage' || (name === 'discount' && this.state.isTrialDays)) {
      if (!/^[0-9]+$|^$/.test(value)) {
        return;
      }
    }
    if (name === 'combinedDiscount' || (name === 'discount' && !this.state.isTrialDays)) {
      if (!/^\d{1,6}(\.)?(\d{1,2})?$|^$/.test(value)) {
        return;
      }
    }
    if (name === 'expiryEnabled') {
      value = value === 'true';
    }
    if (name === 'applicableTo' && value === 'coursePurchase') {
      stateObj = {
        isTrialDays: false,
        isCombined: false,
        discountType: 'percentage',
        isPercentage: true,
      };
    }
    this.setState({ [name]: value, ...stateObj });
  };

  handleDateInput = moment => {
    let now = new Date()
      .toISOString()
      .split('T')
      .shift();
    if (moment.isValid() && (moment.isAfter(now) || moment.isSame(now))) {
      this.setState({ expiresAt: moment.format('YYYY-MM-DD') });
    } else {
      this.setState({ expiresAt: '' });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.isSubmitting) {
      return;
    }

    this.handleCreate();
  };

  toggleDropDown = e => {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
  };

  handleCreate = async () => {
    try {
      let {
        title,
        discount,
        maxUsage,
        expiresAt,
        applicableTo,
        isPercentage,
        isTrialDays,
        expiryEnabled,
        currency,
        combinedDiscount,
        isCombined,
        discountType,
      } = this.state;

      discount = parseFloat(discount);
      combinedDiscount = parseFloat(combinedDiscount);
      maxUsage = parseInt(maxUsage, 10);

      // console.log({ title, discount, maxUsage, expiresAt, applicableTo, isPercentage, expiryEnabled, });
      this.setState({ messageFromAPI: '', isSubmitting: true, isInvalid: false });

      this.errorObj = {};
      if (!title || (title && !title.trim())) {
        this.errorObj['title'] = 'Title is required.';
      }
      if (expiryEnabled && (!expiresAt || (expiresAt && !expiresAt.trim()))) {
        this.errorObj['expiresAt'] = 'Expiry Date is required.';
      }
      if (!applicableTo || (applicableTo && !applicableTo.trim())) {
        this.errorObj['applicableTo'] = 'Applicable To value is required.';
      }
      if (isCombined) {
        if (Number.isNaN(combinedDiscount) || combinedDiscount === 0) {
          this.errorObj['combinedDiscount'] = ['Discount value is required.'];
        }
        if (isPercentage && combinedDiscount > 100) {
          const msg = 'Discount value cannot be greater than 100.';
          if (!this.errorObj['combinedDiscount']) {
            this.errorObj['combinedDiscount'] = [msg];
          } else {
            this.errorObj['combinedDiscount'].push(msg);
          }
        }
      }
      if (Number.isNaN(discount) || discount === 0) {
        const fieldName = isTrialDays ? 'Trial days' : 'Discount';
        this.errorObj['discount'] = [`${fieldName} value is required.`];
      }
      if (isPercentage && discount > 100) {
        const msg = 'Discount value cannot be greater than 100.';
        if (!this.errorObj['discount']) {
          this.errorObj['discount'] = [msg];
        } else {
          this.errorObj['discount'].push(msg);
        }
      }
      if (Number.isNaN(maxUsage)) {
        this.errorObj['maxUsage'] = 'Max. Usage value is required.';
      }

      if (Object.keys(this.errorObj).length) {
        this.setState({ isSubmitting: false, isInvalid: true });
        return;
      }

      const payload = {
        title,
        discount,
        maxUsage,
        expiresAt,
        applicableTo: [applicableTo],
        isPercentage,
        expiryEnabled,
        subdomain: this.props.subdomain,
        isTrialDays,
        discountType,
        combinedDiscount,
      };

      if (
        this.props.membership === 'free' &&
        this.props.clientId &&
        currency !== this.props.currency
      ) {
        payload.update = {
          id: this.props.clientId,
          currency: currency,
        };
      }

      const result = await this.props.createVoucher(payload);
      if (result.success) {
        this.handleModalClose();
        return this.props.fetchVouchers();
      } else {
        this.setState({ messageFromAPI: result.message });
      }
    } catch (err) {}
    this.setState({ isSubmitting: false });
  };

  handleModalClose = () => {
    this.props.toggleCreateVoucherModal();
  };

  handleDropDownClick = e => {
    const { name, value } = e.target;
    const stateObj = { [name]: value, isPercentage: false, isTrialDays: false, isCombined: false };
    switch (value) {
      case 'percentage':
        stateObj.isPercentage = true;
        break;
      case 'trialdays':
        stateObj.isTrialDays = true;
        break;
      case 'percentage_trialdays':
        stateObj.isPercentage = true;
        stateObj.isTrialDays = true;
        stateObj.isCombined = true;
        break;
      case 'amount_trialdays':
        stateObj.isCombined = true;
        stateObj.isTrialDays = true;
        break;
      default:
    }
    this.setState(prevState => {
      if (prevState.discount > 0 && (stateObj.isTrialDays || stateObj.isCombined)) {
        stateObj.discount = parseInt(prevState.discount, 10);
      }
      return { ...stateObj };
    });
  };

  handleUnlimitted = e => {
    const maxUsage = e.target.checked ? 0 : '';
    this.setState({ maxUsage });
  };

  validDate = currentDate => {
    let now = new Date()
      .toISOString()
      .split('T')
      .shift();
    return currentDate.isAfter(now) || currentDate.isSame(now);
  };

  showCurrencySign(currency) {
    if (currency === 'usd') return '$';
    if (currency === 'eur') return '€';
    if (currency === 'gbp') return '£';
    if (currency === 'aud') return 'A$';
    return '$';
  }

  render() {
    const {
      title,
      discount,
      maxUsage,
      expiresAt,
      applicableTo,
      isInvalid,
      dropdownOpen,
      isPercentage,
      isTrialDays,
      expiryEnabled,
      messageFromAPI,
      isSubmitting,
      currency,
      isCombined,
      combinedDiscount,
      discountType,
    } = this.state;

    const { membership } = this.props;

    let currencySymbol = this.showCurrencySign(currency);
    let discountSymbol = isPercentage ? '%' : currencySymbol;

    let discountTypesArray = [
      { value: 'percentage', text: 'Percentage' },
      { value: 'amount', text: 'Amount' },
    ];
    const membershipDiscountTypesArray = [
      { value: 'trialdays', text: 'Trial Days' },
      { value: 'percentage_trialdays', text: 'Percentage + Trial Days' },
      { value: 'amount_trialdays', text: 'Amount + Trial Days' },
    ];

    if (applicableTo === 'memberSubscription') {
      discountTypesArray = [...discountTypesArray, ...membershipDiscountTypesArray];
    }

    return (
      <div className="client-add-edit-modal">
        <div className="modal-header" onClick={this.handleModalClose}>
          <i className="icon icon-cross-out" />
        </div>
        <div className="modal-body">
          <h2>Create Voucher</h2>
          <Form className="bp-form modal-form">
            <FormGroup>
              <Label for="title">
                Title <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="title"
                invalid={isInvalid && 'title' in this.errorObj}
                onChange={this.handleInput}
                value={title}
              />
              <FormFeedback>{'title' in this.errorObj && this.errorObj['title']}</FormFeedback>
            </FormGroup>
            {membership === 'free' && (
              <FormGroup>
                <Label for="currency">Currency</Label>
                <span className="sm-selectWrap">
                  <Input
                    type="select"
                    className="custom-select"
                    name="currency"
                    id="currency"
                    value={currency}
                    onChange={this.handleInput}
                  >
                    <option value="usd">USD - $</option>
                    <option value="eur">EURO - €</option>
                    <option value="gbp">POUND - £</option>
                    <option value="aud">AUD - A$</option>
                  </Input>
                </span>
              </FormGroup>
            )}
            <FormGroup>
              <Label for="applicableTo">
                Applicable To <span className="text-danger">*</span>
              </Label>
              <span className="sm-selectWrap">
                <Input
                  className="custom-select"
                  type="select"
                  name="applicableTo"
                  invalid={isInvalid && 'applicableTo' in this.errorObj}
                  onChange={this.handleInput}
                  value={applicableTo}
                >
                  <option value="memberSubscription">Member Subscription</option>
                  <option value="coursePurchase">Course Purchase</option>
                </Input>
              </span>
              <FormFeedback>
                {'applicableTo' in this.errorObj && this.errorObj['applicableTo']}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="discountType">
                Discount Type <span className="text-danger">*</span>
              </Label>
              <span className="sm-selectWrap">
                <Input
                  className="custom-select"
                  type="select"
                  name="discountType"
                  invalid={isInvalid && 'discountType' in this.errorObj}
                  onChange={this.handleDropDownClick}
                  value={discountType}
                >
                  {discountTypesArray.map((t, i) => (
                    <option key={i} value={t.value}>
                      {t.text}
                    </option>
                  ))}
                </Input>
              </span>
            </FormGroup>
            <FormGroup>
              <Label for="discount">
                {isTrialDays ? 'Trial Days' : 'Discount'} <span className="text-danger">*</span>
              </Label>
              <InputGroup>
                <Input
                  type="text"
                  name="discount"
                  invalid={isInvalid && 'discount' in this.errorObj}
                  onChange={this.handleInput}
                  value={discount}
                />
                {!(isTrialDays || isCombined) && (
                  <InputGroupAddon addonType="append">
                    <InputGroupText>{discountSymbol}</InputGroupText>
                  </InputGroupAddon>
                )}
              </InputGroup>
              <Input type="hidden" invalid={isInvalid && 'discount' in this.errorObj} />
              <FormFeedback>
                {'discount' in this.errorObj && this.errorObj['discount'].join(', ')}
              </FormFeedback>
            </FormGroup>
            {isCombined && (
              <FormGroup>
                <Label for="title">
                  Discount <span className="text-danger">*</span>
                </Label>
                <InputGroup>
                  <Input
                    type="text"
                    name="combinedDiscount"
                    invalid={isInvalid && 'combinedDiscount' in this.errorObj}
                    onChange={this.handleInput}
                    value={combinedDiscount}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>{discountSymbol}</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <Input type="hidden" invalid={isInvalid && 'combinedDiscount' in this.errorObj} />
                <FormFeedback>
                  {'combinedDiscount' in this.errorObj &&
                    this.errorObj['combinedDiscount'].join(', ')}
                </FormFeedback>
              </FormGroup>
            )}
            <FormGroup>
              <Label for="maxUsage">
                Max. number of usages <span className="text-danger">*</span>
              </Label>
              <InputGroup>
                <Input
                  type="text"
                  name="maxUsage"
                  invalid={isInvalid && 'maxUsage' in this.errorObj}
                  onChange={this.handleInput}
                  value={maxUsage}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <Input
                      addon
                      type="checkbox"
                      checked={this.state.maxUsage === 0}
                      onChange={this.handleUnlimitted}
                      aria-label="Checkbox for following text input"
                    />
                    &nbsp;Unlimitted Usage
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <Input type="hidden" invalid={isInvalid && 'maxUsage' in this.errorObj} />
              <FormFeedback>
                {'maxUsage' in this.errorObj && this.errorObj['maxUsage']}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="expiryEnabled">
                Expiry <span className="text-danger">*</span>
              </Label>
              <span className="sm-selectWrap">
                <Input
                  className="custom-select"
                  type="select"
                  name="expiryEnabled"
                  onChange={this.handleInput}
                  value={expiryEnabled}
                >
                  <option value="true">Enabled</option>
                  <option value="false">Never Expires</option>
                </Input>
              </span>
            </FormGroup>
            {expiryEnabled && (
              <FormGroup>
                <Label for="expiresAt">
                  Expiry Date (UTC)<span className="text-danger">*</span>
                </Label>
                <DateTimePicker
                  defaultValue=""
                  className="date"
                  closeOnSelect={true}
                  onChange={this.handleDateInput}
                  value={expiresAt}
                  dateFormat={'YYYY-MM-DD'}
                  timeFormat={false}
                  isValidDate={this.validDate}
                  input={false}
                  open={true}
                  utc={true}
                />
                <Input type="hidden" invalid={isInvalid && 'expiresAt' in this.errorObj} />
                <FormFeedback>
                  {'expiresAt' in this.errorObj && this.errorObj['expiresAt']}
                </FormFeedback>
              </FormGroup>
            )}
            <FormGroup>
              <Input type="hidden" invalid={isInvalid || messageFromAPI !== ''} />
              <FormFeedback>{messageFromAPI}</FormFeedback>
            </FormGroup>
            <Button
              disabled={isSubmitting}
              className="btn-add float-right"
              onClick={this.handleSubmit}
            >
              {isSubmitting ? <i className="fa fa-spinner fa-spin" aria-hidden="true" /> : 'Create'}
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}
CreateVoucher.propTypes = {
  createVoucher: PropTypes.func.isRequired,
  toggleCreateVoucherModal: PropTypes.func.isRequired,
  checkVoucherTitle: PropTypes.func.isRequired,
};

export default connect(null, {
  createVoucher,
  checkVoucherTitle,
})(CreateVoucher);
